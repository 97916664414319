import { Membership } from './membership';

export class AuxiliaryEmail
{
    membershipId: number;
    email: string;

    membership: Membership;

    constructor(auxiliaryEmail: any) {
        if (auxiliaryEmail.membershipId) {
            this.membershipId = auxiliaryEmail.membershipId;
        }
        if (auxiliaryEmail.email) {
            this.email = auxiliaryEmail.email;
        }

        if (auxiliaryEmail.membership) {
            this.membership = new Membership(auxiliaryEmail.membership);
        }
    }
}
