<ng-template #promoBanner let-outline="outline">
    <div class="promo-bar-inline" [class.outline]="outline">
        <i class="fa fa-rocket mr-2"></i>
        <span>{{ 'Limited time offer. 6 months free' }}</span>
    </div>
</ng-template>

<div class="card rounded-xl mx-0">
    <div class="card-body px-0 py-5">
        <div class="grid-layout grid-2 px-4 pr-md-0">
            <div class="px-4">
                <ng-container *ngTemplateOutlet="promoBanner; context: { outline: false }"></ng-container>
                <h2 class="feature-section-header large-font">{{ 'Manage Vendors & Streamline Your Accounts Payable' }}</h2>
                <p class="feature-text mb-2">{{ 'Take control of your association\'s payables with our comprehensive HOA management software. Streamline the entire process by accepting vendor invoices via email to be automatically scanned and uploaded into PayHOA. Then, use workflows to request and automate approvals and set up online payments to vendors to complete the task.' }}</p>
                <p class="feature-price-text font-weight-bold">{{ '$19' }}<small>{{ '/mo' }}</small></p>
                <app-feature-button (onClick)="getStartedEnrollment.emit()">{{ 'Enroll Now' }}</app-feature-button>
                <p class="font-weight-medium mt-3">{{ 'No fee for payments. Cancel anytime.' }}</p>
            </div>
            <div class="px-4 pr-md-0">
                <div class="d-flex mx-auto mt-auto">
                    <img alt="{{ 'Manage Vendors & Streamline Your Accounts Payable' }}" class="img-fluid" src="/assets/images/v2/onboarding/payables/vendors.svg" />
                </div>
            </div>
        </div>

        <hr class="dashed-line" />

        <ul class="grid-layout grid-2 feature-list list-group px-4">
            <li class="d-flex flex-column list-group-item px-5 p-relative border-0">
                <p class="feature-item d-flex flex-nowrap">
                    <mat-icon class="checkmark blue">check_circle</mat-icon>
                    <span>{{ 'Automated Payment Sync' }}</span>
                </p>
                <p class="feature-text mb-0">{{ 'Enjoy automatic synchronization of vendor payments with our HOA accounting software, ensuring real-time financial accuracy.' }}</p>
            </li>

            <li class="d-flex flex-column list-group-item px-5 p-relative border-0">
                <p class="feature-item d-flex flex-nowrap">
                    <mat-icon class="checkmark blue">check_circle</mat-icon>
                    <span>{{ 'Centralized Vendor Document Repository' }}</span>
                </p>
                <p class="feature-text mb-0">{{ 'Store essential documents like invoices, insurance certificates, contracts, and more for each vendor in a centralized repository, consolidating document management.' }}</p>
            </li>

            <li class="d-flex flex-column list-group-item px-5 p-relative border-0">
                <p class="feature-item d-flex flex-nowrap">
                    <mat-icon class="checkmark blue">check_circle</mat-icon>
                    <span>{{ 'Payable Email Notifications' }}</span>
                </p>
                <p class="feature-text mb-0">{{ 'Our payable email notifications are designed to keep you ahead of your payables and ensure nothing falls through the cracks.' }}</p>
            </li>

            <li class="d-flex flex-column list-group-item px-5 p-relative border-0">
                <p class="feature-item d-flex flex-nowrap">
                    <mat-icon class="checkmark blue">check_circle</mat-icon>
                    <span>{{ 'Pay Vendors Online' }}</span>
                </p>
                <p class="feature-text mb-0">{{ 'Put away the checkbook and pay your vendors with just a few clicks.' }}</p>
            </li>
        </ul>
    </div>
</div>

<div class="grid-layout grid-4 full-dialog mx-0">
    <div class="card rounded-xl grid-col-span-2">
        <div class="card-body pb-0">
            <div class="d-flex flex-column h-100 w-100 pt-4 px-4">
                <p class="feature-header">{{ 'Multiple Payment Methods' }}</p>
                <p class="feature-text">{{ 'Payments are automatically sent to vendors based on their preferred payment method. If vendors are outside our network, we’ll handle reaching out to establish their payment preference. If a vendor can’t be reached, a check will automatically be mailed.' }}</p>
                <div class="d-flex mx-auto mt-auto">
                    <img alt="{{ 'Multiple Payment Methods' }}" class="img-fluid" src="/assets/images/v2/onboarding/payables/payment-methods.svg" />
                </div>
            </div>
        </div>
    </div>

    <div class="card rounded-xl grid-col-span-2">
        <div class="card-body pb-0">
            <div class="d-flex flex-column h-100 w-100 pt-4 px-4">
                <p class="feature-header">{{ 'OCR Invoice Reading' }}</p>
                <p class="feature-text">{{ 'Simplify invoice handling with an inbox that utilizes OCR scanning technology. Vendors can email the association invoices, which can be seamlessly scanned into PayHOA for swift approval.' }}</p>
                <div class="d-flex mx-auto mt-auto">
                    <img alt="{{ 'OCR Invoice Reading' }}" class="img-fluid" src="/assets/images/v2/onboarding/payables/ocr.svg" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="grid-layout grid-5 full-dialog mx-0">
    <div class="card rounded-xl grid-col-span-2">
        <div class="card-body pb-0">
            <div class="d-flex flex-column h-100 w-100 pt-4 px-4">
                <p class="feature-header">{{ '1099 Generation & Filing' }}</p>
                <p class="feature-text">{{ 'Generate and file 1099 forms for all your vendors, simplifying tax compliance.' }}</p>
                <div class="d-flex mx-auto mt-auto">
                    <img alt="{{ '1099 Generation & Filing' }}" class="img-fluid" src="/assets/images/v2/onboarding/payables/1099.svg" />
                </div>
            </div>
        </div>
    </div>

    <div class="card rounded-xl grid-col-span-3">
        <div class="card-body pb-0">
            <div class="d-flex flex-column h-100 w-100 pt-4 px-4">
                <p class="feature-header">{{ 'Custom Payment Approval Workflows' }}</p>
                <p class="feature-text">{{ 'Create personalized payment approval workflows based on the vendor and payment amount for tailored control and oversight.' }}</p>
                <div class="d-flex mx-auto mt-auto">
                    <img alt="{{ 'Custom Payment Approval Workflows' }}" class="img-fluid" src="/assets/images/v2/onboarding/payables/approval.svg" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card rounded-xl mt-5 mx-0">
    <div class="card-body">
        <div class="d-flex flex-column align-items-center mt-3 p-4">
            <h2 class="feature-section-header large-font">{{ 'Ready to Get Started?' }}</h2>
            <p class="feature-price-text font-weight-bold">{{ '$19' }}<small>{{ '/mo' }}</small></p>
            <ng-container *ngTemplateOutlet="promoBanner; context: { outline: true }"></ng-container>
            <app-feature-button (onClick)="getStartedEnrollment.emit()">{{ 'Enroll Now' }}</app-feature-button>
            <p class="font-weight-medium mt-3">{{ 'No fee for payments. Cancel anytime.' }}</p>
        </div>
    </div>
</div>

