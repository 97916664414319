<div class="promo-bar" *ngIf="isShowing">
    <i class="fa fa-rocket mr-2"></i>
    <span *ngIf="!pastDeadline">
        Form 1099 filings for Tax Year {{currentYear - 1}} are due by January 31, {{currentYear}}. Click <a [routerLink]="['/app/form-1099']">HERE</a> to e-file through PayHOA.
    </span>
    <span *ngIf="pastDeadline">
       The deadline has passed, but you can still submit a late 1099 filing. Click <a [routerLink]="['/app/form-1099']">HERE</a> to e-file through PayHOA.
    </span>
    <img (click)="close()" class="mat-close" src="/assets/images/payhoa/other/icon_close.svg" alt="close">
</div>
