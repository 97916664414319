import { AppAdminNavigationOrder, AppMobileNavigationOrder, AppNavigationOrder } from '../../enums/app-navigation-order.enum';
import { AppNavigationKeys } from '../../enums/app-navigation-keys.enum';

/** Simple type to define items that show up in e.g. the page-menu component */
export class AppNavItem
{
    id: AppNavigationKeys;
    displayText = '';
    iconString = '';
    routeString = '';
    isActiveRoute = false;
    isVisibleRoute = false;
    externalUrl: string = '';
    order: AppNavigationOrder | AppMobileNavigationOrder | AppAdminNavigationOrder;

    queryParams ?: { [prop: string]: string; } = null;
    hasMarginBelow ? = false;

    constructor(navItem: any) {
        this.id = navItem.id;
        this.displayText = navItem.displayText;
        this.iconString = navItem.iconString;
        this.routeString = navItem.routeString;
        this.queryParams = navItem.queryParams;
        this.isActiveRoute = navItem.isActiveRoute || false;
        this.isVisibleRoute = navItem.isVisibleRoute || false;
        this.order = navItem.order;
        this.hasMarginBelow = navItem.hasMarginBelow;
        this.externalUrl = navItem.externalUrl;
    }
}
