import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { ButtonModule } from '../../../shared/button/button.module';
import { PageAdvertisementComponent } from './page-advertisement.component';

@NgModule({
    imports: [
        SharedModule,
        MatIconModule,
        ButtonModule,
    ],
    exports: [PageAdvertisementComponent],
    declarations: [PageAdvertisementComponent],
    providers: [],
})
export class PageAdvertisementModule
{
}
