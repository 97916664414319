import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';

@Injectable()
export class LoginGuard implements CanActivate
{
    constructor(
        private _router: Router,
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isLoggedIn: boolean = LegFiJwtService.read() !== null;
        if (!isLoggedIn) {
            const loginView = ['/auth/login'];
            const queryParams = {uri: encodeURIComponent(state.url)};
            this._router.navigate(loginView, {queryParams: queryParams});
        } else {
            const jwt = LegFiJwtService.read();
            if (jwt.hasOwnProperty('orgExpired') && jwt.orgExpired && jwt.admin) {
                this._router.navigate(['/app/lock']);
            }
        }

        return isLoggedIn;
    }
}
