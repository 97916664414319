import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncateFilenamePipe, TruncatePipe } from './truncate.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        TruncatePipe,
        TruncateFilenamePipe,
    ],
    exports: [
        TruncatePipe,
        TruncateFilenamePipe,
    ],
})
export class TruncateModule
{
}
