import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import Moment = moment.Moment;
import { Category } from './accounting/category';

export class ChargeLateFee
{
    id: number;
    lateFeeType: string;
    lateAfterDays: number;
    categoryId: number;
    oneTimeLateFeeApplies: Moment;
    oneTimeLateFeeAmount: number;
    oneTimeLateFeeType: string;
    recurringLateFeeBegins: Moment;
    recurringLateFeeInterval: string;
    recurringLateFeeIntervalValue: number;
    recurringLateFeeType: string;
    recurringLateFeeAmount: number;
    applied: boolean = false;

    recurringLateFeeNextApplies: Moment;
    category: Category = null;

    constructor(lateFee: any) {
        const timezone = LegFiJwtService.getTimezone();
        if (lateFee.id) {
            this.id = lateFee.id;
        }
        if (lateFee.lateFeeType) {
            this.lateFeeType = lateFee.lateFeeType;
        }
        if (lateFee.lateAfterDays) {
            this.lateAfterDays = lateFee.lateAfterDays;
        }
        if (lateFee.oneTimeLateFeeApplies) {
            this.oneTimeLateFeeApplies = moment.utc(lateFee.oneTimeLateFeeApplies, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
            if (moment().tz(timezone).isAfter(this.oneTimeLateFeeApplies)) {
                this.applied = true;
            }
        }
        if (lateFee.recurringLateFeeNextApplies) {
            this.recurringLateFeeNextApplies = moment.utc(lateFee.recurringLateFeeNextApplies, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (lateFee.oneTimeLateFeeAmount) {
            this.oneTimeLateFeeAmount = lateFee.oneTimeLateFeeAmount;
        }
        if (lateFee.oneTimeLateFeeType) {
            this.oneTimeLateFeeType = lateFee.oneTimeLateFeeType;
        }
        if (lateFee.recurringLateFeeBegins) {
            this.recurringLateFeeBegins = moment.utc(lateFee.recurringLateFeeBegins, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
            if (moment().tz(timezone).isAfter(this.recurringLateFeeBegins)) {
                this.applied = true;
            }
        }
        if (lateFee.recurringLateFeeInterval) {
            this.recurringLateFeeInterval = lateFee.recurringLateFeeInterval;
        }
        if (lateFee.recurringLateFeeIntervalValue) {
            this.recurringLateFeeIntervalValue = lateFee.recurringLateFeeIntervalValue;
        }
        if (lateFee.recurringLateFeeType) {
            this.recurringLateFeeType = lateFee.recurringLateFeeType;
        }
        if (lateFee.recurringLateFeeAmount) {
            this.recurringLateFeeAmount = lateFee.recurringLateFeeAmount;
        }

        this.categoryId = lateFee.categoryId || null;
        if (lateFee.category) {
            this.category = new Category(lateFee.category);
        }
    }
}
