import { Component } from '@angular/core';

@Component({
    selector: 'lf-auth-layout',
    styleUrls: ['./auth-layout.scss'],
    templateUrl: './auth-layout.component.html',
})
export class AuthLayoutComponent
{
}
