import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[preventClick]',
})
export class PreventClickDirective
{
    @HostListener('click', ['$event']) onClick($event: MouseEvent){
        $event.stopPropagation();
        $event.preventDefault();
    }
}
