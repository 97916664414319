import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Membership } from './membership';
import { Unit } from './unit';
import { Address } from './address';
import Moment = moment.Moment;
import { PaymentGroup } from 'app/models/entities/payment-group';

export class Owner
{
    id: number;
    unitId: number;
    memberId: number;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;
    address: Address;

    // Frontend properties
    checked: boolean;

    membership: Membership;
    unit: Unit;

    // derived property
    name: string;
    paymentsTotal: number = 0;

    thisMonthPayments: PaymentGroup[] = [];
    thisMonthAutopaidTotal = 0;

    constructor(owner: any) {
        if (owner.id) {
            this.id = owner.id;
        }
        if (owner.unitId) {
            this.unitId = owner.unitId;
        }
        if (owner.membershipId) {
            this.memberId = owner.membershipId;
        }
        if (owner.memberId) {
            this.memberId = owner.memberId;
        }
        if (owner.name) {
            this.name = owner.name;
        }
        this.paymentsTotal = owner.paymentsTotal || 0;
        if (owner.thisMonthPayments) {
            this.thisMonthPayments = owner.thisMonthPayments.map(pg => new PaymentGroup(pg));
            this.thisMonthAutopaidTotal = this.thisMonthPayments.filter(pg => {
                return pg.paymentAttempts.filter(pa => pa.autopaid).length > 0;
            }).reduce((acc, pg) => acc + pg.applied, 0);
        }

        const timezone = LegFiJwtService.getTimezone();
        if (owner.createdAt) {
            this.createdAt = moment.utc(owner.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (owner.updatedAt) {
            this.updatedAt = moment.utc(owner.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (owner.deletedAt) {
            this.deletedAt = moment.utc(owner.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (owner.membership) {
            this.membership = new Membership(owner.membership);
            this.name = this.membership.name;
        }
        if (owner.unit) {
            this.unit = new Unit(owner.unit);
        }
        if (owner.address) {
            this.address = new Address(owner.address);
        }
    }
}
