import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'whereIn',
    pure: false,
})
export class WhereInPipe implements PipeTransform
{
    public transform(collection: any[], filterCollection: any[], key?: string) {
        // Collection is empty or no key was found, nothing intersects.
        if (!filterCollection || !filterCollection.length || typeof key === 'undefined') {
            return [];
        }

        return collection.filter(
                (ele) => {
                    if (typeof ele[key] === 'undefined') {
                        return false;
                    }

                    return filterCollection.indexOf(ele[key]) !== -1;
                },
        );
    }
}
