import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhereInPipe } from './where-in.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [WhereInPipe],
    exports: [WhereInPipe],
})
export class WhereInPipeModule
{
}
