import { AfterViewInit, Directive, ElementRef, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: `[sidenav-navigation-loading], sidenav-navigation-loading, [sidenavNavigationLoading]`,
})
export class SidenavNavigationLoadingDirective implements OnInit
{
    @Input() lines: number = 4;

    @HostBinding('class') classValues = 'navigation-loading animated animated-delay fadeIn';

    constructor(private _renderer: Renderer2, private _el: ElementRef) {
    }

    ngOnInit() {
        for (let i = 0; i < this.lines; i++) {
            this._renderer.appendChild(this._el.nativeElement, this._renderer.createElement('span'));
        }
    }
}

@Directive({
    selector: `[sidenav-navigation-item], sidenav-navigation-item, [sidenavNavigationItem]`,
})
export class SidenavNavigationItemDirective implements OnInit, AfterViewInit
{
    @Input() description = '';
    @HostBinding('class') classValues = 'navigation-button animated animated-delay fadeInTop';

    private wrapperRef: Element;

    constructor(private _renderer: Renderer2, private _el: ElementRef) {
    }

    ngOnInit() {
        this.wrapperRef = this._el.nativeElement.querySelectorAll('.mat-button-wrapper')[0];
        this._renderer.setAttribute(this.wrapperRef, 'style', 'display: flex; align-items: center');
    }

    ngAfterViewInit() {
        const title = this.wrapperRef.textContent.trim();
        const element = this._el.nativeElement;

        this._renderer.setAttribute(element, 'aria-label', this.description.trim());
        this._renderer.setAttribute(element, 'data-intercom-target', `${title} Page Menu`);
    }
}
