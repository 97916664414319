<h3 mat-dialog-title>
    Audit History {{ objectName ? ('- ' + camelCaseToCapitalizedWords(objectName) + ' #' + object.id) : '' }}
</h3>
<mat-dialog-content class="p-0">
    <ng-container *ngIf="(!data.id || !data.model) && audits.length === 0; else detailView">
        <app-jumbotron
            headerText="No Object Selected"
            subtext="Please select an object and id to view its audit history."
        >
        </app-jumbotron>
    </ng-container>
    <ng-template #detailView class="fade fadeIn">
        <div *ngIf="isLoading; else recordView" class="d-loading fadeIn" style="min-height:200px">
            <div class="loader">Loading...</div>
        </div>
        <ng-template #recordView>
            <ng-container *ngIf="object; else noRecords">
                <mat-accordion>

                    <!-- Object Details -->
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>Current Values</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="grid-layout grid-3 mt-0">
                            <div class="d-flex flex-column" *ngFor="let index of objectKeys">
                                <label>{{ index }}</label>
                                {{ object[index] }}
                            </div>
                        </div>
                    </mat-expansion-panel>

                    <!-- Audits-->
                    <mat-expansion-panel *ngFor="let audit of audits" [disabled]="audits.length === 0">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <app-audit-summary [audit]="audit"></app-audit-summary>
                            </mat-panel-title>
                            <mat-panel-description class="d-flex flex-column align-items-end">
                                <span>
                                    {{ audit.createdAt | legfiDate: 'MM/DD/YYYY hh:mm a' }}
                                </span>
                                <span *ngIf="!audit.systemAction && audit.user">
                                    <span *ngIf="audit.url === 'console'">
                                        {{ audit.user.email }} via Console
                                    </span>
                                    <span *ngIf="audit.url !== 'console'">
                                        {{ audit.user.email }} at {{ audit.ipAddress }}
                                    </span>
                                </span>
                                <span *ngIf="audit.systemAction || !audit.user">
                                    PayHOA Console
                                </span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <ng-container [ngSwitch]="audit.modelName">
                            <app-charge-audit-reporter *ngSwitchCase="'Charge'" [audit]="audit"></app-charge-audit-reporter>

                            <app-audit-diff-formatter *ngSwitchDefault [audit]="audit"></app-audit-diff-formatter>
                        </ng-container>
                    </mat-expansion-panel>

                    <!-- Related Records -->
                    <mat-expansion-panel [disabled]="relations.length === 0" class="p-0">
                        <mat-expansion-panel-header>
                            <mat-panel-title [class.text-muted]="relations.length === 0">
                                Related Records&nbsp;<span *ngIf="relations.length > 0">({{ relations.length }})</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-accordion>
                            <mat-expansion-panel *ngFor="let key of relations">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="text-muted">{{ camelCaseToCapitalizedWords(key) }}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ul *ngFor="let related of relatedRecords[key]">
                                    <li>
                                        <app-button type="link" (onClick)="selectRelatedRecord(related.className, related.id)">
                                            {{ camelCaseToCapitalizedWords(related.className) }} {{ related.id }}
                                        </app-button>
                                    </li>
                                </ul>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </mat-expansion-panel>
                </mat-accordion>
            </ng-container>
        </ng-template>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="d-flex">
        <select (change)="idInput.value = ''" [(ngModel)]="data.model" class="form-control mr-2">
            <option *ngFor="let model of models" [ngValue]="model">{{ model }}</option>
        </select>
        <input #idInput [(ngModel)]="data.id" class="form-control" placeholder="Object ID">
    </div>
    <app-button mat-dialog-close>Close</app-button>
</mat-dialog-actions>

<ng-template #noRecords>
    <app-jumbotron
        headerText="No Audit Records Found"
        subtext="There are no audit records for this object."
    >
    </app-jumbotron>
</ng-template>
