import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { CreditCard } from './credit-card';
import { PaymentBankAccount } from './payment-bank-account';

export class OrganizationProfile
{
    organizationId: number;
    paymentMethodId: number;
    paymentMethodType: string;
    ein: string;
    email: string;
    timezone: string;
    phone: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    invoiceReturnAddress1: string;
    invoiceReturnAddress2: string;
    invoiceReturnCity: string;
    invoiceReturnState: string;
    invoiceReturnZip: string;
    invoiceReturnCountry: string;
    absorbsCcFees: boolean;
    absorbsAchFees: boolean;
    hasLogo: boolean;
    createdAt: Moment;
    updatedAt: Moment;
    fiscalPeriodStart: number;
    stripeCustomerId: string;
    payabliId:string;
    vendorInvoiceToEmail: string;

    paymentMethod: PaymentBankAccount | CreditCard;

    constructor(organizationProfile: any) {
        if (organizationProfile.organizationId) {
            this.organizationId = organizationProfile.organizationId;
        }
        if (organizationProfile.paymentMethodId) {
            this.paymentMethodId = organizationProfile.paymentMethodId;
        }
        if (organizationProfile.stripeCustomerId) {
            this.stripeCustomerId = organizationProfile.stripeCustomerId;
        }
        if (organizationProfile.payabliId) {
            this.payabliId = organizationProfile.payabliId;
        }
        if (organizationProfile.paymentMethodType) {
            this.paymentMethodType = organizationProfile.paymentMethodType;
        }
        if (organizationProfile.ein) {
            this.ein = organizationProfile.ein;
        }
        if (organizationProfile.email) {
            this.email = organizationProfile.email;
        }
        if (organizationProfile.timezone) {
            this.timezone = organizationProfile.timezone;
        }
        if (organizationProfile.phone) {
            this.phone = organizationProfile.phone;
        }
        if (organizationProfile.address1) {
            this.address1 = organizationProfile.address1;
        }
        if (organizationProfile.address2) {
            this.address2 = organizationProfile.address2;
        }
        if (organizationProfile.city) {
            this.city = organizationProfile.city;
        }
        if (organizationProfile.state) {
            this.state = organizationProfile.state;
        }
        if (organizationProfile.zip) {
            this.zip = organizationProfile.zip;
        }
        if (organizationProfile.country) {
            this.country = organizationProfile.country;
        }

        if (organizationProfile.invoiceReturnAddress1) {
            this.invoiceReturnAddress1 = organizationProfile.invoiceReturnAddress1;
        }
        if (organizationProfile.invoiceReturnAddress2) {
            this.invoiceReturnAddress2 = organizationProfile.invoiceReturnAddress2;
        }
        if (organizationProfile.invoiceReturnCity) {
            this.invoiceReturnCity = organizationProfile.invoiceReturnCity;
        }
        if (organizationProfile.invoiceReturnState) {
            this.invoiceReturnState = organizationProfile.invoiceReturnState;
        }
        if (organizationProfile.invoiceReturnZip) {
            this.invoiceReturnZip = organizationProfile.invoiceReturnZip;
        }
        if (organizationProfile.invoiceReturnCountry) {
            this.invoiceReturnCountry = organizationProfile.invoiceReturnCountry;
        }

        if (organizationProfile.hasOwnProperty('absorbsCcFees')) {
            this.absorbsCcFees = organizationProfile.absorbsCcFees;
        }
        if (organizationProfile.hasOwnProperty('absorbsAchFees')) {
            this.absorbsAchFees = organizationProfile.absorbsAchFees;
        }
        if (organizationProfile.hasLogo) {
            this.hasLogo = organizationProfile.hasLogo;
        }
        if (organizationProfile.vendorInvoiceToEmail) {
            this.vendorInvoiceToEmail = organizationProfile.vendorInvoiceToEmail;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (organizationProfile.createdAt) {
            this.createdAt = moment.utc(organizationProfile.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (organizationProfile.updatedAt) {
            this.updatedAt = moment.utc(organizationProfile.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (organizationProfile.fiscalPeriodStart) {
            this.fiscalPeriodStart = organizationProfile.fiscalPeriodStart;
        }

        if (organizationProfile.paymentMethod?.id) {
            if (organizationProfile.paymentMethodType.indexOf('PaymentBankAccount') !== -1) {
                this.paymentMethod = new PaymentBankAccount(organizationProfile.paymentMethod);
            } else if (organizationProfile.paymentMethodType.indexOf('CreditCard') !== -1) {
                this.paymentMethod = new CreditCard(organizationProfile.paymentMethod);
            }
        }
    }
}
