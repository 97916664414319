import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { CustomUnitFieldValue } from './custom-unit-field-value';
import { Organization } from './organization';

export class CustomUnitField
{
    id: number;
    organizationId: string;
    name: string;
    createdAt: Moment;
    updatedAt: Moment;

    organization: Organization;
    customUnitFieldValues: CustomUnitFieldValue[];

    constructor(customUnitField: any) {
        if (customUnitField.id) {
            this.id = customUnitField.id;
        }
        if (customUnitField.organizationId) {
            this.organizationId = customUnitField.organizationId;
        }
        if (customUnitField.name) {
            this.name = customUnitField.name;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (customUnitField.createdAt) {
            this.createdAt = moment.utc(customUnitField.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (customUnitField.updatedAt) {
            this.updatedAt = moment.utc(customUnitField.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (customUnitField.organization) {
            this.organization = new Organization(customUnitField.organization);
        }
        if (customUnitField.customUnitFieldValues) {
            this.customUnitFieldValues = customUnitField.customUnitFieldValues.map(
                    (customUnitFieldValue) => {
                        return new CustomUnitFieldValue(customUnitFieldValue);
                    },
            );
        }
    }
}
