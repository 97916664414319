import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Unit } from './unit';
import Moment = moment.Moment;

export class UnitOccupancy
{
    id: number;
    unitId: number;
    fromDate: Moment;
    toDate: Moment;
    createdAt: Moment;
    updatedAt: Moment;

    unit: Unit = null;

    constructor(occupancy: any) {
        if (occupancy.id) {
            this.id = occupancy.id;
        }
        if (occupancy.unitId) {
            this.unitId = occupancy.unitId;
        }
        if (occupancy.unit) {
            this.unit = new Unit(occupancy.unit);
        }
        const timezone = LegFiJwtService.getTimezone();
        if (occupancy.fromDate) {
            this.fromDate = moment.utc(occupancy.fromDate, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (occupancy.toDate) {
            this.toDate = moment.utc(occupancy.toDate, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (occupancy.createdAt) {
            this.createdAt = moment.utc(occupancy.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (occupancy.updatedAt) {
            this.updatedAt = moment.utc(occupancy.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
    }
}
