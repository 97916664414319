import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'whereKeyMatches',
})
export class WhereKeyMatchesPipe implements PipeTransform
{
    public transform(collection: any[], search: any, key: string, allOnEmpty: boolean = false) {
        if (search === null && !allOnEmpty) {
            return [];
        }
        if (search === null && allOnEmpty) {
            return collection;
        }

        let filter = search;
        if (filter && filter.length && typeof filter.toLocaleLowerCase !== 'undefined') {
            filter = filter.replace(/[\s]+/g, ' ').toLocaleLowerCase();
        }

        const keys = key.split('|');

        if (filter && filter.length) {
            const newFilter = filter.replace(/[-.*+?^${}()|[\]\\]/g, '\\$&'); // Regex escape
            const regexVal = '^(?=.*\\b' + newFilter.split(/\s+/).join('\\b)(?=.*\\b') + ').*$';
            const reg = RegExp(regexVal, 'i');

            return collection.filter(
                    ele => {
                        return keys.filter(
                                (k) => {
                                    const keyParts = k.split('.');
                                    if (keyParts.length > 1) {
                                        do {
                                            if (ele.hasOwnProperty(keyParts[0])) {
                                                ele = ele[keyParts[0]];
                                                keyParts.splice(0, 1);
                                            } else {
                                                return false;
                                            }
                                        }
                                        while (keyParts.length > 1);
                                    } else if (!(keyParts.length > 0)) {
                                        return false;
                                    }

                                    return reg.test(ele[keyParts[0]].toLocaleLowerCase());
                                },
                        ).length > 0;
                    },
            );
        } else {
            return collection;
        }
    }
}
