import { ColorSourcesEnum } from '../../enums/color-sources.enum';

export interface Taggable {
    id: number;
    taggableId: number;
    taggableColor: string;
    title: string;
    status?: string;
}

export class TagBatchItem {
    id: number;
    tag: string;
    taggableColor: string;
    taggables: Taggable[];
    internalUse: number = 0;
    uses: number;

    constructor(tagBatchItem: any) {
        if (tagBatchItem.id) {
            this.id = tagBatchItem.id;
        }

        if (tagBatchItem.tag) {
            this.tag = tagBatchItem.tag;
        }

        this.taggableColor = tagBatchItem.taggableColor || ColorSourcesEnum.OFF_WHITE;

        if (tagBatchItem.taggables) {
            this.taggables = tagBatchItem.taggables.map((t) => {
                return {
                    ...{
                        id: parseInt(t.id, 10),
                        taggableId: parseInt(t.taggableId, 10),
                        taggableColor: t.taggableColor,
                        title: t.title,
                    },
                    ...(t.status ? { status: t.status } : {}),
                };
            });
        }

        if (tagBatchItem.internalUse) {
            this.internalUse = tagBatchItem.internalUse;
        }

        if (tagBatchItem.uses) {
            this.uses = tagBatchItem.uses;
        }
    }
}
