<div class="range-slider">
    <input #slider
           (change)="updatePosition($event.target.value);"
           (input)="updatePosition($event.target.value);"
           class="slider"
           max="{{max}}"
           min="{{min}}"
           type="range"
           value="{{value}}"/>
    <div *ngIf="useTextbox && !currency"
         class="slider-value">
        <input [(ngModel)]="value"
               type="textbox"/>
    </div>
    <lf-currency *ngIf="useTextbox && currency"
                 [(ngModel)]="value"
                 [currency]="currency"
                 [placeholder]="placeholder"
                 [showSymbol]="true"
                 class="slider-value"
                 ngDefaultControl></lf-currency>
</div>
