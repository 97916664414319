export const environment = {
    production: true,
    useProdMode: true,
    hmr: false,
    SentryEnvironment: 'production',
    SentrySampleRate: 0.2,
    PusherAppKey: '590a25e4eddc8d1b0e3a',
    PusherAppCluster: 'us2',

    MaintenanceWindow: {
        active: false,
        time: '3:00 PM ET',
    },

    /**
     * @type {number}
     */
    SiteId: 2,

    /**
     * @type {string}
     */
    LegFiCoreApi: '//core.payhoa.com',
    MailHogUrl: '',

    /**
     * @type {string}
     */
    PayHoaUi: 'https://payhoa.com',
    PayHOALanding: 'https://www.payhoa.com',
    PayHOALogo: '/assets/images/payhoa/hoa-management-software/payhoa-logo.svg',

    /**
     * @type {string}
     */
    DefaultTimezone: 'US/Eastern',

    /**
     * @type {number}
     */
    IdleTimeoutCountdown: 3600,
    IdleTimeoutThreshold: 60,

    /**
     * @type {string}
     */
    GoogleMapsApiKey: 'AIzaSyD-48eNr3U4_LGTjK6lSzTmvUYzIwUkMDQ',

    /**
     * @type {string}
     */
    GoogleGtagMeasurementId: 'G-KNB3L8H5SJ',

    /**
     * @type {string}
     */
    GoogleGtmContainerId: 'GTM-52N9RCJ',

    /**
     * @type {string}
     */
    LandingPageRedirectId: '791f08f8-fa3b-11ed-be56-0242ac120002',

    /**
     * @type {{env:string, key:string}}
     */
    PlaidEnv: {
        env: 'production',
        key: '554aac611ac4241fb1222e7c764510',
    },

    /**
     * @type {string}
     */
    StripePublishableKey: 'pk_live_pPXLpLt1HObl59UZHWQrh9ul',

    /**
     * @type {number}
     */
    WorkerServerFileSizeLimit: 104857600,  // 100 * 1024 * 1024 bytes,

    S3URL: 'https://s3.us-east-2.amazonaws.com/',

    /**
     * @type {string}
     */
    ImagingCoreBucket: 'imaging-core',
    /**
     * @type {string}
     */
    OrgLogoBucket: 'payhoa-org-logos',

    /**
     * @type {string}
     */
    UserProfileBucket: 'payhoa-core-users',

    RecaptchaSiteKey: '6LfRlSgaAAAAANxSkE1Vr_4QgQYP-dFx1Sv77I-A',

    /**
     * @type {{clientId:string, redirectUri:string, responseType:string}}
     */
    GoogleOAuth: {
        clientId:
                '158745491920-vi8c4s2pnolp9854m6kti8q8t8o07ob0.apps.googleusercontent.com',
        redirectUri: 'https://app.payhoa.com/auth/oauth/callback',
        responseType: 'code',
        scope: {
            calendar: 'https://www.googleapis.com/auth/calendar.readonly',
        },
    },

    Algolia: {
        appId: '3H4I56PC1Q',
    },

    Intercom: {
        appId: 'z2z4xt96',
    },

    TinyMCE: {
        appId: 'kida4qu9hedqmbf57dnqb339l6jnwn7q02bw42kxeoo4wgny',
    },

    HubSpot: {
        portalId: '7222904',
        forms: {
            board: 'e21cd301-66f0-4f14-971d-6dc143722c8a',
            pmc: '75ba270a-74f5-4118-bbdd-2147e189d393',
        },
    },
};

export default environment;
