import { Injectable } from '@angular/core';
import { ColorSourcesEnum } from '../../enums/color-sources.enum';

export interface GraphColorObject
{
    backgroundColor?: string[];
    hoverBackgroundColor?: string[];
    borderColor?: string[];
}

export interface GraphCategory
{
    category: string;
    number: number;
    percent: number;
    class: string;
}

export const fontOptions = {
    family: 'ProximaNovaRegular',
    color: '#DFE6EE',
    size: 12,
};

export const tooltipOptions = {
    backgroundColor: '#485465',
    bodyFont: fontOptions,
    boxPadding: 5,
    titleFont: fontOptions,
    titleAlign: 'center',
    titleMarginBottom: 12,
    bodySpacing: 5,
    xPadding: 10,
    yPadding: 10,
    cornerRadius: 6,
    caretPadding: 4,
    callbacks:{
        labelColor: function(context: any) {
            return {
                borderColor: '#ffffff',
                backgroundColor: context.dataset.backgroundColor[context.dataIndex],
                borderWidth: 0.5,
                borderRadius: 0,
            };
        },
    }
};

export const EmailCategoryGraphColors = {
    backgroundColor: Object.values(ColorSourcesEnum),
    hoverBackgroundColor: Object.values(ColorSourcesEnum),
    borderColor: [
        '#ffffff',
        '#ffffff',
        '#ffffff',
        '#ffffff',
    ],
};


export const GlobalDonutGraphOptions = {
    cutout: '80%',
    plugins: {
        legend: {
            display: false,
        },
        tooltip: tooltipOptions,
    },
    maintainAspectRatio: false,
    responsive: true,
};

export const LineGraphOptions = {
    plugins: {
        legend: {
            display: false,
        },
        tooltips: tooltipOptions,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxis:
                {
                    min: 0,
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                        padding: 7,
                    },
                    font: fontOptions,
                    grid: {
                        borderDash: [
                            3,
                            3,
                        ],
                        drawBorder: false,
                    },
                },
        xAxis:
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                        font: fontOptions,
                    },
                    barPercentage: 0.3,
                    grid: {
                        display: false,
                    },
                },
    },
};

export const EmailCategoryGroup = 'In-Group Communication';
export const EmailCategoryReceipts = 'Receipt';
export const EmailCategoryViolations = 'Violation';
export const EmailCategoryInquiries = 'inquiries';

// sms and phone call mapping colors
export const twilioStatuses = {
    accepted: '#85aba4',
    delivered: '#53C1A9',
    received: '#eb9658',
    receiving: '#FFC15E',
    queued: '#97a4b1',
    scheduled: '#58a2eb',
    sending: '#87BBFD',
    sent: '#6772E5',
    failed: '#eb5858',
    undelivered: '#eb9658',
};

/** Same colors as above just in a string array */
export const mappedColors = [
    '#85aba4',
    '#53C1A9',
    '#eb9658',
    '#FFC15E',
    '#97a4b1',
    '#58a2eb',
    '#87BBFD',
    '#4859A0',
    '#eb5858',
    '#eb9658',
];


const HighchartsTooltipBaseConfig = {
    backgroundColor: '#5B697D',
    borderRadius: 5,
    borderWidth: 0,
    headerFormat:
            '<span style="color: #d5dae0; text-align: center; margin-bottom: 10px; display: block; width: 100%;">{point.key} </span><table style="background: transparent; font-family: \'ProximaNovaRegular\', arial, sans-serif">',
    padding: 10,
    shared: false,
    shape: 'callout',
    useHTML: true,
    style: {
        pointerEvents: 'auto',
    },
    footerFormat: '</table>',
};

export const HighchartsDonutPointFormatter = {
    ...HighchartsTooltipBaseConfig,
    pointFormatter() {
        return (
                `<tr><td style="padding-bottom: 10px; "><div style="background-color: ${this.color}; width: 12px; height: 12px; margin-right: 10px; margin-top: 2px; border-radius: 50%;" ></div></td>
            <td style="line-height: 1.2; color: #fff; font-family: \'ProximaNovaRegular\', arial, sans-serif">
                <span style="color: #fff; text-decoration: none; padding: 10px 10px 10px 0;">
                    $${this.y.toLocaleString()}</span>
                 </td>
            </tr>`
        );
    },
};

export const HighchartsScatterPointFormatter = {
    ...HighchartsTooltipBaseConfig,
    pointFormat:
            `<tr><td style="padding-bottom: 10px; "><div style="background-color: {series.color}; width: 12px; height: 12px; margin-right: 10px; margin-top: 2px; border-radius: 50%;" ></div></td>
            <td style="padding-bottom: 10px; line-height: 1.2; color: #fff; font-family: \'ProximaNovaRegular\', arial, sans-serif">
            <span style="color: #fff; text-decoration: none; padding: 10px 10px 10px 0px;">{series.name}:</span> </td>
            <td style="text-align: right; padding-bottom: 10px; margin-left: 10px; font-family: \'ProximaNovaSemibold\', arial, sans-serif; color: #FFFFFF;">{point.y}</td></tr>`,
};

export const HighchartsDonutOptions = {
    chart: {
        type: 'pie',
        height: 500,
    },
    credits: {
        enabled: false,
    },
    title: {
        text: null,
    },
    legend: {
        enabled: true,
        itemStyle: {
            color: '#383B46',
            'font-family': '\'ProximaNovaRegular\'',
            'font-weight': '400',
            fontSize: 14,
        },
        itemMarginBottom: 10,
        labelFormatter() {
            let name = this.name;
            if (name.length > 25) {
                name = name.slice(0, 25).trim() + '...';
            }
            return `${name} &nbsp;&nbsp;&nbsp;<span>$${this.y.toLocaleString()}</span>`;
        },
        layout: 'vertical',
        margin: 20,
        symbolHeight: 10,
        symbolWidth: 10,
        symbolRadius: '50%',
    },
    tooltip: HighchartsDonutPointFormatter,
    plotOptions: {
        pie: {
            shadow: false,
            dataLabels: {
                enabled: true,
                color: '#ffffff',
                format: '{point.percentage:.1f}%',
                distance: -25,
                style: {
                    'font-family': '\'ProximaNovaRegular\'',
                    'font-weight': '400',
                    fontSize: '12px',
                    'text-outline': 'none',
                },
            },
            showInLegend: true,
            innerSize: '55%',
        },
    },
    series: [],
};

export const HighchartsSplineOptions = {
    chart: {
        type: 'spline',
        height: 450,
    },
    xAxis: {
        type: 'datetime',
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
        showFirstLabel: true,
        labels: {
            format: '{value:%b %e}',
            style: {
                color: '#383B46 !important',
                'text-decoration': 'none !important',
                'font-family': '\'ProximaNovaRegular\'',
                'font-weight': '400 !important',
            },
        },
        gridLineWidth: 0,
    },
    yAxis: {
        title: false,
        floor: 0,
        gridLineDashStyle: 'Dash',
        gridLineColor: '#DFE6EE',
        labels: {
            formatter() {
                return '$' + this.value.toLocaleString();
            },
            style: {
                color: '#383B46 !important',
                'text-decoration': 'none !important',
                'font-family': '\'ProximaNovaRegular\'',
                'font-weight': '400 !important',
            },
        },
    },
    tooltip: HighchartsScatterPointFormatter,
    legend: {
        enabled: true,
        itemStyle: {
            color: '#383B46',
            'font-family': '\'ProximaNovaRegular\'',
            'font-weight': '400',
            fontSize: 14,
        },
        itemMarginBottom: 25,
        symbolHeight: 10,
        symbolWidth: 10,
        symbolRadius: '50%',
        margin: 15,
        verticalAlign: 'top',
    },
    credits: {
        enabled: false,
    },
    title: {
        text: null,
    },
    series: [],
};

@Injectable({
    providedIn: 'root',
})
export class GraphingService
{
    /**
     * Helper to put together the colors for the graphs based on what statuses returned
     *
     * @param categories
     */
    assembleGraphColors(categories): GraphColorObject {
        const colors = categories.map(c => twilioStatuses[(c.category).toLowerCase()]);
        const bgColors = categories.map(c => twilioStatuses[(c.category).toLowerCase()] + '20');
        const borderColors = categories.map(c => '#ffffff');
        return {
            backgroundColor: colors,
            hoverBackgroundColor: bgColors,
            borderColor: borderColors,
        };
    }

    /**
     * Helper to parse the results, statues, and counts for texts and calls for a date range, to render on graph.
     *
     * @param items
     */
    twilioParseHelper(items): GraphCategory[] {
        const total = items.total;
        const statuses = items.statuses;
        const statusCounts = items.statusCounts;
        if (!total) {
            return [];
        }

        // for display in the legend with little dots
        const categories: GraphCategory[] = [];
        statuses.forEach((status, index) => {
            const statusCount = statusCounts[index];
            if (statusCount > 0) {
                const statusWord = status === 'Processed by PayHOA' ? 'Sending' : status;
                categories.push({
                    category: statusWord,
                    number: statusCount,
                    percent: (statusCount / total) * 100,
                    class: 'twilio-' + statusWord.toLowerCase(),
                });
            }
        });

        return categories;
    }

    /**
     * Helper to parse the counts of the 4 types of emails we care about.
     * @param categoryCounts
     */
    parseEmailCategories(categoryCounts: number[]) {
        let total = 0;

        categoryCounts.forEach(c => {
            total += c;
        });

        if (!total) {
            return null;
        }

        return [
            {
                category: 'Group Communications',
                number: categoryCounts[0],
                percent: (categoryCounts[0] / total) * 100,
                class: 'cat-one',
            },
            {
                category: 'Requests',
                number: categoryCounts[1],
                percent: (categoryCounts[1] / total) * 100,
                class: 'cat-two',
            },
            {
                category: 'Violations',
                number: categoryCounts[2],
                percent: (categoryCounts[2] / total) * 100,
                class: 'cat-three',
            },
            {
                category: 'Invoices & Receipts',
                number: categoryCounts[3],
                percent: (categoryCounts[3] / total) * 100,
                class: 'cat-four',
            },
        ];
    }

    public getLineGraphOptions(baseColor: string, hasFill = true) {
        const options = {
            borderColor: baseColor,
            backgroundColor: '',
            fill: false,
            pointBorderColor: baseColor,
            pointBackgroundColor: baseColor,
            pointBorderWidth: 1,
            pointRadius: 3,
            pointHoverBackgroundColor: '#ffffff',
            pointHoverBorderColor: baseColor,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
        };

        if (hasFill) {
            options.backgroundColor = baseColor + '20';
            options.fill = true;
        }

        return options;
    }
}
