import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';

export class PaymentBankAccount
{
    id: number;
    ownerId: number;
    ownerType: string;
    stripeBankAccountId: string;
    friendlyName: string;
    routingNumber: string;
    last4: string;
    verifiedMicrodeposits: boolean;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;
    registeredInStripe: boolean;
    hostedVerificationUrl: string;
    fresh: boolean = false;

    constructor(paymentBankAccount: any) {
        this.id = paymentBankAccount.id || null;
        this.fresh = paymentBankAccount.fresh || false;
        this.ownerId = paymentBankAccount.ownerId || null;
        this.ownerType = paymentBankAccount.ownerType || null;
        this.stripeBankAccountId = paymentBankAccount.stripeBankAccountId || null;
        this.registeredInStripe = paymentBankAccount.registeredInStripe || null;
        this.friendlyName = paymentBankAccount.friendlyName || null;
        this.routingNumber = paymentBankAccount.routingNumber || null;
        this.last4 = paymentBankAccount.last4 || null;
        this.verifiedMicrodeposits = paymentBankAccount.verifiedMicrodeposits || null;

        this.hostedVerificationUrl = paymentBankAccount.hostedVerificationUrl || null;

        const timezone = LegFiJwtService.getTimezone();
        if (paymentBankAccount.createdAt) {
            this.createdAt = moment.utc(paymentBankAccount.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (paymentBankAccount.updatedAt) {
            this.updatedAt = moment.utc(paymentBankAccount.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (paymentBankAccount.deletedAt) {
            this.deletedAt = moment.utc(paymentBankAccount.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
    }
}
