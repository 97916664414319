import { NgModule } from '@angular/core';
import { AppFooterComponent } from './app-footer.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
    imports: [SharedModule],
    exports: [AppFooterComponent],
    declarations: [AppFooterComponent],
})
export class AppFooterModule
{
}
