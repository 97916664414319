import { User } from './user';
import moment, { Moment } from 'moment-timezone';
import { AuditGroup } from './audit-group';

export class Audit
{
    id: number;
    userType: string;
    userId: number;
    event: string;
    auditableId: number;
    auditableType: string;
    modelName: string;
    oldValues: any;
    newValues: any;
    url: string;
    ipAddress: string;
    userAgent: string;
    auditBatchId: number;
    createdAt: Moment;
    updatedAt: Moment;

    user: User;
    auditGroup: AuditGroup;

    systemAction: boolean = false;

    constructor(obj: any) {
        this.id = obj.id || null;
        this.userType = obj.userType || null;
        this.userId = obj.userId || null;
        this.event = obj.event || null;
        this.auditableId = obj.auditableId || null;
        this.auditableType = obj.auditableType || null;
        this.modelName = this.auditableType.split('\\').pop();
        this.oldValues = obj.oldValues || null;
        this.newValues = obj.newValues || null;
        this.url = obj.url || null;
        this.ipAddress = obj.ipAddress || null;
        this.userAgent = obj.userAgent || null;
        this.auditBatchId = obj.auditBatchId || null;

        if (obj.createdAt) {
            this.createdAt = moment.utc(obj.createdAt, 'YYYY-MM-DD hh:mm:ss').tz('America/New_York');
        }
        if (obj.createdAt) {
            this.createdAt = moment.utc(obj.createdAt, 'YYYY-MM-DD hh:mm:ss').tz('America/New_York');
        }

        if (obj.user) {
            this.user = new User(obj.user);
        }
        if (obj.auditGroup) {
            this.auditGroup = new AuditGroup(obj.auditGroup);
        }

        const systemActions = [
            'payable-approved',
            'payable-denied',
        ];

        if (systemActions.indexOf(this.event) !== -1) {
            this.systemAction = true;
        }
    }
}
