import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { CustomUnitField } from './custom-unit-field';
import { Membership } from './membership';

export class CustomUnitFieldValue
{
    id: number;
    customUnitFieldId: number;
    unitId: number;
    value: any;
    createdAt: Moment;
    updatedAt: Moment;
    name: string;

    customUnitField: CustomUnitField;
    membership: Membership;

    constructor(customUnitFieldValue: any) {
        if (customUnitFieldValue.id) {
            this.id = customUnitFieldValue.id;
        }
        if (customUnitFieldValue.customUnitFieldId) {
            this.customUnitFieldId = customUnitFieldValue.customUnitFieldId;
        }
        if (customUnitFieldValue.unitId) {
            this.unitId = customUnitFieldValue.unitId;
        }
        if (customUnitFieldValue.value) {
            this.value = customUnitFieldValue.value;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (customUnitFieldValue.createdAt) {
            this.createdAt = moment.utc(customUnitFieldValue.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (customUnitFieldValue.updatedAt) {
            this.updatedAt = moment.utc(customUnitFieldValue.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (customUnitFieldValue.customUnitField) {
            this.customUnitField = new CustomUnitField(customUnitFieldValue.customUnitField);
        }
        if (customUnitFieldValue.membership) {
            this.membership = new Membership(customUnitFieldValue.membership);
        }
    }
}
