import moment from 'moment-timezone';
import { BillPayTrackingEvent } from './bill-pay-tracking-event';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Vendor } from 'app/models/entities/vendors/vendor';
import { LobBankAccount } from './lob-bank-account';
import { UploadedFile } from 'app/models/entities/uploaded-file';
import { Transaction } from './transactions/transaction';
import { Payable } from './payable';
import Moment = moment.Moment;

export class BillPayment
{
    id: number;
    model: string;
    vendorId: number;
    lobBankAccountId: number;
    amount: number;
    checkNumber: number;
    lobCheckId: number;
    memo: string;
    mailType: string;
    previewUrl: string;
    carrier: string;
    trackingNumber: string;
    trackingURL: string;
    isCancellable: boolean = false;
    isDeletable: boolean = false;
    status: string;

    expectedDeliveryDate: Moment;
    sendDate: Moment;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    billPayTrackingEvents: BillPayTrackingEvent[] = [];
    billPayVendor: Vendor;
    lobBankAccount: LobBankAccount;
    transactions: Transaction[] = [];
    payable: Payable;

    attachments: UploadedFile[] = null;

    hidden: boolean = false;

    constructor(billPayment: any) {
        if (billPayment.id) {
            this.id = billPayment.id;
        }
        if (billPayment.model) {
            this.model = billPayment.model;
        }
        if (billPayment.isDeletable) {
            this.isDeletable = billPayment.isDeletable;
        }
        if (billPayment.vendorId) {
            this.vendorId = billPayment.vendorId;
        }
        if (billPayment.lobBankAccountId) {
            this.lobBankAccountId = billPayment.lobBankAccountId;
        }
        if (billPayment.amount) {
            this.amount = billPayment.amount;
        }
        if (billPayment.checkNumber) {
            this.checkNumber = billPayment.checkNumber;
        }
        if (billPayment.lobCheckId) {
            this.lobCheckId = billPayment.lobCheckId;
        }
        if (billPayment.memo) {
            this.memo = billPayment.memo;
        }
        if (billPayment.transactions) {
            this.transactions = billPayment.transactions.map((t) => new Transaction(t));
        }
        if (billPayment.mailType) {
            this.mailType =
                    billPayment.mailType === 'usps_first_class'
                            ? 'USPS First Class'
                            : 'UPS Next Day Air';
        }
        if (billPayment.previewUrl) {
            this.previewUrl = billPayment.previewUrl;
        }
        if (billPayment.carrier) {
            this.carrier = billPayment.carrier;
        }
        if (billPayment.trackingNumber) {
            this.trackingNumber = billPayment.trackingNumber;
        }
        if (billPayment.trackingURL) {
            this.trackingURL = billPayment.trackingURL;
        }
        if (billPayment.vendor) {
            this.billPayVendor = billPayment.vendor;
        }
        if (billPayment.lobBankAccount) {
            this.lobBankAccount = billPayment.lobBankAccount;
        }
        if (billPayment.status) {
            this.status = billPayment.status;
        }
        if (billPayment.payable) {
            this.payable = new Payable(billPayment.payable);
        }

        const timezone = LegFiJwtService.getTimezone();
        if (billPayment.expectedDeliveryDate) {
            this.expectedDeliveryDate = moment
                    .utc(billPayment.expectedDeliveryDate)
                    .tz(timezone);
        }
        if (billPayment.sendDate) {
            if (billPayment.model === 'payable') {
                this.sendDate = moment(billPayment.sendDate);
            } else {
                this.sendDate = moment
                        .utc(billPayment.sendDate, 'YYYY-MM-DD hh:mm:ss')
                        .tz(timezone);
            }

            const oneHourAgo = moment()
                    .tz(timezone)
                    .subtract(1, 'hour');
            if (oneHourAgo.isBefore(this.sendDate) && !billPayment.deletedAt) {
                this.isCancellable = true;
            }
        }
        if (billPayment.createdAt) {
            this.createdAt = moment
                    .utc(billPayment.createdAt, 'YYYY-MM-DD hh:mm:ss')
                    .tz(timezone);
        }
        if (billPayment.updatedAt) {
            this.updatedAt = moment
                    .utc(billPayment.updatedAt, 'YYYY-MM-DD hh:mm:ss')
                    .tz(timezone);
        }
        if (billPayment.deletedAt) {
            this.deletedAt = moment
                    .utc(billPayment.deletedAt, 'YYYY-MM-DD hh:mm:ss')
                    .tz(timezone);
        }

        if (billPayment.trackingEvents) {
            this.billPayTrackingEvents = billPayment.trackingEvents.map(
                    trackingEvent => {
                        return new BillPayTrackingEvent(trackingEvent);
                    },
            );
        }
        if (billPayment.attachments) {
            this.attachments = billPayment.attachments.map(attachment => {
                return new UploadedFile(attachment);
            });
        }
    }
}
