import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Activity } from './activity';

export class ChargeAudit
{
    id: number;
    activityId: number;
    modifiedField: string;
    oldValue: any;
    newValue: any;
    createdAt: Moment;
    updatedAt: Moment;

    activity: Activity;

    constructor(chargeAudit: any) {
        if (chargeAudit.id) {
            this.id = chargeAudit.id;
        }
        if (chargeAudit.activityId) {
            this.activityId = chargeAudit.activityId;
        }
        if (chargeAudit.modifiedField) {
            this.modifiedField = chargeAudit.modifiedField;
        }
        if (chargeAudit.oldValue) {
            this.oldValue = chargeAudit.oldValue;
        }
        if (chargeAudit.newValue) {
            this.newValue = chargeAudit.newValue;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (chargeAudit.createdAt) {
            this.createdAt = moment.utc(chargeAudit.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (chargeAudit.updatedAt) {
            this.updatedAt = moment.utc(chargeAudit.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (chargeAudit.activity) {
            this.activity = new Activity(chargeAudit.activity);
        }
    }
}
