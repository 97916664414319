import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Organization } from './organization';
import { Site } from './site';
import { JwtPermissionClaim } from '../../services/auth/jwt-permission-claim.model';

export class Module
{
    id: number;
    siteId: number;
    name: string;
    isEnabled: boolean;
    disableRead?: boolean;
    disableWrite?: boolean;
    formId?: number;

    createdAt?: Moment;
    updatedAt?: Moment;

    site?: Site;
    organizations?: Organization[];
    permissions?: JwtPermissionClaim[];

    constructor(module: any) {
        if (module.id) {
            this.id = module.id;
        }
        if (module.siteId) {
            this.siteId = module.siteId;
        }
        if (module.formId) {
            this.formId = module.formId;
        }
        if (module.name) {
            this.name = module.name;
        }
        if (module.hasOwnProperty('isEnabled')) {
            this.isEnabled = module.isEnabled;
        }
        if (module.hasOwnProperty('disableRead')) {
            this.disableRead = module.disableRead;
        }
        if (module.hasOwnProperty('disableWrite')) {
            this.disableWrite = module.disableWrite;
        }
        const timezone = LegFiJwtService.getTimezone();
        if (module.createdAt) {
            this.createdAt = moment.utc(module.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (module.updatedAt) {
            this.updatedAt = moment.utc(module.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (module.site) {
            this.site = new Site(module.site);
        }
        if (module.organizations) {
            this.organizations = module.organizations.map(
                    (organization) => {
                        return new Organization(organization);
                    },
            );
        }
    }
}
