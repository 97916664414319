import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Membership } from './membership';
import { Organization } from './organization';
import { Owner } from './owner';
import { Unit } from './unit';
import { User } from './user';
import Moment = moment.Moment;

export class Address
{
    id: number;
    modelId: number;
    modelType: string;
    line1: string;
    line2: string;
    city: string;
    region: string;
    postalCode: string;
    country: string;
    lat: number;
    long: number;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    model: Membership | Organization | Owner | Unit | User;
    owner: Membership;

    constructor(address: any) {
        if (address.id) {
            this.id = address.id;
        }
        if (address.modelId) {
            this.modelId = address.modelId;
        }
        if (address.modelType) {
            this.modelType = address.modelType;
        }
        if (address.line1) {
            this.line1 = address.line1.trim();
        }
        if (address.line2) {
            this.line2 = address.line2.trim();
        }
        if (address.city) {
            this.city = address.city.trim();
        }
        if (address.region) {
            this.region = address.region.trim();
        }
        if (address.postalCode) {
            this.postalCode = address.postalCode.trim();
        }
        if (address.country) {
            this.country = address.country.trim();
        }
        if (address.lat) {
            this.lat = Number.parseFloat(address.lat);
        }
        if (address.long) {
            this.long = Number.parseFloat(address.long);
        }

        const timezone = LegFiJwtService.getTimezone();
        if (address.createdAt) {
            this.createdAt = moment.utc(address.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (address.updatedAt) {
            this.updatedAt = moment.utc(address.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (address.deletedAt) {
            this.deletedAt = moment.utc(address.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (address.model && address.modelType !== null) {
            if (address.modelType.indexOf('Membership') !== -1) {
                this.model = new Membership(address.model);
            } else if (address.modelType.indexOf('Organization') !== -1) {
                this.model = new Organization(address.model);
            } else if (address.modelType.indexOf('Owner') !== -1) {
                this.model = new Owner(address.model);
            } else if (address.modelType.indexOf('Unit') !== -1) {
                this.model = new Unit(address.model);
            } else if (address.modelType.indexOf('User') !== -1) {
                this.model = new User(address.model);
            }
        }
    }

    // unit address format per new design
    // i.e. 302 Glendover Rd • Lexington, Kentucky 40503
    get addressOneLine() {
        return this.line1 + (this.line2 ? + ' ' + this.line2 : '')
            + ' • ' + this.city + ', ' + this.region + ' ' + this.postalCode;
    }

    formatAddressString() {
        return this.line1 + ' ' + (this.line2
                ? this.line2
                : ' ') + '\n' + this.city + ', ' + this.region + ' ' + this.postalCode;
    }

    getHouseNumber() {
        const p = this.line1.split(' ');
        if (p[0].match(/[\d]+/)) {
            return parseInt(p[0], 10);
        }
        return 0;
    }

    getStreetName() {
        const p = this.line1.split(' ');
        if (p[0].match(/[\d]+/)) {
            p.shift();
            return p.join(' ');
        }
        return this.line1;
    }
}
