import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from 'app/services/auth/legfi-jwt.service';
import { Unit } from 'app/models/entities/unit';
import { Owner } from 'app/models/entities/owner';

export class UnconfirmedOwnerRegistration
{
    id: number;
    email: string;
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;

    organizationId: number;
    unitId: number;
    ownerId: number;

    createdAt: Moment;

    unit: Unit;
    owner: Owner;

    constructor(reg: any) {
        if (reg.id) {
            this.id = reg.id;
        }
        if (reg.email) {
            this.email = reg.email;
        }
        if (reg.name) {
            this.name = reg.name;
        }
        if (reg.address) {
            this.address = reg.address;
        }
        if (reg.city) {
            this.city = reg.city;
        }
        if (reg.state) {
            this.state = reg.state;
        }
        if (reg.zip) {
            this.zip = reg.zip;
        }
        if (reg.organizationId) {
            this.organizationId = reg.organizationId;
        }
        if (reg.unitId) {
            this.unitId = reg.unitId;
        }
        if (reg.ownerId) {
            this.ownerId = reg.ownerId;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (reg.createdAt) {
            this.createdAt = moment.utc(reg.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (reg.unit) {
            this.unit = new Unit(reg.unit);
        }

        if (reg.owner) {
            this.owner = new Owner(reg.owner);
        }
    }
}
