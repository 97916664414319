import { mergeMap, Observable, throwError, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';
import { catchError, map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ApplicationHttpClient } from './application-http-client';
import { IdleTimeoutService } from '../../../services/idle-timeout.service';
import { AuthService } from '../../../services/auth/auth.service';
import environment from 'environments/environment';
import moment from 'moment-timezone';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor
{
    constructor(
            private _http: ApplicationHttpClient,
            private _location: Location,
            private _authService: AuthService,
            private _idle: IdleTimeoutService,
    ) {
    }

    public intercept(
            req: HttpRequest<any>,
            next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        // only intercept requests to the core api
        if (req.url.indexOf(environment.LegFiCoreApi) === -1) {
            return next.handle(req);
        }

        // if the request method is not GET
        if (req.method !== 'GET') {
            // and we do not already have a XSRF token
            const token = this._authService.getCookie('XSRF-TOKEN');
            if (token === null) {
                // fetch a token first, then proceed with this request
                return this._authService.getCsrfCookie().pipe(mergeMap(() => {
                    const clonedRequest = req.clone({
                        setHeaders: {
                            'X-XSRF-TOKEN': this._authService.getCookie('XSRF-TOKEN'),
                        },
                    });
                    return this.attachJwtToRequest(clonedRequest, next);
                }));
            }
        }
        return this.attachJwtToRequest(req, next);
    }

    public attachJwtToRequest(
            req: HttpRequest<any>,
            next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        let headers = req.headers;
        const jwt = LegFiJwtService.raw();
        if (jwt !== null) {
            headers = headers.set('Authorization', 'Bearer ' + jwt);
        }

        const clonedRequest: HttpRequest<any> = req.clone({
            withCredentials: true,
            headers: headers,
        });

        return next.handle(clonedRequest)
                .pipe(catchError((error) => {
                            if (error.status === 419) {
                                // xsrf token expired, fetch a new one and retry the request
                                return this._authService.getCsrfCookie().pipe(mergeMap(() => {
                                    const retryRequest = clonedRequest.clone({
                                        setHeaders: {
                                            'X-XSRF-TOKEN': this._authService.getCookie('XSRF-TOKEN'),
                                        },
                                    });
                                    return next.handle(retryRequest).pipe(catchError((e) => {
                                        return observableThrowError(e);
                                    }));
                                }));
                            }
                            if (error.status === 401 && LegFiJwtService.raw()) {
                                LegFiJwtService.clear();
                            }

                            const currentPage: string = this._location.path();
                            if (
                                    error.status === 401 &&
                                    currentPage.indexOf('/auth/login') === -1 &&
                                    currentPage.indexOf('/auth/payment-login') === -1 &&
                                    error.url.indexOf('plaid') === -1
                            ) {
                                return this.redirectAndThrow401Observable();
                            } else {
                                return observableThrowError(error);
                            }
                        }),
                        map((response: HttpResponse<any>) => {
                            this._idle.reset();

                            if (response.headers && response.headers.has('X-LegFiRefresh')) {
                                LegFiJwtService.store(response.headers.get('X-LegFiRefresh'));
                                moment.tz.setDefault(LegFiJwtService.read().orgTimezone);
                            }
                            return response;
                        }),
                );
    }

    public redirectAndThrow401Observable() {
        this._authService.logout(true);

        return throwError(() => new HttpErrorResponse({
            error: JSON.stringify({
                errors: [
                    {
                        msg: 'You must be logged in to perform this action.',
                        dev: 'No JWT available.',
                    },
                ],
            }),
            status: 401,
            statusText: 'Unauthorized',
        }));
    }
}
