import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AsyncSubject, Subject } from 'rxjs';
import { Ui, Editor, RawEditorOptions } from 'tinymce';
import { FileUploader } from 'ng2-file-upload';
import { Routes } from '../../../config/routes';
import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';
import { GrowlerService } from '../../../services/growler.service';
import environment from '../../../../environments/environment';
import { FileUploaderService } from 'app/services/file-uploader.service';
import { EditorComponent } from '@tinymce/tinymce-angular';

/** TODO: revisit with theming after ng update */
/** Specify content styles for tinyMCE editor loaded within iframe */
export const rteContentStyles: string = `
@font-face {
    font-family: 'ProximaNovaRegular';
    src: url('/assets/fonts/Proxima Nova Regular.otf');
    font-style: normal;
    font-weight: normal;
}

body {
    font-family: 'ProximaNovaRegular', Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.2;
}

.mce-content-body img,
.mce-content-body [contentEditable=false] {
    background: #ff0;
    cursor: default !important;
    padding: 4px 2px;
    outline-offset: -2px;
    margin: 0 1px;
}

.mce-content-body img[data-mce-selected],
.mce-content-body [contentEditable=false][data-mce-selected] {
    outline: 3px solid #ffea00;
}

.mce-content-body img {
    min-width: 50px;
    padding: 8px;
}
`;

@Component({
    selector: 'app-rich-text-editor',
    styleUrls: ['./rich-text-editor.component.scss'],
    templateUrl: './rich-text-editor.component.html',
})
export class RichTextEditorComponent implements OnInit
{
    @Input() label: string;
    @Input() isLoading = true;
    @Input() useNewStyles = false;

    // most have a form group
    @Input() formGroup: UntypedFormGroup;
    @Input() controlName = 'editor';

    // otherwise, use a form control (ex. formly component)
    @Input() formControl: UntypedFormControl;

    @Input() type: 'full' | 'email' | 'formly' | 'compact' = 'full';
    @Input() plugins: string[];
    @Input() toolbar: string[];

    @ViewChild('attachFile') attachFileRef!: ElementRef;
    @ViewChild(EditorComponent) editorRef!: EditorComponent;

    config: RawEditorOptions;

    _uploader: FileUploader;
    _callback: (value: string, meta?: Record<string, any>) => void;

    readonly apiKey = environment.TinyMCE.appId;
    readonly pluginOptions = [
        'lists',
        'link autolink',
        'image',
        'table',
        'code',
        'wordcount',
        // 'emoticons', holding off until tested with Gotenberg PDF exports
    ];
    readonly toolbarOptions = [
        'undo redo',
        'styles',
        'bold italic underline link emoticons',
        'numlist bullist',
        'alignleft aligncenter alignright alignjustify',
        'outdent indent',
    ];
    private readonly editorOptions: RawEditorOptions = {
        branding: false,
        contextmenu: false,
        menubar: false,
        selector: 'app-rich-text-editor',
        plugins: null,
        toolbar: null,
        link_assume_external_targets: 'http',
        elementpath: false,
        noneditable_class: 'placeholder',
        content_style: rteContentStyles,
        browser_spellcheck: true,
    };
    private editorSubject: Subject<any> = new AsyncSubject();

    constructor(
            private _fb: UntypedFormBuilder,
            // TODO: replace with JWT removal and session state updates
            private _legFiJwtService: LegFiJwtService,
            private _growler: GrowlerService,
            private _fileUploaderService: FileUploaderService,
    ) {
    }

    get control() {
        return this.formControl ? this.formControl : this.formGroup.get(this.controlName) as UntypedFormControl;
    }

    get height() {
        switch (this.type) {
            case 'compact':
                return 280;
            case 'formly':
                return 300;
            default:
                return 500;
        }
    }

    ngOnInit() {
        if (!this.formGroup && !this.formControl) {
            this.formGroup = this._fb.group({
                [this.controlName]: [''],
            });
        }

        if (!this.plugins) {
            this.plugins = this.pluginOptions;
        }

        if (!this.toolbar) {
            this.toolbar = this.toolbarOptions;
        }

        let config: RawEditorOptions = {
            ...this.editorOptions,
            height: this.height,
        };

        // TODO: replace with JWT removal and session state updates
        const jwt = this._legFiJwtService.readOrRedirect();
        if (jwt.superUser) {
            // adding for supers for now
            this.toolbar.push('code');
            config = {
                ...config,
                elementpath: true,
            };
        }

        if (this.type === 'email') {
            this.toolbar.splice(1, 0, 'stringToken');
            this.toolbar.splice(4, 0, 'image');
            config = {
                ...config,
                image_caption: true,
                automatic_uploads: true,
                file_picker_types: 'image',
                file_picker_callback: (callback) => {
                    this._callback = callback;
                    this.attachFileRef.nativeElement.click();
                },
                init_instance_callback: function (e) {
                    if (config.elementpath) {
                        const container = e.editorContainer;
                        const elementpath = container.querySelector('.tox-statusbar__path');
                        const tooltip = `Path for the current selection's parent HTML elements (e.g. p > em > strong > span)`;
                        elementpath.setAttribute('title', tooltip);
                    }
                },
            };

            // setting in form control to ensure wordcount is correct
            this.control.setValue(`Hi ${this.getStringTokenContent('first name')}, `);
        }

        this.config = {
            ...config,
            plugins: this.plugins.join(' '),
            toolbar: this.toolbar.join(' | '),
        };
    }

    handleEditorInit(e: any) {
        this.editorSubject.next(e.editor);
        this.editorSubject.complete();

        if (this.type === 'email') {
            this.resetStringToken(e.editor);
            this.resetFileUploader();
        }

        this.isLoading = false;
    }

    focus() {
        this.editorRef.editor?.focus();
    }

    getStringTokenContent(text: string) {
        return `<span class="${this.editorOptions.noneditable_class}">{${text}}</span>`;
    }

    /** helper/init method to set "insert field" placeholders in editor */
    private resetStringToken(editor: Editor) {
        const placeholders = [
            'balance',
            'past-due balance',
            'unit title',
            'unit title + balance',
            'unit title + past-due balance',
            'unit address',
            'unit address + balance',
            'unit address + past-due balance',
            'first name',
            'last name',
            'mailing address',
            'community name',
            'payment link',
            'current date',
            'login qr code',
        ];

        editor.ui.registry.addMenuButton('stringToken', {
            text: 'Insert Field',
            tooltip: 'Insert field to populate with data from PayHOA',
            fetch: (callback) => {
                const items: Ui.Menu.NestedMenuItemContents[] = [];
                for (const placeholder of placeholders) {
                    items.push({
                        type: 'menuitem',
                        text: placeholder,
                        onAction: () => editor.insertContent(this.getStringTokenContent(placeholder)),
                    });
                }
                callback(items);
            },
        });
    }

    /** helper/init method to get file uploader ready or reset */
    private resetFileUploader() {
        // TODO: replace with JWT removal and session state updates
        const jwt = this._legFiJwtService.readOrRedirect();
        if (jwt !== null) {
            this._uploader = this._fileUploaderService.initUploader({
                url: Routes.MakeLegFiCoreUrl(Routes.LegFiCore.DocumentAttachEmail(jwt.orgId)),
                autoUpload: true,
            });
            this._uploader.onBuildItemForm = (fileItem: any, form: any) => {
                form.append('parentId', '');
                form.append('directory', 0);
                form.append('fileName', fileItem.file.name);
            };

            this._uploader.onCompleteItem = (
                    fileItem: any,
                    response: string,
                    status: number,
            ) => {
                if (status === 201) {
                    const result = JSON.parse(response);
                    this._callback(result.url, {title: fileItem.file.name, alt: fileItem.name});
                    this.resetFileUploader();
                } else {
                    this._growler.oops('There was a problem uploading your file.');
                    this.resetFileUploader();
                }
            };
        }
    }
}
