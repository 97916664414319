import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange } from '@angular/core';
import { AbstractControl, ControlValueAccessor } from '@angular/forms';

const noop = () => {
};

@Component({
    selector: 'lf-range-slider',
    styleUrls: ['./range-slider.scss'],
    templateUrl: './range-slider.component.html',
})
export class RangeSliderComponent implements ControlValueAccessor, OnChanges
{
    @Input()
    public min: number = 0;
    @Input()
    public max: number = 250;
    @Input()
    public step: number = 1;
    @Input()
    public currency: string = null;
    @Input()
    public useTextbox: boolean = false;
    @Input()
    public placeholder: string = '0.00';
    @Output()
    public modelChange: EventEmitter<number> = new EventEmitter<number>();
    @Input()
    public model: number = null;
    // ControlValueAccessor properties.
    private onChange: (value: any) => void = noop;
    private onTouched: () => void = noop;

    public _control: AbstractControl;

    public get control(): AbstractControl {
        return this._control || null;
    }

    @Input('formControl')
    public set control(control: AbstractControl) {
        this._control = control;
        this.value = this._control.value;
    }

    public get value(): number {
        return this.model;
    }

    public set value(value: number) {
        value = this.constrainAmount(value);

        if (value !== this.model) {
            this.model = value;
            // Control value accessor - makes ngControl validation work.
            this.onChange(value);
            // Value emitter exposed for us.
            this.modelChange.emit(value);
        }
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        // If min or max was changed, we need to recheck that we are within our constraints.
        let prop = '';
        if (changes.hasOwnProperty('min')) {
            prop = 'min';
        } else if (changes.hasOwnProperty('max')) {
            prop = 'max';
        } else {
            return;
        }

        // Initialize change listener on form.
        if (!changes[prop].isFirstChange()) {
            this.value = this.constrainAmount(this.value);
        }
    }

    // START ControlValueAccessor implementation
    writeValue(value: any): void {
        this.model = value;
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // END ControlValueAccessor implementation

    /**
     * Force the provided amount to be constrained to the min and max inputs, if set.
     * @param {number} amount
     * @returns {number}
     */
    public constrainAmount(amount: number) {
        if (typeof amount !== 'undefined' && amount !== null) {
            if (this.min !== null && this.min > amount) {
                amount = this.min;
            } else if (this.max !== null && this.max < amount) {
                amount = this.max;
            }
        }

        return amount;
    }

    /**
     * Sets the display text from the current control value.
     */
    public updatePosition(newValue: number): void {
        this.value = newValue;
    }
}
