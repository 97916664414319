import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewlineToBreakPipe } from './nl2br.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [NewlineToBreakPipe],
    exports: [NewlineToBreakPipe],
})
export class NewlineToBreakPipeModule
{
}
