import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Organization } from './organization';
import { PaymentAttempt } from './payment-attempt';
import { Subscription } from './subscription';
import Moment = moment.Moment;

export class DailySubscriptionStatus
{
    id: number;
    organizationId: number;
    paymentAttemptId: number;
    dailyDate: Moment;
    memberCount: number;
    estimatedCost: number;
    interval: string;
    hasBillingMethod: boolean;
    trial: boolean;
    expired: boolean;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    organization: Organization;
    paymentAttempt: PaymentAttempt;
    subscription: Subscription;

    constructor(dailySubscriptionStatus: any) {
        if (dailySubscriptionStatus.id) {
            this.id = dailySubscriptionStatus.id;
        }
        if (dailySubscriptionStatus.organizationId) {
            this.organizationId = dailySubscriptionStatus.organizationId;
        }
        if (dailySubscriptionStatus.paymentAttemptId) {
            this.paymentAttemptId = dailySubscriptionStatus.paymentAttemptId;
        }
        if (dailySubscriptionStatus.memberCount) {
            this.memberCount = dailySubscriptionStatus.memberCount;
        }
        if (dailySubscriptionStatus.estimatedCost) {
            this.estimatedCost = dailySubscriptionStatus.estimatedCost;
        }
        if (dailySubscriptionStatus.interval) {
            this.interval = dailySubscriptionStatus.interval;
        }
        if (dailySubscriptionStatus.hasBillingMethod) {
            this.hasBillingMethod = dailySubscriptionStatus.hasBillingMethod;
        }
        if (dailySubscriptionStatus.trial) {
            this.trial = dailySubscriptionStatus.trial;
        }
        if (dailySubscriptionStatus.expired) {
            this.expired = dailySubscriptionStatus.expired;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (dailySubscriptionStatus.dailyDate) {
            this.dailyDate = moment.utc(dailySubscriptionStatus.dailyDate, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (dailySubscriptionStatus.createdAt) {
            this.createdAt = moment.utc(dailySubscriptionStatus.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (dailySubscriptionStatus.updatedAt) {
            this.updatedAt = moment.utc(dailySubscriptionStatus.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (dailySubscriptionStatus.organization) {
            this.organization = new Organization(dailySubscriptionStatus.organization);
        }
        if (dailySubscriptionStatus.paymentAttempt) {
            this.paymentAttempt = new PaymentAttempt(dailySubscriptionStatus.paymentAttempt);
        }
        if (dailySubscriptionStatus.subscription) {
            this.subscription = new Subscription(dailySubscriptionStatus.subscription);
        }
    }
}
