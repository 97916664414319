import { NgModule } from '@angular/core';
import { CPATaxBannerComponent } from './cpatax-banner.component';
import { SharedModule } from '../../../shared/shared.module';


@NgModule({
    declarations: [
        CPATaxBannerComponent,
    ],
    imports: [
        SharedModule,
    ],
    exports: [
        CPATaxBannerComponent,
    ],
})
export class CPATaxBannerModule
{
}
