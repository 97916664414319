import { Directive } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    // tslint:disable-next-line
    selector: 'input[type=file]',
    host: {
        '(change)': 'onChange($event.target.files)',
        '(blur)': 'onTouched()',
    },
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: FormlyFileValueAccessorDirective, multi: true},
    ],
})
// https://github.com/angular/angular/issues/7341
export class FormlyFileValueAccessorDirective implements ControlValueAccessor
{
    value: any;
    onChange = (_) => {
    };
    onTouched = () => {
    };

    writeValue(value) {
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }
}
