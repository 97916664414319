import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tag } from '../../models/entities/tag';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { Routes } from '../../config/routes';
import { map } from 'rxjs/operators';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { TagBatchItem } from '../../models/entities/tag-batch-item';
import { TaggableTypes } from './tags-data.service';
import { HttpParams } from '@angular/common/http';

export interface OrgTagRequest {
    value: string;
    display: string;
    modelIds?: number[];
    taggableColor?: string;
}

export interface PatchTagsRequest {
    modelIds: number[];
    create: Tag[];
    delete: number[];
    onlyTrashed?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class TagsService {
    constructor(private _http: ApplicationHttpClient) {}

    /**
     * @param {TaggableTypes} taggableType
     * @returns {Observable<TagBatch[]>}
     */
    getOrganizationTags(taggableType: TaggableTypes): Observable<TagBatchItem[]> {
        const tagType = taggableType === 'Owner' ? 'member' : taggableType.toLowerCase();
        const jwt: JwtLegFiClaims = LegFiJwtService.read();

        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.ManageOrganizationTagsByType(jwt.orgId, tagType));

        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map((res) => new TagBatchItem(res));
        }));
    }

    /**
     * @param {string} value
     * @param {string} taggableColor
     * @param {TaggableTypes} taggableType
     * @returns {Observable<TagBatch>}
     */
    createOrganizationTag(value: string, taggableColor: string, taggableType: TaggableTypes) {
        const tagType = taggableType === 'Owner' ? 'member' : taggableType.toLowerCase();
        const jwt: JwtLegFiClaims = LegFiJwtService.read();

        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.ManageOrganizationTagsByType(jwt.orgId, tagType));

        const requestBody: OrgTagRequest = {
            display: value,
            value,
            taggableColor,
        };

        return this._http.post(url, JSON.stringify(requestBody)).pipe(map((response: Object) => new TagBatchItem(response)));
    }

    /**
     * @param {string} tag
     * @param {string} value
     * @param {string} taggableColor
     * @param {TaggableTypes} taggableType
     * @returns {Observable<TagBatch>}
     */
    patchOrganizationTag(tag: string, value: string, taggableColor: string, taggableType: TaggableTypes) {
        const tagType = taggableType === 'Owner' ? 'member' : taggableType.toLowerCase();
        const jwt: JwtLegFiClaims = LegFiJwtService.read();

        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.ManageOrganizationTagsByType(jwt.orgId, tagType));

        const requestBody: OrgTagRequest = {
            display: value,
            value,
            taggableColor,
        };

        let params = new HttpParams();
        params = params.append('tag', tag);

        return this._http.patch(url, JSON.stringify(requestBody), { params }).pipe(map((response: Object) => new TagBatchItem(response)));
    }

    /**
     * @param {string} tag
     * @param {string} taggableType
     * @returns {Observable<Object>}
     */
    deleteOrganizationTag(tag: string, taggableType: TaggableTypes): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const type = taggableType === 'Owner' ? 'member' : taggableType.toLowerCase();
        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.ManageOrganizationTagsByType(jwt.orgId, type));

        let params = new HttpParams();
        params = params.append('tag', encodeURIComponent(tag));

        return this._http.delete(url, { params });
    }

    /**
     * @param {string} taggableType
     * @param {number[]} taggableIds
     * @param {boolean} onlyTrashed
     * @returns {Observable<Tag[]>}
     */
    getTagsForType(taggableType: TaggableTypes,
                   taggableIds?: number[],
                   onlyTrashed = false): Observable<Tag[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const type = taggableType === 'Owner' ? 'member' : taggableType.toLowerCase();
        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationTagsByType(jwt.orgId, type));

        let params = new HttpParams();
        if (taggableIds && taggableIds.length > 0) {
            for (const id of taggableIds) {
                params = params.append('modelIds[]', id);
            }
        }

        if (onlyTrashed) {
            params = params.append('onlyTrashed', 1);
        }

        return this._http.get(url, { params }).pipe(map((response: Object[]) => {
            return response.map((res) => new Tag(res));
        }));
    }

    /**
     * @param {Tag} tag
     * @param {TaggableTypes} taggableType
     * @param {number[]} taggableIds
     * @returns {Observable<Tag[]>}
     */
    createTagsForType(tag: Tag, taggableType: TaggableTypes, taggableIds: number[]): Observable<Tag[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const requestBody: OrgTagRequest = {
            modelIds: [...taggableIds],
            display: tag.display,
            value: tag.value,
            taggableColor: tag.taggableColor,
        };

        const type = taggableType === 'Owner' ? 'member' : taggableType.toLowerCase();
        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationTagsByType(jwt.orgId, type));

        return this._http.post(url, JSON.stringify(requestBody)).pipe(map((response: Object[]) => {
            return response.map((res) => new Tag(res));
        }));
    }

    /**
     * @param {TaggableTypes} taggableType
     * @param {number[]} taggableIds
     * @param {Tag[]} create
     * @param {number[]} remove
     * @param {boolean} onlyTrashed
     * @returns {Observable<Tag[]>}
     */
    patchTagsForModel(taggableType: TaggableTypes, taggableIds: number[], create: Tag[], remove: number[], onlyTrashed = false): Observable<Tag[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const requestBody: PatchTagsRequest = {
            modelIds: taggableIds,
            create,
            delete: remove,
            onlyTrashed
        };

        const type = taggableType === 'Owner' ? 'member' : taggableType.toLowerCase();
        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationTagsByType(jwt.orgId, type));

        return this._http.patch(url, JSON.stringify(requestBody)).pipe(map((response: Object[]) => {
            return response.map((res) => new Tag(res));
        }));
    }

    /**
     * @param {string} tag
     * @param {string} taggableType
     * @param {number[]} taggableIds
     * @param {boolean} onlyTrashed
     * @returns {Observable<Object>}
     */
    deleteTagsForType(tag: string, taggableType: TaggableTypes, taggableIds: number[], onlyTrashed = false): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const type = taggableType === 'Owner' ? 'member' : taggableType.toLowerCase();
        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationTagsByType(jwt.orgId, type));

        let params = new HttpParams();
        params = params.append('tag', tag);

        if (taggableIds && taggableIds.length > 0) {
            for (const id of taggableIds) {
                params = params.set('modelIds[]', id);
            }
        }

        if (onlyTrashed) {
            params = params.append('onlyTrashed', 1);
        }

        return this._http.delete(url, { params });
    }
}
