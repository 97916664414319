import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
    selector: '[avatarTitle]',
})
export class AvatarBlockTitleDirective
{
    @HostBinding('class') classValue = 'avatar-block-title';

    constructor(public _el: ElementRef) {
    }

    get title() {
        return this._el.nativeElement.textContent;
    }
}

@Directive({
    selector: '[avatarDescription]',
})
export class AvatarBlockDescriptionDirective
{
    @HostBinding('class') classValue = 'avatar-block-description';

    constructor(public _el: ElementRef) {
    }

    get description() {
        return this._el.nativeElement.textContent;
    }
}
