import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalService } from '../../../services/modal.service';
import { ModalComponent } from './modal.component';
import { ComponentInjectorModule } from '../component-injector/component-injector.module';
import { IfOnDomModule } from '../../../directives/if-on-dom.module';

// import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        ComponentInjectorModule,
        IfOnDomModule,
    ],
    declarations: [ModalComponent],
    exports: [ModalComponent],
    providers: [ModalService],
})
export class ModalModule
{
}
