import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { SurveyQuestion } from './survey-question';
import { SurveyQuestionChoice } from './survey-question-choice';
import { Membership } from './membership';
import { Survey } from './survey';
import Moment = moment.Moment;

export class SurveyResponse
{
    id: number;
    surveyId: number;
    membershipId: number;
    surveyQuestionId: number;
    surveyQuestionChoiceId: number;
    responseText: string;
    createdAt: Moment;
    updatedAt: Moment;

    membership: Membership;
    choice: SurveyQuestionChoice;
    question: SurveyQuestion;
    survey: Survey;

    constructor(survey: any) {
        if (survey.id) {
            this.id = survey.id;
        }
        if (survey.surveyId) {
            this.surveyId = survey.surveyId;
        }
        if (survey.membershipId) {
            this.membershipId = survey.membershipId;
        }
        if (survey.surveyQuestionId) {
            this.surveyQuestionId = survey.surveyQuestionId;
        }
        if (survey.surveyQuestionChoiceId) {
            this.surveyQuestionChoiceId = survey.surveyQuestionChoiceId;
        }
        if (survey.responseText) {
            this.responseText = survey.responseText;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (survey.createdAt) {
            this.createdAt = moment.utc(survey.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (survey.updatedAt) {
            this.updatedAt = moment.utc(survey.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (survey.membership) {
            this.membership = new Membership(survey.membership);
        }
        if (survey.choice) {
            this.choice = new SurveyQuestionChoice(survey.choice);
        }
        if (survey.question) {
            this.question = new SurveyQuestion(survey.question);
        }
        if (survey.survey) {
            this.survey = new Survey(survey.survey);
        }
    }
}
