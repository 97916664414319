import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum HeaderBarEvents {
    ProfileImageChange = 'profile',
    OrgLogoImageChange = 'organization',
    OrgLogoRemoval = 'organization-removal'
}

export interface HeaderBarChange {
    id: number;
    type: HeaderBarEvents;
}

// TODO: Replace use of ImageChangeEmitterService for this service
@Injectable({
    providedIn: 'root',
})
export class HeaderBarListenerService
{
    private imageSubject$: Subject<HeaderBarChange> = new Subject<HeaderBarChange>();

    onImageChange() {
        return this.imageSubject$.asObservable();
    }

    changeImage(id: number, type: HeaderBarEvents) {
        switch(type) {
            case HeaderBarEvents.ProfileImageChange:
            case HeaderBarEvents.OrgLogoImageChange:
            case HeaderBarEvents.OrgLogoRemoval:
                this.imageSubject$.next({ id, type });
                break;
        }
    }
}
