import { Component, OnInit } from '@angular/core';
import { SidenavContentRef } from '../../sidenav/sidenav-content/sidenav-content-ref';
import { NotificationContext } from '../notifications-list/notifications-list.component';
import { NotificationsService } from '../../../../services/notifications/notifications-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DatabaseNotification } from '../../../../models/entities/database-notification';

@Component({
    selector: 'app-notifications-sidenav',
    templateUrl: './notifications-sidenav.component.html',
})
export class NotificationsSidenavComponent implements OnInit
{
    notifications$: Observable<DatabaseNotification[]>;
    isDeleting = false;

    readonly context = NotificationContext.PMC;
    readonly saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
            private _sidenavRef: SidenavContentRef<NotificationsSidenavComponent>,
            private _notificationService: NotificationsService,
    ) {}

    ngOnInit() {
        this.notifications$ = this._notificationService.notifications$.asObservable();
        this.saving$.asObservable().subscribe({
            next: (loading) => {
                this._sidenavRef.loading = loading;
            },
        });
    }

    handleNotificationNavigation($event: any) {
        this._sidenavRef.close();
    }
}
