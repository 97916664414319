import { User } from './user';
import moment, { Moment } from 'moment-timezone';
import { Organization } from './organization';
import { Audit } from './audit';

export class AuditGroup
{
    id: number;
    userId: number;
    propertyManagementCompanyId: number;
    organizationId: number;
    controller: string;
    action: string;
    requestMethod: string;
    timeTaken: number;
    userFriendlyDescription: string;
    auditBatchId: number;
    createdAt: Moment;
    updatedAt: Moment;

    user: User;
    organization: Organization;
    audits: Audit[];

    constructor(obj: any) {
        this.id = obj.id || null;
        this.userId = obj.userId || null;
        this.propertyManagementCompanyId = obj.propertyManagementCompanyId || null;
        this.organizationId = obj.organizationId || null;
        this.controller = obj.controller || null;
        this.action = obj.action || null;
        this.requestMethod = obj.requestMethod || null;
        this.timeTaken = obj.timeTaken || null;
        this.userFriendlyDescription = obj.userFriendlyDescription || null;
        this.auditBatchId = obj.auditBatchId || null;

        if (obj.createdAt) {
            this.createdAt = moment.utc(obj.createdAt, 'YYYY-MM-DD hh:mm:ss').tz('America/New_York');
        }
        if (obj.createdAt) {
            this.createdAt = moment.utc(obj.createdAt, 'YYYY-MM-DD hh:mm:ss').tz('America/New_York');
        }

        if (obj.user) {
            this.user = new User(obj.user);
        }
        if (obj.organization) {
            this.organization = new Organization(obj.organization);
        }
        if (obj.audits) {
            this.audits = obj.audits.map((audit) => new Audit(audit));
        }
    }
}
