import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-maintenance-mode-dialog',
    templateUrl: './maintenance-mode-dialog.component.html',
})
export class MaintenanceModeDialogComponent
{
    constructor(
            private _dialogRef: MatDialogRef<MaintenanceModeDialogComponent>,
    ) {
    }

    retry() {
        window.location.reload();
    }

}
