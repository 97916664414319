import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { SlimLoadingBarService } from 'ng2-al-slim-bar';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';


import { SiteIdentifier } from '../../../../sites/site-identifier.service';
import { SiteIdentifierModel } from '../../../../sites/site-identifier.model';

@Component({
    selector: 'lf-slim-loader',
    templateUrl: './slim-loader.component.html',
    styleUrls: ['./slim-loader.component.scss'],
})
export class SlimLoaderComponent implements OnInit
{
    public color: string = '#4495D4';
    public site: SiteIdentifierModel = SiteIdentifier;

    constructor(
            private _slimLoadingBarService: SlimLoadingBarService,
            private _router: Router,
    ) {
    }

    ngOnInit() {
        /**
         * Currently watch the router for changes and run loader
         */
        this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            this._slimLoadingBarService.complete();
        });
        this._router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
            this._slimLoadingBarService.start();
        });
    }

}
