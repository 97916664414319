import { Category } from '../../accounting/category';
import { Equity } from '../../accounting/equity';
import { FixedAsset } from '../../accounting/fixed-asset';
import { Liability } from '../../accounting/liability';

export class JournalEntry
{
    id: number;
    transactionId: number;
    classId: number;
    classType: string;
    type: string;
    description: string;
    amount: number;

    class: Category | FixedAsset | Liability | Equity;

    constructor(body: any) {
        this.id = body.id;
        this.transactionId = body.transactionId;
        this.classId = body.classId;
        this.classType = body.classType;
        this.type = body.type;
        this.description = body.description;
        this.amount = body.amount;
        if (body.classType.includes('Asset')) {
            this.class = new FixedAsset(body.class);
        } else if (body.classType.includes('Liability')) {
            this.class = new Liability(body.class);
        } else if (body.classType.includes('Equity')) {
            this.class = new Equity(body.class);
        } else if (body.classType.includes('Category')) {
            this.class = new Category(body.class);
        }
    }
}
