import moment from 'moment-timezone';
import { Component, OnInit } from '@angular/core';
import { OrganizationService } from '../../../../services/organization/organization.service';
import { LegFiJwtService } from '../../../../services/auth/legfi-jwt.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Organization } from '../../../../models/entities/organization';
import { Subscription } from '../../../../models/entities/subscription';
import { SubscriptionProduct } from '../../../../models/entities/subscription-product';

@UntilDestroy()
@Component({
    selector: 'app-promo-bar',
    templateUrl: './promo-bar.component.html',
    styleUrls: ['./promo-bar.component.scss'],
})
export class PromoBarComponent implements OnInit
{
    isOnTrial = false;
    isShowing = false;
    nextBilling = null;
    updateCountdown = null;
    remainingTime = '';

    constructor(private _organizationService: OrganizationService) {
    }

    ngOnInit() {
        const jwt = LegFiJwtService.read();
        if (jwt.admin || jwt.superUser) {
            this._organizationService.getOrganizationById(jwt.orgId, [
                'profile.paymentMethod',
                'subscriptions',
                'subscriptions.subscriptionProducts',
            ])
                    .pipe(untilDestroyed(this))
                    .subscribe((org: Organization) => {
                        if (!org.subscriptions.length) {
                            return;
                        }
                        const totalBilling = org.subscriptions.reduce((total, subscription: Subscription) => {
                            return total + subscription.productSum;
                        }, 0);
                        if (totalBilling === 0) {
                            return;
                        }
                        this.isShowing = !org.profile.paymentMethod;
                        const subs = org.subscriptions.filter((sub: Subscription) => {
                            const prod = sub.subscriptionProducts.filter((product: SubscriptionProduct) => product.productId === 4);
                            if (prod) {
                                return sub.lastBilling === null;
                            }
                            return false;
                        });
                        this.isOnTrial = subs.length > 0;
                        if (subs.length > 0) {
                            const sub = subs[0];
                            this.nextBilling = sub.nextBilling;
                        }

                        if (this.isOnTrial) {
                            const countDown = () => {
                                const duration = moment.duration((this.nextBilling.unix() - moment().unix()), 'seconds');
                                const d = String(moment.duration(duration).asDays().toFixed(0)),
                                        h = String(moment.duration(duration).hours()),
                                        m = String(moment.duration(duration).minutes());
                                this.remainingTime = (d.length === 1 ? '0' + d : d) + ' days : '
                                        + (h.length === 1 ? '0' + h : h) + ' hours : '
                                        + (m.length === 1 ? '0' + m : m) + ' minutes';
                            };
                            this.updateCountdown = setInterval(countDown, 30000);
                            countDown();
                        }
                    });
        }
    }

    close() {
        if (this.updateCountdown) {
            clearInterval(this.updateCountdown);
        }
        this.isShowing = false;
    }
}
