import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'lf-material-search',
    templateUrl: './material-search.component.html',
    styleUrls: ['./material-search.component.scss'],
})
export class MaterialSearchComponent implements OnInit, OnChanges, AfterViewInit
{
    @Input() isLoading: boolean;
    @Input() defaultValue: string = '';
    @Input() defaultPlaceholder: string;
    @Input() delayTime = 250;
    @Input() hasGroupAddon = true;
    @Input() hasButtonStyles = false;

    // adding here as we clean up mat-input styles
    @Input() controlName = 'search';
    @Input() newStyles = false;
    newInput = false;

    @Output() updated: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('searchInput') searchInput: ElementRef;

    form: UntypedFormGroup;

    constructor(
            private _fb: UntypedFormBuilder,
            @Inject(DOCUMENT) private document: Document,
    ) {
    }

    get control() {
        return this.form.get(this.controlName) as UntypedFormControl;
    }

    get searchValue() {
        return this.control.value;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.hasOwnProperty('newStyles') && changes.newStyles && changes.newStyles.isFirstChange()) {
            this.newInput = this.newStyles;
        }
    }

    ngOnInit() {
        this.defaultPlaceholder = this.defaultPlaceholder ?? 'Search...';
        if (!this.form) {
            this.form = this._fb.group({
                [this.controlName]: [this.defaultValue],
            });
        }

        if (this.newInput) {
            this.control.valueChanges.pipe(debounceTime(this.delayTime), distinctUntilChanged(), untilDestroyed(this)).subscribe({
                next: (value) => this.updated.emit(value),
            });
        }
    }

    ngAfterViewInit() {
        if (!this.newInput) {
            fromEvent(this.searchInput.nativeElement, 'keyup')
                    .pipe(untilDestroyed(this))
                    .pipe(
                            debounceTime(this.delayTime),
                            distinctUntilChanged(),
                            tap(() => this.emitChange()),
                    )
                    .subscribe();

            fromEvent(this.searchInput.nativeElement, 'paste')
                    .pipe(untilDestroyed(this))
                    .pipe(
                            debounceTime(this.delayTime),
                            distinctUntilChanged(),
                            tap(() => this.emitChange()),
                    )
                    .subscribe();
        }
    }

    clearSearch() {
        this.control.setValue('');
        this.emitChange();
    }

    focus() {
        this.searchInput.nativeElement.focus();
    }

    hasFocus(): boolean {
        return this.searchInput ? this.searchInput.nativeElement === this.document.activeElement : false;
    }

    private emitChange() {
        this.updated.emit(this.control.value);
    }
}
