import { Moment } from 'moment';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import moment from 'moment-timezone';

export class UploadedFileAssociation
{
    public id: number;
    public linkedWithType: string;
    public linkedWithId: number;
    public uploadedFileId: number;
    public createdAt: Moment;
    public updatedAt: Moment;
    public deletedAt: Moment;

    public constructor(ref: any) {
        this.id = ref.id || null;
        this.linkedWithType = ref.linkedWithType || null;
        this.linkedWithId = ref.linkedWithId || null;
        this.uploadedFileId = ref.uploadedFileId || null;

        const timezone = LegFiJwtService.getTimezone();
        if (this.createdAt) {
            this.createdAt = moment.utc(ref.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (this.updatedAt) {
            this.updatedAt = moment.utc(ref.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (this.deletedAt) {
            this.deletedAt = moment.utc(ref.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
    }
}
