import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    transform(value: string, limit: number = 100, ellipsis: string = '...'): string {
        if (value.length <= limit) {
            return value;
        }

        return value.substring(0, limit).trim() + ellipsis;
    }
}

@Pipe({
    name: 'truncateFilename'
})
export class TruncateFilenamePipe implements PipeTransform {
    transform(fileName: string, limit: number = 100, ellipsis: string = '...'): string {
        if (fileName.length <= limit) {
            return fileName;
        }

        const parts = fileName.split('.');
        const ending = parts.pop();
        const name = parts.pop().trim();

        const tail = name.substring(name.length - 1) + '.' + ending;
        limit -= tail.length;

        return fileName.substring(0, limit).trim() + ellipsis + tail;
    }
}
