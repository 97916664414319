import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[loadingList]',
})
export class LoadingListDirective implements OnChanges
{
    @Input() loadingList = false;
    @Input() lines: number = 0;
    @Input() width: number | string;

    private items: HTMLElement[] = [];

    constructor(
            private _el: ElementRef,
            private _renderer: Renderer2,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.hasOwnProperty('loadingList')) {
            if (this.loadingList) {
                this.attachLoadingLines(this.lines > 0);
            } else {
                this.detachLoadingLines();
            }
        }
    }

    private attachLoadingLines(usePlaceholders: boolean) {
        if (usePlaceholders) {
            for (const node of this._el.nativeElement.querySelectorAll('mat-list-item')) {
                this._renderer.addClass(node, 'hidden');
            }

            for (let i = 0; i < this.lines; i++) {
                const placeholderItem = this._renderer.createElement('mat-list-item');
                placeholderItem.classList.add('mat-list-item');
                placeholderItem.classList.add('loading-list-item');

                const placeholderSpan = this._renderer.createElement('span');
                placeholderSpan.classList.add('loading-line');
                this.width = this.width ? (typeof this.width === 'number' ? this.width + 'px' : this.width) : '100%';
                this._renderer.setAttribute(placeholderSpan, 'style', `width: ${this.width}`);
                this._renderer.appendChild(placeholderItem, placeholderSpan);

                this.items.push(placeholderItem);
                this._renderer.appendChild(this._el.nativeElement, placeholderItem);
            }
            return;
        }

        for (const node of this._el.nativeElement.querySelectorAll('mat-list-item')) {
            this._renderer.addClass(node, 'hidden');

            const item = this._renderer.createElement('mat-list-item');
            item.classList.add('mat-list-item');
            item.classList.add('loading-list-item');

            const span = this._renderer.createElement('span');
            span.classList.add('loading-line');

            if (node.querySelector('.mat-line')) {
                this._renderer.appendChild(item, node.querySelector('.mat-line'));
            }

            this._renderer.appendChild(item, span);

            this.items.push(item);
            this._renderer.appendChild(this._el.nativeElement, item);
        }
    }

    private detachLoadingLines() {
        if (this.items.length === 0) {
            return;
        }

        for (const i in this.items) {
            if (this.items[i]) {
                const item = this.items[i];
                const node = this._el.nativeElement.querySelectorAll('mat-list-item')[i];

                this._renderer.removeClass(node, 'hidden');
                if (item.querySelector('.mat-line')) {
                    this._renderer.appendChild(node.querySelector('.mat-list-text'), item.querySelector('.mat-line'));
                }

                this._renderer.removeChild(this._el.nativeElement, item);
            }
        }

        this.items = [];
    }
}

@Directive({
    selector: '[loadingListItem]',
})
export class LoadingListItemDirective implements OnChanges
{
    @Input() loadingListItem: boolean;
    @Input() width: number | string;

    private span: HTMLElement = null;

    constructor(
            private _el: ElementRef,
            private _renderer: Renderer2,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.hasOwnProperty('loadingListItem')) {
            if (this.loadingListItem) {
                this._el.nativeElement.classList.add('loading-list-item-line');
                this.span = this._renderer.createElement('span');
                this.span.classList.add('loading-line');
                this.width = this.width ? (typeof this.width === 'number' ? this.width + 'px' : this.width) : '100%';
                this._renderer.setAttribute(this.span, 'style', `width: ${this.width}`);
                this._renderer.appendChild(this._el.nativeElement, this.span);
            } else {
                this._el.nativeElement.classList.remove('loading-list-item-line');
                if (this.span) {
                    this._renderer.removeChild(this._el.nativeElement, this.span);
                    this.span = null;
                }
            }
        }
    }
}
