import { AfterContentInit, Component, ContentChild, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AvatarBlockDescriptionDirective, AvatarBlockTitleDirective } from './avatar-block.directive';

export enum AvatarBlockTypes {
    INITIALS = 'initials',
    ICON = 'icon',
    SVG = 'svg',
    IMG = 'img',
}

@Component({
    selector: 'app-avatar-block',
    templateUrl: './avatar-block.component.html',
    styleUrls: ['./avatar-block.component.scss'],
})
export class AvatarBlockComponent implements OnChanges, AfterContentInit
{
    @Input() iconImage: string;
    @Input() icon: string;
    @Input() svgIcon: string;
    @Input() initials: string;
    @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'lg';
    @Input() isLoadingInitialData = false;
    @Input() wrappedText = false;

    @HostBinding('class') classValue = 'avatar-block-container';
    @HostBinding('class.heading-block') headingClassValue = false;
    @HostBinding('class.wrapped-text') wrappedTextClassValue = false;

    @ContentChild(AvatarBlockTitleDirective) titleRef!: AvatarBlockTitleDirective;
    @ContentChild(AvatarBlockDescriptionDirective) descriptionRef!: AvatarBlockDescriptionDirective;

    iconType: AvatarBlockTypes = AvatarBlockTypes.INITIALS;
    iconTypes = AvatarBlockTypes;
    classMapping: { [prop: string]: boolean; } = {};

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (changes.hasOwnProperty('iconImage') && this.iconImage) {
                this.iconType = AvatarBlockTypes.IMG;
            } else if (changes.hasOwnProperty('svgIcon') && this.svgIcon) {
                this.iconType = AvatarBlockTypes.SVG;
            } else if (changes.hasOwnProperty('icon') && this.icon) {
                this.iconType = AvatarBlockTypes.ICON;
            } else if (changes.hasOwnProperty('initials') && this.initials) {
                this.iconType = AvatarBlockTypes.INITIALS;
            }

            if (changes.hasOwnProperty('size')) {
                this.classMapping = {
                    sm: this.size === 'sm',
                    md: this.size === 'md',
                    lg: this.size === 'lg',
                    xl: this.size === 'xl'
                };
            }

            if (changes.hasOwnProperty('wrappedText')) {
                this.wrappedTextClassValue = this.wrappedText;
            }
        }
    }

    ngAfterContentInit() {
        if(this.titleRef) {
            this.headingClassValue = this.titleRef._el.nativeElement.nodeName === 'H2';
            this.titleRef._el.nativeElement.textContent = this.titleRef.title;
        }

        if (this.descriptionRef) {
            this.descriptionRef._el.nativeElement.textContent = this.descriptionRef.description;
        }
    }
}
