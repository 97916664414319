import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ModalService
{
    public modalSaved: EventEmitter<{
        component: any,
        object: any
    }> = new EventEmitter<{
        component: any,
        object: any
    }>();

    public modalClosed: EventEmitter<{
        component: any
    }> = new EventEmitter<{
        component: any
    }>();

    /**
     * @param {any} component
     * @param {any} object
     */
    public save(component: any, object: any) {
        this.modalSaved.emit({
            component: component,
            object: object,
        });
    }

    /**
     * @param {any} component
     */
    public close(component: any) {
        this.modalClosed.emit({
            component: component,
        });
    }
}
