<ng-template #unitResult let-unit="unit">
    <span class="d-block pb-2 text-uppercase font-weight-bold add-blue"><small>{{ 'Units' }}</small></span>
    <span class="d-block pb-1 font-weight-bold">{{ unit.title }}</span>
    <dl class="d-flex">
        <dt class="text-muted"><small>{{ 'Details' }}</small></dt>
        <dd class="px-3">
            <small>
                {{ 'Organization Name: ' + unit.organization.name }}<br>
                {{ 'Balance: ' + (unit.balance | legfiCurrency:'USD':'symbol':'.2') }}<br>
                {{ 'Address: ' + unit.addressString }}<br>
                <ng-container *ngIf="unit.owners.length > 0">
                    {{ 'Owners: ' + unit.ownerString }}
                </ng-container>
            </small>
        </dd>
    </dl>
</ng-template>

<ng-template #memberResult let-member="member">
    <span class="d-block pb-2 text-uppercase font-weight-bold add-blue"><small>{{ 'People' }}</small></span>
    <span class="d-block pb-1 font-weight-bold">{{ member.profile.name }}</span>
    <dl class="d-flex">
        <dt class="text-muted"><small>{{ 'Details' }}</small></dt>
        <dd class="px-3">
            <small>
                {{ 'Organization Name: ' + member.organization.name }}<br>
                {{ 'Email: ' + member.user.email }}<br>
                <ng-container *ngIf="member.owners.length > 0">
                    {{ 'Units: ' + member.unitsString }}<br>
                </ng-container>
            </small>
        </dd>
    </dl>
</ng-template>

<div class="dropdown-search-container">
    <lf-material-search [hasGroupAddon]="false"
                        [hasButtonStyles]="true"
                        [isLoading]="isLoading"
                        (updated)="isSearching = true; search($event)"
                        (click)="openIfResults()"></lf-material-search>
    <div class="dropdown-container mat-menu-container animated-short fadeIn"
         [hidden]="!matMenuOpened || isOnSearchPage">
        <div class="mat-menu-panel">
            <div *ngIf="searchResults.length !== 0 && !isLoading"
                 class="mat-menu-all-results">
                <a class="btn d-flex w-100 add-blue"
                   [routerLink]="['/app/search']"
                   mat-menu-item>{{ 'Show all results&hellip;' }}</a>
            </div>

            <div class="dropdown-search-results"
                 (click)="stopClickFromClosing($event)">
                <ng-container *ngIf="searchResults.length === 0  || isSearching; else results">
                    <div class="px-3 text-muted">{{ (isLoading || isSearching) ? '&hellip;' : 'No results found' }}</div>
                </ng-container>
                <ng-template #results>
                    <ng-container *ngFor="let item of searchResults">
                        <button class="text-wrap py-2"
                                (click)="requestRedirect(item); matSearch.clearSearch()"
                                mat-menu-item>
                            <ng-container *ngIf="item.index === 'units'">
                                <ng-template *ngTemplateOutlet="unitResult; context:{ unit: item.model }">
                                </ng-template>
                            </ng-container>
                            <ng-container *ngIf="item.index === 'members'">
                                <ng-template *ngTemplateOutlet="memberResult; context:{ member: item.model }">
                                </ng-template>
                            </ng-container>
                        </button>
                    </ng-container>
                </ng-template>
            </div>
        </div>
    </div>
</div>
