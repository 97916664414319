import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Organization } from './organization';
import { User } from './user';
import Moment = moment.Moment;

export class CreditCard
{
    id: number;
    stripeCardId: string;
    ownerId: number;
    ownerType: string;
    name: string;
    cardBrand: string;
    expMonth: number;
    expYear: number;
    last4: string;
    dynamicLast4: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;
    fresh: boolean = false;

    owner: Organization | User;

    constructor(creditCard: any) {
        if (creditCard.id) {
            this.id = creditCard.id;
        }
        this.fresh = creditCard.fresh || false;
        if (creditCard.stripeCardId) {
            this.stripeCardId = creditCard.stripeCardId;
        }
        if (creditCard.ownerId) {
            this.ownerId = creditCard.ownerId;
        }
        if (creditCard.ownerType) {
            this.ownerType = creditCard.ownerType;
        }
        if (creditCard.name) {
            this.name = creditCard.name;
        }
        if (creditCard.cardBrand) {
            this.cardBrand = creditCard.cardBrand.toLowerCase();
        }
        if (creditCard.expMonth) {
            this.expMonth = creditCard.expMonth;
        }
        if (creditCard.expYear) {
            this.expYear = creditCard.expYear;
        }
        if (creditCard.last4) {
            this.last4 = creditCard.last4;
        }
        if (creditCard.dynamicLast4) {
            this.dynamicLast4 = creditCard.dynamicLast4;
        }
        if (creditCard.address1) {
            this.address1 = creditCard.address1;
        }
        if (creditCard.address2) {
            this.address2 = creditCard.address2;
        }
        if (creditCard.city) {
            this.city = creditCard.city;
        }
        if (creditCard.state) {
            this.state = creditCard.state;
        }
        if (creditCard.zip) {
            this.zip = creditCard.zip;
        }
        if (creditCard.country) {
            this.country = creditCard.country;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (creditCard.createdAt) {
            this.createdAt = moment.utc(creditCard.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (creditCard.updatedAt) {
            this.updatedAt = moment.utc(creditCard.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (creditCard.deletedAt) {
            this.deletedAt = moment.utc(creditCard.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (creditCard.owner && creditCard.ownerType !== null) {
            if (creditCard.ownerType.indexOf('Organization') !== -1) {
                this.owner = new Organization(creditCard.owner);
            } else if (creditCard.ownerType.indexOf('User') !== -1) {
                this.owner = new User(creditCard.owner);
            }
        }
    }
}
