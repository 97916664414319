import { Component, Input } from '@angular/core';
import { AdjustColorValues } from '../../../directives/adjust-color/adjust-color.directive';

@Component({
    selector: 'app-color-square',
    templateUrl: './color-square.component.html',
    styleUrls: ['./color-square.component.scss']
})
export class ColorSquareComponent {
    @Input() color: string = '#ffffff';
    @Input() isDisabled: boolean;

    readonly adjustColorValues = AdjustColorValues;
}
