import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
    {
        path: 'community-association-management-software',
        redirectTo: 'hoa-management-software-landing',
    },
    {
        path: 'condo-management-software',
        redirectTo: 'hoa-management-software-landing',
    },
    {
        path: 'hoa-coa-association-management-software-app',
        redirectTo: 'hoa-management-software-landing',
    },
    {
        path: 'hoa-management-software',
        redirectTo: 'hoa-management-software-landing',
    },
    {
        path: 'hoa-management-software-landing',
        loadChildren:
                () => import('./hoa-management-software-landing/hoa-management-software-landing.module').then(m => m.HoaManagementSoftwareLandingModule),
    },
    {
        path: 'hoa-management-software-screens',
        loadChildren:
                () => import('./hoa-management-software-screens/hoa-management-software-screens.module').then(m => m.HoaManagementSoftwareScreensModule),
    },
    {
        path: 'organization/:orgId/verify',
        loadChildren:
                () => import('./organizations/email-verification/email-verification.module').then(m => m.EmailVerificationModule),
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MarketingLayoutRoutingModule
{ }
