import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegfiCurrencyPipe } from './legfi-currency.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [LegfiCurrencyPipe],
    exports: [LegfiCurrencyPipe],
})
export class LegfiCurrencyPipeModule
{
}
