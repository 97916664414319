import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { SharedDirectivesModule } from '../../shared/directives/shared-directives.module';
import { SidenavContentComponent } from './sidenav-content.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SidenavActionsDirective, SidenavCloseDirective, SidenavContentContainerDirective, SidenavContentDirective, SidenavLabelDirective, SidenavTitleDirective } from './sidenav-content.directive';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

@NgModule({
    imports: [
        CommonModule,
        SharedDirectivesModule,
        MatToolbarModule,
        MatIconModule,
        MatListModule,
        MatButtonModule,
        MatMenuModule,
        MatProgressBarModule,
    ],
    declarations: [
        SidenavContentComponent,
        SidenavContentContainerDirective,
        SidenavTitleDirective,
        SidenavContentDirective,
        SidenavActionsDirective,
        SidenavCloseDirective,
        SidenavLabelDirective,
    ],
    providers: [],
    exports: [
        SidenavContentComponent,
        SidenavContentContainerDirective,
        SidenavTitleDirective,
        SidenavContentDirective,
        SidenavActionsDirective,
        SidenavCloseDirective,
        SidenavLabelDirective,
    ],
})
export class SidenavContentModule
{
}
