import { Component } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
    selector: 'app-super-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class SuperButtonComponent extends ButtonComponent {
    constructor() {
        super();
        this.isSuperOnly = true;
    }
}
