import moment from 'moment-timezone';
import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';
import { Membership } from '../membership';

export class FormSubmissionComment
{
    id: number;
    submissionId: number;
    membershipId: number;
    message: string;
    isAdmin: boolean;

    createdAt: moment.Moment;
    updatedAt: moment.Moment;
    deletedAt: moment.Moment;

    membership: Membership;

    constructor(comment: any) {
        this.id = comment.id;
        this.submissionId = comment.submissionId;
        this.membershipId = comment.membershipId;
        this.message = comment.message;
        this.isAdmin = comment.isAdmin || false;

        const timezone = LegFiJwtService.getTimezone();
        if (comment.createdAt) {
            this.createdAt = moment.utc(comment.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (comment.updatedAt) {
            this.updatedAt = moment.utc(comment.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (comment.deletedAt) {
            this.deletedAt = moment.utc(comment.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (comment.membership) {
            this.membership = new Membership(comment.membership);
        }
    }

    get authorName() {
        return this.membership?.name || this.membership?.profile.name || this.membership?.user.email || 'Unknown';
    }
}
