export class OrganizationFormCount {
    id: number;
    name: string;

    isEnabled: boolean;
    isPrivate: boolean;

    approversCount: number;
    closedSubmissionsCount: number;
    openSubmissionsCount: number;
    submissionsCount: number;

    canUserRead: boolean;
    canUserWrite: boolean;
    canUserWriteStatuses: boolean;
    isUserApprover: boolean;

    constructor(request: any) {
        this.id = request.id || 0;
        this.name = request.name || '';

        this.isEnabled = request.isEnabled === 1 || false;
        this.isPrivate = request.isPrivate === 1 || false;

        this.approversCount = request.approversCount || 0;
        this.openSubmissionsCount = request.openSubmissionsCount || 0;
        this.closedSubmissionsCount = request.closedSubmissionsCount || 0;
        this.submissionsCount = request.submissionsCount || 0;

        // user access
        this.canUserRead = request.canUserRead;
        this.canUserWrite = request.canUserWrite;
        this.canUserWriteStatuses = request.canUserWriteStatuses;
        this.isUserApprover = request.isUserApprover;
    }
}
