import { Inject, Injectable, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, filter, Observable, of } from 'rxjs';
import { TrackingService } from './tracking.service';
import { ApplicationHttpClient } from '../components/shared/http/application-http-client';
import { map } from 'rxjs/operators';
import { SignUpTargets } from '../enums/sign-up-targets.enum';
import environment from '../../environments/environment';

export interface HubSpotConfig {
    portalId: string;
    boardMemberFormId: string;
    propertyMgrFormId: string;
    appLoaderId: string;
    appEmbedId: string;
}

// TODO: cleanup as model class in future release
export interface HubSpotInputs {
    community_name: HTMLInputElement;
    email: HTMLInputElement;
    firstname: HTMLInputElement;
    lastname: HTMLInputElement;
    phone: HTMLInputElement;
    submitted_from_ip: HTMLInputElement;
    utm_campaign: HTMLInputElement;
    utm_content: HTMLInputElement;
    utm_medium: HTMLInputElement;
    utm_source: HTMLInputElement;
    utm_term: HTMLInputElement;
}

@Injectable({
    providedIn: 'root',
})
export class HubSpotService {
    private config: HubSpotConfig;
    private _renderer: Renderer2;
    private inputs: HubSpotInputs;
    private form: HTMLFormElement;

    private readonly formSubmittedSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
            private _http: ApplicationHttpClient,
            private _rendererFactory: RendererFactory2,
            @Inject(DOCUMENT) private document: Document
    ) {
        this._renderer = this._rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });

        this.config = {
            portalId: environment.HubSpot.portalId,
            boardMemberFormId: environment.HubSpot.forms.board,
            propertyMgrFormId: environment.HubSpot.forms.pmc,
            appLoaderId: 'hs-script-loader',
            appEmbedId: 'hs-script-forms-embed',
        };
    }

    get formSubmitted$() {
        return this.formSubmittedSubject$.asObservable().pipe(filter((res) => !!res));
    }

    get formContainer() {
        return 'hsFormContainer';
    }

    get ipAddress(): Observable<string> {
        if (!environment.production) {
            return of('127.0.0.1');
        }

        return this._http.get('https://api.ipify.org?format=json').pipe(
                map((data: { ip: string }) => data.ip),
        );
    }

    togglePixel(url: string) {
        if (!environment.production) {
            return;
        }

        // include HubSpot pixel for tracking in non-homeowner sign-up routes
        if (url.indexOf('/sign-up') > -1 && url.indexOf('/sign-up/homeowner') === -1) {
            const scriptElement = this.document.head.querySelector(`#${this.config.appLoaderId}`);
            if (!scriptElement) {
                this.injectTrackingScript();
            }
        } else {
            const scriptElement = this.document.head.querySelector(`#${this.config.appLoaderId}`);
            if (scriptElement) {
                this._renderer.removeChild(this.document.head, scriptElement);
            }
        }
    }

    event(name: string, props: { [prop: string]: any; }) {
        if (!environment.production) {
            return;
        }

        const w = window as any;
        const _hsq = (w._hsq = w._hsq || []);
        _hsq?.push([
            name,
            props,
        ]);
    }

    addEmbeddedForm(formTarget: SignUpTargets) {
        if (!environment.production) {
            return;
        }

        const scriptElement = this.document.body.querySelector(`#${this.config.appEmbedId}`);
        if (!scriptElement && this.document.body.querySelector(`#${this.formContainer}`)) {
            switch(formTarget) {
                case SignUpTargets.BOARD_MEMBER:
                    this.injectFormsScript(this.config.boardMemberFormId);
                    break;
                case SignUpTargets.PROPERTY_MANAGER:
                    this.injectFormsScript(this.config.propertyMgrFormId);
                    break;
                default:
                    console.error('unsupported form target for sign-up');
                    break;
            }
        }
    }

    removeEmbeddedForm() {
        if (!environment.production) {
            return;
        }

        this.formSubmittedSubject$.next(false);

        const scriptElement = this.document.body.querySelector(`#${this.config.appEmbedId}`);
        if (scriptElement) {
            this._renderer.removeChild(this.document.body, scriptElement);
        }
    }

    submitHsForm(values: { [key: string]: any; }) {
        if (!environment.production) {
            this.formSubmittedSubject$.next(true);
            return of(true);
        }

        if (this.inputs?.community_name) {
            this.inputs.community_name.value = values?.groupName;
        }

        if (this.inputs?.email) {
            this.inputs.email.value = values?.email;
        }

        if (this.inputs?.firstname && this.inputs?.lastname && values?.fullName) {
            const name: string[] = values.fullName.split(' ');
            this.inputs.firstname.value = name.shift();
            this.inputs.lastname.value = name.join(' ');
        }

        if (this.inputs?.phone) {
            this.inputs.phone.value = values?.phone;
        }

        if (this.inputs?.submitted_from_ip) {
            this.inputs.submitted_from_ip.value = values?.ipAddress;
        }

        if (TrackingService.read()) {
            const tracking = TrackingService.read();
            const queryParams = tracking.queryParams;
            if (queryParams) {
                Object.keys(queryParams).forEach((key) => {
                    if (this.inputs[key]) {
                        this.inputs[key].value = queryParams[key];
                    }
                });
            }
        }

        // Remove tracking data stored in local storage
        TrackingService.clear();

        this.form.submit();
        return this.formSubmittedSubject$.asObservable();
    }

    private injectTrackingScript() {
        // attach hs tracking script to dom
        const script: HTMLScriptElement = this._renderer.createElement('script');
        script.id = this.config.appLoaderId;
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = `https://js.hs-scripts.com/${this.config.portalId}.js`;
        this._renderer.appendChild(this.document.head, script);
    }

    private injectFormsScript(formId: string) {
        this.inputs = {} as HubSpotInputs;
        this.form = null;

        // attach hs forms api script to dom
        const script: HTMLScriptElement = this._renderer.createElement('script');
        script.id = this.config.appEmbedId;
        script.type = 'text/javascript';
        script.charset = 'utf-8';
        script.src = `https://js.hsforms.net/forms/embed/v2.js`;
        script.onload = () => {
            const w = window as any;
            const _hbspt = (w.hbspt = w.hbspt || []);
            _hbspt?.forms?.create({
                portalId: this.config.portalId,
                formId,
                target: `#${this.formContainer}`,
                onFormReady: (form: HTMLFormElement) => {
                    this.form = form;
                    for (const key of Object.keys(this.form?.elements)) {
                        // filter out the numeric fields
                        if (!(/\d/.test(key))) {
                            this.inputs[key] = this.form.elements[key];
                        }
                    }
                },
                onFormSubmitted: () => {
                    this.formSubmittedSubject$.next(true);
                },
            });
        };

        this._renderer.appendChild(this.document.body, script);
    }
}
