import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppNavItem } from '../../../../models/entities/app-nav-item';
import { JwtLegFiClaims } from '../../../../services/auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../../../../services/auth/legfi-jwt.service';
import { ActivatedRoute, Event, NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SurveysService } from '../../../../services/communication/surveys.service';
import { OrganizationService } from '../../../../services/organization/organization.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { filter, Observable } from 'rxjs';
import { RequestFormService } from '../../../../services/forms/request-form.service';
import { OrganizationPreference } from '../../../../models/entities/organization-preference';
import { map } from 'rxjs/operators';
import { AppNavigationService } from '../../../../services/app-navigation.service';
import { AppNavigationKeys } from '../../../../enums/app-navigation-keys.enum';
import { AuthService } from '../../../../services/auth/auth.service';

@UntilDestroy()
@Component({
    selector: 'app-page-menu',
    templateUrl: './page-menu.component.html',
})
export class PageMenuComponent implements OnInit
{
    @Input() isAdminMenu: boolean;
    @Output() toggleSidenav: EventEmitter<void> = new EventEmitter<void>();

    jwt: JwtLegFiClaims;
    isLoggedIn = false;
    appNavigation$: Observable<AppNavItem[]> = this._appNavigationService.navigation$();
    appNavigationKeys = AppNavigationKeys;

    constructor(
            private _router: Router,
            private _activatedRoute: ActivatedRoute,
            private _observer: BreakpointObserver,
            private _formService: RequestFormService,
            private _surveysService: SurveysService,
            private _organizationService: OrganizationService,
            private _appNavigationService: AppNavigationService,
            private _authService: AuthService,
    ) {
        this.jwt = LegFiJwtService.read();
        this.isLoggedIn = this.jwt !== null;
    }

    ngOnInit() {
        this._appNavigationService.resetNavigation((this.jwt.superUser || this.jwt.impersonatedBy) && this.isAdminMenu);
        this._organizationService.getOrganizationById(null, ['preferences']).pipe(
                map((org) => {
                    this._appNavigationService.updateLaunchpad(org);
                    return org.preferences || {} as OrganizationPreference;
                }),
                untilDestroyed(this),
        ).subscribe({
            next: (preferences) => {
                this._appNavigationService.updateWithPreferences(preferences);
                this._appNavigationService.updateActiveRoute(this._router.url);

                if (!this.jwt.superUser && !this.jwt.admin && preferences.surveysModuleEnabled) {
                    this._surveysService.getSurveys(null).pipe(untilDestroyed(this)).subscribe({
                        next: (surveys) => this._appNavigationService.updateSurveys(!!surveys.filter(s => !s.closed)),
                    });
                }
            },
        });

        const hasCustomFormsPermissions = LegFiJwtService.doesUserHaveModulePermission('forms', false);
        if (hasCustomFormsPermissions) {
            this._appNavigationService.updateCustomFormsPermissions(hasCustomFormsPermissions);
        } else {
            this._formService.getOrgFormsForMember(this.jwt.memberId).pipe(untilDestroyed(this)).subscribe({
                next: (forms) => {
                    this._appNavigationService.updateCustomFormsPermissions(forms.filter((f) => f.canUserRead || f.canUserWrite).length > 0);
                },
            });
        }

        this._activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe({
            next: (params) => {
                this._appNavigationService.updateActiveRoute(this._router.url, params);
            },
        });

        this._router.events.pipe(
                filter((event) => event instanceof NavigationStart),
                untilDestroyed(this),
        ).subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                this._appNavigationService.updateActiveRoute(event.url);
            }
        });

        this._observer.observe(['(max-width: 945px)']).pipe(untilDestroyed(this)).subscribe((res) => {
            this._appNavigationService.updateMobileMode(res.matches);
        });
    }

    updateSideNav() {
        this.toggleSidenav.emit();
    }

    redirectToExternal(navItem: AppNavItem) {
        if (navItem.externalUrl) {
            window.open(navItem.externalUrl, '_blank');
            return false;
        }
    }

    /** Special case for log out nav item in mobile view */
    logout() {
        this._authService.logout();
    }
}
