import { ModuleWithProviders, NgModule } from '@angular/core';
import { NGB_DROPDOWN_DIRECTIVES } from './dropdown.directive';
import { LFDropdownConfig } from './dropdown.config';

export { LFDropdownConfig } from './dropdown.config';

@NgModule({declarations: NGB_DROPDOWN_DIRECTIVES, exports: NGB_DROPDOWN_DIRECTIVES})
export class LFDropdownModule
{
    static forRoot(): ModuleWithProviders<any> {
        return {ngModule: LFDropdownModule, providers: [LFDropdownConfig]};
    }
}
