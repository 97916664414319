export class SiteIdentifier
{
    static origin() {
        return window.location.origin;
    }

    static identity() {
        return 'payhoa';
    }

    static formattedName() {
        return 'PayHOA';
    }

    static contextTenantUppercase() {
        return 'Owner';
    }

    public static contextTenantLowercase() {
        return 'owner';
    }

    public static propertyContext() {
        return 'community';
    }
}
