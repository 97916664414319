import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhereKeyMatchesPipe } from './where-key-matches.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [WhereKeyMatchesPipe],
    exports: [WhereKeyMatchesPipe],
})
export class WhereKeyMatchesPipeModule
{
}
