import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener, OnInit, ViewContainerRef } from '@angular/core';
import { SidenavContentService } from './sidenav-content.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Directive({
    selector: '[sidenavContentContainer]',
})
export class SidenavContentContainerDirective {
    constructor(public viewContainerRef: ViewContainerRef) { }
}

@Directive({
    selector: `[sidenav-close], sidenav-close, [sidenavClose]`,
})
export class SidenavCloseDirective {
    @HostBinding('class') classValue = 'sidenav-close';

    constructor(private _sidenav: SidenavContentService) {
    }

    @HostListener('click', ['$event']) onClick(){
        this._sidenav.close();
    }
}

@Directive({
    selector: '[sidenav-label], sidenav-label, [sidenavLabel]',
})
export class SidenavLabelDirective
{
    @HostBinding('class') classValue = 'sidenav-label';
}


@UntilDestroy()
@Directive({
    selector: `[sidenav-content], sidenav-content, [sidenavContent]`,
})
export class SidenavContentDirective implements OnInit {
    @HostBinding('class') classValues = 'sidenav-content animated fadeIn';
    @HostBinding('class.with-actions') contentHasActions = false;

    constructor(private _sidenav: SidenavContentService) {
    }

    ngOnInit(): void {
        this._sidenav._actions$.pipe(untilDestroyed(this)).subscribe({
            next: (elementRef) => this.contentHasActions = !!elementRef,
        });
    }
}

@Directive({
    selector: `[sidenav-title], sidenav-title, [sidenavTitle]`,
})
export class SidenavTitleDirective implements AfterViewInit {
    constructor(private _el: ElementRef, private _sidenav: SidenavContentService) {
    }

    ngAfterViewInit(): void {
        this._sidenav._title = this._el;
    }
}

@Directive({
    selector: `[sidenav-actions], sidenav-actions, [sidenavActions]`,
})
export class SidenavActionsDirective implements OnInit {
    constructor(private _el: ElementRef, private _sidenav: SidenavContentService) {
    }

    ngOnInit(): void {
        this._sidenav._actions = this._el;
    }
}
