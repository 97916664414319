import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Site } from './site';
import { Subscription } from './subscription';
import { SubscriptionProduct } from './subscription-product';

export class Product
{
    id: number;
    siteId: number;
    key: string;
    label: string;
    description: string;
    addon: boolean;
    available: boolean;
    createdAt: Moment;
    updatedAt: Moment;

    site: Site;
    subscriptions: Subscription[];
    subscriptionProducts: SubscriptionProduct[];

    constructor(product: any) {
        if (product.id) {
            this.id = product.id;
        }
        if (product.siteId) {
            this.siteId = product.siteId;
        }
        if (product.key) {
            this.key = product.key;
        }
        if (product.label) {
            this.label = product.label;
        }
        if (product.description) {
            this.description = product.description;
        }
        if (product.addon) {
            this.addon = product.addon;
        }
        if (product.available) {
            this.available = product.available;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (product.createdAt) {
            this.createdAt = moment.utc(product.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (product.updatedAt) {
            this.updatedAt = moment.utc(product.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (product.site) {
            this.site = new Site(product.site);
        }
        if (product.subscriptions) {
            this.subscriptions = product.subscriptions.map(
                    (subscription) => {
                        return new Subscription(subscription);
                    },
            );
        }
        if (product.subscriptionProducts) {
            this.subscriptionProducts = product.subscriptionProducts.map(
                    (subscriptionProduct) => {
                        return new SubscriptionProduct(subscriptionProduct);
                    },
            );
        }
    }
}
