import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { DailySubscriptionStatus } from './daily-subscription-status';
import { DepositBankAccount } from './deposit-bank-account';
import { OrganizationProfile } from './organization-profile';
import { OrganizationRates } from './organization-rates';
import { PaymentAttempt } from './payment-attempt';
import { Subscription } from './subscription';
import { UnifiedBankAccount } from './unified-bank-account';
import { PaymentGroup } from './payment-group';
import { CustomUnitField } from './custom-unit-field';
import { OrganizationPreference } from 'app/models/entities/organization-preference';
import { CreditCard } from './credit-card';
import { PaymentBankAccount } from './payment-bank-account';
import Moment = moment.Moment;
import { AccountCredit } from './account-credit';
import { PaginationMeta } from './pagination';
import { Membership } from './membership';

export class Organization
{
    id: number;
    siteId: number;
    parentOrganizationId: number;
    budgetTemplateId: number;
    name: string;
    verified: boolean;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    customUnitFields?: CustomUnitField[];

    billingSubscription: Subscription;
    isRollupGroup: boolean;
    fundableAmount: number;
    stripeDepositLegalEntities: any[];
    hasBillingMethod: boolean;
    pastDueBalance: number;
    propertyManagementCompanyId: number = null;
    propertyManagementCompanyName: string;
    unitsCount = null;

    profile: OrganizationProfile;
    rates: OrganizationRates;
    unifiedBankAccounts: UnifiedBankAccount[];
    depositBankAccounts: DepositBankAccount[];
    paymentBankAccounts: PaymentBankAccount[];
    dailySubscriptionStatuses: DailySubscriptionStatus[];
    subscriptionPayments: PaymentGroup[];
    subscriptions: Subscription[];
    unassociatedFunds: PaymentAttempt[];
    site: any;
    children: Organization[];
    preferences: OrganizationPreference;
    creditCards: CreditCard[];
    accountCredits: AccountCredit[] = [];
    admins: Membership[];

    constructor(organization: any) {
        if (organization.id) {
            this.id = organization.id;
        }
        if (organization.siteId) {
            this.siteId = organization.siteId;
        }
        if (organization.parentOrganizationId) {
            this.parentOrganizationId = organization.parentOrganizationId;
        }
        if (organization.budgetTemplateId) {
            this.budgetTemplateId = organization.budgetTemplateId;
        }
        if (organization.name) {
            this.name = organization.name;
        }
        if (organization.verified) {
            this.verified = organization.verified;
        }

        if (organization.site) {
            this.site = organization.site;
        }

        if (organization.children) {
            this.children = organization.children;
        }

        if (organization.billingSubscription) {
            this.billingSubscription = new Subscription(organization.billingSubscription);
        }
        if (organization.isRollupGroup) {
            this.isRollupGroup = organization.isRollupGroup;
        }
        if (organization.fundableAmount) {
            this.fundableAmount = organization.fundableAmount;
        }
        if (organization.stripeDepositLegalEntities) {
            this.stripeDepositLegalEntities = organization.stripeDepositLegalEntities;
        }
        if (organization.hasBillingMethod) {
            this.hasBillingMethod = organization.hasBillingMethod;
        }
        if (organization.propertyManagementCompanyId) {
            this.propertyManagementCompanyId = organization.propertyManagementCompanyId;
        }
        if (organization.propertyManagementCompanyName) {
            this.propertyManagementCompanyName = organization.propertyManagementCompanyName;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (organization.createdAt) {
            this.createdAt = moment.utc(organization.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (organization.updatedAt) {
            this.updatedAt = moment.utc(organization.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (organization.deletedAt) {
            this.deletedAt = moment.utc(organization.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (organization.customUnitFields) {
            this.customUnitFields = organization.customUnitFields.map((customUnitField) => {
                return new CustomUnitField(customUnitField);
            });
        }

        if (organization.accountCredits) {
            this.accountCredits = organization.accountCredits.map((accountCredit) => {
                return new AccountCredit(accountCredit);
            });
        }

        if (organization.profile) {
            this.profile = new OrganizationProfile(organization.profile);
        }
        if (organization.rates) {
            this.rates = new OrganizationRates(organization.rates);
        }
        if (organization.unifiedBankAccounts) {
            this.unifiedBankAccounts = organization.unifiedBankAccounts.map(
                    (bankAccount) => {
                        return new UnifiedBankAccount(bankAccount);
                    },
            );
        }
        if (organization.depositBankAccounts) {
            this.depositBankAccounts = organization.depositBankAccounts.map(
                    (depositBankAccount) => {
                        return new DepositBankAccount(depositBankAccount);
                    },
            );
        }
        if (organization.dailySubscriptionStatuses) {
            this.dailySubscriptionStatuses = organization.dailySubscriptionStatuses.map(
                    (dailySubscriptionStatus) => {
                        return new DailySubscriptionStatus(dailySubscriptionStatus);
                    },
            );
        }
        if (organization.subscriptionPayments) {
            this.subscriptionPayments = organization.subscriptionPayments.map(
                    (payment) => {
                        return new PaymentGroup(payment);
                    },
            );
        }
        if (organization.subscriptions) {
            this.subscriptions = organization.subscriptions.map(
                    (subscription) => {
                        return new Subscription(subscription);
                    },
            );
        }
        if (organization.unassociatedFunds) {
            this.unassociatedFunds = organization.unassociatedFunds.map(
                    (payment) => {
                        return new PaymentAttempt(payment);
                    },
            );
        }
        if (organization.preferences) {
            this.preferences = new OrganizationPreference(organization.preferences);
        }
        if (organization.hasOwnProperty('pastDueBalance')) {
            this.pastDueBalance = organization.pastDueBalance;
        }
        if (organization.creditCards) {
            this.creditCards = organization.creditCards.map(cc => new CreditCard(cc));
        }
        if (organization.paymentBankAccounts) {
            this.paymentBankAccounts = organization.paymentBankAccounts.map(cc => new PaymentBankAccount(cc));
        }
        if (organization.unitsCount) {
            this.unitsCount = organization.unitsCount;
        }
        if (organization.admins) {
            this.admins = organization.admins.map((a) => new Membership(a));
        }
    }

    get creditSum() {
        return this.accountCredits.reduce((total, credit) => {
            return total + credit.remaining;
        }, 0);
    }

    get initials(): string {
        const parts = this.name.split(' ').map((p) => {
            return p.split(/(?=[A-Z])/).filter((n) => {
                const initial = n.charAt(0);
                return !isNaN(+initial) || initial === initial.toUpperCase();
            });
        });
        const flattened = parts.reduce((a, b) => a.concat(b), []);
        return flattened[0].charAt(0) + (flattened[1] ? flattened[1].charAt(0) : '');
    }

    get adminNames(): string {
        return this.admins.map((u) => u.profile.givenNames + ' ' + u.profile.familyName).join(',<br>');
    }
}

export class SimpleOrganization {
    id: number;
    name: string;
    visible: boolean;

    constructor(organization: any) {
        if (organization.id) {
            this.id = organization.id;
        }
        if (organization.name) {
            this.name = organization.name;
        }

        // managing visibility in the header-menu
        this.visible = false;
    }

    get initials(): string {
        const parts = this.name.split(' ').map((p) => {
            return p.split(/(?=[A-Z])/).filter((n) => {
                const initial = n.charAt(0);
                return !isNaN(+initial) || initial === initial.toUpperCase();
            });
        });
        const flattened = parts.reduce((a, b) => a.concat(b), []);
        return flattened[0].charAt(0) + (flattened[1] ? flattened[1].charAt(0) : '');
    }
}


export class OrganizationResponse
{
    data: Organization[];
    meta: PaginationMeta;

    constructor(request: any) {
        this.data = (request.data && request.data.length) ? request.data.map((c: any) => {
            return new Organization(c);
        }) : [];
        this.meta = new PaginationMeta(request);
    }
}

