<div class="mx-0">
    <div class="d-flex flex-column align-items-center p-4 text-center">
        <div class="d-flex mb-5">
            <img alt="{{ 'Advanced Integration with Alliance Association Bank' }}" class="img-fluid" src="/assets/images/v2/onboarding/alliance-banking/alliance-banking-integration.svg" />
        </div>
        <h2 class="feature-section-header large-font">{{ 'Advanced Integration with Alliance Association Bank' }}</h2>
        <p class="feature-text">{{ 'Take your HOA accounting software to the next level with our direct integration with Alliance Association Bank, enhancing your financial capabilities and efficiency.' }}</p>
        <app-feature-button (onClick)="requestScheduleCall.emit()">{{ 'Schedule a Call' }}</app-feature-button>
    </div>
</div>

<div class="grid-layout grid-5 full-dialog mx-0">
    <div class="card rounded-xl grid-col-span-2">
        <div class="card-body pb-0">
            <div class="d-flex flex-column h-100 w-100 pt-4 px-4">
                <p class="feature-header">{{ 'Integrated Check Images & Bank Statements' }}</p>
                <p class="feature-text">{{ 'View check images and access bank statements conveniently within your PayHOA account.' }}</p>
                <div class="d-flex mx-auto mt-auto">
                    <img alt="{{ 'Integrated Check Images & Bank Statements' }}" class="img-fluid" src="/assets/images/v2/onboarding/alliance-banking/integrated-check-images.svg" />
                </div>
            </div>
        </div>
    </div>

    <div class="card rounded-xl grid-col-span-3">
        <div class="card-body">
            <div class="d-flex flex-column h-100 w-100 p-4">
                <p class="feature-header">{{ 'Direct API Connection for Accuracy' }}</p>
                <p class="feature-text">{{ 'Ensure precision with a direct API connection that guarantees accurate transaction data.' }}</p>
                <div class="d-flex mx-auto mt-auto">
                    <img alt="{{ 'Direct API Connection for Accuracy' }}" class="img-fluid" src="/assets/images/v2/onboarding/alliance-banking/direct-api-connection.svg" />
                </div>
            </div>
        </div>
    </div>

    <div class="card rounded-xl grid-col-span-3">
        <div class="card-body pb-0">
            <div class="d-flex flex-column h-100 w-100 pt-4 px-4">
                <p class="feature-header">{{ 'Free Lockbox Payment Processing' }}</p>
                <p class="feature-text">{{ 'Lockbox provides a PO BOX for your owners to mail check payments. Those checks are scanned, deposited electronically and coded automatically. Lockbox eliminates the need for you to open envelopes, record payments, make bank deposits, and code the revenue.' }}</p>
                <div class="d-flex mx-auto mt-auto">
                    <img alt="{{ 'Free Lockbox Payment Processing' }}" class="img-fluid" src="/assets/images/v2/onboarding/alliance-banking/lockbox-payment-processing.svg" />
                </div>
            </div>
        </div>
    </div>

    <div class="card rounded-xl grid-col-span-2">
        <div class="card-body pb-0">
            <div class="d-flex flex-column h-100 w-100 pt-4 px-4">
                <p class="feature-header">{{ 'Internal Funds Transfer' }}</p>
                <p class="feature-text">{{ 'Simplify financial management by transferring funds between various accounts directly within the PayHOA platform.' }}</p>
                <div class="d-flex mx-auto mt-auto">
                    <img alt="{{ 'Internal Funds Transfer' }}" class="img-fluid" src="/assets/images/v2/onboarding/alliance-banking/internal-funds-transfer.svg" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card rounded-xl mt-5 mx-0">
    <div class="card-body">
        <div class="d-flex flex-column align-items-center mt-3 p-4">
            <h2 class="feature-section-header large-font">{{ 'Ready to Get Started?' }}</h2>
            <app-feature-button (onClick)="requestScheduleCall.emit()">{{ 'Schedule a Call' }}</app-feature-button>
        </div>
    </div>
</div>
