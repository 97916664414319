import { ColorSourcesEnum } from '../../enums/color-sources.enum';

export class Tag {
    id: number;
    organizationId: number;
    taggableId: number;
    taggableType: string;
    taggableColor: string;
    tag: string = '';
    internalUse: number = 0;
    filter: string = 'equals';

    constructor(tag: any) {
        if (tag.id) {
            this.id = tag.id;
        }
        if (tag.organizationId) {
            this.organizationId = tag.organizationId;
        }
        if (tag.taggableId) {
            this.taggableId = tag.taggableId;
        }
        if (tag.taggableType) {
            this.taggableType = tag.taggableType;
        }
        if (tag.tag) {
            this.tag = tag.tag;
        }
        if (tag.internalUse) {
            this.internalUse = tag.internalUse;
        }

        this.taggableColor = tag.taggableColor || ColorSourcesEnum.OFF_WHITE;

        if (tag.filter) {
            this.filter = tag.filter;
        }
    }

    public get readonly() {
        return this.internalUse > 0;
    }

    public get display() {
        return this.tag;
    }

    public set display(val) {
        this.tag = val;
    }

    public get value() {
        return this.tag;
    }

    public set value(val) {
        this.tag = val;
    }
}
