import { Inject, Injectable, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';

/** https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects#messenger-attributes */
export interface IntercomMessengerConfig {
    app_id: string;
    custom_launcher_selector?: string;
    alignment?: 'left' | 'right';
    vertical_padding?: number;
    horizontal_padding?: number;
    hide_default_launcher?: boolean;
    session_duration?: number;
    action_color?: string;
    background_color?: string;
}

/** https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects#data-attributes */
export interface IntercomDataConfig {
    email?: string;
    user_id?: number;
    name?: string;
    admin?: boolean;
    super?: boolean;
    can_send_message?: boolean;
    company?: {
        id: number;
        name: string;
    };
}

type IntercomSettings = IntercomMessengerConfig & IntercomDataConfig;

@Injectable({
    providedIn: 'root',
})
export class IntercomService {
    private config: IntercomSettings;
    private _renderer: Renderer2;

    constructor(
            private _rendererFactory: RendererFactory2,
            @Inject(DOCUMENT) private document: Document
    ) {
        this._renderer = this._rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });

        this.config = {
            app_id: environment.Intercom.appId,
        };
    }

    boot(config: IntercomDataConfig = {}) {
        const settings = {
            ...this.config,
            ...config,
        };
        const w = window as any;

        if (typeof w.Intercom === 'function') {
            w.Intercom('reattach_activator');
            w.Intercom('update', settings);
        } else {
            this.injectScript(settings);
            w.Intercom('update', settings);
        }
    }

    shutdown() {
        const w = window as any;
        if (typeof w.Intercom === 'function') {
            w.Intercom('shutdown');
        }
    }

    newMessage() {
        const w = window as any;
        if (typeof w.Intercom === 'function') {
            // 'showMessages'
            w.Intercom('showNewMessage');
        }
    }

    private injectScript(config: IntercomSettings) {
        const w = window as any;
        const i = () => {
            i.c(arguments);
        };
        i.q = [];
        i.c = (args) => {
            i.q.push(args);
        };
        w.Intercom = i;

        // window config for Intercom
        w.intercomSettings = config;

        // attach script to dom
        const script = this._renderer.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://widget.intercom.io/widget/${config.app_id}`;

        this._renderer.appendChild(this.document.head, script);
    }
}
