export class DashboardWidget
{
    id: number;
    image: string;
    total: number;
    title: string;
    shortTitle: string;
    url: string;
    rank: number;
    isEnabled: boolean;

    constructor(request: any) {
        this.id = request.id || null;
        this.image = request.image || null;
        this.total = request.total || 0;
        this.title = request.title || null;
        this.rank = request.rank || null;
        this.url = request.url || null;
        this.isEnabled = request.isEnabled;

        this.shortTitle = request.title && request.title.includes('Requests')
                ? request.title.split(' ')[0]
                : request.title;

    }
}
