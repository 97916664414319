<mat-toolbar #sidenavHeader>
    <toolbar-title>{{ title }}</toolbar-title>

    <toolbar-spacer></toolbar-spacer>

    <button mat-icon-button disableRipple [attr.aria-label]="'Close ' + title" sidenav-close>
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<mat-progress-bar mode="query"
                  [class.payhoa-working-progress-bar]="true"
                  [class.active]="loading$ | async">
</mat-progress-bar>

<ng-template sidenavContentContainer></ng-template>

<mat-toolbar #sidenavActions [hidden]="!showActions"></mat-toolbar>
