import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoticeBarComponent } from './notice-bar.component';
import { OrganizationService } from '../../../../services/organization/organization.service';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [NoticeBarComponent],
    exports: [NoticeBarComponent],
    providers: [OrganizationService],
})
export class NoticeBarModule
{
}
