// Types of questions that can be in a form; may expand later
export const FormQuestionTypeEnum: FormQuestionType[] = [
    {id: 1, type: 'input', label: 'Text input', icon: 'fa-i-cursor'},
    {id: 2, type: 'textarea', label: 'Textarea', icon: 'fa-commenting-o'},
    {id: 3, type: 'file', label: 'File upload', icon: 'fa-paperclip'},
    {id: 4, type: 'checkbox', label: 'Checkbox', icon: 'fa-check-square-o'},
    {id: 5, type: 'select', label: 'Dropdown (choose one)', icon: 'fa-caret-square-o-down'},
    {id: 6, type: 'multiselect', label: 'Dropdown (choose multiple)', icon: 'fa-caret-square-o-down'},
    {id: 7, type: 'plaintext', label: 'Add your own informational text', icon: ''},
    {id: 8, type: 'hr', label: 'Divider', icon: ''},
];

/**
 * Basically lookup for what types of questions can be in a form.
 */
export class FormQuestionType
{
    id: number;
    icon: string;
    type: string;
    label: string;

    constructor(request: any) {
        this.id = request.id;
        this.icon = request.icon;
        this.label = request.label || '';

        this.type = request.type;
    }
}
