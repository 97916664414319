import { AppAdminNavigationOrder, AppMobileNavigationOrder, AppNavigationOrder } from '../enums/app-navigation-order.enum';
import { AppNavigationProperties } from '../models/entities/app-navigation-properties';
import { AppNavigationKeys } from '../enums/app-navigation-keys.enum';

export class AppNavigation
{
    static readonly unitIdKey = '[UNIT_ID]';
    static SideNavigation: AppNavigationProperties[] = [
        {
            id: AppNavigationKeys.LAUNCHPAD,
            text: 'Launchpad',
            icon: 'launchpad',
            route: ['/app/launchpad'],
            order: AppNavigationOrder.LAUNCHPAD,
        },
        {
            id: AppNavigationKeys.ORG_DASHBOARD,
            text: 'Dashboard',
            icon: 'dashboard',
            route: ['/app/organization/dashboard'],
            order: AppNavigationOrder.DASHBOARD,
        },
        {
            id: AppNavigationKeys.MEMBER_DASHBOARD,
            text: 'Dashboard',
            icon: 'dashboard',
            route: ['/app/owner/dashboard'],
            order: AppNavigationOrder.DASHBOARD,
        },
        {
            id: AppNavigationKeys.UNIT_LIST,
            text: 'Units',
            icon: 'units',
            route: ['/app/unit/list'],
            order: AppNavigationOrder.UNITS,
        },
        {
            id: AppNavigationKeys.PEOPLE_LIST,
            text: 'People',
            icon: 'members',
            route: ['/app/people'],
            order: AppNavigationOrder.PEOPLE,
        },
        {
            id: AppNavigationKeys.INVOICES,
            text: 'Invoices',
            icon: 'invoices',
            route: ['/app/charges/organization/issued'],
            order: AppNavigationOrder.INVOICES,
        },
        {
            id: AppNavigationKeys.PAYMENTS,
            text: 'Payments',
            icon: 'payments',
            route: ['/app/payments/incoming'],
            order: AppNavigationOrder.PAYMENTS,
            hasMarginBelow: true,
        },
        {
            id: AppNavigationKeys.VENDORS,
            text: 'Vendors',
            icon: 'billpay',
            route: ['/app/vendors'],
            order: AppNavigationOrder.VENDORS,
        },
        {
            id: AppNavigationKeys.PAYABLES,
            text: 'Payables',
            icon: 'payables',
            route: ['/app/payables'],
            order: AppNavigationOrder.PAYABLES,
        },
        {
            id: AppNavigationKeys.TRANSACTIONS,
            text: 'Transactions',
            icon: 'transactions',
            route: ['/app/transactions'],
            order: AppNavigationOrder.TRANSACTIONS,
        },
        {
            id: AppNavigationKeys.BUDGETS,
            text: 'Budgets',
            icon: 'budgets',
            route: ['/app/budgets'],
            order: AppNavigationOrder.BUDGETS,
        },
        {
            id: AppNavigationKeys.REPORTS,
            text: 'Reports',
            icon: 'reports',
            route: ['/app/reports'],
            order: AppNavigationOrder.REPORTS,
            hasMarginBelow: true,
        },
        {
            id: AppNavigationKeys.BROADCAST,
            text: 'Broadcast',
            icon: 'communication',
            route: ['/app/broadcast'],
            order: AppNavigationOrder.BROADCAST,
        },
        {
            id: AppNavigationKeys.MAIL_ROOM,
            text: 'Mail Room',
            icon: 'mailbox',
            route: ['/app/mail'],
            order: AppNavigationOrder.MAIL_ROOM,
        },
        {
            id: AppNavigationKeys.OTHER,
            text: 'Other Tools',
            icon: 'other',
            route: ['/app/other-tools'],
            order: AppNavigationOrder.OTHER,
            hasMarginBelow: true,
        },
        {
            id: AppNavigationKeys.SURVEYS,
            text: 'Surveys',
            icon: 'reports',
            route: ['/app/surveys'],
            order: AppNavigationOrder.SURVEYS,
        },
        {
            id: AppNavigationKeys.MESSAGE_BOARD,
            text: 'Message Board',
            icon: 'communication',
            route: ['/app/message-board/boards'],
            order: AppNavigationOrder.MESSAGE_BOARDS,
        },
        {
            id: AppNavigationKeys.CALENDAR,
            text: 'Calendar',
            icon: 'calendar',
            route: ['/app/calendar'],
            order: AppNavigationOrder.CALENDAR,
        },
        {
            id: AppNavigationKeys.ORG_REQUESTS,
            text: 'Requests',
            icon: 'org_requests',
            route: '/app/forms',
            order: AppNavigationOrder.ORG_REQUESTS,
        },
        {
            id: AppNavigationKeys.MEMBER_REQUESTS,
            text: 'Requests',
            icon: 'requests',
            route: [
                `/app/unit/detail/${this.unitIdKey}`,
                {tab: 'requests'},
            ],
            order: AppNavigationOrder.REQUESTS,
        },
        {
            id: AppNavigationKeys.ORG_VIOLATIONS,
            text: 'Violations',
            icon: 'violations',
            route: '/app/violations',
            order: AppNavigationOrder.ORG_VIOLATIONS,
        },
        {
            id: AppNavigationKeys.MEMBER_VIOLATIONS,
            text: 'Violations',
            icon: 'violations',
            route: ['/app/violations/member'],
            order: AppNavigationOrder.VIOLATIONS,
        },
        {
            id: AppNavigationKeys.ORG_DOCUMENTS,
            text: 'Documents',
            icon: 'documents',
            route: ['/app/communications/documents'],
            order: AppNavigationOrder.ORG_DOCUMENTS,
        },
        {
            id: AppNavigationKeys.MEMBER_DOCUMENTS,
            text: 'Documents',
            icon: 'documents',
            route: [
                `/app/unit/detail/${this.unitIdKey}`,
                {tab: 'documents'},
            ],
            order: AppNavigationOrder.DOCUMENTS,
        },
        {
            id: AppNavigationKeys.MEMBERSHIP_DIRECTORY,
            text: 'Member Directory',
            icon: 'members',
            route: [
                `/app/member-directory`,
            ],
            order: AppNavigationOrder.MEMBERSHIP_DIRECTORY,
        },
        {
            id: AppNavigationKeys.HELP,
            text: 'Help Articles',
            icon: 'other',
            order: AppNavigationOrder.HELP,
            route: '',
            externalUrl: 'https://intercom.help/payhoa/en/collections/1767520-homeowner-how-to-articles',
        },
    ];

    static SideAdminNavigation: AppNavigationProperties[] = [
        {
            id: AppNavigationKeys.MANAGE_ORGANIZATIONS,
            text: 'Manage Organizations',
            icon: 'organizations',
            route: ['/admin/organizations'],
            order: AppAdminNavigationOrder.MANAGE_ORGANIZATIONS,
        },
        {
            id: AppNavigationKeys.LOCKBOX,
            text: 'CPI Lockbox',
            icon: 'lockbox',
            route: ['/admin/lockbox'],
            order: AppAdminNavigationOrder.LOCKBOX,
        },
        {
            id: AppNavigationKeys.LOCKBOX,
            text: 'Alliance Integration',
            icon: 'lockbox',
            route: ['/admin/alliance'],
            order: AppAdminNavigationOrder.LOCKBOX,
        },
        {
            id: AppNavigationKeys.BILLING,
            text: 'Billing Report',
            icon: 'reports',
            route: ['/admin/reports/billing-report'],
            order: AppAdminNavigationOrder.BILLING,
        },
        {
            id: AppNavigationKeys.PRODUCTS,
            text: 'Manage Products',
            icon: 'products',
            route: ['/admin/products'],
            order: AppAdminNavigationOrder.PRODUCTS,
        },
        {
            id: AppNavigationKeys.PLANS,
            text: 'Manage Plans',
            icon: 'plans',
            route: ['/admin/subscription-templates'],
            order: AppAdminNavigationOrder.PLANS,
        },
    ];

    static SideMobileNavigation: AppNavigationProperties[] = [
        {
            id: AppNavigationKeys.ORG_SETTINGS,
            text: 'Organization Settings',
            icon: 'settings',
            route: ['/app/settings/organization'],
            order: AppMobileNavigationOrder.ORG_SETTINGS,
        },
        {
            id: AppNavigationKeys.MAKE_PAYMENT,
            text: 'Make a Payment',
            icon: 'payments',
            route: ['/app/make-payment'],
            order: AppMobileNavigationOrder.MAKE_PAYMENT,
        },
        {
            id: AppNavigationKeys.OWNERS_ACCOUNT,
            text: 'My Dashboard',
            icon: 'units',
            route: ['/app/owner/dashboard'],
            order: AppMobileNavigationOrder.OWNERS_ACCOUNT,
        },
        {
            id: AppNavigationKeys.MY_ACCOUNT,
            text: 'Account Settings',
            icon: 'members',
            route: ['/app/settings/user'],
            order: AppMobileNavigationOrder.MY_ACCOUNT,
        },
        {
            id: AppNavigationKeys.LOG_OUT,
            text: 'Sign Out',
            icon: 'maintenance',
            route: ['logout'],
            order: AppMobileNavigationOrder.LOG_OUT,
        },
    ];
}
