import { NgModule } from '@angular/core';
import { Reminder1099Component } from './reminder-1099.component';
import { OrganizationService } from '../../../../services/organization/organization.service';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [Reminder1099Component],
    exports: [Reminder1099Component],
    providers: [OrganizationService],
})
export class Reminder1099Module
{
}
