import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlimLoaderComponent } from './slim-loader.component';
import { SlimLoadingBarModule } from 'ng2-al-slim-bar';

@NgModule({
    imports: [
        CommonModule,
        SlimLoadingBarModule.forRoot(),
    ],
    declarations: [
        SlimLoaderComponent,
    ],
    exports: [
        SlimLoaderComponent,
    ],
})
export class SlimLoaderModule
{
}
