import { Component, EventEmitter, HostBinding, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';

export type ButtonSize = 'sm' | 'md';
export type ButtonType = 'default' | 'spinner' | 'primary' | 'primary-outline' | 'secondary' | 'warn' | 'warn-outline' | 'link' | 'success' | 'success-outline';
export type ButtonTargetType = '_blank' | '_self';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnChanges, OnInit
{
    @Input() type: ButtonType = 'default';
    @Input() size: ButtonSize;
    @Input() svg: string;
    @Input() icon: string;
    @Input() iconColor: string = null;
    @Input() iconTooltip: string = null;
    @Input() iconPosition: 'before'|'after' = 'before';

    @Input() buttonClasses: string[] = [];
    @Input() iconClasses: string[] = [];
    @Input() mobileText: string;
    @Input() isActive = false;
    @Input() isDisabled = false;
    @Input() isWorking = false;
    @Input() isInline = false;
    @Input() isSubmit = false;
    @Input() isSuperOnly = false;
    @Input() targetType: ButtonTargetType = '_self';
    @Input() routeTo: any[] | string;
    @Input() params: { [prop: string]: string | number; } = null;
    @Input() queryParams: { [prop: string]: string | number; } = null;

    @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    @HostBinding('class') hostClass: string = 'mat-button-container';

    routeString: string = null;
    buttonClassSet = new Set<string>();
    iconClassSet = new Set<string>();
    label: string = null;
    tooltip: string = null;
    spinnerSize = 28;

    /** Add callback to extended components to call after rebuilding buttonClasses */
    protected buttonClassesCallback: () => void;

    /** Add callback to extended components to call after rebuilding iconClasses */
    protected iconClassesCallback: () => void;

    get faIcon(): string {
        if (!this.icon && !this.svg) {
            return;
        }

        const fa = this.icon ? this.icon : (this.svg ? this.svg : '');
        if (fa.indexOf('file-') > -1) {
            return `fa fa-${fa}-o${this.iconColor ? ' ' + this.iconColor : ''}`;
        }

        return;
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                const change = changes[propName];
                switch (propName) {
                    case 'routeTo': {
                        if (Array.isArray(change.currentValue)) {
                            this.routeString = change.currentValue[0];
                            this.queryParams = change.currentValue[1];
                        } else {
                            this.routeString = change.currentValue;
                            this.queryParams = null;
                        }
                        break;
                    }
                    case 'isInline':
                        if (change.currentValue) {
                            this.hostClass += ' mat-button-inline-container';
                        }
                        this.check('mat-button-inline', change.currentValue);
                        break;
                    case 'isActive':
                        this.check('mat-button-active', change.currentValue);
                        break;
                    case 'buttonClasses':
                        this.rebuildButtonClasses(change.currentValue);
                        break;
                    case 'iconClass':
                        this.rebuildIconClasses(change.currentValue);
                        break;
                    case 'icon':
                    case 'svg':
                        if (change.currentValue) {
                            this.buttonClassSet.add('with-mat-icon').add('mat-button-' + change.currentValue);
                            if (change.currentValue !== change.previousValue) {
                                this.buttonClassSet.delete(change.previousValue);
                            }
                        }
                        break;
                }
            }
        }
    }

    ngOnInit() {
        this.rebuildButtonClasses();
        this.rebuildIconClasses();
    }

    buttonClicked(event: MouseEvent) {
        if (!this.isDisabled) {
            this.onClick.emit(event);
        }
    }

    protected rebuildButtonClasses(buttonClasses = this.buttonClasses) {
        this.buttonClassSet.clear();
        for (const buttonClass of buttonClasses) {
            this.buttonClassSet.add(buttonClass);
        }
        this.buttonClassSet.add('align-items-center');

        if (!this.buttonClassSet.has('mr-0') && !this.buttonClassSet.has('mx-auto')) {
            this.buttonClassSet.add('mr-3');
        }

        if (this.type === 'link') {
            this.buttonClassSet.add('mat-button-link');
        }

        if (this.type === 'success' || this.type === 'success-outline') {
            this.buttonClassSet.add('mat-button-success');
        }

        this.check('mat-button-inline', this.isInline);
        this.check('mat-button-active', this.isActive);
        if (this.icon || this.svg) {
            this.buttonClassSet.add('with-mat-icon').add('mat-button-' + (this.svg ? this.svg : this.icon));
        }

        if (this.buttonClassesCallback) {
            this.buttonClassesCallback();
        }
    }

    protected rebuildIconClasses(iconClasses = this.iconClasses) {
        this.iconClassSet.clear();
        for (const iconClass of iconClasses) {
            this.iconClassSet.add(iconClass);
        }

        if (this.iconClassesCallback) {
            this.iconClassesCallback();
        }
    }

    protected check(prop: string, active = false) {
        if (active) {
            this.buttonClassSet.add(prop);
        } else {
            this.buttonClassSet.delete(prop);
        }
    }
}
