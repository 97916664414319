import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Organization } from './organization';
import Moment = moment.Moment;

export class OrganizationRates
{
    id: number;
    organizationId: number;
    gracePeriodDays: number;
    merchantRate: number;
    merchantMinimum: number;
    checkRate: number;
    checkRateFlat: number;
    createdAt: Moment;
    updatedAt: Moment;

    organization: Organization;

    constructor(organizationRates: any) {
        if (organizationRates.id) {
            this.id = organizationRates.id;
        }
        if (organizationRates.organizationId) {
            this.organizationId = organizationRates.organizationId;
        }
        if (organizationRates.gracePeriodDays) {
            this.gracePeriodDays = parseInt(organizationRates.gracePeriodDays, 10);
        }
        if (organizationRates.merchantRate) {
            this.merchantRate = parseFloat(organizationRates.merchantRate);
        }
        if (organizationRates.merchantMinimum) {
            this.merchantMinimum = parseFloat(organizationRates.merchantMinimum);
        }
        if (organizationRates.checkRate) {
            this.checkRate = parseFloat(organizationRates.checkRate);
        }
        if (organizationRates.checkRateFlat) {
            this.checkRateFlat = parseFloat(organizationRates.checkRateFlat);
        }

        const timezone = LegFiJwtService.getTimezone();
        if (organizationRates.createdAt) {
            this.createdAt = moment.utc(organizationRates.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (organizationRates.updatedAt) {
            this.updatedAt = moment.utc(organizationRates.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (organizationRates.organization) {
            this.organization = new Organization(organizationRates.organization);
        }
    }
}
