import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Membership } from './membership';
import { Survey } from './survey';
import Moment = moment.Moment;

export class SurveyParticipant
{
    id: number;
    surveyId: number;
    membershipId: number;
    responded: boolean = false;
    createdAt: Moment;
    updatedAt: Moment;

    membership: Membership;
    survey: Survey;

    constructor(survey: any) {
        if (survey.id) {
            this.id = survey.id;
        }
        if (survey.surveyId) {
            this.surveyId = survey.surveyId;
        }
        if (survey.membershipId) {
            this.membershipId = survey.membershipId;
        }
        if (survey.responded) {
            this.responded = survey.responded;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (survey.createdAt) {
            this.createdAt = moment.utc(survey.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (survey.updatedAt) {
            this.updatedAt = moment.utc(survey.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (survey.membership) {
            this.membership = new Membership(survey.membership);
        }
        if (survey.survey) {
            this.survey = new Survey(survey.survey);
        }
    }
}
