import { Unit } from './unit';

export class Image
{
    id: number;
    imageableId: number;
    imageableType: string;
    description: string;
    small: string;
    medium: string;
    large: string;

    big?: string;
    imageable: Unit;

    constructor(image: any) {
        if (image.id) {
            this.id = image.id;
        }
        if (image.imageableId) {
            this.imageableId = image.imageableId;
        }
        if (image.imageableType) {
            this.imageableType = image.imageableType;
        }
        if (image.description) {
            this.description = image.description;
        }
        if (image.small) {
            this.small = image.small;
        }
        if (image.medium) {
            this.medium = image.medium;
        }
        if (image.large) {
            this.large = image.large;
            this.big = image.large;
        }

        if (image.imageable) {
            if (image.imageableType.indexOf('Unit') !== -1) {
                this.imageable = new Unit(image.imageable);
            }
        }
    }
}
