import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { AuthActivity } from './auth-activity';
import { Charge } from './charge';
import { ChargeAudit } from './charge-audit';
import { CommunicationActivity } from './communication-activity';
import { FailedAuthAudit } from './failed-auth-audit';
import { PaymentAttempt } from './payment-attempt';
import { PaymentAttemptAudit } from './payment-attempt-audit';
import { UserAudit } from './user-audit';
import { User } from './user';

export class Activity
{
    id: number;
    userId: number;
    activityableId: number;
    activityableType: string;
    action: string;
    impersonator: number;
    createdAt: Moment;
    updatedAt: Moment;

    activityable: FailedAuthAudit | AuthActivity | CommunicationActivity | Charge | PaymentAttempt | User;
    user: User;
    userAudits: UserAudit[];
    chargeAudits: ChargeAudit[];
    paymentAttemptAudits: PaymentAttemptAudit[];

    constructor(activity: any) {
        if (activity.id) {
            this.id = activity.id;
        }
        if (activity.userId) {
            this.userId = activity.userId;
        }
        if (activity.activityableId) {
            this.activityableId = activity.activityableId;
        }
        if (activity.activityableType) {
            this.activityableType = activity.activityableType;
        }
        if (activity.action) {
            this.action = activity.action;
        }
        if (activity.impersonator) {
            this.impersonator = activity.impersonator;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (activity.createdAt) {
            this.createdAt = moment.utc(activity.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (activity.updatedAt) {
            this.updatedAt = moment.utc(activity.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (activity.user) {
            this.user = new User(activity.user);
        }
        if (activity.activityable && activity.activityableType !== null) {
            if (activity.activityableType.indexOf('FailedAuthAudit') !== -1) {
                this.activityable = new FailedAuthAudit(activity.activity);
            } else if (activity.activityableType.indexOf('AuthActivity') !== -1) {
                this.activityable = new AuthActivity(activity.activity);
            } else if (activity.activityableType.indexOf('CommunicationActivity') !== -1) {
                this.activityable = new CommunicationActivity(activity.activity);
            } else if (activity.activityableType.indexOf('Charge') !== -1) {
                this.activityable = new Charge(activity.activity);
            } else if (activity.activityableType.indexOf('PaymentAttempt') !== -1) {
                this.activityable = new PaymentAttempt(activity.activity);
            } else if (activity.activityableType.indexOf('User') !== -1) {
                this.activityable = new User(activity.activity);
            }
        }
        if (activity.userAudits) {
            this.userAudits = activity.userAudits.map((userAudit) => {
                return new UserAudit(userAudit);
            });
        }
        if (activity.chargeAudits) {
            this.chargeAudits = activity.chargeAudits.map((chargeAudit) => {
                return new ChargeAudit(chargeAudit);
            });
        }
        if (activity.paymentAttemptAudits) {
            this.paymentAttemptAudits = activity.paymentAttemptAudits.map((paymentAttemptAudit) => {
                return new PaymentAttemptAudit(paymentAttemptAudit);
            });
        }
    }
}
