import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Survey } from './survey';
import { SurveyQuestionChoice } from './survey-question-choice';
import { SurveyResponse } from './survey-response';
import Moment = moment.Moment;

export class SurveyQuestion
{
    id?: number;
    surveyId?: number;
    questionType: string;
    questionText: string;
    createdAt: Moment;
    updatedAt: Moment;

    survey: Survey;
    choices: SurveyQuestionChoice[];
    responses: SurveyResponse[];
    images: {
        id: number,
        content: string,
        href: string,
    }[];

    constructor(question: any) {
        if (question.id) {
            this.id = question.id;
        }
        if (question.surveyId) {
            this.surveyId = question.surveyId;
        }
        if (question.questionType) {
            this.questionType = question.questionType;
        }
        if (question.questionText) {
            this.questionText = question.questionText;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (question.createdAt) {
            this.createdAt = moment.utc(question.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (question.updatedAt) {
            this.updatedAt = moment.utc(question.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (question.survey) {
            this.survey = new Survey(question.survey);
        }
        if (question.choices) {
            this.choices = question.choices.map((choice: any) => {
                return new SurveyQuestionChoice(choice);
            });
        }

        if (question.responses) {
            this.responses = question.responses.map((response: any) => {
                return new SurveyResponse(response);
            });
        }
    }
}
