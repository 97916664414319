import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Group } from './group';
import { Membership } from './membership';
import { User } from './user';

export class GroupMember
{
    id: number;
    organizationId: number;
    membershipId: number;
    createdAt: Moment;
    updatedAt: Moment;

    group: Group;
    membership: Membership;
    users: User[];

    constructor(groupMember: any) {
        if (groupMember.id) {
            this.id = groupMember.id;
        }
        if (groupMember.organizationId) {
            this.organizationId = groupMember.organizationId;
        }
        if (groupMember.membershipId) {
            this.membershipId = groupMember.membershipId;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (groupMember.createdAt) {
            this.createdAt = moment.utc(groupMember.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (groupMember.updatedAt) {
            this.updatedAt = moment.utc(groupMember.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (groupMember.group) {
            this.group = new Group(groupMember.group);
        }
        if (groupMember.membership) {
            this.membership = new Membership(groupMember.membership);
        }
        if (groupMember.users) {
            this.users = groupMember.users.map(
                    (user) => {
                        return new User(user);
                    },
            );
        }
    }
}
