import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegfiJsonPipe } from './legfi-json.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [LegfiJsonPipe],
    exports: [LegfiJsonPipe],
})
export class LegfiJsonPipeModule
{
}
