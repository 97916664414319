import { Component } from '@angular/core';

@Component({
    selector: 'app-super-star',
    templateUrl: './super-star.component.html',
})
export class SuperStarComponent
{

}
