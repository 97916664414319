import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { AccountCredit } from './account-credit';
import { Charge } from './charge';
import { PaymentAttempt } from './payment-attempt';
import { Subscription } from './subscription';
import Moment = moment.Moment;

export class PaymentItem
{
    id: number;
    paymentAttemptId: number;
    payableId: number;
    payableType: string;
    quantity: number;
    amount: number;
    currency: string;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;
    importedAt: Moment;

    payable: Charge | AccountCredit | Subscription = null;
    paymentAttempt: PaymentAttempt;

    totalAmount: number;

    constructor(paymentItem: any) {
        if (paymentItem.id) {
            this.id = paymentItem.id;
        }
        if (paymentItem.paymentAttemptId) {
            this.paymentAttemptId = paymentItem.paymentAttemptId;
        }
        if (paymentItem.payableId) {
            this.payableId = paymentItem.payableId;
        }
        if (paymentItem.payableType) {
            this.payableType = paymentItem.payableType;
        }
        if (paymentItem.quantity) {
            this.quantity = paymentItem.quantity;
        } else if (paymentItem.quantity === 0) {
            paymentItem.quantity = 0;
        }
        if (paymentItem.amount !== null) {
            this.amount = paymentItem.amount;
        }
        if (paymentItem.currency) {
            this.currency = paymentItem.currency;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (paymentItem.createdAt) {
            this.createdAt = moment.utc(paymentItem.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (paymentItem.updatedAt) {
            this.updatedAt = moment.utc(paymentItem.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (paymentItem.deletedAt) {
            this.deletedAt = moment.utc(paymentItem.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (paymentItem.importedAt) {
            this.importedAt = moment.utc(paymentItem.importedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (paymentItem.payable && paymentItem.payableType !== null) {
            if (paymentItem.payableType.indexOf('Charge') !== -1) {
                this.payable = new Charge(paymentItem.payable);
            } else if (paymentItem.payableType.indexOf('AccountCredit') !== -1) {
                this.payable = new AccountCredit(paymentItem.payable);
            } else if (paymentItem.payableType.indexOf('Subscription') !== -1) {
                this.payable = new Subscription(paymentItem.payable);
            }
        }
        if (paymentItem.paymentAttempt) {
            this.paymentAttempt = new PaymentAttempt(paymentItem.paymentAttempt);
        }
    }
}
