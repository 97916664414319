import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IdleTimeoutDialogComponent, IdleTimeoutDialogInput, IdleTimeoutDialogOutput } from '../components/shared/idle-timeout-dialog/idle-timeout-dialog.component';
import { MatDialogSizes } from '../enums/mat-dialog-sizes.enum';
import { BehaviorSubject } from 'rxjs';
import { LocalService } from './storage/local.service';
import { AuthService } from './auth/auth.service';
import environment from '../../environments/environment';
import moment from 'moment-timezone';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class IdleTimeoutService
{
    dialogRef: MatDialogRef<IdleTimeoutDialogComponent, IdleTimeoutDialogOutput> = null;

    private idleTimeoutInterval: NodeJS.Timer = null;
    private idleTimeoutCountdownSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(environment.IdleTimeoutCountdown);
    private readonly idleTimeoutThreshold = environment.IdleTimeoutThreshold;

    constructor(
            private _authService: AuthService,
            private _dialog: MatDialog,
            private _router: Router,
    ) {
    }

    watch() {
        if (this.idleTimeoutInterval !== null) {
            return;
        }
        this.idleTimeoutInterval = setInterval(() => {
            // kill the loop if we're on the auth layout without being stopped naturally
            if (this._router.url.indexOf('/auth/') > -1) {
                return this.stop();
            }

            if (LocalService.get('payhoa-idle-timeout') === null) {
                LocalService.set('payhoa-idle-timeout', moment.now().toString());
            }

            const stored = +LocalService.get('payhoa-idle-timeout');

            // increment difference +1 to progress countdown value
            const difference = moment().diff(stored, 'seconds') + 1;
            const countdown = environment.IdleTimeoutCountdown - difference;

            if (countdown < 1) {
                const output: IdleTimeoutDialogOutput = {
                    keepAlive: false,
                    preserveLocation: true,
                };

                if (this.dialogRef !== null) {
                    this.dialogRef.close(output);
                } else {
                    this.handleIdleTimedOut(output);
                }
            } else if (!this.dialogRef && countdown <= this.idleTimeoutThreshold) {
                const data: IdleTimeoutDialogInput = {
                    countdown$: this.idleTimeoutCountdownSubject$.asObservable(),
                };

                this.dialogRef = this._dialog.open(IdleTimeoutDialogComponent, {
                    disableClose: true,
                    width: MatDialogSizes.XS,
                    data,
                });

                this.dialogRef.afterClosed().subscribe({
                    next: (result: IdleTimeoutDialogOutput) => this.handleIdleTimedOut(result),
                });
            }

            // console.log('[idle-timeout] watching', countdown);
            this.idleTimeoutCountdownSubject$.next(countdown);
        }, 1000);
    }

    reset() {
        this.dialogRef = null;
        LocalService.set('payhoa-idle-timeout', moment.now().toString());
        this.idleTimeoutCountdownSubject$.next(environment.IdleTimeoutCountdown);
    }

    stop() {
        this.dialogRef = null;
        clearInterval(this.idleTimeoutInterval);
        LocalService.destroy('payhoa-idle-timeout');
    }

    private handleIdleTimedOut(result: IdleTimeoutDialogOutput): void {
        if (result?.keepAlive) {
            return this.reset();
        }

        this.stop();
        this._authService.logout(result?.preserveLocation);
    }
}
