import moment from 'moment-timezone';
import { Membership } from '../membership';

/** Submitted form requests can have approvals (one or more) */
export class FormSubmissionApproval
{
    id: number;
    formApproverId: number;
    formSubmissionId: number;
    approves: boolean;
    denies: boolean;
    createdAt: moment.Moment;
    canApprove: boolean;
    canReset: boolean;
    membership: Membership;
    approverDeleted: boolean;

    constructor(request: any) {
        this.id = request.id;
        this.formApproverId = request.formApproverId;
        this.formSubmissionId = request.formSubmissionId;
        this.approves = request.approves;
        this.denies = request.denies;
        this.canApprove = request.canApprove;
        this.canReset = request.canReset;
        this.approverDeleted = request.approverDeleted;

        if (request.createdAt) {
            this.createdAt = moment(request.createdAt);
        }

        if (request.membership) {
            this.membership = request.membership;
        }
    }
}
