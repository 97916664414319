import moment, { Moment } from 'moment-timezone';
import { UnifiedBankAccount } from './unified-bank-account';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { PaymentAttempt } from './payment-attempt';

export class DepositBankAccount
{
    id: number;
    unifiedBankAccountId: number;
    organizationId: number;
    stripeManagedAccountId: number;
    currentlyDue: any;
    currentDeadline: Moment;
    eventuallyDue: any;
    disabledReason: string;
    routingNumber: string;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    lastDeposit: any;
    pendingFunds: number;
    friendlyName: string;
    internalBalance: number;

    unifiedBankAccount: UnifiedBankAccount;
    attentionFlags: any[];
    paymentAttempts: any[];
    registeredInStripe: boolean = false;

    constructor(depositBankAccount: any) {
        if (depositBankAccount.id) {
            this.id = depositBankAccount.id;
        }
        if (depositBankAccount.unifiedBankAccountId) {
            this.unifiedBankAccountId = depositBankAccount.unifiedBankAccountId;
        }
        if (depositBankAccount.organizationId) {
            this.organizationId = depositBankAccount.organizationId;
        }
        if (depositBankAccount.stripeManagedAccountId) {
            this.stripeManagedAccountId = depositBankAccount.stripeManagedAccountId;
        }
        if (depositBankAccount.hasOwnProperty('registeredInStripe')) {
            this.registeredInStripe = depositBankAccount.registeredInStripe;
        }
        if (depositBankAccount.currentlyDue) {
            this.currentlyDue = JSON.parse(depositBankAccount.currentlyDue);
        }
        if (depositBankAccount.eventuallyDue) {
            this.eventuallyDue = JSON.parse(depositBankAccount.eventuallyDue);
        }
        const timezone = LegFiJwtService.getTimezone();

        if (depositBankAccount.currentDeadline) {
            this.currentDeadline = moment.utc(depositBankAccount.currentDeadline, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (depositBankAccount.disabledReason) {
            this.disabledReason = depositBankAccount.disabledReason;
        }
        if (depositBankAccount.routingNumber) {
            this.routingNumber = depositBankAccount.routingNumber;
        }

        if (depositBankAccount.createdAt) {
            this.createdAt = moment.utc(depositBankAccount.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (depositBankAccount.updatedAt) {
            this.updatedAt = moment.utc(depositBankAccount.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (depositBankAccount.deletedAt) {
            this.deletedAt = moment.utc(depositBankAccount.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (depositBankAccount.hasOwnProperty('lastDeposit')) {
            this.lastDeposit = depositBankAccount.lastDeposit || 0;
        }
        if (depositBankAccount.hasOwnProperty('pendingFunds')) {
            this.pendingFunds = depositBankAccount.pendingFunds || 0;
        }
        if (depositBankAccount.friendlyName) {
            this.friendlyName = depositBankAccount.friendlyName;
        }
        if (depositBankAccount.hasOwnProperty('internalBalance')) {
            this.internalBalance = depositBankAccount.internalBalance;
        }

        if (depositBankAccount.unifiedBankAccount) {
            this.unifiedBankAccount = new UnifiedBankAccount(depositBankAccount.unifiedBankAccount);
        }

        if (depositBankAccount.paymentAttempts) {
            this.paymentAttempts = depositBankAccount.paymentAttempts.map(
                    (paymentAttempt) => {
                        return new PaymentAttempt(paymentAttempt);
                    },
            );
        }
    }
}
