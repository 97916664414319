import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';

import { OffClickModule } from '../../../../directives/off-click/off-click.module';
import { WhereKeyMatchesPipe } from '../../../../pipes/collection/where-key-matches.pipe';
import { WhereKeyMatchesPipeModule } from '../../../../pipes/collection/where-key-matches.module';
import { SingleOwnerSelectorComponent } from './single-owner-selector.component';
import { OwnersService } from '../../../../services/units/owners.service';

@NgModule({
    imports: [
        // Shared
        SharedModule,
        // Dependencies
        OffClickModule,
        // Pipes
        WhereKeyMatchesPipeModule,
    ],
    exports: [SingleOwnerSelectorComponent],
    declarations: [SingleOwnerSelectorComponent],
    providers: [
        // Services
        OwnersService,
        // Pipes
        WhereKeyMatchesPipe,
    ],
})
export class SingleOwnerSelectorModule
{
}
