import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';

@Component({
    selector: 'lf-component-injector',
    template: '',
})
export class ComponentInjectorComponent implements OnDestroy, OnInit
{
    @Input()
    public type: any;
    @Input()
    public inputs: {} = null;
    public component: ComponentRef<any>;
    private componentFactory: ComponentFactory<any>;
    // private _dynamicComponentLoader:DynamicComponentLoader;
    private _componentFactoryResolver: ComponentFactoryResolver;
    private _viewContainerRef: ViewContainerRef;

    constructor(_componentFactoryResolver: ComponentFactoryResolver, _viewContainerRef: ViewContainerRef) {
        this._componentFactoryResolver = _componentFactoryResolver;
        this._viewContainerRef = _viewContainerRef;
    }

    ngOnInit() {
        // Load a sub-component if provided.
        if (this.type !== null) {
            this.componentFactory = this._componentFactoryResolver.resolveComponentFactory(this.type);
            this.component = this._viewContainerRef.createComponent(this.componentFactory);
            this.loadInputs(this.inputs);
        }
    }

    ngOnDestroy() {
        if (this.component !== null) {
            this.component.destroy();
        }
    }

    /**
     * @param {any} inputs
     */
    public loadInputs(inputs: { [key: string]: any }) {
        if (this.component !== null && typeof inputs !== 'undefined' && inputs !== null) {
            for (const inputName in inputs) {
                if (inputs.hasOwnProperty(inputName)) {
                    this.component.instance[inputName] = inputs[inputName];
                }
            }
        }
    }
}
