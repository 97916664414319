import { Component } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
    selector: 'app-sidenav-link',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class SidenavLinkComponent extends ButtonComponent {
    constructor() {
        super();
        this.type = 'link';
        this.hostClass += ' pt-2';
        this.buttonClassesCallback = () => this.buttonClassSet.add('nav-link').add('mat-button-nav-link');
    }
}
