import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

import { ErrorComponent } from './error.component';

@NgModule({
    imports: [
        SharedModule,
        // Routing
        RouterModule.forChild([
            {
                path: 'error',
                component: ErrorComponent,
            },
        ]),
    ],
    declarations: [ErrorComponent],
    exports: [
        ErrorComponent
    ]
})
export class ErrorModule
{
}
