import { Moment } from 'moment';
import { UploadedFile } from './uploaded-file';
import moment from 'moment/moment';
import { Organization } from './organization';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Payable } from './payable';

export class InboundEmail
{
    id: number;
    attachmentMeta: any[];
    emailType: string;
    fromEmail: string;
    subject: string;
    body: string;
    organizationId: number;
    toEmail: string;
    webhookId: number;
    organization: Organization;
    attachments: UploadedFile[];
    payables: Payable[];
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;
    archivedAt: Moment;

    constructor(inboundEmail: any) {
        if (inboundEmail.id) {
            this.id = inboundEmail.id;
        }
        this.attachmentMeta = inboundEmail.attachmentMeta || [];
        this.emailType = inboundEmail.emailType;
        this.fromEmail = inboundEmail.fromEmail;
        this.subject = inboundEmail.subject;
        this.body = inboundEmail.body;
        this.organizationId = inboundEmail.organizationId;
        this.toEmail = inboundEmail.toEmail;
        this.webhookId = inboundEmail.webhookId;
        this.organization = inboundEmail.organization || new Organization({});
        this.attachments = inboundEmail.attachments || [];
        this.payables = inboundEmail.payables || [];
        const timezone = LegFiJwtService.getTimezone();
        this.createdAt = moment(inboundEmail.createdAt).tz(timezone);
        this.updatedAt = moment(inboundEmail.updatedAt).tz(timezone);
        if (inboundEmail.deletedAt) {
            this.deletedAt = moment(inboundEmail.deletedAt).tz(timezone);
        }
        if (inboundEmail.archivedAt) {
            this.archivedAt = moment(inboundEmail.archivedAt).tz(timezone);
        }
    }
}
