import moment from 'moment-timezone';
import Moment = moment.Moment;
import { PayableApprovalRule } from './payable-approval-rules';
import { PayableApproval } from './payable-approvals';
import { PayabliPayment } from './payabli-payment';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';

export class PayableFulfillment {
    id: number;
    organizationId: number;
    payableId: number;
    fulfillmentType: string;
    fulfillmentReferenceType: string;
    fulfillmentReferenceId: number;
    memo: string;
    approvalRuleId: number;
    approvalStatus: string;
    approvalStatusAt: Moment;
    approvedApprovalsCount: number;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;
    scheduledPaymentDate: Moment;
    paymentSentAt: Moment;
    fulfillmentReference: PayabliPayment;
    approvals: PayableApproval[] = [];
    approvalRule: PayableApprovalRule;

    constructor(fulfillment: any) {
        if (fulfillment.id) {
            this.id = fulfillment.id;
        }
        this.organizationId = fulfillment.organizationId || 0;
        this.payableId = fulfillment.payableId || 0;
        this.fulfillmentType = fulfillment.fulfillmentType || '';
        this.fulfillmentReferenceType = fulfillment.fulfillmentReferenceType || '';
        this.fulfillmentReferenceId = fulfillment.fulfillmentReferenceId || 0;
        this.memo = fulfillment.memo || '';
        this.approvalRuleId = fulfillment.approvalRuleId || 0;
        this.approvalStatus = fulfillment.approvalStatus || '';

        if (fulfillment.approvals && fulfillment.approvals.length > 0) {
            this.approvals = fulfillment.approvals.map(approval => new PayableApproval(approval));
        }
        if (fulfillment.approvalRule) {
            this.approvalRule = new PayableApprovalRule(fulfillment.approvalRule);
        }
        if (fulfillment.approvedApprovalsCount) {
            this.approvedApprovalsCount = fulfillment.approvedApprovalsCount;
        }
        if (fulfillment.fulfillmentReference) {
            this.fulfillmentReference = new PayabliPayment(fulfillment.fulfillmentReference);
        }

        // timestamps
        const timezone = LegFiJwtService.getTimezone();
        if (fulfillment.scheduledPaymentDate) {
            this.scheduledPaymentDate = moment(fulfillment.scheduledPaymentDate).tz(timezone);
        }
        if (fulfillment.paymentSentAt) {
            this.paymentSentAt = moment(fulfillment.paymentSentAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (fulfillment.approvalStatusAt) {
            this.approvalStatusAt = moment(fulfillment.approvalStatusAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (fulfillment.createdAt) {
            this.createdAt = moment.utc(fulfillment.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (fulfillment.updatedAt) {
            this.updatedAt = moment.utc(fulfillment.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (fulfillment.deletedAt) {
            this.deletedAt = moment.utc(fulfillment.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
    }
}
