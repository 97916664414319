import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nl2br',
})
export class NewlineToBreakPipe implements PipeTransform
{
    public transform(value: string, args: any[] = []) {
        if (value === null) {
            return '';
        }

        return String(value).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br />' + '$2');
    }
}
