<div class="card mb-0">
    <div class="card-header">
        <h2>{{'Advanced Filters'}}</h2>
    </div>

    <div class="card-body" [class.filters-loading]="!formSubmissionFilters">
        <div *ngIf="!formSubmissionFilters" class="d-loading">
            <div class="loader">{{'Loading...'}}</div>
        </div>
        <form [formGroup]="form" *ngIf="formSubmissionFilters">
            <div class="row">
                <div class="col-3">
                    <div class="mr-4">
                        <div class="form-group">
                            <label class="d-flex">{{'Request State'}}</label>
                            <mat-radio-group formControlName="state">
                                <mat-radio-button *ngFor="let state of formSubmissionStates | keyvalue: originalOrder"
                                                  class="mr-3"
                                                  value="{{ state.value }}">
                                    {{ state.value | capitalize }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>

                <div *ngIf="formSubmissionFilters.statuses?.length"
                     formArrayName="statuses"
                     class="col-9">
                    <div class="mr-4">
                        <div class="form-group">
                            <label class="d-flex">{{'Submission Status'}}</label>
                            <ng-container *ngFor="let status of formSubmissionFilters.statuses; let i=index">
                                <mat-checkbox [formControlName]="i">
                                    <app-color-square [color]="status.color">{{status.name}}</app-color-square>
                                </mat-checkbox>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="mr-4">
                        <div class="form-group">
                            <label class="d-flex">{{'Other Tags'}}</label>
                            <lf-tags taggableType="Unit"
                                     taggableMode="Filter"
                                     class="d-inline-flex"
                                     [placeholder]="'Unit Tags...'"
                                     [(items)]="formSubmissionFilters.unitTags"></lf-tags>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="formSubmissionFilters" class="card-footer px-4 d-flex justify-content-end">
        <app-button (onClick)="closeFilters.emit()">{{'Close'}}</app-button>
        <app-button (onClick)="clearFilters()">{{'Clear Filters'}}</app-button>
        <app-button type="primary"
                    [buttonClasses]="['mr-0']"
                    (onClick)="onFiltersUpdated.emit(formSubmissionFilters)">{{'Filter Submissions'}}</app-button>
    </div>
</div>
