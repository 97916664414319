export class PageAdvertisement
{
    id: number;
    name: string;
    color: string;
    icon: string;
    tagline: string;
    cta: {
        url: string;
        text: string;
    };
    useFeatureDialog: boolean;
    isEnabled: boolean;
    isDismissible: boolean;

    constructor(ad: any) {
        this.id = ad.id || null;
        this.name = ad.name || null;
        this.color = ad.adColor || null;
        this.icon = ad.iconImage || null;
        this.tagline = ad.tagline || null;
        this.cta = {
            url: ad.ctaUrl || null,
            text: ad.ctaText || null,
        };
        this.useFeatureDialog = ad.useFeatureDialog;
        this.isEnabled = ad.isEnabled;
        this.isDismissible = ad.isDismissible;
    }
}
