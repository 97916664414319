export class JwtPermissionClaim
{
    name: string;
    moduleId: number;
    writable: boolean;
    groupId: number;
    groupName: string;

    constructor(claim) {
        if (claim.name) {
            this.name = claim.name;
        }
        if (claim.moduleId) {
            this.moduleId = claim.moduleId;
        }
        this.writable = claim.writable;
        if (claim.groupId) {
            this.groupId = claim.groupId;
        }
        if (claim.groupName) {
            this.groupName = claim.groupName;
        }
    }
}
