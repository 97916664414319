import { PaginationMeta } from './pagination';
import { User } from './user';
import { Organization } from './organization';

export class PropertyManagementCompany
{
    id: number;
    name: string;
    membersCount: number;
    organizationsCount: number;
    unitsCount: number;
    users: User[];
    organizations: Organization[];

    constructor(obj: any) {
        this.id = obj.id || null;
        this.name = obj.name || null;
        this.membersCount = obj.membersCount || null;
        this.organizationsCount = obj.organizationsCount || null;
        this.unitsCount = obj.unitsCount || null;

        if (obj.organizations) {
            this.organizations = obj.organizations.map((o: any) => new Organization(o));
        }

        if (obj.users) {
            this.users = obj.users.map((u: any) => new User(u));
        }
    }

    get initials(): string {
        const parts = this.name.split(' ').map((p) => {
            return p.split(/(?=[A-Z])/).filter((n) => {
                const initial = n.charAt(0);
                return !isNaN(+initial) || initial === initial.toUpperCase();
            });
        });
        const flattened = parts.reduce((a, b) => a.concat(b), []);
        return flattened[0].charAt(0) + (flattened[1] ? flattened[1].charAt(0) : '');
    }

    get emails(): string {
        return this.users.map((u) => u.email).join(',<br>');
    }
}

export class PropertyManagementCompanyResponse
{
    data: PropertyManagementCompany[];
    meta: PaginationMeta;

    constructor(request: any) {
        this.data = (request.data && request.data.length) ? request.data.map((c: any) => {
            return new PropertyManagementCompany(c);
        }) : [];
        this.meta = new PaginationMeta(request);
    }
}
