<ng-container [ngSwitch]="audit.event">

    <ng-container *ngSwitchCase="'created'">
        <mat-icon>star</mat-icon>
        <span class="ml-2">
            Created
        </span>
    </ng-container>

    <ng-container *ngSwitchCase="'updated'">
        <mat-icon>edit</mat-icon>
        <span class="ml-2">
            Updated
        </span>
    </ng-container>

    <ng-container *ngSwitchCase="'deleted'">
        <mat-icon>delete</mat-icon>
        <span class="ml-2">
        Deleted
        </span>
    </ng-container>

    <ng-container *ngSwitchCase="'restored'">
        <mat-icon>history</mat-icon>
        <span class="ml-2">
        Restored
        </span>
    </ng-container>

    <ng-container *ngSwitchCase="'new-charge-email-sent'">
        <mat-icon>mail</mat-icon>
        <span class="ml-2">
        Email Notification Sent
        </span>
    </ng-container>

    <ng-container *ngSwitchCase="'deleted-by-payment-plan'">
        <mat-icon>call_split</mat-icon>
        <span class="ml-2">
        Converted to Payment Plan
        </span>
    </ng-container>

    <ng-container *ngSwitchCase="'created-by-payment-plan'">
        <mat-icon>call_split</mat-icon>
        <span class="ml-2">
        Created by Payment Plan
        </span>
    </ng-container>

    <ng-container *ngSwitchCase="'payable-scheduled'">
        <mat-icon>schedule</mat-icon>
        <span class="ml-2">
        Scheduled for Payment
        </span>
    </ng-container>

    <ng-container *ngSwitchCase="'payable-cancelled'">
        <mat-icon>block</mat-icon>
        <span class="ml-2">
        Payment Cancelled, Returned to Inbox
        </span>
    </ng-container>

    <ng-container *ngSwitchCase="'payable-approval-cast'">
        <mat-icon>thumb_up</mat-icon>
        <span class="ml-2">
        Payment Approval Given
        </span>
    </ng-container>

    <ng-container *ngSwitchCase="'payable-denial-cast'">
        <mat-icon>thumb_down</mat-icon>
        <span class="ml-2">
        Payment Approval Denied
        </span>
    </ng-container>

    <ng-container *ngSwitchCase="'payable-approved'">
        <mat-icon>check_circle</mat-icon>
        <span class="ml-2">
        Payment Approved via Rules
        </span>
    </ng-container>

    <ng-container *ngSwitchCase="'payable-denied'">
        <mat-icon>block</mat-icon>
        <span class="ml-2">
        Payment Denied via Rules
        </span>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <mat-icon>edit</mat-icon>
        <span class="ml-2">
        {{ audit.event }}
        </span>
    </ng-container>

</ng-container>
