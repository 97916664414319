import { ComponentRef, ElementRef, Injectable, InjectionToken, Injector, Type, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject, Subject } from 'rxjs';
import { SidenavContentRef } from './sidenav-content-ref';

/**
 * Injection token that can be used to access the data that was passed in to the sidenav content.
 */
export const SIDENAV_CONTENT_DATA = new InjectionToken<any>('AppSidenavContentData');

/**
 * Configuration for opening sidenav content with the SidenavContent service.
 */
export class SidenavContentConfig<D = any> {
    data?: D | null = null;
    // on open, set to true to display initial loading state, otherwise keep hidden
    initialLoadingState?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class SidenavContentService
{
    private vcr: ViewContainerRef;
    private sidenav: MatSidenav;
    private readonly loadingState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly titleSubject$: Subject<ElementRef> = new Subject<ElementRef>();
    private readonly actionsSubject$: Subject<ElementRef> = new Subject<ElementRef>();

    /** Get current stream for sidenav content's loading state */
    get _loading$() {
        return this.loadingState$.asObservable();
    }

    /** Get current stream for sidenav content's title */
    get _title$() {
        return this.titleSubject$.asObservable();
    }

    /** Get current stream for sidenav content's actions */
    get _actions$() {
        return this.actionsSubject$.asObservable();
    }

    /** Set view container for sidenav content */
    set _container(viewContainerRef: ViewContainerRef) {
        this.vcr = viewContainerRef;
    }

    /** Set elements for sidenav content's actions */
    set _actions(elementRef: ElementRef) {
        this.actionsSubject$.next(elementRef);
    }

    /** Set sidenav reference for sidenav content */
    set _sidenav(sidenavRef: MatSidenav) {
        this.sidenav = sidenavRef;
    }

    /** Set elements for sidenav content's title */
    set _title(elementRef: ElementRef) {
        this.titleSubject$.next(elementRef);
    }

    /**
     * Toggles loading state for sidenav content
     * @param isLoading
     * @returns void
     */
    set loading(isLoading: boolean) {
        this.loadingState$.next(isLoading);
    }

    /**
     * Opens content within sidenav content
     * @param component
     * @param config
     * @returns ComponentRef<any>
     */
    open(component: Type<any>, config?: SidenavContentConfig<any>) {
        const sidenavContentRef = new SidenavContentRef<ComponentRef<any>>(this.sidenav, this, config);
        const providers = [
            { provide: component, useValue: component },
            { provide: SIDENAV_CONTENT_DATA, useValue: config?.data },
            { provide: SidenavContentRef, useValue: sidenavContentRef },
        ];
        const elementInjector = Injector.create({ providers });

        this.vcr.clear();
        sidenavContentRef._componentRef = this.vcr.createComponent(component, {injector: elementInjector});

        this.close = sidenavContentRef.close.bind(sidenavContentRef);
        return sidenavContentRef;
    }

    /**
     * Closes sidenav content and resets title, actions, and loading states
     * Set from componentRef on .open()
     * @returns void
     */
    close: () => void = () => console.error('Component ref for sidenav is not initialized.');
}
