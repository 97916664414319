import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Activity } from './activity';

export class UserAudit
{
    id: number;
    activityId: number;
    modifiedField: string;
    oldValue: string;
    newValue: string;
    createdAt: Moment;
    updatedAt: Moment;

    activity: Activity;

    constructor(userAudit: any) {
        if (userAudit.id) {
            this.id = userAudit.id;
        }
        if (userAudit.activityId) {
            this.activityId = userAudit.activityId;
        }
        if (userAudit.modifiedField) {
            this.modifiedField = userAudit.modifiedField;
        }
        if (userAudit.oldValue) {
            this.oldValue = userAudit.oldValue;
        }
        if (userAudit.newValue) {
            this.newValue = userAudit.newValue;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (userAudit.createdAt) {
            this.createdAt = moment.utc(userAudit.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (userAudit.updatedAt) {
            this.updatedAt = moment.utc(userAudit.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (userAudit.activity) {
            this.activity = new Activity(userAudit.activity);
        }
    }
}
