import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { RecurringCharge } from './recurring-charge';
import Moment = moment.Moment;

export class ExpectedCharge
{
    id: number;
    recurringChargeTemplateId: number;
    billingDate: Moment;
    chargeCreated: Moment = null;
    createdAt: Moment;
    updatedAt: Moment;

    recurringCharges: RecurringCharge[] = [];

    constructor(expectedCharge) {
        if (expectedCharge.id) {
            this.id = expectedCharge.id;
        }
        if (expectedCharge.recurringChargeTemplateId) {
            this.recurringChargeTemplateId = expectedCharge.recurringChargeTemplateId;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (expectedCharge.billingDate) {
            this.billingDate = moment.utc(expectedCharge.billingDate, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (expectedCharge.chargeCreated) {
            this.chargeCreated = moment.utc(expectedCharge.chargeCreated, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (expectedCharge.createdAt) {
            this.createdAt = moment.utc(expectedCharge.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (expectedCharge.updatedAt) {
            this.updatedAt = moment.utc(expectedCharge.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (expectedCharge.recurringCharges) {
            this.recurringCharges = expectedCharge.recurringCharges.map((rc) => {
                return new RecurringCharge(rc);
            });
        }
    }
}
