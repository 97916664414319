import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Routes } from '../../config/routes';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { NotificationPreference } from '../../models/entities/notification-preference';

export interface TwoFactorVerificationResponse
{
    verificationKey: string;
}

export class PasswordChangeRequest
{
    oldPassword: string;
    newPassword: string;

    constructor(request: any) {
        if (request) {
            if (request.oldPassword) {
                this.oldPassword = request.oldPassword;
            }
            if (request.newPassword) {
                this.newPassword = request.newPassword;
            }
        }
    }
}

export interface GoogleQRResponse
{
    qr: string;
    secret: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserSettingsService
{
    private _http: ApplicationHttpClient;

    constructor(_http: ApplicationHttpClient) {
        this._http = _http;
    }

    public getGoogleAuthQrCode(): Observable<GoogleQRResponse> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.get(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.UserGoogleAuthSetup(jwt.id)));
    }

    public finishGoogleAuthSetup(currentCode, secretKey): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.UserGoogleAuthSetup(jwt.id)), JSON.stringify({
            code: currentCode,
            secret: secretKey,
        }));
    }

    public removeGoogleAuth(code: string, userId: number = null) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.UserGoogleAuthDisable(userId || jwt.id)), JSON.stringify({
            code: code,
        }));
    }

    public removeSmsAuth(code: string, userId: number = null) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.UserSmsAuthDisable(userId || jwt.id)), JSON.stringify({
            code: code,
        }));
    }

    public startSmsAuth(phoneNumber: string): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.UserSmsAuthSetup(jwt.id)),
                JSON.stringify({
                    phone: phoneNumber,
                }));
    }

    public finishSmsSetup(currentCode, phoneNumber): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.UserSmsAuthConfirm(jwt.id)), JSON.stringify({
            code: currentCode,
            phoneNumber: phoneNumber,
        }));
    }

    public sendSmsVerificationCode(userId: number = null): Observable<Object> {
        if (!userId) {
            const jwt: JwtLegFiClaims = LegFiJwtService.read();
            if (jwt === null) {
                return this._http.redirectAndThrow401Observable();
            }
            userId = jwt.id;
        }

        return this._http.post(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.UserSmsAuthSend(userId)), JSON.stringify({}));
    }

    public verifySmsCode(currentCode, userId = null): Observable<TwoFactorVerificationResponse> {
        if (!userId) {
            const jwt: JwtLegFiClaims = LegFiJwtService.read();
            if (jwt === null) {
                return this._http.redirectAndThrow401Observable();
            }
            userId = jwt.id;
        }

        return this._http.post(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.UserSmsAuthValidate(userId)), JSON.stringify({
            code: currentCode,
        }));
    }

    public verifyGoogleAuthCode(currentCode, userId = null): Observable<TwoFactorVerificationResponse> {
        if (!userId) {
            const jwt: JwtLegFiClaims = LegFiJwtService.read();
            if (jwt === null) {
                return this._http.redirectAndThrow401Observable();
            }
            userId = jwt.id;
        }

        return this._http.post(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.UserGoogleAuthValidate(userId)), JSON.stringify({
            code: currentCode,
        }));
    }

    /**
     * Gets user + membership details from Core.
     * @param {number} userId
     * @param {Object} requestBody
     * @returns {Observable}
     */
    public patchUser(
            userId: number,
            requestBody: Object,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.patch(
                Routes.MakeLegFiCoreUrl(Routes.LegFiCore.User(userId || jwt.id)),
                JSON.stringify(requestBody),
        );
    }

    /**
     * Gets user + membership details from Core.
     * @param {number} userId
     * @param {Object} requestBody
     * @returns {Observable}
     */
    public changePassword(
            userId: number,
            requestBody: PasswordChangeRequest,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.UserChangePassword(userId || jwt.id),
                ),
                JSON.stringify(requestBody),
        );
    }

    /**
     * Gets a user's notification preferences.
     * @param userId
     * @returns {Observable<NotificationPreference[]>}
     */
    public getNotificationPreferences(userId: number): Observable<NotificationPreference[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.get(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.UserNotificationPreferences(userId || jwt.id),
                ),
        );
    }

    /**
     * Update a user's notification preferences.
     * @param userId
     * @param preferenceId
     * @param updateRequest
     * @returns {Observable<NotificationPreference>}
     */
    public patchNotificationPreferences(
            userId: number,
            preferenceId: number,
            updateRequest: Object,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.patch(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.UserNotificationPreference(
                                userId || jwt.id,
                                preferenceId,
                        ),
                ),
                JSON.stringify(updateRequest),
        ).pipe(
                map((response: Object) => {
                    return new NotificationPreference(response);
                }));
    }


}
