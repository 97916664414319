import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ScrollService } from '../../../../services/scroll.service';

@Component({
    selector: 'app-feature-alliance-banking',
    templateUrl: './feature-alliance-banking.component.html',
})
export class FeatureAllianceBankingComponent implements OnInit
{
    @Output() requestScheduleCall: EventEmitter<any> = new EventEmitter();

    constructor(
            private _scrollService: ScrollService,
    ) {
    }

    ngOnInit(): void {
        // Scroll to top on view load.
        this._scrollService.scrollToTop();
    }
}
