import { Injectable } from '@angular/core';
import { LegFiJwtService } from '../auth/legfi-jwt.service';

@Injectable({
    providedIn: 'root',
})
export class LocalService
{
    public static get(key: string): string {
        return localStorage.getItem(key);
    }

    public static set(key: string, value: string) {
        return localStorage.setItem(key, value);
    }

    public static destroy(key: string) {
        return localStorage.removeItem(key);
    }

    public static key(domain: string, ids: number[] = []): string {
        if (!domain) {
            return '';
        }

        const jwt = LegFiJwtService.read();
        ids = [jwt.orgId, ...ids];

        // TODO: crypto.js or something to create hashes
        return `${domain}-${ids.join('-')}`;
    }
}
