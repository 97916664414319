import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LegFiJwtService } from '../../../../services/auth/legfi-jwt.service';
import { JwtLegFiClaims } from '../../../../services/auth/jwt-legfi-claims.model';
import { OrganizationService, SimpleOrganization } from '../../../../services/organization/organization.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GrowlerService } from '../../../../services/growler.service';
import { Unit } from '../../../../models/entities/unit';
import { HostListenerService } from '../../../../services/host-listener.service';
import { MaterialSearchComponent } from '../../material-search/material-search.component';

export type SwitcherType = 'org' | 'unit';

export interface SwitchOrganizationItem {
    id: number;
    org: string;
    unit?: {
        id: number;
        title: string;
    };
}

export interface SwitchEvent {
    type: SwitcherType;
    item: SwitchOrganizationItem;
}

@UntilDestroy()
@Component({
    selector: 'app-header-switcher',
    styleUrls: ['./header-switcher.component.scss'],
    templateUrl: './header-switcher.component.html',
})
export class HeaderSwitcherComponent implements OnInit
{
    @Input() switcherType: SwitcherType;
    @Output() newHoaRequested: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() switchRequested: EventEmitter<SwitchEvent> = new EventEmitter<SwitchEvent>();
    @ViewChild(MaterialSearchComponent) matSearch: MaterialSearchComponent;

    isLoading = true;
    matMenuOpened = false;

    // total available to select (outside current org): will set once organizationItems are initially loaded
    totalAvailable = -1;

    organizationItems: SwitchOrganizationItem[] = [];
    currentOrgId: number = 0;
    currentOrgName = '';
    searchString = '';

    jwt: JwtLegFiClaims;

    // Keep a master list to update list based on load/filter
    private organizations: SimpleOrganization[] = [];

    constructor(
            private _organizationService: OrganizationService,
            private _growler: GrowlerService,
            private _host: HostListenerService,
            private _elementRef: ElementRef,
    ) {
    }

    ngOnInit() {
        this.jwt = LegFiJwtService.read();
        this.currentOrgId = this.jwt.orgId;
        this.currentOrgName = this.jwt.orgName;

        this.populateSwitcher();

        this._host.onMouseClick().pipe(untilDestroyed(this)).subscribe({
            next: (event) => {
                if (this.matMenuOpened && !this._elementRef.nativeElement.contains(event.target)) {
                    this.matMenuOpened = false;
                }
            }
        });
    }

    filterSwitcher(term = '') {
        this.searchString = term.trim().toLowerCase();
        if (this.switcherType === 'unit') {
            this.updateUnitOrgList();
        } else {
            this.updateOrgList();
        }
    }

    requestSwitch(item: SwitchOrganizationItem) {
        this.switchRequested.emit({
            type: this.switcherType,
            item,
        });
    }

    requestNewHoaModal() {
        this.newHoaRequested.emit(true);
    }

    openMenu() {
        if (!this.matMenuOpened) {
            this.matMenuOpened = true;
            setTimeout(() => this.matSearch?.focus());
        }
    }

    closeMenu() {
        this.matMenuOpened = false;
    }

    stopClickFromClosing(event: Event) {
        event.stopPropagation();
        event.preventDefault();
    }

    private populateSwitcher() {
        this.isLoading = true;

        let obs = this._organizationService.getSimpleOrgList(this.jwt.id);
        if (this.jwt.superUser) {
            obs = this._organizationService.getSimpleOrgList(null, this.switcherType === 'unit');
        }

        obs.pipe(untilDestroyed(this)).subscribe({
            next: (organizations: SimpleOrganization[]) => {
                this.organizations = organizations;
                if (this.switcherType === 'unit' && !this.jwt.superUser) {
                    this.totalAvailable = this.updateUnitOrgList();
                } else {
                    this.totalAvailable = this.updateOrgList();
                }
            },
            error: () => {
                this._growler.error(`Oops!`, `There was a problem loading organizations.`);
            },
            complete: () => this.isLoading = false,
        });
    }

    private updateUnitOrgList(): number {
        this.organizationItems = this.organizations.map((org) => {
            if (org.units && org.units.length) {
                return org.units.map((unit: Unit) => {
                    return {
                        id: org.id,
                        org: org.name,
                        unit: {
                            id: unit.id,
                            title: unit.title,
                        },
                    };
                });
            }

            return [{
                id: org.id,
                org: org.name,
                unit: null,
            }];
        }).reduce((a, b) => {
            return a.concat(b);
        });

        if (this.searchString !== '') {
            this.organizationItems = this.organizationItems.filter((org) => {
                const value = org.org + ' ' + org.id + (org.unit ? (' ' + org.unit.title) : '');
                return value.toLowerCase().indexOf(this.searchString) > -1;
            });
        }

        // send back count minus of units to select
        return this.organizationItems.length;
    }

    private updateOrgList(): number {
        let filtered = this.organizations.filter((org) => org.id !== this.currentOrgId);

        if (this.searchString !== '') {
            filtered = filtered.filter((org) => {
                const value = org.name + ' ' + org.id;
                return org.id !== this.currentOrgId && value.toLowerCase().indexOf(this.searchString) > -1;
            });
        }

        this.organizationItems = filtered.map((org) => {
            return {
                id: org.id,
                org: org.name,
            };
        });

        // send back count (already accounts for filtered "current" org)
        return this.organizationItems.length;
    }
}
