import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Membership } from './membership';
import { Activity } from './activity';
import { AuthActivity } from './auth-activity';
import { Charge } from './charge';
import { CommunicationActivity } from './communication-activity';
import { CreditCard } from './credit-card';
import { Nonce } from './nonce';
import { NotificationPreference } from './notification-preference';
import { Organization } from './organization';
import { PaymentBankAccount } from './payment-bank-account';

export class User
{
    id: number;
    stripeCustomerId: string;
    email: string;
    name: string;
    superUser: boolean;
    createdAt: Moment;
    updatedAt: Moment;

    lastLogin: Moment;
    bankAccountLimit = 2;
    creditCardsCount = 0;
    paymentBankAccountsCount = 0;
    isGoogleAuthVerificationEnrolled = false;
    isSmsAuthVerificationEnrolled = false;
    smsLast4 = '';

    activitiesBy: Activity[];
    activitiesFor: Activity[];
    authActivities: AuthActivity[];
    paymentBankAccounts: PaymentBankAccount[];
    charges: Charge[];
    creditCards: CreditCard[];
    communicationActivities: CommunicationActivity[];
    memberships: Membership[];
    nonces: Nonce[];
    notificationPreferences: NotificationPreference[];
    organizations: Organization[];

    constructor(user: any) {
        if (user.id) {
            this.id = user.id;
        }
        if (user.stripeCustomerId) {
            this.stripeCustomerId = user.stripeCustomerId;
        }
        if (user.email) {
            this.email = user.email;
        }
        if (user.name) {
            this.name = user.name;
        }
        if (user.superUser) {
            this.superUser = user.superUser;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (user.createdAt) {
            this.createdAt = moment.utc(user.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (user.updatedAt) {
            this.updatedAt = moment.utc(user.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (user.lastLogin) {
            this.lastLogin = moment.utc(user.lastLogin, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (user.activitiesBy) {
            this.activitiesBy = user.activitiesBy.map((activity) => {
                return new Activity(activity);
            });
        }
        if (user.activitiesFor) {
            this.activitiesFor = user.activitiesFor.map((activity) => {
                return new Activity(activity);
            });
        }
        if (user.authActivities) {
            this.authActivities = user.authActivities.map((authActivity) => {
                return new AuthActivity(authActivity);
            });
        }
        if (user.paymentBankAccounts) {
            this.paymentBankAccounts = user.paymentBankAccounts.map((bankAccount) => {
                return new PaymentBankAccount(bankAccount);
            });
        }
        if (user.charges) {
            this.charges = user.charges.map((charge) => {
                return new Charge(charge);
            });
        }
        if (user.creditCards) {
            this.creditCards = user.creditCards.map((creditCard) => {
                return new CreditCard(creditCard);
            });
        }
        if (user.communicationActivities) {
            this.communicationActivities = user.communicationActivities.map((communicationActivity) => {
                return new CommunicationActivity(communicationActivity);
            });
        }
        if (user.memberships) {
            this.memberships = user.memberships.map((membership) => {
                return new Membership(membership);
            });
            if (!this.name && this.memberships.length > 0) {
                this.name = this.memberships[0].name;
            }
        }
        if (user.nonces) {
            this.nonces = user.nonces.map((nonce) => {
                return new Nonce(nonce);
            });
        }
        if (user.notificationPreferences) {
            this.notificationPreferences = user.notificationPreferences.map((notificationPreference) => {
                return new NotificationPreference(notificationPreference);
            });
        }
        if (user.organizations) {
            this.organizations = user.organizations.map((organization) => {
                return new Organization(organization);
            });
        }
        if (user.bankAccountLimit) {
            this.bankAccountLimit = user.bankAccountLimit;
        }
        this.creditCardsCount = user.creditCardsCount || 0;
        this.paymentBankAccountsCount = user.paymentBankAccountsCount || 0;

        if (user.hasOwnProperty('googleAuthVerificationEnrolled')) {
            this.isGoogleAuthVerificationEnrolled = user.googleAuthVerificationEnrolled;
            this.isSmsAuthVerificationEnrolled = !!user.smsVerificationEnrolled;
            if (this.isSmsAuthVerificationEnrolled) {
                this.smsLast4 = user.smsVerificationEnrolled;
            }
        }

        // find something reasonable to fall back on if we have no name to display        
        if (!this.name) {
            this.name = this.email;
        }
    }

    hasEmailAddress() {
        return this.email && this.email.trim() !== '';
    }

    hasAutogeneratedEmailAddress() {
        return this.email && this.email.match(/^autogenerated_.*@(payhoa\.com|legfi\.com)/);
    }
}
