<ng-template #legacyInput>
    <form class="input-group add-border"
          [formGroup]="form"
          [class.btn-search-input]="hasButtonStyles"
          [class.flex-nowrap]="hasButtonStyles"
          [class.search-input-loading]="isLoading"
          [class.search-input-focused]="hasFocus()">
    <span *ngIf="hasGroupAddon"
          class="input-group-addon">
        <img src="/assets/images/payhoa/other/search.svg"/>
    </span>
        <span *ngIf="!hasGroupAddon"
              class="search-input-container">
        <span *ngIf="isLoading"
              class="d-inline-flex p-relative p-0 loading"></span>
    </span>
        <input #searchInput
               formControlName="search"
               class="form-control"
               type="text"
               aria-label="Material search input"
               [placeholder]="defaultPlaceholder"/>
        <span *ngIf="hasGroupAddon"
              class="input-group-addon"
              (click)="clearSearch()">
        <img src="/assets/images/app/icons/icon_close.svg"/>
    </span>
    </form>
</ng-template>

<ng-container *ngIf="newInput; else legacyInput">
    <ng-container [formGroup]="form">
        <mat-form-field appearance="outline"
                        class="payhoa-field payhoa-search w-100">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput
                   type="text"
                   aria-label="Search and find results"
                   [formControlName]="'search'"
                   [placeholder]="defaultPlaceholder">
        </mat-form-field>
    </ng-container>
</ng-container>
