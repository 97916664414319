import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';

export class FailedAuthAudit
{
    id: number;
    ip: any;
    failedCredential: string;
    createdAt: Moment;
    updatedAt: Moment;

    constructor(failedAuthAudit: any) {
        if (failedAuthAudit.id) {
            this.id = failedAuthAudit.id;
        }
        if (failedAuthAudit.ip) {
            this.ip = failedAuthAudit.ip;
        }
        if (failedAuthAudit.failedCredential) {
            this.failedCredential = failedAuthAudit.failedCredential;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (failedAuthAudit.createdAt) {
            this.createdAt = moment.utc(failedAuthAudit.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (failedAuthAudit.updatedAt) {
            this.updatedAt = moment.utc(failedAuthAudit.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
    }
}
