import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Activity } from './activity';

export class CommunicationResponse
{
    id: number;
    activityId: number;
    event: string;
    category: string;
    additional: any;
    createdAt: Moment;
    updatedAt: Moment;

    activity: Activity;

    constructor(communicationResponse: any) {
        if (communicationResponse.id) {
            this.id = communicationResponse.id;
        }
        if (communicationResponse.activityId) {
            this.activityId = communicationResponse.activityId;
        }
        if (communicationResponse.event) {
            this.event = communicationResponse.event;
            if (this.event === 'Letter sent via Lob' || this.event === 'Statement sent via Lob') {
                this.event = 'Created';
            }
        }
        if (communicationResponse.category) {
            this.category = communicationResponse.category;
        }
        if (communicationResponse.additional) {
            try {
                JSON.parse(communicationResponse.additional);
            } catch (e) {
                this.additional = communicationResponse.additional;
            }
        }

        const timezone = LegFiJwtService.getTimezone();
        if (communicationResponse.createdAt) {
            this.createdAt = moment.utc(communicationResponse.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (communicationResponse.updatedAt) {
            this.updatedAt = moment.utc(communicationResponse.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (communicationResponse.activity) {
            this.activity = new Activity(communicationResponse.activity);
        }
    }
}
