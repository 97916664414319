<ng-container [ngSwitch]="audit.event">
    <ng-container *ngSwitchCase="'created-by-payment-plan'">
        <div class="mb-2">
            This charge was created as part of a payment plan.
            {{ audit.oldValues.length }} charge{{ audit.oldValues.length === 1 ? '' : 's' }}
            totalling {{ audit.oldValues | whereIn:[false]:'paid' | sum:'chargeAmount' | legfiCurrency: 'USD':'symbol':'.2' }}
            {{ audit.oldValues.length === 1 ? 'was' : 'were' }} split into {{ audit.newValues.length }} payments.
        </div>

        <mat-accordion>
            <mat-expansion-panel class="p-0">
                <mat-expansion-panel-header>
                    <mat-panel-title>Deleted Charges ({{ audit.oldValues.length }})</mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template *ngTemplateOutlet="chargeTable; context: { charges: audit.oldValues, audit: audit }"></ng-template>
            </mat-expansion-panel>

            <mat-expansion-panel class="p-0">
                <mat-expansion-panel-header>
                    <mat-panel-title>New Payment Plan Charges ({{ audit.newValues.length }})</mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template *ngTemplateOutlet="chargeTable; context: { charges: audit.newValues, audit: audit }"></ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>

    <ng-container *ngSwitchCase="'deleted-by-payment-plan'">
        <div class="mb-2">
            This charge was converted into a payment plan.
            {{ audit.oldValues.length }} charge{{ audit.oldValues.length === 1 ? '' : 's' }}
            totalling {{ audit.oldValues | whereIn:[false]:'paid' | sum:'chargeAmount' | legfiCurrency: 'USD':'symbol':'.2' }}
            {{ audit.oldValues.length === 1 ? 'was' : 'were' }} split into {{ audit.newValues.length }} payments.
        </div>

        <mat-accordion>
            <mat-expansion-panel class="p-0">
                <mat-expansion-panel-header>
                    <mat-panel-title>Deleted Charges ({{ audit.oldValues.length }})</mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template *ngTemplateOutlet="chargeTable; context: { charges: audit.oldValues, audit: audit }"></ng-template>
            </mat-expansion-panel>

            <mat-expansion-panel class="p-0">
                <mat-expansion-panel-header>
                    <mat-panel-title>New Payment Plan Charges ({{ audit.newValues.length }})</mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template *ngTemplateOutlet="chargeTable; context: { charges: audit.newValues, audit: audit }"></ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>

    <app-audit-diff-formatter *ngSwitchDefault [audit]="audit"></app-audit-diff-formatter>
</ng-container>


<ng-template #chargeTable let-charges="charges" let-audit="audit">
    <mat-table [dataSource]="charges">
        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
            <mat-cell *matCellDef="let row" [class.font-weight-bold]="audit.auditableId === row.id">
                {{ row.title }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="activeAfter">
            <mat-header-cell *matHeaderCellDef>Active After</mat-header-cell>
            <mat-cell *matCellDef="let row">
                {{ row.activeAfter | legfiDate: 'MM/DD/YYYY' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="chargeAmount">
            <mat-header-cell *matHeaderCellDef>Amount</mat-header-cell>
            <mat-cell *matCellDef="let row">
                {{ row.chargeAmount| legfiCurrency:'USD':'symbol':'.2' }}
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="['title', 'activeAfter', 'chargeAmount']; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: ['title', 'activeAfter', 'chargeAmount']"></mat-row>
    </mat-table>
</ng-template>
