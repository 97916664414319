import { Injectable } from '@angular/core';
import moment from 'moment-timezone';

@Injectable({
    providedIn: 'root',
})
export class CloneService
{
    public static clone(obj: any) {
        let copy: any;

        // Handle 3 simple types, and null or undefined
        if (null === obj || typeof obj !== 'object') {
            return obj;
        }

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());

            return copy;
        }

        // Handle Moment
        if (moment.isMoment(obj)) {
            return obj.clone();
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            const len = obj.length;
            for (let i = 0; i < len; i++) {
                copy[i] = CloneService.clone(obj[i]);
            }

            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = Object.create(null);
            let value: any;
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    value = obj[key];

                    if (value !== null && typeof value === 'object') {
                        copy[key] = CloneService.clone(value);
                    } else {
                        copy[key] = value;
                    }
                }
            }

            return copy;
        }

        throw new Error('Unable to copy object! Unsupported object type.');
    }
}
