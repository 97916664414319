import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PageAdvertisement } from '../../../../models/entities/page-advertisement';
import { GenericDialogResult } from '../../shared/generic-mat-dialog/generic-dialog-result';

@Component({
    selector: 'app-feature-dialog',
    templateUrl: './feature-dialog.component.html',
    styleUrls: ['feature-dialog.component.scss'],
})
export class FeatureDialogComponent
{
    constructor(
            private _dialogRef: MatDialogRef<FeatureDialogComponent>,
            @Inject(MAT_DIALOG_DATA) public data: PageAdvertisement,
    ) {
    }

    close() {
        const output: GenericDialogResult = {
            success: true,
        };
        this._dialogRef.close(output);
    }
}
