import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    selector: 'lf-admin-layout',
    styleUrls: ['../../app.component.scss'],
    templateUrl: './admin-layout.component.html',
})
export class AdminLayoutComponent implements AfterViewInit
{
    isInMobileMode = false;
    @ViewChild(MatSidenav) sidenav!: MatSidenav;

    constructor(
            private _observer: BreakpointObserver,
    ) {
    }

    /** Set up mat sidebar / menu functionality based on screen width */
    ngAfterViewInit() {
        setTimeout(() => {
            this._observer.observe(['(max-width: 945px)']).subscribe((res) => {
                if (res.matches) {
                    this.sidenav.mode = 'over';
                    this.sidenav.close().then(() => {
                        this.isInMobileMode = true;
                    });

                } else {
                    this.sidenav.mode = 'side';
                    this.sidenav.open().then(() => {
                        this.isInMobileMode = false;
                    });
                }
            });
        });
    }

    // if the page is in mobile view, they clicked to change routes; toggle the sidenav
    handleSidenavClickOnMobile(): void {
        if (this.sidenav.mode === 'over') {
            this.sidenav.close();
        }
    }
}
