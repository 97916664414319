import { NgModule } from '@angular/core';
import { PromoBarComponent } from './promo-bar.component';
import { OrganizationService } from '../../../../services/organization/organization.service';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [PromoBarComponent],
    exports: [PromoBarComponent],
    providers: [OrganizationService],
})
export class PromoBarModule
{
}
