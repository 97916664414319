<app-jumbotron *ngIf="!loadingInitialData && (showJumbotron$ | async)"
               [newStyles]="true"
               [headerText]=" (0 | i18nPlural: notificationsMapping) + ' to display.'"
               [showEmptyImg]="false">
</app-jumbotron>

<mat-list [loadingList]="loadingInitialData && isDashboardCard" [lines]="3">
    <ng-container *ngIf="!loadingInitialData">
        <mat-list-item *ngFor="let notification of notifications$ | async" class="add-hover" [class.deleting]="isDeleting">
            <button mat-button mat-line disableRipple (click)="handleNotificationClick(notification)">
                <span class="notification-item">
                    <span class="unread-dot"><mat-icon *ngIf="!notification.readAt">circle</mat-icon></span>
                    <app-avatar-block [iconImage]="notification.data.icon" [svgIcon]="'v2:fi-br-question'" [wrappedText]="!isDashboardCard">
                        <span avatarTitle>{{ notification.data.title }}</span>
                        <span avatarDescription>{{ notification.data.organizationName }}</span>
                    </app-avatar-block>
                    <span *ngIf="isDashboardCard" class="notification-date">{{ notification.createdAt | legfiDate: 'MM/DD' }}</span>
                </span>
                <span *ngIf="!isDashboardCard" class="notification-description">
                    {{ notification.createdAt | legfiDate: 'MM/DD/YYYY h:mm a' }}
                    <hr>
                    {{ notification.data.description }}
                </span>
            </button>

            <mat-toolbar *ngIf="!isDashboardCard">
                <button mat-flat-button disableRipple color="accent"
                        (click)="deleteNotifications([notification.id])">
                    <mat-icon [svgIcon]="'v2:fi-br-trash'"></mat-icon>
                </button>
            </mat-toolbar>
        </mat-list-item>
    </ng-container>
</mat-list>
