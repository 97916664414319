import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import moment from 'moment-timezone';
import Moment = moment.Moment;
import { Organization } from './organization';
import { Payable } from './payable';
import { PayableApprover } from './payableApprover';
import { PayableApprovalRule } from './payable-approval-rules';
import { Membership } from './membership';


export class PayableApproval
{
    id: number;
    organizationId: number;
    payableFulfillmentId: number;
    payableApprovalRuleId: number;
    payableApproverId: number;
    approved: boolean;
    denied: boolean;
    createdAt: Moment;
    updatedAt: Moment;
    canApprove: boolean;
    canReset: boolean;
    organization: Organization;
    payable: Payable;
    approvalRule: PayableApprovalRule;
    approver: PayableApprover;
    membership: Membership;

    constructor(payableApproval: any = {}) {
        if (payableApproval.id) {
            this.id = payableApproval.id;
        }
        if (payableApproval.organizationId) {
            this.organizationId = payableApproval.organizationId;
        }
        if (payableApproval.payableFulfillmentId) {
            this.payableFulfillmentId = payableApproval.payableFulfillmentId;
        }
        if (payableApproval.payableApprovalRuleId) {
            this.payableApprovalRuleId = payableApproval.payableApprovalRuleId;
        }
        if (payableApproval.payableApproverId) {
            this.payableApproverId = payableApproval.payableApproverId;
        }
        if (payableApproval.approved) {
            this.approved = payableApproval.approved;
        }
        if (payableApproval.denied) {
            this.denied = payableApproval.denied;
        }
        const timezone = LegFiJwtService.getTimezone();
        if (payableApproval.createdAt) {
            this.createdAt = moment.utc(payableApproval.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (payableApproval.updatedAt) {
            this.updatedAt = moment.utc(payableApproval.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (payableApproval.canApprove) {
            this.canApprove = payableApproval.canApprove;
        }
        if (payableApproval.canReset) {
            this.canReset = payableApproval.canReset;
        }

        // relationships
        if (payableApproval.organization) {
            this.organization = new Organization(payableApproval.organization);
        }
        if (payableApproval.payable) {
            this.payable = new Payable(payableApproval.payable);
        }
        if (payableApproval.approvalRule) {
            this.approvalRule = new PayableApprovalRule(payableApproval.approvalRule);
        }
        if (payableApproval.approver) {
            this.approver = new PayableApprover(payableApproval.approver);
        }
        if (payableApproval.membership) {
            this.membership = new Membership(payableApproval.membership);
        }
    }
}
