import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureAllianceBankingComponent } from './feature-alliance-banking.component';
import { ButtonModule } from '../../../shared/button/button.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        FeatureAllianceBankingComponent,
    ],
    exports: [
        FeatureAllianceBankingComponent,
    ],
    imports: [
        CommonModule,
        ButtonModule,
        MatIconModule,
    ],
})
export class FeatureAllianceBankingModule
{
}
