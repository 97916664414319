import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sum',
})
export class SumPipe implements PipeTransform
{
    public transform(collection: any[], key: any) {
        if (!collection) {
            return 0;
        }

        return collection.reduce((a, b) => a + b[key], 0);
    }
}
