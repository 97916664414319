import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Category } from './accounting/category';
import { DepositBankAccount } from './deposit-bank-account';
import { Membership } from './membership';
import { Organization } from './organization';
import { Unit } from './unit';
import { Charge } from './charge';
import { Note } from './note';
import { RecurringChargeTemplateUnit } from './recurring-charge-template-unit';
import { ChargeLateFee } from './charge-late-fee';
import { ExpectedCharge } from './expected-charge';
import Moment = moment.Moment;

export class RecurringChargeTemplate
{
    id: number;
    organizationId: number;
    payorId: number;
    payorType: string;
    depositBankAccountId: number;
    categoryId: number;
    title: string;
    description: string;
    emailAppendMessage: string;
    currency: string;
    chargeAmount: number;
    startDate: Moment;
    endDate: Moment;
    dueAfterDays: number;
    earlyBeforeDays: number;
    earlyDiscount: number;
    lateAfterDays: number;
    lateFee: number;
    recurBasis: string;
    recursOn: number;
    recursEvery: number;
    firstDueDate: Moment;
    pausedAt: Moment;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    category: Category;
    depositBankAccount: DepositBankAccount;
    organization: Organization;
    payor: Membership | Unit;
    charges: Charge[];
    notes: Note[];

    reason: string;
    hidden: boolean;
    checked: boolean;
    showDetails: boolean;

    removeLateFees: boolean;
    removeEarlyDiscounts: boolean;
    emailInvoice: boolean;

    generateFutureForRecurring: boolean;

    lateFees: {
        id: number;
        lateFeeType: string;
        lateAfterDays: number;
        oneTimeLateFeeType: any;
        oneTimeLateFeeApplies: any;
        oneTimeLateFeeAmount: number;
        recurringLateFeeBegins: any;
        recurringLateFeeInterval: string;
        recurringLateFeeIntervalValue: number;
        recurringLateFeeType: string;
        recurringLateFeeAmount: number;
    }[] = [];

    templateUnits: RecurringChargeTemplateUnit[] = [];
    activeTemplateUnits: RecurringChargeTemplateUnit[] = [];
    numberLinksPaused: number = 0;

    pivot: RecurringChargeTemplateUnit;

    totalForAllUnits: number = 0;
    minimumCharge: number = Number.MAX_SAFE_INTEGER;
    maximumCharge: number = Number.MIN_SAFE_INTEGER;

    expectedCharges: ExpectedCharge[] = [];

    nextExpectedCharge: ExpectedCharge = null;
    lastExpectedCharge: ExpectedCharge = null;

    constructor(template: any) {
        if (template.id) {
            this.id = template.id;
        }
        if (template.generateFutureForRecurring) {
            this.generateFutureForRecurring = template.generateFutureForRecurring;
        }
        if (template.organizationId) {
            this.organizationId = template.organizationId;
        }
        if (template.payorId) {
            this.payorId = template.payorId;
        }
        if (template.payorType) {
            this.payorType = template.payorType;
        }
        if (template.depositBankAccountId) {
            this.depositBankAccountId = template.depositBankAccountId;
        }
        if (template.categoryId) {
            this.categoryId = template.categoryId;
        }
        if (template.title) {
            this.title = template.title;
        }
        if (template.description) {
            this.description = template.description;
        }
        if (template.emailAppendMessage) {
            this.emailAppendMessage = template.emailAppendMessage;
        }
        if (template.currency) {
            this.currency = template.currency.toLocaleUpperCase();
        }
        if (template.chargeAmount) {
            this.chargeAmount = template.chargeAmount;
        }
        if (template.hasOwnProperty('dueAfterDays')) {
            this.dueAfterDays = template.dueAfterDays;
        }

        if (template.earlyBeforeDays) {
            this.earlyBeforeDays = template.earlyBeforeDays;
        }
        if (template.earlyDiscount) {
            this.earlyDiscount = template.earlyDiscount;
        }
        if (template.hasOwnProperty('lateAfterDays')) {
            this.lateAfterDays = template.lateAfterDays;
        }
        if (template.lateFee) {
            this.lateFee = template.lateFee;
        }
        if (template.recurBasis) {
            this.recurBasis = template.recurBasis;
            if (template.recurBasis === 'monthly' && template.recursEvery === 3) {
                this.recurBasis = 'quarterly';
            }
            if (template.recurBasis === 'monthly' && template.recursEvery === 6) {
                this.recurBasis = 'biannually';
            }
            if (template.recurBasis === 'monthly' && template.recursEvery === 12) {
                this.recurBasis = 'yearly';
            }
        }
        if (template.recursOn) {
            this.recursOn = template.recursOn;
        }
        if (template.recursEvery) {
            this.recursEvery = template.recursEvery;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (template.firstDueDate) {
            this.firstDueDate = moment.utc(template.firstDueDate, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (template.pausedAt) {
            this.pausedAt = moment.utc(template.pausedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (template.startDate) {
            this.startDate = moment.utc(template.startDate, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (template.endDate) {
            this.endDate = moment.utc(template.endDate, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (template.createdAt) {
            this.createdAt = moment.utc(template.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (template.updatedAt) {
            this.updatedAt = moment.utc(template.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (template.deletedAt) {
            this.deletedAt = moment.utc(template.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (template.category) {
            this.category = new Category(template.category);
        }
        if (template.depositBankAccount) {
            this.depositBankAccount = new DepositBankAccount(template.depositBankAccount);
        }
        if (template.organization) {
            this.organization = new Organization(template.organization);
        }
        if (template.payor && template.payorType !== null) {
            if (template.payorType.indexOf('Membership') !== -1) {
                this.payor = new Membership(template.payor);
            } else if (template.payorType.indexOf('Unit') !== -1) {
                this.payor = new Unit(template.payor);
            }
        }
        if (template.charges) {
            this.charges = template.charges.map((c) => {
                return new Charge(c);
            });
        }
        if (template.notes) {
            this.notes = template.notes.map((n) => {
                return new Note(n);
            });
        }
        if (template.lateFees) {
            this.lateFees = template.lateFees.map((fee) => {
                return new ChargeLateFee(fee);
            });
        }
        if (typeof template['emailInvoice'] !== 'undefined') {
            this.emailInvoice = template.emailInvoice;
        }

        // when loading templates related to units, pivot will contain a fragment of a single RecurringChargeTemplate record
        if (template.pivot) {
            this.pivot = new RecurringChargeTemplateUnit(template.pivot);
        }
        if (template.templateUnits) {
            this.templateUnits = template.templateUnits.map((u) => {
                return new RecurringChargeTemplateUnit(u);
            });
            this.activeTemplateUnits = this.templateUnits.filter((u) => !u.pausedAt);
            this.numberLinksPaused = this.templateUnits.length - this.activeTemplateUnits.length;

            this.templateUnits.forEach((templateUnit) => {
                if (templateUnit.amount < this.minimumCharge) {
                    this.minimumCharge = templateUnit.amount;
                }
                if (templateUnit.amount > this.maximumCharge) {
                    this.maximumCharge = templateUnit.amount;
                }
            });
            if (this.templateUnits.length === 0) {
                this.minimumCharge = 0;
                this.maximumCharge = 0;
            }
            this.totalForAllUnits = this.templateUnits.reduce((a, b) => {
                return a + b.amount;
            }, 0);

            if (template.templateUnits.length === 1) {
                const tu = template.templateUnits[0];
                if (tu.pausedAt) {
                    this.pausedAt = tu.pausedAt;
                }
            }
        } else {
            this.minimumCharge = 0;
            this.maximumCharge = 0;
            this.totalForAllUnits = 0;
        }
        if (template.expectedCharges) {
            this.expectedCharges = template.expectedCharges.map((c) => {
                return new ExpectedCharge(c);
            }).sort((a, b) => b.billingDate - a.billingDate);
            this.nextExpectedCharge = this.expectedCharges.filter(a => !a.chargeCreated).pop();
            this.lastExpectedCharge = this.expectedCharges.filter(a => a.chargeCreated).shift();
        }
    }

    public getDueDate(billingDate: Moment) {
        const dueOn = this.firstDueDate;
        const startOn = this.startDate;

        const out = billingDate.clone();

        if (this.recurBasis !== 'weekly') {
            const sameMonth = dueOn.month() === startOn.month() && dueOn.year() === startOn.year();

            if (!sameMonth) {
                const monthsBetween = dueOn.diff(startOn, 'months', true);
                out.add(Math.ceil(monthsBetween), 'months');
            }

            if (out.daysInMonth() < dueOn.date()) {
                out.date(out.daysInMonth());
            } else {
                out.date(dueOn.date());
            }
        } else {
            const daysBetween = dueOn.diff(startOn, 'days');
            out.add(daysBetween, 'days');
        }
        return out;
    }

    public getRecursOnDisplay() {
        if (this.recurBasis === 'monthly') {
            const display = this.numberToString(this.recursOn);
            let recurrence = `of every month`;
            if (this.recursEvery > 1) {
                recurrence = `every ${this.recursEvery} months`;
            }
            return `monthly on the ${display} ${recurrence}`;
        } else if (this.recurBasis === 'weekly') {
            const display = this.startDate.format('dddd');
            return `weekly on ${display}s`;
        } else if (this.recurBasis === 'daily') {
            return `daily`;
        } else if (this.recurBasis === 'quarterly') {
            const display = this.numberToString(this.recursOn);
            if (this.recursEvery > 1) {
                return `every ${this.recursEvery} quarters on the ${display} of the month`;
            }
            return `every 3 months on the ${display} of the month`;
        } else if (this.recurBasis === 'biannually') {
            const display = this.numberToString(this.recursOn);
            return `every 6 months on the ${display} of the month`;
        } else if (this.recurBasis === 'yearly') {
            const display = this.numberToString(this.recursOn);
            const month = this.startDate.format('MMMM');
            return `yearly on the ${display} of ${month}`;
        }
        return '';
    }

    private numberToString(day: number) {
        if (day >= 11 && day <= 13) {
            return `${day}th`;
        }

        const recursOnAsString = `${this.recursOn}`;
        const finalDigit = recursOnAsString.slice(-1);

        if (finalDigit === '1') {
            return `${day}st`;
        } else if (finalDigit === '2') {
            return `${day}nd`;
        } else if (finalDigit === '3') {
            return `${day}rd`;
        }
        return `${this.recursOn}th`;
    }
}
