import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Module } from './module';
import { Group } from './group';
import { Membership } from './membership';

export class Permission
{
    id: number;
    moduleId: number;
    ownerId: number;
    ownerType: string;
    writable: boolean;
    createdAt: Moment;
    updatedAt: Moment;

    module: Module;
    owner: Group | Membership;

    constructor(permission: any) {
        if (permission.id) {
            this.id = permission.id;
        }
        if (permission.moduleId) {
            this.moduleId = permission.moduleId;
        }
        if (permission.ownerId) {
            this.ownerId = permission.ownerId;
        }
        if (permission.ownerType) {
            this.ownerType = permission.ownerType;
        }
        if (permission.writable) {
            this.writable = permission.writable;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (permission.createdAt) {
            this.createdAt = moment.utc(permission.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (permission.updatedAt) {
            this.updatedAt = moment.utc(permission.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (permission.module) {
            this.module = new Module(permission.module);
        }
        if (permission.owner && permission.ownerType !== null) {
            if (permission.ownerType.indexOf('Group') !== -1) {
                this.owner = new Group(permission.owner);
            } else if (permission.ownerType.indexOf('Membership') !== -1) {
                this.owner = new Membership(permission.owner);
            }
        }
    }
}
