import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './app-layout.component';
import { LoginGuard } from '../../common/auth/login.guard';
import { UnlimitedGuard } from '../../common/auth/unlimited.guard';
import { PermissionsGuard } from '../../common/auth/permissions.guard';
import { RouteModules } from '../../enums/route-modules.enum';

const appLayoutRoutes: Routes = [
    {
        path: 'app',
        component: AppLayoutComponent,
        children: [
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'launchpad',
                loadChildren: () => import('./launchpad/launchpad.module').then(m => m.LaunchpadModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'people',
                loadChildren:
                        () => import('./members/people-list/people-list.module').then(m => m.PeopleListModule),
                data: {module: RouteModules.MEMBERS},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'members/invite',
                loadChildren:
                        () => import('./members/member-invites/member-invites.module').then(m => m.MemberInvitesModule),
            },
            {
                canActivate: [
                    UnlimitedGuard,
                    LoginGuard,
                ],
                path: 'members/:id',
                loadChildren:
                        () => import('./members/member-details/member-details.module').then(m => m.MemberDetailsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'member-directory',
                loadChildren:
                        () => import('./members/member-directory/member-directory.module').then(m => m.MemberDirectoryModule),
            },
            {
                canActivate: [
                    UnlimitedGuard,
                    LoginGuard,
                ],
                path: 'owner/dashboard',
                loadChildren:
                        () => import('./dashboard/owner-dashboard/owner-dashboard.module').then(m => m.OwnerDashboardModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'owner/create',
                loadChildren:
                        () => import('./owners/add-single-owner/add-single-owner.module').then(m => m.AddSingleOwnerModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'charges/organization/issued',
                loadChildren:
                        () => import('./charges/issued-charges/issued-charges.module').then(m => m.IssuedChargesModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'charges/new',
                loadChildren:
                        () => import('./charges/bulk-charge-creator/bulk-charge-creator.module').then(m => m.BulkChargeCreatorModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'payments/incoming',
                loadChildren:
                        () => import('./payments/incoming-payments/incoming-payments.module').then(m => m.IncomingPaymentsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'payments/import-lockbox',
                loadChildren:
                        () => import('./payments/import-lockbox-payments/import-lockbox-payments.module').then(m => m.ImportLockboxPaymentsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'payables',
                loadChildren:
                        () => import('./accounts-payable/payables-dashboard/payables-dashboard.module').then(m => m.PayablesDashboardModule),
                data: {module: RouteModules.BILLPAY},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'form-1099',
                loadChildren:
                        () => import('./accounts-payable/form1099/form1099-dashboard/form1099-dashboard.module').then(m => m.Form1099DashboardModule),
                data: {module: RouteModules.BILLPAY, feature: 'form1099.enabled'},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'form-1099/add/:type',
                loadChildren: () => import('./accounts-payable/form1099/form1099-form/form1099-form.module').then(m => m.Form1099FormModule),
                data: {module: RouteModules.BILLPAY, feature: 'form1099.enabled'},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'form-1099/edit/:id',
                loadChildren: () => import('./accounts-payable/form1099/form1099-form/form1099-form.module').then(m => m.Form1099FormModule),
                data: {module: RouteModules.BILLPAY, feature: 'form1099.enabled'},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'bill-pay',
                loadChildren:
                        () => import('./bill-pay/legacy-payments/legacy-payments.module').then(m => m.LegacyPaymentsModule),
                data: {module: RouteModules.BILLPAY},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'bill-pay/:vendorId',
                loadChildren:
                        () => import('./bill-pay/bill-pay-payment/bill-pay-payment.module').then(m => m.BillPayPaymentModule),
                data: {module: RouteModules.BILLPAY, write: true},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'bill-pay/create',
                loadChildren:
                        () => import('./bill-pay/bill-pay-payment/bill-pay-payment.module').then(m => m.BillPayPaymentModule),
                data: {module: RouteModules.BILLPAY, write: true},
            },
            {
                path: 'vendors',
                redirectTo: 'bill-pay',
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'vendor/details/:id',
                loadChildren:
                        () => import('./accounts-payable/vendors/vendor-details/vendor-details.module').then(m => m.VendorDetailsModule),
                data: {module: RouteModules.BILLPAY},
            },
            {
                canActivate: [
                    PermissionsGuard,
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'vendor/add',
                loadChildren:
                        () => import('./bill-pay/vendor-editor/vendor-editor.module').then(m => m.VendorEditorModule),
                data: {module: RouteModules.BILLPAY, write: true},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'vendor/edit/:id',
                loadChildren:
                        () => import('./bill-pay/vendor-editor/vendor-editor.module').then(m => m.VendorEditorModule),
                data: {module: RouteModules.BILLPAY},
            },
            {
                canActivate: [LoginGuard],
                path: 'make-payment',
                loadChildren:
                        () => import('./payments/make-payment/make-payment.module').then(m => m.MakePaymentModule),
            },
            {
                //     canActivate: [LoginGuard, UnlimitedGuard],
                //     path: 'record-payment',
                //     loadChildren: './payments/record-owner-payment/record-owner-payment.module'
                // }, {
                canActivate: [],
                path: 'receipt',
                loadChildren:
                        () => import('./payments/payment-receipt/payment-receipt.module').then(m => m.PaymentReceiptModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'surveys',
                loadChildren:
                        () => import('./communications/surveys/surveys.module').then(m => m.SurveysModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'surveys/create',
                loadChildren:
                        () => import('./communications/survey-editor/survey-editor.module').then(m => m.SurveyEditorModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'surveys/:surveyId/results',
                loadChildren:
                        () => import('./communications/survey-results/survey-results.module').then(m => m.SurveyResultsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'surveys/:surveyId/:showModal',
                loadChildren:
                        () => import('./communications/show-survey/show-survey.module').then(m => m.ShowSurveyModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'surveys/:surveyId',
                loadChildren:
                        () => import('./communications/show-survey/show-survey.module').then(m => m.ShowSurveyModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'transactions/import',
                loadChildren:
                        () => import('./accounting/transactions-import/transactions-import.module').then(m => m.TransactionsBulkImportModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'transactions',
                loadChildren:
                        () => import('./accounting/transactions/transactions.module').then(m => m.TransactionsModule),
                data: {module: RouteModules.TRANSACTIONS},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reconciliation/:reconciliationId',
                loadChildren: () => import('./accounting/bank-reconciliation/bank-reconciliation.module').then(m => m.BankReconciliationModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'budgets',
                loadChildren:
                        () => import('./accounting/budgets/budgets.module').then(m => m.BudgetsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'budgets/new',
                loadChildren:
                        () => import('./accounting/budget-editor/budget-editor.module').then(m => m.BudgetEditorModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'chart-of-accounts',
                loadChildren:
                        () => import('./accounting/chart-of-accounts/chart-of-accounts.module').then(m => m.ChartOfAccountsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'check-printing',
                loadChildren:
                        () => import('./accounts-payable/check-printing/check-printing.module').then(m => m.CheckPrintingModule),
                data: {
                    module: RouteModules.BILLPAY,
                    feature: 'check-printing',
                },
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'collections/past-due-emails',
                loadChildren:
                        () => import('./collections/past-due-emails/past-due-emails.module').then(m => m.PastDueEmailsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'communications/send',
                loadChildren:
                        () => import('./communications/communication-sender/communication-sender.module').then(m => m.CommunicationSenderModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'communications/org-log',
                loadChildren:
                        () => import('./communications/communication-logs/communication-logs.module').then(m => m.CommunicationLogsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'communications/documents',
                loadChildren:
                        () => import('./communications/documents/documents.module').then(m => m.DocumentsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'communications/send-statements',
                loadChildren:
                        () => import('./communications/send-statements/send-statements.module').then(m => m.SendStatementsModule),
            },

            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'communications/pdf-statement-downloader',
                loadChildren:
                        () => import('./communications/pdf-statement-downloader/pdf-statement-downloader.module').then(m => m.PdfStatementDownloaderModule),
            },
            {
                canActivate: [],
                path: 'lock',
                loadChildren: () => import('./authentication/lock/lock.module').then(m => m.LockModule),
            },
            /**
             * Begin Reports Section
             */
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports',
                loadChildren: () => import('./reports/reports/reports.module').then(m => m.ReportsModule),
            },
            /**
             * Report Packets
             */
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/packets',
                loadChildren: () => import('./reports/report-packets/report-packets.module').then(m => m.ReportPacketsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/packets/packet-detail/:id',
                loadChildren: () => import('./reports/report-packets/report-packet-detail/report-packet-detail.module').then(m => m.ReportPacketDetailModule),
            },
            /**
             * Master Reports
             */
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'reports/churn-vs-new',
                loadChildren: () => import('./reports/churn-vs-new/churn-vs-new.module').then(m => m.ChurnVsNewModule),
                data: {module: RouteModules.SUPER_ADMIN},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'reports/all-orgs-list',
                loadChildren: () => import('./reports/all-orgs-list/all-orgs-list.module').then(m => m.AllOrgsListModule),
                data: {module: RouteModules.SUPER_ADMIN},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'reports/non-billed',
                loadChildren: () => import('./reports/non-billed/non-billed.module').then(m => m.NonBilledModule),
                data: {module: RouteModules.SUPER_ADMIN},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'reports/bookkeeping-prospects',
                loadChildren: () => import('./reports/bookkeeping-prospects/bookkeeping-prospects.module').then(m => m.BookkeepingProspectsModule),
                data: {module: RouteModules.SUPER_ADMIN},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'reports/master-billing',
                loadChildren: () => import('./reports/master-billing/master-billing.module').then(m => m.MasterBillingModule),
                data: {module: RouteModules.SUPER_ADMIN},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'reports/api-performance',
                loadChildren: () => import('./reports/api-performance/api-performance.module').then(m => m.ApiPerformanceModule),
                data: {module: RouteModules.SUPER_ADMIN},
            },
            /**
             * Business Overview
             */
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/balance-sheet-ledger',
                loadChildren:
                        () => import('./reports/balance-sheet-ledger/balance-sheet-ledger.module').then(m => m.BalanceSheetLedgerModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/general-ledger',
                loadChildren:
                        () => import('./reports/general-ledger/general-ledger.module').then(m => m.GeneralLedgerModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/account-register',
                loadChildren: () => import('./reports/account-register/account-register.module').then(m => m.AccountRegisterModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/check-register',
                loadChildren:
                        () => import('./reports/check-register/check-register.module').then(m => m.CheckRegisterModule),
            },

            /** Profit VS Loss */
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/profit-vs-loss-ledger',
                loadChildren:
                        () => import('./reports/profit-loss-ledger/profit-loss-ledger.module').then(m => m.ProfitLossLedgerModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/profit-vs-loss-by-month',
                loadChildren:
                        () => import('./reports/profit-loss-by-month/profit-loss-by-month.module').then(m => m.ProfitLossByMonthModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/profit-vs-loss-by-quarter',
                loadChildren:
                        () => import('./reports/profit-loss-by-quarter/profit-loss-by-quarter.module').then(m => m.ProfitLossByQuarterModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/profit-vs-loss-detail',
                loadChildren:
                        () => import('./reports/profit-loss-detail/profit-loss-detail.module').then(m => m.ProfitLossDetailModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/profit-vs-loss-percentage',
                loadChildren:
                        () => import('./reports/profit-loss-percentage/profit-loss-percentage.module').then(m => m.ProfitLossPercentageModule),
            },

            /** Budget VS Actual */
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/budget-report',
                loadChildren:
                        () => import('./reports/budget-report/budget-report.module').then(m => m.BudgetReportModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/budget-vs-actual',
                loadChildren:
                        () => import('./reports/budget-vs-actual/budget-vs-actual.module').then(m => m.BudgetVsActualModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/budget-performance-by-month',
                loadChildren:
                        () => import('./reports/budget-performance-by-month/budget-performance-by-month.module').then(m => m.BudgetPerformanceByMonthModule),
            },

            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'transactions/rules',
                loadChildren: () => import('./accounting/transactions/transaction-rules/transaction-rules.module').then(m => m.TransactionRulesModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'rules/create',
                loadChildren: () => import('./accounting/transactions/rules-create/rules-create.module').then(m => m.RulesCreateModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'rules/edit/:id',
                loadChildren: () => import('./accounting/transactions/rules-edit/rules-edit.module').then(m => m.RulesEditModule),
            },

            /** Other business overview reports */
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/journal-entries',
                loadChildren:
                        () => import('./reports/journal-entries/journal-entries.module').then(m => m.JournalEntriesModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/bank-reconciliation',
                loadChildren:
                        () => import('./reports/bank-reconciliation/bank-reconciliation.module').then(m => m.BankReconciliationModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/alliance-statements',
                loadChildren:
                        () => import('./reports/alliance-statements/alliance-statements.module').then(m => m.AllianceStatementsModule),
            },

            /**
             * Who Owes You
             */
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/aging-of-accounts',
                loadChildren:
                        () => import('./reports/aging-of-accounts/aging-of-accounts.module').then(m => m.AgingOfAccountsModule),
            },

            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/unit-balance/list',
                loadChildren:
                        () => import('./reports/unit-balance-list/unit-balance-list.module').then(m => m.UnitBalanceListModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/unit-balance/summary',
                loadChildren:
                        () => import('./reports/unit-balance-summary/unit-balance-summary.module').then(m => m.UnitBalanceSummaryModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/unit-balance/detail',
                loadChildren:
                        () => import('./reports/unit-balance-detail/unit-balance-detail.module').then(m => m.UnitBalanceDetailModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/delinquent-accounts/summary',
                loadChildren:
                        () => import('./reports/delinquent-accounts/delinquent-accounts.module').then(m => m.DelinquentAccountsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/delinquent-accounts/detail',
                loadChildren:
                        () => import('./reports/delinquent-accounts-detail/delinquent-accounts-detail.module').then(m => m.DelinquentAccountsDetailModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/invoices/by-unit',
                loadChildren:
                        () => import('./reports/invoices-by-unit/invoices-by-unit.module').then(m => m.InvoicesByUnitModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/invoices/by-revenue-category',
                loadChildren:
                        () => import('./reports/invoices-by-revenue-category/invoices-by-revenue-category.module').then(m => m.InvoicesByRevenueCategoryModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/invoices/by-title',
                loadChildren:
                        () => import('./reports/invoices-by-title/invoices-by-title.module').then(m => m.InvoicesByTitleModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/recurring-charges/new',
                loadChildren:
                        () => import('./reports/new-recurring-charge-templates/new-recurring-charge-templates.module').then(m => m.NewRecurringChargeTemplatesModule),
            },

            /**
             * Payment Reports
             */
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/payments/by-unit',
                loadChildren:
                        () => import('./reports/payments-by-unit/payments-by-unit.module').then(m => m.PaymentsByUnitModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/prepayments/by-unit',
                loadChildren:
                        () => import('./reports/prepayments-by-unit/prepayments-by-unit.module').then(m => m.PrepaymentsByUnitModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/receipts',
                loadChildren:
                        () => import('./reports/receipts-detail/receipts-detail.module').then(m => m.ReceiptsDetailModule),
            },

            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/deposit-detail',
                loadChildren: () => import('./reports/deposit-detail/deposit-detail.module').then(m => m.DepositDetailModule),
            },

            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/returned-payments',
                loadChildren:
                        () => import('./reports/returned-payments/returned-payments.module').then(m => m.ReturnedPaymentsModule),
            },

            /**
             * Expense Reports
             */

            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/expenses/by-vendor',
                loadChildren:
                        () => import('./reports/expenses-by-vendor/expenses-by-vendor.module').then(m => m.ExpensesByVendorModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/expenses/vendor-overview',
                loadChildren:
                        () => import('./reports/vendor-overview/vendor-overview.module').then(m => m.VendorOverviewModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/expenses/subscription-history',
                loadChildren:
                        () => import('./reports/subscription-history/subscription-history.module').then(m => m.SubscriptionHistoryModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/expenses/by-category',
                loadChildren:
                        () => import('./reports/expenses-by-category/expenses-by-category.module').then(m => m.ExpensesByCategoryModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/expenses/detail',
                loadChildren:
                        () => import('./reports/expenses-detail/expenses-detail.module').then(m => m.ExpensesDetailModule),
            },

            /**
             * Other Reports
             */
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/unit-contact-info',
                loadChildren:
                        () => import('./reports/unit-contact-info/unit-contact-info.module').then(m => m.UnitContactInfoModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/vendor-info',
                loadChildren:
                        () => import('./reports/vendor-info/vendor-info.module').then(m => m.VendorInfoModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/ownership-change',
                loadChildren:
                        () => import('./reports/ownership-change/ownership-change.module').then(m => m.OwnershipChangeModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/violations',
                loadChildren:
                        () => import('./reports/violations/violations.module').then(m => m.ViolationsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'reports/custom-report',
                loadChildren:
                        () => import('./reports/custom-report/custom-report.module').then(m => m.CustomReportModule),
            },

            /**
             * End Reports Section
             */
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'settings/user',
                loadChildren:
                        () => import('./owners/owner-settings/owner-settings.module').then(m => m.OwnerSettingsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'settings/user/security',
                loadChildren:
                        () => import('./settings/user/security/security.module').then(m => m.SecurityModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'settings/user/financial-accounts',
                loadChildren:
                        () => import('./settings/user/wallet/wallet.module').then(m => m.WalletModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'settings/user/financial-accounts/:id',
                loadChildren: () => import('./settings/user/wallet/wallet.module').then(m => m.WalletModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'settings/user/notifications',
                loadChildren:
                        () => import('./settings/user/notification-settings/notification-settings.module').then(m => m.NotificationSettingsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'settings/user/autopay',
                loadChildren:
                        () => import('./settings/user/autopay/autopay-settings.module').then(m => m.AutopaySettingsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'settings/organization',
                loadChildren:
                        () => import('./settings/organization/organization-settings/organization-settings.module').then(m => m.OrganizationSettingsModule),
                data: {module: RouteModules.ORG_ADMIN},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'settings/organization/preferences',
                loadChildren:
                        () => import('./settings/organization/organization-preferences/organization-preferences.module').then(m => m.OrganizationPreferencesModule),
                data: {module: RouteModules.ORG_ADMIN},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'settings/organization/financial-accounts',
                loadChildren:
                        () => import('./settings/organization/financial-accounts/financial-accounts.module').then(m => m.OrgFinancialAccountsModule),
                data: {module: RouteModules.ORG_ADMIN},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'settings/organization/review-feed/:id',
                loadChildren:
                        () => import('./settings/organization/plaid-feed/plaid-feed.module').then(m => m.PlaidFeedModule),
                data: {module: RouteModules.ORG_ADMIN},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'settings/organization/billing',
                loadChildren:
                        () => import('./settings/organization/billing/billing.module').then(m => m.BillingModule),
                data: {module: RouteModules.ORG_ADMIN},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'settings/organization/manage-tags',
                loadChildren:
                        () => import('./settings/organization/tags/tags.module').then(m => m.TagsModule),
                data: {module: RouteModules.ORG_ADMIN},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'settings/organization/manage-custom-fields',
                loadChildren:
                        () => import('./settings/organization/custom-fields/custom-fields.module').then(m => m.CustomFieldsModule),
                data: {module: RouteModules.ORG_ADMIN},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'settings/organization/permissions',
                loadChildren:
                        () => import('./settings/organization/permissions/permissions.module').then(m => m.PermissionsModule),
                data: {module: RouteModules.ORG_ADMIN},
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                    PermissionsGuard,
                ],
                path: 'organization/dashboard',
                loadChildren:
                        () => import('./dashboard/organization-dashboard/organization-dashboard.module').then(m => m.OrganizationDashboardModule),
                data: {module: RouteModules.DASHBOARD},
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/app/owner/dashboard',
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'calendar',
                loadChildren:
                        () => import('./calendar/events-calendar/events-calendar.module').then(m => m.EventsCalendarModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'broadcast/list',
                loadChildren:
                        () => import('./broadcast/broadcast-list/broadcast-list.module').then(m => m.BroadcastListModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'broadcast',
                loadChildren:
                        () => import('./broadcast/broadcast.module').then(m => m.BroadcastModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'mail/send/:which',
                loadChildren:
                        () => import('./mail/mail-sender/mail-sender.module').then(m => m.MailSenderModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'mail/:tab',
                loadChildren:
                        () => import('./mail/mail.module').then(m => m.MailModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'mail',
                loadChildren:
                        () => import('./mail/mail.module').then(m => m.MailModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'other-tools',
                loadChildren:
                        () => import('./other-tools/other-tools.module').then(m => m.OtherToolsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'violations/member',
                loadChildren:
                        () => import('./violations/member-violations/member-violations.module').then(m => m.MemberViolationsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'violations',
                loadChildren:
                        () => import('./violations/violation-dashboard/violation-dashboard.module').then(m => m.ViolationDashboardModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'violations/create',
                loadChildren:
                        () => import('./violations/violation-editor/violation-editor.module').then(m => m.ViolationEditorModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'violations/edit/:id',
                loadChildren:
                        () => import('./violations/violation-editor/violation-editor.module').then(m => m.ViolationEditorModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'violations/detail/:id',
                loadChildren:
                        () => import('./violations/violation-detail/violation-detail.module').then(m => m.ViolationDetailModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'violations/history/:id',
                loadChildren:
                        () => import('./violations/violation-history/violation-history.module').then(m => m.ViolationHistoryModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'violations/settings',
                loadChildren:
                        () => import('./violations/violation-settings/violation-settings.module').then(m => m.ViolationSettingsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'forms/:formId',
                loadChildren:
                        () => import('./forms/form-submitter/form-submitter.module').then(m => m.FormSubmitterModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'forms',
                loadChildren:
                        () => import('./forms/form-dashboard/form-dashboard.module').then(m => m.FormDashboardModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'new-form',
                loadChildren:
                        () => import('./forms/new-form/new-form.module').then(m => m.NewFormModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'form-detail/:formId',
                loadChildren:
                        () => import('./forms/form-detail/form-detail.module').then(m => m.FormDetailModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'form-settings/:formId',
                loadChildren:
                        () => import('./forms/form-settings/form-settings.module').then(m => m.FormSettingsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'form-submission/:submissionId/edit',
                loadChildren:
                        () => import('./forms/form-submission-detail/form-submission-editor/form-submission-editor.module').then(m => m.FormSubmissionEditorModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'form-submission/:submissionId',
                loadChildren:
                        () => import('./forms/form-submission-detail/form-submission-detail.module').then(m => m.FormSubmissionDetailModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'unit/detail/:id',
                loadChildren:
                        () => import('./units/unit-dashboard/unit-dashboard.module').then(m => m.UnitDashboardModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'broadcast/list',
                loadChildren:
                        () => import('./broadcast/broadcast-list/broadcast-list.module').then(m => m.BroadcastListModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'broadcast',
                loadChildren:
                        () => import('./broadcast/broadcast.module').then(m => m.BroadcastModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'mail',
                loadChildren:
                        () => import('./mail/mail.module').then(m => m.MailModule),
            },

            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'unit/detail/editor/:id',
                loadChildren:
                        () => import('./units/unit-detail-editor/unit-detail-editor.module').then(m => m.UnitDetailEditorModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'unit/create',
                loadChildren:
                        () => import('./units/unit-detail-editor/unit-detail-editor.module').then(m => m.UnitDetailEditorModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'unit/list',
                loadChildren:
                        () => import('./units/unit-list/unit-list.module').then(m => m.UnitListModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'unit/bulk-importer',
                loadChildren:
                        () => import('./units/unit-importer/unit-importer.module').then(m => m.UnitImporterModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'search',
                loadChildren:
                        () => import('./search/global-search-results/global-search-results.module').then(m => m.GlobalSearchResultsModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'sample/unit',
                loadChildren:
                        () => import('./onboarding/feature-pages/sample-unit/sample-unit.module').then(m => m.SampleUnitModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'site-builder',
                loadChildren:
                        () => import('./site-builder/site-builder.module').then(m => m.SiteBuilderModule),
            },
            // Message boards
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'message-board/boards',
                loadChildren:
                        () => import('./message-board/board-list/board-list.module').then(m => m.BoardListModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'message-board/board/create',
                loadChildren:
                        () => import('./message-board/board-editor/board-editor.module').then(m => m.BoardEditorModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'message-board/board/:boardId/edit',
                loadChildren:
                        () => import('./message-board/board-editor/board-editor.module').then(m => m.BoardEditorModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'message-board/board/:boardId/threads',
                loadChildren:
                        () => import('./message-board/thread-list/thread-list.module').then(m => m.ThreadListModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'message-board/board/:boardId/thread/create',
                loadChildren:
                        () => import('./message-board/thread-editor/thread-editor.module').then(m => m.ThreadEditorModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'message-board/board/:boardId/thread/:threadId',
                loadChildren:
                        () => import('./message-board/thread-detail/thread-detail.module').then(m => m.ThreadDetailModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'message-board/board/:boardId/thread/:threadId/edit',
                loadChildren:
                        () => import('./message-board/thread-editor/thread-editor.module').then(m => m.ThreadEditorModule),
            },
            {
                canActivate: [
                    LoginGuard,
                    UnlimitedGuard,
                ],
                path: 'content-copier',
                loadChildren:
                        () => import('./other-tools/content-copier/content-copier.module').then(m => m.ContentCopierModule),
            },
        ],
    },
];

export const appLayoutRouting = RouterModule.forChild(appLayoutRoutes);
