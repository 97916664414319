import { Component } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
    selector: 'app-spinner-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class SpinnerButtonComponent extends ButtonComponent {
    constructor() {
        super();
        this.icon = this.type = 'spinner';
    }
}
