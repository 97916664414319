import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { CommunicationResponse } from './communication-response';
import { Organization } from './organization';
import { User } from './user';
import { PaginationLinks, PaginationMeta } from './pagination';


export const onDemandInvoice = 'On-Demand Invoice';
export const customPdf = 'Custom PDF';
export const billPayCheck = 'Bill Pay Check';
export const violationLetter = 'Violation Letter';

export class CommunicationActivity
{
    id: number;
    organizationId: number;
    senderId: number;
    recipientId: number;
    body: string;
    type: string;
    createdAt: Moment;
    updatedAt: Moment;

    category: string;
    recipient: User;
    sender: User;
    senderName: string;
    recipientName: string;
    recipientAddress: string;
    recipientEmail: string;
    recipientPhone: string;
    subject: string;
    organization: Organization;
    responses: CommunicationResponse[] = [];
    sentType: string;

    // misc bool to show hide lob timeline in UI
    isShowingTimeline ? = false;
    // misc bool to show hide cancellation message in UI
    isCancelling ? = false;
    // if this belongs to a lob item that has been cancelled
    cancelled ? = false;

    constructor(communicationActivity: any) {
        if (communicationActivity.id) {
            this.id = communicationActivity.id;
        }
        if (communicationActivity.organizationId) {
            this.organizationId = communicationActivity.organizationId;
        }
        if (communicationActivity.senderId) {
            this.senderId = communicationActivity.senderId;
        }
        if (communicationActivity.recipientId) {
            this.recipientId = communicationActivity.recipientId;
        }
        this.subject = communicationActivity.subject || '';
        if (communicationActivity.body) {
            this.body = communicationActivity.body;
        }
        if (communicationActivity.type) {
            this.type = communicationActivity.type;
            switch (this.type) {
                case 'email':
                    this.sentType = 'Email';
                    break;
                case 'letter':
                    this.sentType = customPdf;
                    break;
                case 'paper':
                    this.sentType = onDemandInvoice;
                    break;
                case 'violation_letter':
                    this.sentType = violationLetter;
                    break;
            }
        }
        if (communicationActivity.senderName) {
            this.senderName = communicationActivity.senderName;
        }
        if (communicationActivity.recipientName) {
            this.recipientName = communicationActivity.recipientName;
        }

        this.recipientAddress = communicationActivity.recipientAddress
                ? communicationActivity.recipientAddress.replace(', ', '<br />')
                : '';
        this.recipientPhone = communicationActivity.recipientPhone || '';
        this.recipientEmail = communicationActivity.recipientEmail || '';

        if (communicationActivity.category) {
            this.category = communicationActivity.category;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (communicationActivity.createdAt) {
            this.createdAt = moment.utc(communicationActivity.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (communicationActivity.updatedAt) {
            this.updatedAt = moment.utc(communicationActivity.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (communicationActivity.deletedAt) {
            this.cancelled = true;
        }

        if (communicationActivity.recipient) {
            if (communicationActivity.recipientType.indexOf('User') > -1) {
                this.recipient = new User(communicationActivity.recipient);
            }
        }
        if (communicationActivity.sender) {
            this.sender = new User(communicationActivity.sender);
        }
        if (communicationActivity.organization) {
            this.organization = new Organization(communicationActivity.organization);
        }
        if (communicationActivity.responses) {
            this.responses = communicationActivity.responses.map((response) => {
                return new CommunicationResponse(response);
            });
            this.responses.sort((a: CommunicationResponse, b: CommunicationResponse) => {
                if (a.createdAt.isSame(b.createdAt)) {
                    if (a.event === 'processed' && b.event === 'delivered') {
                        return 1;
                    }
                    if (b.event === 'processed' && a.event === 'delivered') {
                        return -1;
                    }
                }
                return a.createdAt.isBefore(b.createdAt) ? 1 : -1;
            });
        }
    }
}

export class CommunicationLogResponse
{
    data: CommunicationActivity[];
    meta: PaginationMeta;

    constructor(request: any) {
        this.data = (request.data && request.data.length) ? request.data.map(tx => {
            return new CommunicationActivity(tx);
        }) : [];
        this.meta = new PaginationMeta(request.meta);
    }
}
