import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { AccountCredit } from './account-credit';
import { AuxiliaryEmail } from './auxiliary-email';
import { Charge } from './charge';
import { CustomFieldValue } from './custom-field-value';
import { Group } from './group';
import { MembershipProfile } from './membership-profile';
import { Organization } from './organization';
import { OrganizationFile } from './organization-file';
import { PaymentGroup } from './payment-group';
import { Permission } from './permission';
import { User } from './user';
import { Note } from './note';
import { ScheduledPhoneCall } from './scheduled-phone-call';
import { SurveyResponse } from './survey-response';
import { JwtPermissionClaim } from '../../services/auth/jwt-permission-claim.model';
import { Unit } from './unit';
import { Tag } from './tag';
import { Owner } from './owner';
import { MessageBoardSubscription } from './message-board-subscription';
import { LobBankAccount } from './lob-bank-account';

export class Membership
{
    id: number;
    userId: number;
    organizationId: number;
    balance: number;
    isAdmin: boolean;
    locked: boolean = false;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment = null;

    autopaidChargeSum: number;
    lastPayment: PaymentGroup;
    memberType: Group;
    overdueBalance: number;
    accountNumber: string;

    organization: Organization;
    profile: MembershipProfile;
    user: User;
    accountCredits: AccountCredit[];
    auxiliaryEmails: AuxiliaryEmail[];
    charges: Charge[];
    customFieldValues: CustomFieldValue[];
    documents: Document[];
    groups: Group[];
    notes: Note[];
    paymentGroups: PaymentGroup[];
    permissions: Permission[];
    scheduledPhoneCalls: ScheduledPhoneCall[];
    surveyResponses: SurveyResponse[];
    tags: Tag[] = [];
    owners: Owner[];
    messageBoardSubscriptions: MessageBoardSubscription[];
    lobBankAccounts: LobBankAccount[];

    claims: JwtPermissionClaim[];

    // derived properties
    name: string = '';
    address: string = '';

    checked?: boolean;
    hidden?: boolean;

    unitsString: string = '';

    constructor(membership: any) {
        if (membership.id) {
            this.id = membership.id;
        }
        if (membership.userId) {
            this.userId = membership.userId;
        }
        if (membership.organizationId) {
            this.organizationId = membership.organizationId;
        }
        if (membership.balance) {
            this.balance = membership.balance;
        }
        if (membership.isAdmin) {
            this.isAdmin = membership.isAdmin;
        }
        if (membership.name) {
            this.name = membership.name;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (membership.createdAt) {
            this.createdAt = moment.utc(membership.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (membership.updatedAt) {
            this.updatedAt = moment.utc(membership.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (membership.deletedAt) {
            this.deletedAt = moment.utc(membership.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (membership.hasOwnProperty('autopaidChargeSum')) {
            this.autopaidChargeSum = membership.autopaidChargeSum;
        }
        if (membership.lastPayment) {
            this.lastPayment = new PaymentGroup(membership.lastPayment);
        }
        if (membership.memberType) {
            this.memberType = new Group(membership.memberType);
        }
        if (membership.overdueBalance) {
            this.overdueBalance = membership.overdueBalance;
        }
        if (membership.accountNumber) {
            this.accountNumber = membership.accountNumber;
        }

        this.locked = !!membership.locked;

        if (membership.organization) {
            this.organization = new Organization(membership.organization);
        }
        if (membership.owners) {
            this.owners = membership.owners.map((o) => {
                return new Owner(o);
            });

            this.unitsString = this.owners.map(function(o: Owner) {
                if (o.unit) {
                    return o.unit.name;
                }
            }).join(', ');
        }
        if (membership.profile) {
            this.profile = new MembershipProfile(membership.profile);
            if (this.profile.givenNames || this.profile.familyName) {
                const given = this.profile.givenNames ? this.profile.givenNames : '';
                const family = this.profile.familyName ? this.profile.familyName : '';
                this.name = given + ' ' + family;
            }
        }
        if (membership.user) {
            this.user = new User(membership.user);
        }
        if (membership.accountCredits) {
            this.accountCredits = membership.accountCredits.map((accountCredit) => {
                return new AccountCredit(accountCredit);
            });
        }
        if (membership.auxiliaryEmails) {
            this.auxiliaryEmails = membership.auxiliaryEmails.map((auxiliaryEmail) => {
                return new AuxiliaryEmail(auxiliaryEmail);
            });
        }
        if (membership.charges) {
            this.charges = membership.charges.map((charge) => {
                return new Charge(charge);
            });
        }
        if (membership.customFieldValues) {
            this.customFieldValues = membership.customFieldValues.map((customFieldValue) => {
                return new CustomFieldValue(customFieldValue);
            });
        }
        if (membership.documents) {
            this.documents = membership.documents.map((document) => {
                return new OrganizationFile(document);
            });
        }
        if (membership.groups) {
            this.groups = membership.groups.map((group) => {
                return new Group(group);
            });
        }
        if (membership.notes) {
            this.notes = membership.notes.map((note) => {
                return new Note(note);
            });
        }
        if (membership.paymentGroups) {
            this.paymentGroups = membership.paymentGroups.map((paymentGroup) => {
                return new PaymentGroup(paymentGroup);
            });
        }
        if (membership.permissions) {
            this.permissions = membership.permissions.map((permission) => {
                return new Permission(permission);
            });
        }
        if (membership.scheduledPhoneCalls) {
            this.scheduledPhoneCalls = membership.scheduledPhoneCalls.map((phoneCall) => {
                return new ScheduledPhoneCall(phoneCall);
            });
        }
        if (membership.surveyResponses) {
            this.surveyResponses = membership.surveyResponses.map((surveyResponse) => {
                return new SurveyResponse(surveyResponse);
            });
        }
        if (membership.claims) {
            this.claims = membership.claims.map((claim) => {
                return new JwtPermissionClaim(claim);
            });
        }
        if (membership.tags) {
            this.tags = membership.tags.map((tag) => {
                return new Tag(tag);
            });
        }
        if (membership.lobBankAccounts) {
            this.lobBankAccounts = membership.lobBankAccounts.map((account) => {
                return new LobBankAccount(account);
            });
        }

        // TODO ....
        this.messageBoardSubscriptions = []; //  SampleBoardSubs.concat(SampleThreadSubs);
    }
}

export class MembershipTableItem
{
    id: number;
    userId: number;
    name = '';
    email = '';
    status = '';
    permission = '';
    lastLogin = '';
    units: Unit[];
    unitsString = '';
    user: User;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment = null;
    locked = false;
    isAdmin = false;
    isSuper = false;
    organizationId: number;
    tags: Tag[] = [];
    tagList = '';
    membership: Membership;

    checked = false;

    constructor(membership: any) {
        this.membership = new Membership(membership);
        if (membership.id) {
            this.id = membership.id;
        }

        if (membership.userId) {
            this.userId = membership.userId;
        }

        if (membership.profile) {
            this.name = membership.profile.givenNames + ' ' + membership.profile.familyName;
        }

        if (membership.email) {
            this.email = membership.email;
        }

        if (membership.owners) {
            this.status = membership.owners.length ? 'Owner' : 'Other';
        }

        this.permission = 'User';
        if (membership.hasPermissions) {
            this.permission = 'User (Custom)';
        }
        if (membership.isAdmin) {
            this.permission = 'Admin';
            this.isAdmin = true;
        }
        if (membership.locked) {
            this.permission = 'Locked (' + (membership.lockedReason ? membership.lockedReason : 'N/A') + ')';
            this.locked = true;
        }

        if (membership.lastLogin) {
            this.lastLogin = membership.lastLogin;
        } else {
            this.lastLogin = null;
        }

        if (membership.owners) {
            const units = [];
            for (const o of membership.owners) {
                if (o.unit) {
                    units.push(o.unit);
                }
            }

            this.units = units;
            this.unitsString = this.units.map(unit => unit.title).join(' ');
        }

        if (membership.createdAt) {
            this.createdAt = membership.createdAt;
        }

        if (membership.updatedAt) {
            this.updatedAt = membership.updatedAt;
        } else {
            this.updatedAt = null;
        }

        if (membership.deletedAt) {
            this.deletedAt = membership.deletedAt;
        } else {
            this.deletedAt = null;
        }

        if (membership.user) {
            this.user = membership.user;
        }

        if (membership.tags) {
            this.tags = membership.tags.map(tag => {
                return new Tag(tag);
            });
        }
    }
}
