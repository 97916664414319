import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { NotificationType } from './notification-type';
import { User } from './user';

export class NotificationPreference
{
    id: number;
    userId: number;
    notificationTypeId: number;
    email: boolean;
    sms: boolean;
    createdAt: Moment;
    updatedAt: Moment;

    user: User;
    notificationType: NotificationType;

    constructor(notificationPreference: any) {
        if (notificationPreference.id) {
            this.id = notificationPreference.id;
        }
        if (notificationPreference.userId) {
            this.userId = notificationPreference.userId;
        }
        if (notificationPreference.notificationTypeId) {
            this.notificationTypeId = notificationPreference.notificationTypeId;
        }
        if (notificationPreference.email) {
            this.email = notificationPreference.email;
        }
        if (notificationPreference.sms) {
            this.sms = notificationPreference.sms;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (notificationPreference.createdAt) {
            this.createdAt = moment.utc(notificationPreference.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (notificationPreference.updatedAt) {
            this.updatedAt = moment.utc(notificationPreference.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (notificationPreference.user) {
            this.user = new User(notificationPreference.user);
        }
        if (notificationPreference.notificationType) {
            this.notificationType = new NotificationType(notificationPreference.notificationType);
        }
    }
}
