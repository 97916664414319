import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from 'app/components/shared/http/application-http-client';
import { Routes } from 'app/config/routes';
import { UploadedFile } from 'app/models/entities/uploaded-file';
import { Observable } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class UploadedFilesService
{
    private _plainClient: HttpClient;

    constructor(
            private _http: ApplicationHttpClient,
            _handler: HttpBackend,
    ) {
        this._plainClient = new HttpClient(_handler);
    }

    uploadFileBase64(file: string, linked_with_type: string = null,
                     linked_with_id: number = null,
    ): Observable<UploadedFile> {
        const url = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.UploadFile(),
        );
        const data = {
            file_base64: file,
        };
        if (linked_with_type !== null) {
            data['linked_with_type'] = linked_with_type;
        }
        if (linked_with_id !== null) {
            data['linked_with_id'] = linked_with_id;
        }
        return this._http.post(url, JSON.stringify(data)).pipe(map(response => {
            return new UploadedFile(response);
        }));
    }

    unlink(file: UploadedFile, from_type, from_id): Observable<Object> {
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.SeverFileLink(file.id, from_type, from_id),
        );
        return this._http.delete(url);
    }

    unlinkMany(files: UploadedFile[], from_type, from_id): Observable<Object> {
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.SeverFileLinks(files.map(f => f.id), from_type, from_id),
        );
        return this._http.delete(url);
    }
}
