import { takeUntil } from 'rxjs/operators';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ElasticSearchService } from '../../../../services/search/elastic-search.service';
import { Router } from '@angular/router';
import { GrowlerService } from '../../../../services/growler.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { Unit } from '../../../../models/entities/unit';
import { Membership } from '../../../../models/entities/membership';
import { MaterialSearchComponent } from '../../material-search/material-search.component';
import { HostListenerService } from '../../../../services/host-listener.service';

export type DropdownSearchType = 'units' | 'members';

export interface DropdownSearchItem
{
    index: DropdownSearchType;
    model: Unit | Membership;
}

export interface DropdownSearchEvent
{
    type: DropdownSearchType;
    orgId: number;
    redirect?: string;
}

@UntilDestroy()
@Component({
    selector: 'lf-dropdown-search',
    styleUrls: ['./dropdown-search.component.scss'],
    templateUrl: './dropdown-search.component.html',
})
export class DropdownSearchComponent implements OnInit
{
    @Output() dropdownSearchRedirect: EventEmitter<DropdownSearchEvent> = new EventEmitter<DropdownSearchEvent>();
    @ViewChild(MaterialSearchComponent) matSearch: MaterialSearchComponent;

    searchString = '';
    searchResults: DropdownSearchItem[] = [];

    isLoading = false;
    isSearching = false;
    isOnSearchPage = false;
    matMenuOpened = false;

    private cancelPrevious: Subject<void> = new Subject();

    constructor(
            private _searchService: ElasticSearchService,
            private _router: Router,
            private _growler: GrowlerService,
            private _host: HostListenerService,
            private _elementRef: ElementRef,
    ) {
    }

    ngOnInit() {
        // if we are on the search page, we don't want to open the dropdown
        this.isOnSearchPage = this._router.url.indexOf('/app/search') > -1;
        this._router.events.subscribe({
            next: (event) => {
                this.isOnSearchPage = this._router.url.indexOf('/app/search') > -1;
            },
        });

        this._host.onMouseClick().pipe(untilDestroyed(this)).subscribe({
            next: (event) => {
                if (this.matMenuOpened && !this._elementRef.nativeElement.contains(event.target)) {
                    this.matMenuOpened = false;
                }
            },
        });
    }

    /**
     * start a new search
     */
    search(term = '') {
        this.matMenuOpened = true;

        if (term === this.searchString) {
            return;
        } else if (term === '') {
            this.isLoading = this.isSearching = false;
            this.searchString = '';

            this.matMenuOpened = false;
            return;
        }

        this.searchString = term;
        this.cancelPrevious.next();

        this.isLoading = true;
        this._searchService.search(term, this.isOnSearchPage ? 25 : 3).pipe(
                takeUntil(this.cancelPrevious),
                untilDestroyed(this),
        ).subscribe({
            next: (hits) => {
                this.isLoading = this.isSearching = false;
                this.searchResults = hits.map((hit) => {
                    let model: Unit | Membership;
                    switch (hit.index) {
                        case 'units':
                            model = new Unit(hit);
                            break;
                        case 'members':
                            model = new Membership(hit);
                            break;
                    }
                    return {
                        index: hit.index,
                        model,
                    };
                });
            },
            error: () => this._growler.error('Error', 'There was a problem loading search results.'),
        });
    }

    openIfResults() {
        if (this.searchString !== '') {
            this.matMenuOpened = true;
        }
    }

    /**
     * prevent from closing dropdown
     */
    stopClickFromClosing(event: Event) {
        event.stopPropagation();
        event.preventDefault();
    }

    /**
     * Handle click event of dropdown selection.
     * @param item: DropdownSearchItem
     */
    requestRedirect(item: DropdownSearchItem) {
        let redirect = '';
        switch (item.index) {
            case 'units':
                redirect = `/app/unit/detail/${item.model.id}`;
                break;
            case 'members':
                redirect = `/app/members/${item.model.id}`;
                break;
        }

        this.dropdownSearchRedirect.emit({
            type: item.index,
            orgId: item.model.organizationId,
            redirect,
        });
    }
}
