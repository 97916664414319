import { NgModule } from '@angular/core';
import { CommonModule, I18nPluralPipe, NgOptimizedImage } from '@angular/common';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SharedDirectivesModule } from '../../shared/directives/shared-directives.module';
import { NotificationsListComponent } from './notifications-list.component';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLineModule } from '@angular/material/core';
import { JumbotronModule } from '../../../shared/jumbotron/jumbotron.module';
import { CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AvatarBlockModule } from '../../shared/avatar-block/avatar-block.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { LegfiDatePipeModule } from '../../../../pipes/format/legfi-date.module';

@NgModule({
    declarations: [
        NotificationsListComponent,
    ], imports: [
        CommonModule,
        MatLegacyListModule,
        MatLineModule,
        JumbotronModule,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        NgOptimizedImage,
        SharedDirectivesModule,
        AvatarBlockModule,
        MatIconModule,
        MatLegacyButtonModule,
        LegfiDatePipeModule,
        MatLegacyMenuModule,
        MatToolbarModule,
    ], exports: [
        NotificationsListComponent,
    ], providers: [
        I18nPluralPipe,
    ],
})
export class NotificationsListModule
{
}
