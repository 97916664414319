import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PageMenuComponent } from './page-menu.component';
import { SharedModule } from '../../../shared/shared.module';
import { ButtonModule } from '../../../shared/button/button.module';

@NgModule({
    imports: [
        SharedModule,
        MatIconModule,
        ButtonModule,
    ],
    exports: [PageMenuComponent],
    declarations: [PageMenuComponent],
    providers: [],
})
export class PageMenuModule
{
}
