import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { filter, Observable } from 'rxjs';
import { PropertyManagementCompanyService } from '../../services/property-management-company/property-management-company.service';
import { PropertyManagementCompany } from '../../models/entities/property-management-company';
import { SvgIconService } from '../../services/svg-icon.service';
import { PropertyManagementCompanyDataService } from '../../services/property-management-company/property-manager-company-data.service';
import { SidenavContentService } from './sidenav/sidenav-content/sidenav-content.service';
import { HostListenerService } from '../../services/host-listener.service';

@UntilDestroy()
@Component({
    selector: 'app-pmc-layout',
    styleUrls: ['./pmc-layout.component.scss'],
    templateUrl: './pmc-layout.component.html',
})
export class PmcLayoutComponent implements OnInit
{
    pmc$: Observable<PropertyManagementCompany>;

    @ViewChild('leftSidenav') leftSidenavRef!: MatSidenav;

    readonly appLogoSrc = '/assets/images/v2/payhoa/logo.svg';

    constructor(
            private _propertyManagementCompanyService: PropertyManagementCompanyService,
            private _propertyManagementCompanyData: PropertyManagementCompanyDataService,
            private _activatedRoute: ActivatedRoute,
            private _host: HostListenerService,
            private _svg: SvgIconService,
            private _sidenav: SidenavContentService,
    ) {
        // For now, use v2 svg icons for pmc dashboard
        this._svg.registerV2SvgIcons();
    }

    @ViewChild('rightSidenav', { static: true }) set rightSidenavRef(content: MatSidenav) {
        if (content) {
            this._sidenav._sidenav = content;
        }
    }

    get mobileMode$() {
        return this._host.isMobileMode();
    }

    ngOnInit() {
        this.pmc$ = this._propertyManagementCompanyData.pmc$;
        this._propertyManagementCompanyData.clear();

        this._activatedRoute.paramMap.pipe(
                map((params) => +params.get('pmcId')),
                filter((pmcId) => !!pmcId),
                switchMap((pmcId) => {
                    this._propertyManagementCompanyData.pmcId = pmcId;
                    return this._propertyManagementCompanyService.getPropertyManagementCompany(pmcId);
                }),
                untilDestroyed(this),
        ).subscribe({
            next: (pmc) => this._propertyManagementCompanyData.switch(pmc),
        });
    }

    // if the page is in mobile view, they clicked to change routes; toggle the sidenav
    handleSidenavClickOnMobile(): void {
        if (this.leftSidenavRef.mode === 'over') {
            this.leftSidenavRef.close();
        }
    }
}
