import { FixedAsset } from '../../accounting/fixed-asset';
import { Liability } from '../../accounting/liability';

export class Transfer
{
    id: number;
    transactionId: number;
    sourceId: number;
    sourceType: string;
    destinationId: number;
    destinationType: string;

    source?: FixedAsset | Liability = null;
    destination?: FixedAsset | Liability = null;

    constructor(transfer: any) {
        this.id = transfer.id || null;
        this.transactionId = transfer.transactionId || null;
        this.sourceId = transfer.sourceId || null;
        this.sourceType = transfer.sourceType || null;
        this.destinationId = transfer.destinationId || null;
        this.destinationType = transfer.destinationType || null;

        if (transfer.source) {
            if (transfer.sourceType.includes('Asset')) {
                this.source = new FixedAsset(transfer.source);
            } else if (transfer.sourceType.includes('Liability')) {
                this.source = new Liability(transfer.source);
            } else {
                this.source = transfer.source;
            }
        }

        if (transfer.destination) {
            if (transfer.destinationType.includes('Asset')) {
                this.destination = new FixedAsset(transfer.destination);
            } else if (transfer.destinationType.includes('Liability')) {
                this.destination = new Liability(transfer.destination);
            } else {
                this.destination = transfer.destination;
            }
        }
    }
}
