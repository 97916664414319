<form class="pmc-layout-search">
    <mat-form-field appearance="outline">
        <mat-icon [svgIcon]="'v2:fi-br-search'" matPrefix></mat-icon>
        <input type="text"
               aria-label="Search"
               matInput
               matAutocompleteDisabled
               [placeholder]="'Search....'"
               [formControl]="control"
               [matAutocomplete]="auto">
        <mat-spinner *ngIf="isSearching" [diameter]="15" matSuffix></mat-spinner>
    </mat-form-field>
    <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete">
        <mat-option *ngIf="results.length > 0">{{ isSearching ? '...' : 'Show all results' }}</mat-option>
        <mat-divider *ngIf="filteredResults"></mat-divider>
        <ng-template [ngForOf]="filteredResults" let-result ngFor>
            <mat-option [class.hidden]="isSearching" [value]="getValue(result)" (click)="isSearching = true; resultSelected.emit(control.value)">
                <ng-container [ngSwitch]="result.index">
                    <ng-template [ngSwitchCase]="'units'">
                        {{ result.model.title }}
                    </ng-template>
                    <ng-template [ngSwitchCase]="'members'">
                        {{ result.model.profile.name }}
                    </ng-template>
                </ng-container>
            </mat-option>
        </ng-template>
    </mat-autocomplete>
</form>
