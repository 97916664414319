import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'formly-field-textarea',
    template: `<app-rich-text-editor type="formly"
                                     [formControl]="control"
                                     [class.is-invalid]="showError"
                                     [formlyAttributes]="field"></app-rich-text-editor>`,
})
export class FormlyFieldTextareaComponent extends FieldType
{
    get control () {
        return this.formControl as UntypedFormControl;
    }

    get type() {
        return this.to.type || 'textarea';
    }
}
