<mat-sidenav-container class="pmc-layout-container" [class.mobile-layout]="mobileMode$ | async">
    <mat-sidenav #leftSidenav position="start" [mode]="(mobileMode$ | async) ? 'over' : 'side'" [opened]="!(mobileMode$ | async)">
        <div class="app-logo">
            <a [routerLink]="['/pmc']">
                <img class="img" alt="{{ 'Home' }}" [attr.src]="appLogoSrc"/>
            </a>
        </div>

        <app-sidenav-navigation></app-sidenav-navigation>
    </mat-sidenav>

    <mat-toolbar class="app-header">
        <app-header-search></app-header-search>
        <span class="mat-toolbar-spacer"></span>
        <app-header-menu></app-header-menu>
    </mat-toolbar>

    <div class="app-content">
        <router-outlet></router-outlet>
    </div>

    <mat-sidenav #rightSidenav position="end" disableClose autoFocus="false">
        <app-sidenav-content></app-sidenav-content>
    </mat-sidenav>
</mat-sidenav-container>
