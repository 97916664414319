<h3 mat-dialog-title>{{ 'Your Session is About to Expire' }}</h3>

<mat-dialog-content class="small-content">
    <p>{{ 'You are being logged out due to inactivity. Do you want to stay signed in? '}}</p>
    <p *ngIf="data.countdown$ | async as count" class="font-weight-medium">
        {{ 'Time remaining: ' + count + ' second' + (count === 1 ? '' : 's') }}
    </p>
</mat-dialog-content>

<mat-dialog-actions>
    <app-button mat-dialog-close>{{ 'Sign Out Now' }}</app-button>
    <app-button type="primary" [buttonClasses]="['mr-0']" (onClick)="closeAndConfirm()">{{ 'Stay Signed In' }}</app-button>
</mat-dialog-actions>
