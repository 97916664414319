import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { PaymentAttempt } from './payment-attempt';
import Moment = moment.Moment;

export class PaymentAttemptFee
{
    id: number;
    paymentAttemptId: number;
    rateType: string;
    amount: string;
    absorbed: boolean;
    createdAt: Moment;
    updatedAt: Moment;

    paymentAttempt: PaymentAttempt;

    constructor(paymentAttemptFee: any) {
        if (paymentAttemptFee.id) {
            this.id = paymentAttemptFee.id;
        }
        if (paymentAttemptFee.paymentAttemptId) {
            this.paymentAttemptId = paymentAttemptFee.paymentAttemptId;
        }
        if (paymentAttemptFee.rateType) {
            this.rateType = paymentAttemptFee.rateType;
        }
        if (paymentAttemptFee.amount) {
            this.amount = paymentAttemptFee.amount;
        }
        if (paymentAttemptFee.absorbed) {
            this.absorbed = paymentAttemptFee.absorbed;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (paymentAttemptFee.createdAt) {
            this.createdAt = moment.utc(paymentAttemptFee.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (paymentAttemptFee.updatedAt) {
            this.updatedAt = moment.utc(paymentAttemptFee.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (paymentAttemptFee.paymentAttempt) {
            this.paymentAttempt = new PaymentAttempt(paymentAttemptFee.paymentAttempt);
        }
    }
}
