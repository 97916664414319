import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import StyledAlert from '../../components/shared/alerts';
import { AuthService } from '../../services/auth/auth.service';
import { from } from 'rxjs';
import { GrowlerService } from '../../services/growler.service';

@Injectable()
export class UnlimitedGuard implements CanActivate
{
    constructor(
            private _authService: AuthService,
            private _growler: GrowlerService,
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = LegFiJwtService.read();

        // If the user is using a limited token, prompt them for a password
        // and proceed only if it results in a valid login
        if (token.limited) {
            return from(StyledAlert.fire({
                title: 'Login Required',
                html: 'Please provide your password to continue.',
                icon: 'question',
                input: 'password',
                showCancelButton: true,
                confirmButtonText: 'Login',
                showLoaderOnConfirm: true,
                preConfirm: (value) => {
                    return new Promise((resolve) => {
                        this._authService
                                .upgradeLimitedToken({password: value})
                                .subscribe(
                                        () => {
                                            this._growler.success(
                                                    'Success',
                                                    'Your login has been upgraded.',
                                            );
                                            resolve(true);
                                        },
                                        () => {
                                            this._growler.error(
                                                    'Error',
                                                    'There was a problem upgrading your login.',
                                            );
                                            resolve(false);
                                        },
                                );
                    });
                },
            }).then((result) => {
                if (result.value) {
                    return true;
                }
            }));
        }

        return true;
    }
}
