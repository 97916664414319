import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { CustomFieldValue } from './custom-field-value';
import { Organization } from './organization';

export class CustomField
{
    id: number;
    organizationId: string;
    name: string;
    createdAt: Moment;
    updatedAt: Moment;

    organization: Organization;
    customFieldValues: CustomFieldValue[];

    constructor(customField: any) {
        if (customField.id) {
            this.id = customField.id;
        }
        if (customField.organizationId) {
            this.organizationId = customField.organizationId;
        }
        if (customField.name) {
            this.name = customField.name;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (customField.createdAt) {
            this.createdAt = moment.utc(customField.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (customField.updatedAt) {
            this.updatedAt = moment.utc(customField.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (customField.organization) {
            this.organization = new Organization(customField.organization);
        }
        if (customField.customFieldValues) {
            this.customFieldValues = customField.customFieldValues.map(
                    (customFieldValue) => {
                        return new CustomFieldValue(customFieldValue);
                    },
            );
        }
    }
}
