import { Membership } from '../membership';
import moment from 'moment-timezone';
import { FormSubmissionApproval } from './form-submission-approval';
import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';

/** A form can have one or more approvers */
export class FormApprover
{
    id: number;
    formId: number;
    membershipId: number;
    membership: Membership;

    approvals: FormSubmissionApproval[];
    approved: number;

    createdAt: moment.Moment;
    updatedAt: moment.Moment;

    constructor(request: any) {
        this.id = request.id || 0;
        this.formId = request.formId || 0;
        this.membershipId = request.membershipId || 0;

        if (request.membership) {
            this.membership = new Membership(request.membership);
        }

        if (request.approvals) {
            this.approvals = request.approvals.map((approval: any) => new FormSubmissionApproval(approval));
        }
        this.approved = this.approvals ? this.approvals.length : 0;

        const timezone = LegFiJwtService.getTimezone();

        this.createdAt = moment.utc(request.createdAt).tz(timezone);
        this.updatedAt = moment.utc(request.updatedAt).tz(timezone);
    }
}
