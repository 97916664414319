import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Routes } from '../../config/routes';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { Survey } from '../../models/entities/survey';
import { SurveyResponse } from '../../models/entities/survey-response';

@Injectable({
    providedIn: 'root',
})
export class SurveysService
{
    private _http: ApplicationHttpClient;

    constructor(_http: ApplicationHttpClient) {
        this._http = _http;
    }

    /**
     * Get surveys for the specified organization.
     * @param {number} organizationId
     * @returns {Observable<Survey[]>}
     */
    public getSurveys(organizationId: number): Observable<Survey[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrgSurveys(organizationId || jwt.orgId),
        );

        return this._http.get(url).pipe(map(
                (response: Object[]) => {
                    return response.map(
                            (survey) => {
                                return new Survey(survey);
                            },
                    );
                },
        ));
    }

    /**
     * Get survey for the specified organization and surveyId.
     * @param {number} organizationId
     * @param {number} surveyId
     * @returns {Observable<Survey>}
     */
    public getSurvey(
            organizationId: number,
            surveyId: number,
    ): Observable<{ survey: Survey, alreadyResponded: boolean }> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrgSurvey(organizationId || jwt.orgId, surveyId),
        );

        return this._http.get(url).pipe(map(
                (response: any) => {
                    const res = response;
                    return {survey: new Survey(res.survey), alreadyResponded: res.alreadyResponded};
                },
        ));
    }

    /**
     * Get survey for the specified organization and surveyId.
     * @param {number} organizationId
     * @param {number} surveyId
     * @returns {Observable<Survey>}
     */
    public getSurveyAdminResults(organizationId: number, surveyId: number): Observable<Survey> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrgSurveyResults(organizationId || jwt.orgId, surveyId),
        );

        return this._http.get(url).pipe(map(
                (response: Object) => {
                    return new Survey(response);
                },
        ));
    }

    /**
     * Create survey for the specified organization.
     * @param {number} organizationId
     * @param {any} body
     * @returns {Observable<Survey>}
     */
    public createSurvey(organizationId: number, body: any): Observable<Survey> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrgSurveys(organizationId || jwt.orgId),
        );

        return this._http.post(url, JSON.stringify(body)).pipe(map(
                (response: Object) => {
                    const res = response;
                    return new Survey(res);
                },
        ));
    }

    /**
     * Update survey for the specified organization.
     * @param {number} organizationId
     * @param {number} surveyId
     * @param {any} body
     * @returns {Observable<Survey>}
     */
    public updateSurvey(organizationId: number, surveyId: number, body: any): Observable<Survey> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrgSurvey(organizationId || jwt.orgId, surveyId),
        );

        return this._http.patch(url, JSON.stringify(body)).pipe(map(
                (response: Object) => {
                    const res = response;
                    return new Survey(res);
                },
        ));
    }

    /**
     * Delete survey for the specified organization.
     * @param {number} organizationId
     * @param {number} surveyId
     * @returns {any}
     */
    public copySurvey(organizationId: number, surveyId: number): Observable<Survey> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrgCopySurvey(organizationId || jwt.orgId, surveyId),
        );

        return this._http.post(url, '').pipe(map(response => {
            return new Survey(response);
        }));
    }

    /**
     * Delete survey for the specified organization.
     * @param {number} organizationId
     * @param {number} surveyId
     * @returns {any}
     */
    public deleteSurvey(organizationId: number, surveyId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrgSurvey(organizationId || jwt.orgId, surveyId),
        );

        return this._http.delete(url);
    }

    /**
     * Close a survey for the specified organization.
     * @param {number} organizationId
     * @param {number} surveyId
     * @returns {any}
     */
    public closeSurvey(organizationId: number, surveyId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.CloseOrgSurvey(organizationId || jwt.orgId, surveyId),
        );

        return this._http.post(url, '');
    }

    /**
     * Re-opens a survey for the specified organization.
     * @param {number} organizationId
     * @param {number} surveyId
     * @returns {any}
     */
    public reopenSurvey(organizationId: number, surveyId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.ReopenOrgSurvey(organizationId || jwt.orgId, surveyId),
        );

        return this._http.post(url, '');
    }

    /**
     * Send a specific survey to selected members
     * @param {number} organizationId
     * @param {number} surveyId
     * @param {any} body array of member id's
     * @returns {Observable<Object>}
     */
    public shareSurvey(organizationId: number, surveyId: number, body: any): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.OrgSurveyShare(organizationId || jwt.orgId, surveyId),
                ), JSON.stringify(body),
        );
    }

    /**
     * @param {number} orgId
     * @param {number} surveyId
     * @param {any[]} answers
     * @returns {Observable<SurveyResponse>}
     */
    public createSurveyResponse(orgId: number, surveyId: number, answers: any[]): Observable<SurveyResponse> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const surveyAnswers: {
            responseText: string,
            surveyQuestionChoiceId: number,
            surveyQuestionId: number
        }[] = [];
        for (const answer of answers) {
            surveyAnswers.push({
                responseText: answer.responseText,
                surveyQuestionChoiceId: answer.surveyQuestionChoiceId,
                surveyQuestionId: answer.surveyQuestionId,
            });
        }

        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.OrgSurveyResponse(orgId || jwt.orgId, surveyId),
                ),
                JSON.stringify({answers: surveyAnswers}),
        ).pipe(map(
                (response: Object) => {
                    return new SurveyResponse(response);
                },
        ));
    }
}
