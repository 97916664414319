import { Component, OnInit } from '@angular/core';
import { OrganizationService } from '../../../../services/organization/organization.service';
import { LegFiJwtService } from '../../../../services/auth/legfi-jwt.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Organization } from '../../../../models/entities/organization';
import { Subscription } from '../../../../models/entities/subscription';
import { SubscriptionProduct } from '../../../../models/entities/subscription-product';
import { JwtLegFiClaims } from '../../../../services/auth/jwt-legfi-claims.model';
import { Form1099Service } from '../../../../services/billing/form1099.service';
import { Form1099 } from '../../../../models/entities/form1099';

@UntilDestroy()
@Component({
    selector: 'app-reminder-1099-bar',
    templateUrl: './reminder-1099.component.html',
    styleUrls: ['./reminder-1099.component.scss'],
})
export class Reminder1099Component implements OnInit
{
    isOnTrial = false;
    hasFiled1099s = false;
    isShowing = false;
    pastDeadline = false;
    currentYear = new Date().getFullYear();

    constructor(
            private _organizationService: OrganizationService,
            private _1099Service: Form1099Service,
    ) {
    }

    ngOnInit() {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        this.pastDeadline = new Date().getTime() > new Date(`${this.currentYear}-01-31`).getTime();
        if ((jwt.admin || jwt.superUser) && jwt.orgId !== 3582) {
            this.orgHasSubscription(jwt.orgId);
            setTimeout(() => {
                this.orgHasFiled1099s();
            }, 1000);
        }
    }

    close() {
        this.isShowing = false;
    }

    protected orgHasSubscription(orgId: number) {
        this._organizationService.getOrganizationById(orgId, [
            'profile.paymentMethod',
            'subscriptions',
            'subscriptions.subscriptionProducts',
        ]).pipe(untilDestroyed(this)).subscribe({
            next: (org: Organization) => {
                if (!org.subscriptions.length) {
                    return;
                }
                const subs = org.subscriptions.filter((sub: Subscription) => {
                    const prod = sub.subscriptionProducts.filter((product: SubscriptionProduct) => product.productId === 4);
                    if (prod) {
                        return sub.lastBilling === null;
                    }
                    return false;
                });
                this.isOnTrial = subs.length > 0;
            }
        });
    }

    protected orgHasFiled1099s() {
        this._1099Service.getForm1099s().pipe(untilDestroyed(this)).subscribe({
            next: (form1099s: Form1099[]) => {
                this.hasFiled1099s = form1099s.filter((form1099: Form1099) => form1099.transmittedAt !== null).length > 0;
                // add/remove !this.pastDeadline to toggle visibility
                this.isShowing = !this.hasFiled1099s && !this.isOnTrial && !this.pastDeadline;
            }
        });
    }
}
