import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegfiDatePipe } from './legfi-date.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [LegfiDatePipe],
    exports: [LegfiDatePipe],
})
export class LegfiDatePipeModule
{
}
