import { Component, Input, OnInit } from '@angular/core';
import { Audit } from 'app/models/entities/audit';

@Component({
    selector: 'app-charge-audit-reporter',
    templateUrl: './charge-audit-reporter.component.html',
    styleUrls: ['./charge-audit-reporter.component.scss'],
})
export class ChargeAuditReporterComponent implements OnInit
{
    @Input() audit: Audit;
    
    constructor() {
    }

    ngOnInit(): void {
    }

}
