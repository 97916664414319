import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ControlMessagesModule } from './control-messages/control-messages.module';
import { SuperStarModule } from '../app-layout/shared/super-star/super-star.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { LoadingOverlayModule } from '../app-layout/shared/loading-overlay/loading-overlay.module';

// TODO fix AoT Compiler Error (https://github.com/angular/angular/issues/11262)


@NgModule({
    imports: [
        // Angular stuff
        CommonModule,
    ],
    exports: [
        // Angular stuff.
        CommonModule,
        FormsModule,
        LoadingOverlayModule,
        MatDialogModule,
        ReactiveFormsModule,
        RouterModule,
        // Components
        ControlMessagesModule,
        SuperStarModule,
        MatTooltipModule,
    ],
})
export class SharedModule
{
}
