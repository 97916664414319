export enum AppNavigationKeys
{
    LAUNCHPAD,
    ORG_DASHBOARD,
    MEMBER_DASHBOARD,
    UNIT_LIST,
    PEOPLE_LIST,
    INVOICES,
    PAYMENTS,
    VENDORS,
    TRANSACTIONS,
    BUDGETS,
    REPORTS,
    BROADCAST,
    MAIL_ROOM,
    OTHER,
    SURVEYS,
    MESSAGE_BOARD,
    CALENDAR,
    ORG_REQUESTS,
    MEMBER_REQUESTS,
    ORG_VIOLATIONS,
    MEMBER_VIOLATIONS,
    ORG_DOCUMENTS,
    MEMBER_DOCUMENTS,
    ORG_SETTINGS,
    MAKE_PAYMENT,
    OWNERS_ACCOUNT,
    MY_ACCOUNT,
    LOG_OUT,
    MANAGE_PAYMENTS,
    MANAGE_ORGANIZATIONS,
    LOCKBOX,
    BILLING,
    PRODUCTS,
    PLANS,
    FAILED_JOBS,
    HELP,
    PAYABLES,
    MEMBERSHIP_DIRECTORY,
}
