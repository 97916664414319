import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Tag } from '../../../models/entities/tag';
import { TaggableMode, TaggableTypes, TagsDataService } from '../../../services/tags/tags-data.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { TagModel } from 'ngx-chips/core/tag-model';
import { TagInputComponent } from 'ngx-chips';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TagsDialogComponent, TagsDialogInput, TagsDialogOutput } from './tags-dialog/tags-dialog.component';
import { MatLegacyRadioGroup as MatRadioGroup } from '@angular/material/legacy-radio';
import { GrowlerService } from '../../../services/growler.service';
import { map } from 'rxjs/operators';
import { HostListenerService } from '../../../services/host-listener.service';
import { TagsService } from '../../../services/tags/tags.service';
import { AdjustColorValues } from '../../../directives/adjust-color/adjust-color.directive';
import { ColorSourcesEnum } from '../../../enums/color-sources.enum';
import { MatDialogSizes } from 'app/enums/mat-dialog-sizes.enum';

@UntilDestroy()
@Component({
    selector: 'lf-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit, OnChanges
{
    @Input() taggableType: TaggableTypes;
    @Input() taggableMode: TaggableMode;
    @Input() taggableId: number;
    @Input() items: Tag[] = [];
    @Input() placeholder: string;

    @Input() hasArchived = false;
    @Input() isEditable = false;
    @Input() isLoading = false;

    @Input() maxItems: number;
    @Input() appendToBody = true;
    @Input() hasButtonStyles = true;
    @Input() doSuppressHoverButton = false;
    @Input() doSuppressImplicitSave = false;

    @Output() itemAdded: EventEmitter<Tag> = new EventEmitter<Tag>();
    @Output() itemRemoved: EventEmitter<Tag> = new EventEmitter<Tag>();
    @Output() itemChange: EventEmitter<Tag> = new EventEmitter<Tag>();
    @Output() itemsChange: EventEmitter<Tag[]> = new EventEmitter<Tag[]>();
    @Output() itemsCleared: EventEmitter<any> = new EventEmitter<{}>();
    @Output() onUpdate: EventEmitter<Tag[]> = new EventEmitter<Tag[]>();

    @ViewChild(TagInputComponent) tagInput: TagInputComponent;

    readonly adjustColorValues = AdjustColorValues;

    readonly filterEqualsText = 'Has tag';
    readonly filterNoEqualsText = 'Does not have tag';
    readonly filterDefaultValue = 'equals';

    readonly dropdownShortDelay = 10;
    readonly dropdownDelay = 240;

    readonly autocompleteInitialCount = 100;
    readonly autocompleteMaxAfterSearch = 25;

    renderedItems: Tag[] = [];
    tagsCount = 0;

    hasDialog = false;

    // on add/edit/remove tags, go ahead and save tag change to server
    // (otherwise, you need to explicitly save or use the tags-dialog)
    hasImplicitSave = false;

    isRadioGroupVisible: boolean;
    isRefreshingColor = false;

    model: UntypedFormGroup;

    emptyText = 'No assigned tags';

    private obs: Observable<Tag[]>;

    /**
     * value to hold for HostListeners KeyUp/Down events, so we know when we are removing a tag
     * in filter mode. (This is because of the additionally radio options that prevent power users
     * from normally removing a tag by keying "Backspace".)
     */
    private backEventTargetType: string;

    constructor(
            private _builder: UntypedFormBuilder,
            private _elementRef: ElementRef,
            private _dialog: MatDialog,
            private _tagsDataService: TagsDataService,
            private _tagsService: TagsService,
            private _growler: GrowlerService,
            private _host: HostListenerService,
    ) {
    }

    get placeholderText(): string {
        return this._tagsDataService.getPlaceholderTextByModeAndType(this.taggableType, this.taggableMode);
    }

    get emptyPlaceholderText(): string {
        return this.placeholder
                ? this.placeholder
                : this._tagsDataService.getEmptyPlaceholderTextByModeAndType(this.taggableType, this.taggableMode);
    }

    set tags(tags: Tag[]) {
        // always sort with autotags on top
        this.items = tags.sort((a, b) => b.internalUse > 0 ? 0 : (a.internalUse > 0 ? -1 : 1));
        this.refreshColors();
    }

    @ViewChild(MatRadioGroup) set content(content: MatRadioGroup) {
        if (content) {
            setTimeout(() => {
                this.tagInput.dropdown.hide();
                if (this.model.controls.condition.value === 'not_equals') {
                    content._radios.last.focus();
                } else {
                    content._radios.first.focus();
                }
            }, this.dropdownShortDelay);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('hasArchived' in changes) {
            if (this.taggableType) {
                this.obs = undefined;
                if (this.taggableMode === 'Filter') {
                    this.clearItems();
                }
                this.getTags(this.taggableType);
            }
        }

        if ('items' in changes) {
            this.tags = this.items;
        }
    }

    ngOnInit() {
        this.setHostListenersForTags();
        this.setDefaultsFromTaggableMode();

        this.buildModel();
        this.getTags(this.taggableType);

        this.onItemsChanged({emitEvent: false});
    }

    searchTags = (text: string): Observable<Tag[]> => {
        return this.obs.pipe(
                map((tags) => {
                    if (this.taggableMode === 'Editor') {
                        tags = tags.filter((tag) => !tag.internalUse);
                    }

                    if (text.trim() === '') {
                        return tags.slice(0, this.autocompleteInitialCount);
                    }
                    const str = text.toLocaleLowerCase().trim();
                    return tags.filter((tag) => tag.tag.toLocaleLowerCase().indexOf(str) > -1)
                            .slice(0, this.autocompleteMaxAfterSearch);
                }),
        );
    };

    renderTags() {
        if (this.maxItems) {
            this.renderedItems = [];
            this.tagsCount = this.items.length;
            this.renderedItems = this.items.slice(0, this.maxItems);

            if (this.tagsCount > this.maxItems) {
                // add visual cue displaying number of total tags for set
                this.renderedItems.push(new Tag({
                    id: -1,
                    tag: '+' + (this.items.length - this.renderedItems.length),
                }));
            }
        } else {
            this.renderedItems = this.items.slice(0);
        }
    }

    onItemAdded(tag: TagModel) {
        if (this.taggableMode === 'Filter') {
            setTimeout(() => this.tagInput.selectItem(tag));
        }

        // onAdd, send up added item
        this.itemAdded.emit(tag as Tag);

        this.onItemsChanged();

        if (this.hasImplicitSave && this.isEditable) {
            this._tagsService.createTagsForType(tag as Tag, this.taggableType, [this.taggableId]).subscribe({
                next: () => this._growler.success('Success', `Tag added for ${this.taggableType.toLowerCase()}.`),
                error: () => this._growler.error('Error', 'Could not add tag.'),
            });
        }
    }

    onItemRemoved(model: TagModel) {
        const tag = model as Tag;

        // onRemove, send up added item
        this.itemRemoved.emit(tag);

        this.onItemsChanged();

        if (this.taggableMode === 'Filter') {
            if (this.items.length === 0) {
                // go ahead and refocus with dropdown
                this.refocusWithDropdown();
            }
        }

        if (this.hasImplicitSave && this.isEditable) {
            const tagText = tag.tag || tag.value;
            this._tagsService.deleteTagsForType(tagText, this.taggableType, [this.taggableId]).subscribe({
                next: () => this._growler.success('Success', `Tag removed for ${this.taggableType.toLowerCase()}.`),
                error: () => this._growler.error('Error', 'Could not remove tag.'),
            });
        }
    }

    onItemSelected(tag: TagModel) {
        if (this.isRadioGroupVisible) {
            this.toggleRadioDropdown();
            setTimeout(() => this.toggleRadioDropdown(tag as Tag), this.dropdownDelay);
        } else if (tag) {
            // if filter mode, set initial value and open radio dropdown
            if (this.taggableMode === 'Filter') {
                this.toggleRadioDropdown(tag as Tag);
            }

            // if tag is present and view mode, open view all dialog
            this.viewAllItems();
        }
    }

    onItemsChanged(props: { emitEvent?: boolean; } = {}) {
        if (!props.hasOwnProperty('emitEvent') || props.emitEvent !== false) {
            this.itemsChange.emit(this.items);
        }
    }

    onInputFocused() {
        // just in case, make sure radio dropdown is hidden when typing new tag into input
        this.toggleRadioDropdown();
    }

    onAdding(model: string | TagModel): Observable<TagModel> {
        if (typeof model === 'string') {
            const max = Object.keys(ColorSourcesEnum).length - 1;
            const index = Math.floor(Math.random() * (max));
            const taggableColor = Object.values(ColorSourcesEnum)[index];
            const tag = new Tag({tag: model, taggableColor, taggableId: this.taggableId});
            return of(tag);
        }

        return of(model);
    }

    /**
     * Get the org tags
     * @type: TaggableTypes
     */
    getTags(type: TaggableTypes) {
        // prevent multiple assignments
        if (!this.obs) {
            this.obs = this._tagsDataService.getObservableByType(type, this.hasArchived);
            this.obs.pipe(untilDestroyed(this)).subscribe(() => {
                this.renderTags();
            });
        }
    }

    /** Get the tag display text by given display name and filter (if applicable) */
    getTagDisplay(tag: Tag) {
        if (this.taggableMode === 'Filter' || !tag.filter) {
            return tag.display;
        }

        return (tag.filter === 'not_equals'
                ? this.filterNoEqualsText
                : this.filterEqualsText) + ' "' + tag.display + '" for ' + this.taggableType;
    }

    /** Show the dialog for viewing a list of all tags applied */
    viewAllItems() {
        if (!this.hasDialog) {
            return;
        }

        const data: TagsDialogInput = {
            items: this.items,
            obs: this.obs,
            taggableType: this.taggableType,
            taggableIds: [this.taggableId],
            readonly: !this.isEditable,
            placeholder: this.placeholderText,
            secondaryPlaceholder: this.emptyPlaceholderText,
            hasArchived: this.hasArchived,
        };
        this._dialog.open(TagsDialogComponent, {
            width: MatDialogSizes.MD,
            data,
        }).afterClosed().subscribe({
            next: (res: TagsDialogOutput) => {
                if (res?.success) {
                    this.tags = res.items;
                    this.renderTags();
                    this.onItemsChanged();
                }
            },
        });
    }

    refreshColors() {
        this.isRefreshingColor = true;
        setTimeout(() => this.isRefreshingColor = false, 0);
    }

    private setDefaultsFromTaggableMode() {
        switch (this.taggableMode) {
            case 'Filter':
                this.hasDialog = this.hasImplicitSave = false;
                this.isEditable = true;
                break;
            case 'Table':
                this.hasButtonStyles = false;
                this.maxItems = this.maxItems !== undefined ? this.maxItems : 3;
                this.hasImplicitSave = false;
                this.hasDialog = true;
                break;
            case 'Editor':
                this.hasButtonStyles = false;
                this.hasImplicitSave = !this.doSuppressImplicitSave;
                this.hasDialog = false;
                break;
            default:
                console.error('Tag type not provided.');
        }
    }

    /** Build the model object. Resets the validation state of the form. */
    private buildModel() {
        if (this.taggableMode === 'Filter') {
            this.model = new UntypedFormGroup({
                condition: new UntypedFormControl(),
            });

            this.model.controls.condition.valueChanges.pipe(untilDestroyed(this))
                    .subscribe((value: 'equals' | 'not_equals') => {
                        if (this.isRadioGroupVisible) {
                            this.setTagFilterValue(value);
                            this.onUpdate.emit(this.items);
                        }
                    });
        }
    }

    private clearItems() {
        this.items = [];
        this.itemsCleared.emit();
    }

    private setTagFilterValue(value?: 'equals' | 'not_equals') {
        if (this.tagInput.selectedTag) {
            const tagWithFilter = this.tagInput.selectedTag as Tag;

            if (tagWithFilter && value) {
                // only change value if tag is found AND if "value" param is passed in
                tagWithFilter.filter = value || this.filterDefaultValue;
            }

            // send up that item Changed after update
            this.itemChange.emit(tagWithFilter);

            this.onItemsChanged();
        }
    }

    private toggleRadioDropdown(tag?: Tag) {
        if (this.model) {
            this.model.controls.condition.setValue(tag?.filter, {emitEvent: tag !== undefined});
            this.isRadioGroupVisible = tag !== undefined;

            if (!this.isRadioGroupVisible) {
                this.model.controls.condition.reset();
            }
        }
    }

    /**
     * ngx-chips component call .focus with autocomplete params is not working!
     * forcing dropdown to show after arbitrary 240ms timeout...
     */
    private refocusWithDropdown() {
        this.tagInput.dropdown.hide();
        setTimeout(() => {
            this.tagInput.focus(true);
            setTimeout(() => {
                this.tagInput.dropdown.updatePosition();
                this.tagInput.dropdown.show();
            }, this.dropdownDelay);
        }, this.dropdownShortDelay);
    }

    /**
     * to avoid performance issues, leverage HostListenerService's subjects and observables to trigger events as needed
     */
    private setHostListenersForTags() {
        if (this.taggableMode === 'Filter') {
            this._host.onMouseClick().pipe(untilDestroyed(this)).subscribe({
                next: (event) => this.handleHostClickForFilterChip(event),
            });

            this._host.onKeydown().pipe(untilDestroyed(this)).subscribe({
                next: (event) => this.handleHostKeydownForFilterChip(event),
            });
        }

        this._host.onKeyup().pipe(untilDestroyed(this)).subscribe({
            next: (event) => this.handleHostKeyupForFilterChip(event),
        });
    }

    /**
     * listener check for clicking outside the component bounds. If either dropdowns are visible, hide
     * to avoid locking them in the ui. (The default behavior from ngx-chips of setting "keepOpen" to false
     * is not working for some reason.)
     */
    private handleHostClickForFilterChip(event: MouseEvent) {
        const isOutOfBounds = !this._elementRef.nativeElement.contains(event.target);

        if (isOutOfBounds && this.isRadioGroupVisible) {
            // clicked outside radio group dropdown, close it and trigger tagInput deselect
            this.toggleRadioDropdown();
            this.tagInput.selectItem(undefined);
        }

        if (isOutOfBounds && this.tagInput.dropdown.isVisible) {
            // clicked outside active dropdown, close it
            this.tagInput.dropdown.hide();
        }
    }

    /**
     * For filter chips, track "event.target" from HTML input and set for key up interpretation.
     */
    private handleHostKeydownForFilterChip(event: KeyboardEvent) {
        if (this._elementRef.nativeElement.contains(event.target) && event.code === 'Backspace' && this.taggableMode === 'Filter') {
            if (event.target instanceof HTMLInputElement) {
                this.backEventTargetType = (event.target as HTMLInputElement).type;
            }
        }
    }

    /**
     * A couple of key up listeners for power users to interact with tags
     * TODO: revisit L/T to ensure you can traverse before/after chips as expected
     */
    private handleHostKeyupForFilterChip(event: KeyboardEvent) {
        // scope keyboard event to this component
        if (this._elementRef.nativeElement.contains(event.target)) {
            switch (event.code) {
                case 'Enter':
                    if (this.tagInput.onlyFromAutocomplete) {
                        this.items.push(new Tag(this.tagInput.dropdown.items[0]));
                        this.tagInput.dropdown.hide();
                        setTimeout(() => this.tagInput.selectItem(this.tagInput.tags.last.model));
                    }
                    break;
                case 'Backspace':
                    /**
                     * For filter chips, using tracking to remove item when we backspace within the radio dropdown
                     * and simulate chips without the extra step.
                     */
                    if (this.taggableMode === 'Filter' && event.target instanceof HTMLInputElement) {
                        const type = (event.target as HTMLInputElement).type;
                        if (type === 'radio' && this.backEventTargetType === type) {
                            this.tagInput.removeItem(this.tagInput.tags.last.model, this.tagInput.tags.length - 1);
                            this.backEventTargetType = undefined;
                        }
                    } else if (this.tagInput.dropdown.isVisible) {
                        this.tagInput.dropdown.hide();
                    }
                    break;
            }
        } else if (event.code === 'Tab') {
            // tab falls outside of scope, so using radio dropdown to help
            if (this.taggableMode === 'Filter' && this.isRadioGroupVisible) {
                this.toggleRadioDropdown();
                this.refocusWithDropdown();
            }
        }
    }
}
