import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { Observable } from 'rxjs';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { Routes } from '../../config/routes';
import { Form } from '../../models/entities/forms/form';
import { FormQuestion } from '../../models/entities/forms/form-question';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormQuestionOption } from '../../models/entities/forms/form-question-option';
import { Membership } from '../../models/entities/membership';
import { FormPermission } from '../../models/entities/forms/form-permission';
import { UploadedFile } from '../../models/entities/uploaded-file';
import { FormSubmission, FormSubmissionFlat } from '../../models/entities/forms/form-submission';
import { UnitsService } from '../units/units.service';
import { FormAnswer } from '../../models/entities/forms/form-answer';
import { HttpHeaders } from '@angular/common/http';
import { FormSubmissionApproval } from '../../models/entities/forms/form-submission-approval';
import { RequestFormRoutes } from './request-form-routes';
import { FormSubmissionStatus } from '../../models/entities/forms/form-submission-status';
import { FormApprover } from '../../models/entities/forms/form-approver';
import { OrganizationForm } from '../../models/entities/forms/organization-form';
import { OrganizationFormCount } from '../../models/entities/forms/organization-form-count';
import { FormSubmissionComment } from '../../models/entities/forms/form-submission-comment';
import { Forms } from 'app/components/shared/http/request-interfaces';

export interface UpdateFormPermissionsRequest
{
    membershipIds: number[];
    isRemoving: boolean;
    isWritable: boolean;
}

export interface UpdateFormStatusRequest
{
    isToggleOn?: boolean;
    isMarkedPrivate?: boolean;
}

export interface UpdateFormRequestCompletionRequest
{
    isToggleOn: boolean;
}

export interface UpdateApprovalNotificationStatusRequest
{
    isManual: boolean;
}

export interface ApproveFormSubmissionRequest
{
    isApprove: boolean;
}

export interface CreateFormAnswerRequest
{
    formId: number;
    unitIds: number[];
    answers: { questionId: number; answer: string }[];
}

export interface EditFormAnswerRequest
{
    answers: { answerId: number; questionId: number; answer: string }[];
}

export interface CreateFormRequestCommentRequest
{
    message: string;
    notifyAdmins: boolean;
    recipientMemberIds: number[];
}

export interface UpdateFormUserSettingsRequest
{
    memberIds?: number[];
    readMemberIds?: number[];
    writeMemberIds?: number[];
    approverIds?: number[];
}

export interface FormBulkPermissionPatchRequest
{
    memberId: number;
    isReadable: boolean;
    isWritable: boolean;
    hasFullFormReadAccess: boolean;
    hasFullFormWriteAccess: boolean;
}

export interface FormSubmissionStatusSortRequest
{
    statuses: FormSubmissionStatus[];
}

export interface ChangeFormSubmissionStatusesRequest
{
    formSubmissionStatusId: number;
    formSubmissionIds: number[];
    recipientMemberIds: number[];
    organizationId?: number;
}

@Injectable({
    providedIn: 'root',
})
export class RequestFormService
{
    constructor(
            private _http: ApplicationHttpClient,
            private _unitsService: UnitsService,
    ) {
    }

    getOrganizationRequestForms(): Observable<OrganizationForm[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Forms);
        return this._http.get(url)
                .pipe(map((response: Object[]) => response.map((form: Object) => new OrganizationForm(form))));
    }

    getOrganizationRequestForm(formId: number): Observable<OrganizationForm> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Form(formId));
        return this._http.get(url).pipe(map((response: Object) => new OrganizationForm(response)));
    }

    getOrganizationRequestFormCounts(): Observable<OrganizationFormCount[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.FormCounts);
        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map((counts: Object) => new OrganizationFormCount(counts))
                    .filter(f => f.canUserRead || f.canUserWrite);
        }));
    }

    getOrganizationFormSubmissions(formId: number): Observable<FormSubmissionFlat[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.FormSubmissions(formId));
        return this._http.get(url)
                .pipe(map((response: Object[]) => response.map((submission: Object) => new FormSubmissionFlat(submission))));
    }

    /**
     * Get the details of a form to show in fillable form.
     *
     * @param formId
     */
    getFormForBuilder(formId: number): Observable<Form> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormBuilder(jwt.orgId, formId),
        );

        return this._http.get(url).pipe(map((response: Object) => {
            return new Form(response);
        }));
    }

    /**
     * Get a form with all the possible includes, even mapped submissions
     * with answers and all that.
     *
     * @param formId
     */
    getFormDetailWithSubmissions(formId: number): Observable<Form> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.Form(jwt.orgId, formId) + '/submissions',
        );

        return this._http.get(url).pipe(map((response: Object) => {
            return new Form(response);
        }));
    }

    /**
     * Get all forms for an org. Simple form object that includes
     * user permission to the form, as well as pending/complete counts.
     */
    getFormsWithCountsForOrg(orgId = null): Observable<Form[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.Forms(orgId ?? jwt.orgId),
        );

        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map((obj) => {
                const form = new Form(obj['form']);
                form.numComplete = obj['completed'];
                form.numPending = obj['pending'];
                return form;
            });
        }));
    }

    /**
     * Get enabled forms with counts specific to the unit being viewed.
     */
    getFormsWithCountsForUnit(unitId: number): Observable<Form[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.UnitForms(jwt.orgId, unitId),
        );

        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map((obj) => {
                const form = new Form(obj['form']);
                form.numComplete = obj['completed'];
                form.numPending = obj['pending'];
                return form;
            });
        }));
    }

    /**
     * Get all forms for an org but check perms for a specific member
     */
    getOrgFormsForMember(memberId: number): Observable<Form[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.Forms(jwt.orgId) + `/member/${memberId}`,
        );

        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map((form) => {
                return new Form(form);
            });
        }));
    }

    /**
     * Get the permitted members and admins who have access to this form.
     * TODO - DEPRECATE
     */
    getFormAccessors(formId: number, ifShowAllMembers: number): Observable<any[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.Form(jwt.orgId, formId) + '/accessors/' + ifShowAllMembers,
        );

        return this._http.get(url);
    }

    /**
     * Create a new form from form builder elements.
     *
     * @param requestBody
     */
    createFormFromBuilder(requestBody: Form): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormBuilders(jwt.orgId),
        );

        return this._http
                .post(url, JSON.stringify(requestBody))
                .pipe(map((response: Object) => {
                    return new Form(response);
                }));
    }

    /**
     * Create a new form from form builder elements.
     *
     * @param requestBody
     * @param formId
     */
    updateFormBuilderElements(requestBody: Form, formId: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormBuilder(jwt.orgId, formId),
        );

        return this._http
                .patch(url, JSON.stringify(requestBody))
                .pipe(map((response: Object) => {
                    return new Form(response);
                }));
    }

    /**
     * Submit answers to a form
     *
     * @param requestBody
     */
    createFormRequest(requestBody: CreateFormAnswerRequest): Observable<number> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormSubmissions(jwt.orgId),
        );

        return this._http
                .post(url, JSON.stringify(requestBody))
                .pipe(map((response: number) => {
                    return response;
                }));
    }

    /**
     * Edit a form submission.
     *
     * @param requestBody
     * @param submissionId
     */
    editFormRequest(requestBody: EditFormAnswerRequest, submissionId: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormSubmission(jwt.orgId, submissionId),
        );

        return this._http
                .patch(url, JSON.stringify(requestBody),
                ).pipe(map((response: number) => {
                    return response;
                }));
    }

    /**
     * Edit the form submission status id of one or more requests; maybe email folks as well.
     *
     * @param requestBody
     */
    changeFormSubmissionStatuses(requestBody: ChangeFormSubmissionStatusesRequest): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const organizationId = requestBody.organizationId || jwt.orgId;
        const url: string = Routes.MakeLegFiCoreUrl(`${RequestFormRoutes.PayhoaCore.FormSubmissions(organizationId)}/statuses`);

        return this._http.patch(url, JSON.stringify(requestBody));
    }


    /**
     * Get the submissions for a form specific to a unit.
     *
     * @param formId
     * @param unitId
     */
    getUnitFormSubmissions(formId: number, unitId: number) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http
                .get(
                        Routes.MakeLegFiCoreUrl(
                                RequestFormRoutes.PayhoaCore.Form(jwt.orgId, formId) + `/submissions/${unitId}`,
                        ),
                ).pipe(
                        map((response: Object[]) => {
                            return response.map(r => new FormSubmission(r));
                        }));
    }

    /**
     * Get the answered details of one person's submitted data.
     *
     * @param submissionId
     */
    getFormSubmission(submissionId: number): Observable<FormSubmission> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http
                .get(
                        Routes.MakeLegFiCoreUrl(
                                RequestFormRoutes.PayhoaCore.FormSubmission(jwt.orgId, submissionId),
                        ),
                ).pipe(map((response: Object[]) => {
                    const submission = new FormSubmission(response['submission']);
                    submission.answers = response['answers'].map(a => new FormAnswer(a));
                    return submission;
                }));
    }

    /**
     * Get the attachment for the form, if it exists.
     * Can also get the attachments for a form submission.
     *
     * @param id can be form or submission
     * @param which
     */
    loadAttachments(id: number, which: string = 'form'): Observable<UploadedFile[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return ApplicationHttpClient.instance.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.LinkedFiles(which, id),
        );

        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map(attachment => {
                return new UploadedFile(attachment);
            });
        }));
    }

    /**
     * Notify others button on form submission page
     */
    sendFormRequestNotification(
            submissionId: number,
            requestBody: {
                memberIds: number[];
                emailOwners: boolean;
                emailAdmins: boolean;
                organizationId?: number;
            },
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const orgId = requestBody?.organizationId || jwt.orgId;
        const url = Routes.MakeLegFiCoreUrl(RequestFormRoutes.PayhoaCore.FormSubmissionWord(orgId, submissionId, 'notification'));
        return this._http.post(url, JSON.stringify(requestBody));
    }


    /**
     * They clicked to re-notify a specific approver.
     *
     * @param submissionId
     * @param approverId
     */
    sendApprovalReminder(submissionId: number, approverId: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormApproval(jwt.orgId, submissionId, approverId),
        );

        return this._http.post(url, '');
    }

    /**
     * Get the list of who receives emails for an form type
     *
     * @param formId
     */
    getFormEmailRecipients(formId: number): Observable<Membership[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.Form(jwt.orgId, formId) + '/email-recipients',
        );

        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map((member) => {
                return new Membership(member);
            });
        }));
    }

    /**
     * Update the list of member ids who should receive the emails for this Form.
     *
     * @param formId
     * @param ids
     */
    updateFormEmailRecipients(formId: number, ids: number[]): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormSettings(jwt.orgId, formId, 'email-recipients'),
        );

        const request: UpdateFormUserSettingsRequest = {
            memberIds: ids,
        };

        return this._http.patch(url, JSON.stringify(request));
    }

    /**
     * Update permissions for this specific form
     *
     * @param formId
     * @param request
     */
    updateBulkFormPermissions(formId: number, request: FormBulkPermissionPatchRequest[]) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormPermission(jwt.orgId, formId),
        );

        return this._http.patch(url, JSON.stringify({permissions: request}));
    }

    /**
     * Update whether this form is enabled or disabled for the org.
     *
     * @param formId
     * @param options
     */
    updateFormStatus(formId: number, options: { isEnabled?: boolean; isPrivate?: boolean; }): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormSettings(jwt.orgId, formId, 'status'),
        );

        const request: UpdateFormStatusRequest = {};
        if ('isEnabled' in options) {
            request.isToggleOn = options.isEnabled;
        }

        if ('isPrivate' in options) {
            request.isMarkedPrivate = options.isPrivate;
        }

        return this._http.patch(url, JSON.stringify(request));
    }

    /**
     * Toggle submission completed, or 're-opened'.
     *
     * @param submissionId
     * @param isToggleOn
     * @param organizationId
     */
    completeFormRequest(
            submissionId: number,
            isToggleOn: boolean,
            organizationId?: number,
    ): Observable<void> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const orgId = organizationId || jwt.orgId;
        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormSubmissionWord(orgId, submissionId, 'complete'),
        );
        const request: UpdateFormRequestCompletionRequest = {isToggleOn: isToggleOn};
        return this._http.post(url, JSON.stringify(request));
    }

    /**
     * Toggle the approval workflow off.
     *
     * @param formId
     */
    disableFormApprovals(formId: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormSettings(jwt.orgId, formId, 'approvals'),
        );

        return this._http.delete(url);
    }

    /**
     * Toggle the approval workflow on by assigning some member ids.
     *
     * @param formId
     * @param memberIds
     */
    enableFormApprovals(formId: number, memberIds: number[]): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormSettings(jwt.orgId, formId, 'approvals'),
        );

        const request: UpdateFormUserSettingsRequest = {
            approverIds: memberIds,
        };

        return this._http.patch(url, JSON.stringify(request));
    }

    /**
     * Update whether they want approval notifications to send automatically or manually.
     *
     * @param formId
     * @param isManual
     */
    updateFormApprovalNotifications(formId: number, isManual: boolean): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormSettings(jwt.orgId, formId, 'manual-approval'),
        );

        const request: UpdateApprovalNotificationStatusRequest = {
            isManual: isManual,
        };

        return this._http.patch(url, JSON.stringify(request));
    }


    /**
     * Designated approver is sending their approval of a submission/request.
     *
     * @param submissionId
     * @param isApprove is a 0 for deny, 1 for approve
     */
    approveFormRequest(submissionId: number, isApprove: number): Observable<number> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormApprovals(jwt.orgId, submissionId),
        );

        const request: ApproveFormSubmissionRequest = {
            isApprove: !!isApprove,
        };

        return this._http.post(url, JSON.stringify(request));
    }

    /**
     * Designated approver is re-setting their approval of a submission/request.
     *
     * @param approvalId
     */
    resetApproval(approvalId: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormApprovals(jwt.orgId, approvalId),
        );

        return this._http.delete(url);
    }

    /** For the approval detail table, get specific mapped info about the approvers and given approval */
    getMappedApproversAndApprovals(submissionId: number): Observable<FormSubmissionApproval[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormApprovals(jwt.orgId, submissionId),
        );

        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map((obj) => {
                return new FormSubmissionApproval(obj);
            });
        }));
    }

    /**
     * Get the list of permitted accessors for a specified form
     *
     * @param formId
     */
    getFormPermittedMembers(formId: number): Observable<FormPermission[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.FormPermissions(formId));
        return this._http.get(url).pipe(map((data: Object[]) => {
            return data.map(d => new FormPermission(d));
        }));
    }

    /**
     * Get the list of members of org and map to their form permissions
     *
     * @param formId
     */
    getNonAdminMembersForFormPermissions(formId: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(`${Routes.LegFiCore.FormPermissions(formId)}/members`);
        return this._http.get(url).pipe(map((data: Object[]) => {
            return data.map(d => new FormPermission(d));
        }));
    }

    /**
     * Get the list of approvers for a specified form
     *
     * @param formId
     */
    getFormApprovers(formId: number): Observable<FormApprover[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.FormApprovers(formId));
        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map((approver) => new FormApprover(approver));
        }));
    }

    /**
     * Delete a form submission.
     *
     * @param submissionId
     * @param organizationId
     *
     */
    deleteFormRequest(
            submissionId: number,
            organizationId?: number,
    ): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const orgId = organizationId || jwt.orgId;
        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormSubmission(orgId, submissionId),
        );
        return this._http.delete(url);
    }

    /**
     * Delete a form.
     *
     * @param formId
     */
    deleteForm(formId: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.Form(jwt.orgId, formId),
        );

        return this._http.delete(url);
    }

    /**
     * Generate PDF of a Form Submission
     *
     * @param {number} submissionId
     * @returns {Observable<Object>}
     */
    getFormRequestPdfReport(submissionId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormSubmissionWord(jwt.orgId, submissionId, 'pdf'),
        );

        const newHeaders = new HttpHeaders({
            'Content-Type': 'application/pdf',
        });

        const options: Object = {
            headers: newHeaders,
            responseType: 'arraybuffer',
        };

        return this._http.get(url, options);
    }

    /** Get all the form sub statuses for this org, but filter by form id on get */
    getOrgFormSubmissionStatuses(formId: number): Observable<FormSubmissionStatus[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormSubmissionStatuses(jwt.orgId),
        );

        return this._http.get(url).pipe(map((response: Object[]) => {
            const statuses = response.map((obj) => {
                return new FormSubmissionStatus(obj);
            });
            return statuses.filter(s => s.formId === formId);
        }));
    }

    /** Upsert a form submission status; statusId as 0 is fine for create */
    updateOrCreateFormSubmissionStatus(requestBody: FormSubmissionStatus, statusId: number = 0): Observable<void> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormSubmissionStatus(jwt.orgId, statusId),
        );

        return this._http.post(url, JSON.stringify(requestBody));
    }

    /** Re-sort all the form sub statuses for the org */
    updateStatusSortOrder(requestBody: FormSubmissionStatusSortRequest): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormSubmissionStatuses(jwt.orgId) + '/sort',
        );

        return this._http.patch(url, JSON.stringify(requestBody));
    }

    /** Delete a form sub status */
    deleteFormSubmissionStatus(statusId: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                RequestFormRoutes.PayhoaCore.FormSubmissionStatus(jwt.orgId, statusId),
        );

        return this._http.delete(url);
    }


    /**
     * Map form array form groups to a save object of FormQuestion[]
     *
     * @returns the array of form questions for saving from the create page
     */
    mapQuestionsForSaving(questionRows: UntypedFormArray): FormQuestion[] {
        return questionRows.controls
                .map((row: UntypedFormGroup) => {
                    return new FormQuestion(row.getRawValue());
                });
    }

    /**
     * Very similar to the mapping method below, this one maps a saved db Form object to formly for rendering.
     *
     * @param form
     */
    mapSavedQuestionsToFormly(form: Form): FormlyFieldConfig[] {
        return form.questions.map((qRow: FormQuestion) => {
            let newField: FormlyFieldConfig = {
                id: qRow.id + '',
                key: qRow.id + '',
                type: qRow.type,
                templateOptions: {
                    label: qRow.label,
                    required: qRow.isRequired && qRow.isEnabled,
                    description: qRow.description,
                    multiple: qRow.isMultiselect,
                    disabled: !qRow.isEnabled,
                    indeterminate: false,
                },
            };

            // get the options if this is a select (one or multi)
            if (qRow.type === 'select') {
                newField.templateOptions.options = qRow.options
                        .map((option: FormQuestionOption) => {
                            return {
                                id: option.id || 0,
                                value: option.value,
                                label: option.label,
                            };
                        });

                if (!qRow.isMultiselect && qRow.isRequired) {
                    newField.templateOptions.options.unshift({
                        id: null,
                        value: null,
                        label: '--',
                    });
                }
            }

            if (qRow.type === 'plaintext') {
                newField = {
                    template: '<p>' + qRow.label + '</p>',
                };
            }

            if (qRow.type === 'hr') {
                newField = {
                    template: '<hr/>',
                };
            }

            return newField;
        });
    }

    /**
     * Helper to map the question form array values to a formly template for rendering. Used on create, to view preview as formly
     *
     * @param questionRows
     */
    mapQuestionsToFormlyConfig(questionRows: UntypedFormArray): FormlyFieldConfig[] {
        // map the question form controls to the json config objects that will save to the DB
        return questionRows.controls.map((qRow: UntypedFormGroup) => {
            const label = qRow.get('label').value || 'no label';
            let newField: FormlyFieldConfig = {
                id: qRow.get('id').value,
                key: qRow.get('label').value,
                type: qRow.get('type').value.type,
                templateOptions: {
                    label: label,
                    required: qRow.get('isRequired').value,
                    description: qRow.get('type').value.type === 'multiselect' ?
                            'Control/command + click to select multiple rows' : '',
                },
            };

            const qType = qRow.get('type').value.type;
            if (qType === 'select' || qType === 'multiselect') {
                const optionRows = qRow.get('options') as UntypedFormArray;
                const options = optionRows.controls
                        .filter((option: UntypedFormGroup) => option.get('label').value)
                        .map((option: UntypedFormGroup) => {
                            return {
                                value: option.get('id').value,
                                label: option.get('label').value,
                            };
                        });

                newField.templateOptions.options = options;

                // multiselect is not a true type; handle differently
                if (qType === 'multiselect') {
                    newField.type = 'select';
                    newField.templateOptions.multiple = true;
                }
            }

            if (qType === 'plaintext') {
                newField = {
                    template: '<p>' + qRow.get('label').value + '</p>',
                };
            }

            if (qType === 'hr') {
                newField = {
                    template: '<hr/>',
                };
            }

            return newField;
        });
    }

    /**
     * Helper to join questions plus existing response from a user, on the edit submission screen.
     *
     * @param sub
     */
    mapQuestionsAndAnswersForEdit(sub: FormSubmission): any {
        const model = {};
        sub.answers.forEach((a) => {
            let modelValue = a.answer;

            // help dropdowns get the right answers selected
            if (a.question.type === 'select' && !a.question.isMultiselect && modelValue) {
                modelValue = a.question.options.find(o => o.label === modelValue).value + '';
            }

            if (a.question.type === 'select' && a.question.isMultiselect && modelValue) {
                const arrayOfChoices = a.answer.split(',').map(t => t.trim());

                // @ts-ignore
                modelValue = a.question.options
                        .filter(o => arrayOfChoices.find(c => c === o.label))
                        .map(o => o.value);
            }

            if (a.question.type === 'file') {
                model[a.question.key] = a.files;
            } else {
                model[a.question.key] = modelValue;
            }
        });

        model['submissionId'] = sub.id;

        return model;
    }

    /**
     * Shared code to handle mapping the form submission answers back for save or edit.
     *
     * @param formValue
     * @param questions
     * @param submission
     */
    mapAnswersForSubmissionSaveOrEdit(
            formValue: any,
            questions: FormQuestion[],
            submission: FormSubmission = null,
    ): any[] {
        return questions
                .filter(q => formValue[q.id] || q.type === 'checkbox')
                .map(q => {
                    // adjust a few values before sending
                    let answer = formValue[q.id];
                    if (q.isMultiselect || q.type === 'file') {
                        // multiselect and files are array of numbers; need to store as string
                        answer = answer.toString();
                    }
                    if (q.type === 'checkbox') {
                        // checkbox has to store as string so literally true or false
                        answer = answer ? 'true' : 'false';
                    }
                    return !!submission ?
                            {
                                answerId: submission.answers.find(a => a.formQuestionId === q.id) ?
                                        submission.answers.find(a => a.formQuestionId === q.id).id : 0,
                                questionId: q.id,
                                answer: answer,
                            } :
                            {
                                questionId: q.id,
                                answer: answer,
                            };
                });
    }

    /** Helper to get the specific status from the given org list and specific status title. */
    getStatusFromOrgList(status: string, orgStatuses: FormSubmissionStatus[]): FormSubmissionStatus {
        return orgStatuses.filter(s => s.title === status) ? orgStatuses.filter(s => s.title === status)[0] : null;
    }

    getComments(submissionId: number, filters: { [key: string]: any } = {}): Observable<FormSubmissionComment[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject(filters);
        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.FormSubmissionComments(submissionId) + query);

        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map((comment) => {
                return new FormSubmissionComment(comment);
            });
        }));
    }

    createComment(
            submissionId: number,
            requestBody: Forms.CreateFormRequestCommentRequest,
    ): Observable<Object> {
        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.FormSubmissionComments(submissionId));
        return this._http.post(url, JSON.stringify(requestBody));
    }

    updateComment(
            submissionId: number,
            commentId: number,
            requestBody: Forms.UpdateFormRequestCommentRequest,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.FormSubmissionComments(submissionId) + '/' + commentId);

        return this._http.patch(url, JSON.stringify(requestBody));
    }

    deleteComment(
            submissionId: number,
            commentId: number,
            filters: { [key: string]: any } = {},
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject(filters);
        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.FormSubmissionComments(submissionId) + '/' + commentId + query);

        return this._http.delete(url);
    }
}
