import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import environment from '../../environments/environment';
import { OrganizationReviewActions } from '../enums/organization-review-actions.enum';

export enum PaymentTypes {
    BANK_ACCOUNT = 'bank account',
    CREDIT_CARD = 'credit card',
}

export enum RegistrationTypes {
    JOIN = 'join HOA',
    CREATE = 'create HOA',
}

export interface RegistrationParams {
    type: RegistrationTypes;
    landingPageConversion: boolean;
    email: string;
    orgId: number;
}

@Injectable({
    providedIn: 'root',
})
export class GtmService {
    constructor(private _gtm: GoogleTagManagerService) {
        this._gtm.addGtmToDom();
    }

    /* Triggered when a logged-in user adds a unit in PayHOA. */
    addUnit(params: { orgId: number; unitName: string; addedBy: number; }) {
        this.handleTag({
            event: 'add_unit',
            organization_id: params.orgId,
            user_id: params.addedBy,
        });
    }

    /* Triggered when a logged-in user adds a bank account in PayHOA. */
    addBankAccount(params: { orgId: number; addedBy: number; }) {
        this.handleTag({
            event: 'add_bank_account',
            organization_id: params.orgId,
            user_id: params.addedBy,
        });
    }

    /* Triggered when a logged-in user adds an organization payment method in PayHOA. */
    addPaymentMethod(params: { type: PaymentTypes; orgId: number; addedBy: number; }) {
        this.handleTag({
            event: 'add_payment_method',
            payment_type: params.type,
            organization_id: params.orgId,
            user_id: params.addedBy,
        });
    }

    /* Triggered when a logged-in user creates an invoice (set of invoices) in PayHOA. */
    createInvoice(params: { payorType: string; orgId: number; createdBy: number; }) {
        this.handleTag({
            event: 'create_invoice',
            organization_id: params.orgId,
            user_id: params.createdBy,
        });
    }

    /* Triggered when a logged-in user schedules a demo with PayHOA. */
    demoScheduled(params: { orgId: number; addedBy: number; }) {
        this.handleTag({
            event: 'demo_scheduled',
            organization_id: params.orgId,
            user_id: params.addedBy,
        });
    }

    /* Triggered when a visitor authenticates and logs into PayHOA. */
    login(params: { userId: number; }) {
        this.handleTag({
            event: 'login',

            // TODO: Support requested params
            // email: params.email,
            // organization_id: params.orgId,
            user_id: params.userId,
        });
    }

    /* Triggered when a logged-in user navigates to another page within PayHOA. */
    pageView(params: { pageName: string; previousRoute: string; }) {
        this.handleTag({
            event: 'page',
            page_name: params.pageName,
            previous_url: params.previousRoute,
        }, false);
    }

    /* Triggered when a visitor starts the registration process from landing pages */
    registrationStart(params: RegistrationParams) {
        this.handleTag({
            event: 'registration_start',
            email: params.email,
            registration_type: params.type,
            landing_page_conversion: params.landingPageConversion,
            organization_id: params.orgId,

            // TODO: Support requested params
            // user_id: params.userId,
        });
    }

    /* Triggered when a visitor completes the registration process as new HOA or member joining HOA */
    registrationComplete(params: RegistrationParams) {
        this.handleTag({
            event: 'registration_complete',
            email: params.email,
            registration_type: params.type,
            landing_page_conversion: params.landingPageConversion,
            organization_id: params.orgId,

            // TODO: Support requested params
            // user_id: params.userId,
        });
    }

    /* Triggered when a superUser reviews a pending organization, setting the status as 'approve', 'dismiss', or 'reject' */
    reviewOrganization(params: { orgId: number; action: OrganizationReviewActions }) {
        let eventName = '';
        switch (params.action) {
            case OrganizationReviewActions.APPROVE:
                eventName = 'organization_approved';
                break;
            case OrganizationReviewActions.DISMISS:
                eventName = 'organization_dismissed';
                break;
            case OrganizationReviewActions.REJECT:
                eventName = 'organization_rejected';
                break;
            default:
                eventName = 'unknown';
        }
        this.handleTag({
            event: eventName,
            organization_id: params.orgId,
        });
    }

    event(name: string, params: { [key: string]: string; }) {
        this.handleTag({
            event: name,
            ...params,
        }, false);
    }

    private handleTag(tag: object, log = true) {
        if (environment.production) {
            this._gtm.pushTag(tag);
        } else if (log)  {
            console.log(tag);
        }
    }
}
