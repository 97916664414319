export class RequestFormRoutes
{
    public static PayhoaCore = {

        Forms(orgId: number): string {
            return `/organizations/${orgId}/forms`;
        },
        Form(orgId: number, formId: number): string {
            return `/organizations/${orgId}/forms/${formId}`;
        },
        FormApprovals(orgId: number, submissionId: number): string {
            return `/organizations/${orgId}/form-approvals/${submissionId}`;
        },
        FormApproval(orgId: number, submissionId: number, approvalId: number ): string {
            return `/organizations/${orgId}/form-approvals/${submissionId}/notify/${approvalId}`;
        },
        FormBuilders(orgId: number): string {
            return `/organizations/${orgId}/form-builder`;
        },
        FormBuilder(orgId: number, formId: number): string {
            return `/organizations/${orgId}/form-builder/${formId}`;
        },
        FormPermission(orgId: number, formId: number): string {
            return `/organizations/${orgId}/form-permissions/${formId}`;
        },
        FormSubmissions(orgId: number): string {
            return `/organizations/${orgId}/form-submissions`;
        },
        FormSubmission(orgId: number, submissionId: number): string {
            return `/organizations/${orgId}/form-submissions/${submissionId}`;
        },
        FormSubmissionStatuses(orgId: number): string {
            return `/organizations/${orgId}/form-submission-statuses`;
        },
        FormSubmissionStatus(orgId: number, statusId: number): string {
            return `/organizations/${orgId}/form-submission-statuses/${statusId}`;
        },
        FormSubmissionWord(orgId: number, submissionId: number, word: string): string {
            return `/organizations/${orgId}/form-submissions/${submissionId}/${word}`;
        },
        FormSettings(orgId: number, formId: number, word: string): string {
            return `/organizations/${orgId}/form-settings/${formId}/${word}`;
        },
        UnitForms(orgId: number, unitId: number): string {
            return `/organizations/${orgId}/forms/unit/${unitId}`;
        },
    };
}
