import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';
import { VendorCustomField } from './vendor-custom-field';

export class VendorCustomFieldValue
{

    id: number;
    vendorId: number;
    value: string = '';

    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    customField: VendorCustomField;

    constructor(customField: any) {
        if (customField.id) {
            this.id = customField.id;
        }
        if (customField.vendorId) {
            this.vendorId = customField.vendorId;
        }
        if (customField.value) {
            this.value = customField.value;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (customField.createdAt) {
            this.createdAt = moment.utc(customField.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (customField.updatedAt) {
            this.updatedAt = moment.utc(customField.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (customField.deletedAt) {
            this.deletedAt = moment.utc(customField.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (customField.vendorCustomField) {
            this.customField = new VendorCustomField(customField.vendorCustomField);
        }
    }
}
