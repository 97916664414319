import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ValidationMessageService } from '../../../services/validation-message.service';
import { ControlMessagesComponent } from './control-messages.component';

@NgModule({
    imports: [CommonModule],
    exports: [ControlMessagesComponent],
    declarations: [ControlMessagesComponent],
    providers: [
        ValidationMessageService,
    ],
})
export class ControlMessagesModule
{
}
