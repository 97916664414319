<h3 mat-dialog-title>Create New HOA</h3>

<mat-dialog-content class="small-content">
    <div class="form-group">
        <label class="mr-2">HOA Name *</label>
        <input #input
               [(ngModel)]="newHoaGroupName"
               class="form-control mr-2"
               placeholder="Name"
               required
               type="text"/>
    </div>
    <re-captcha
        *ngIf="environment.production"
        #recaptchaComponent
        (resolved)="resolved($event)"
        siteKey="{{siteKey}}"
    ></re-captcha>
</mat-dialog-content>
<mat-dialog-actions>
    <button (click)="close()" class="btn btn-outline-secondary">
        Cancel
    </button>
    <button (click)="simpleRegistration()"
            [disabled]="submitDisabled"
            class="btn btn-primary">
        <span *ngIf="!isRegistering">Create</span>
        <span *ngIf="isRegistering">Creating</span>
    </button>
</mat-dialog-actions>
