import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { SharedDirectivesModule } from '../../shared/directives/shared-directives.module';
import { NotificationsSidenavComponent } from './notifications-sidenav.component';
import { SidenavContentModule } from '../../sidenav/sidenav-content/sidenav-content.module';
import { NotificationsListModule } from '../notifications-list/notifications-list.module';
@NgModule({
    imports: [
        CommonModule,
        SidenavContentModule,
        NotificationsListModule,
        MatIconModule,
        MatLegacyButtonModule,
        MatLegacyMenuModule,
        SharedDirectivesModule,
    ],
    declarations: [
        NotificationsSidenavComponent,
    ],
    providers: [],
})
export class NotificationsSidenavModule
{
}
