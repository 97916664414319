import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Product } from './product';
import { Subscription } from './subscription';

export class SubscriptionProduct
{
    id: number;
    subscriptionId: number;
    productId: number;
    amountPerBasis: number;
    createdAt: Moment;
    updatedAt: Moment;

    subscription: Subscription;
    product: Product;

    constructor(subscriptionProduct: any) {
        if (subscriptionProduct.id) {
            this.id = subscriptionProduct.id;
        }
        if (subscriptionProduct.subscriptionId) {
            this.subscriptionId = subscriptionProduct.subscriptionId;
        }
        if (subscriptionProduct.productId) {
            this.productId = subscriptionProduct.productId;
        }
        if (subscriptionProduct.amountPerBasis) {
            this.amountPerBasis = subscriptionProduct.amountPerBasis;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (subscriptionProduct.createdAt) {
            this.createdAt = moment.utc(subscriptionProduct.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (subscriptionProduct.updatedAt) {
            this.updatedAt = moment.utc(subscriptionProduct.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (subscriptionProduct.subscription) {
            this.subscription = new Subscription(subscriptionProduct.subscription);
        }
        if (subscriptionProduct.product) {
            this.product = new Product(subscriptionProduct.product);
        }
    }
}
