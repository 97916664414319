import { FormSubmissionStatus } from './forms/form-submission-status';

export class ViolationStatus
{
    id: number;
    organizationId: number;
    sortOrder: number;
    title: string;
    color: string;
    bgColor: string;

    constructor(request: any) {
        this.id = request.id || 0;
        this.sortOrder = request.sortOrder;
        this.title = request.title;
        this.color = request.color;
        this.bgColor = request.color + '40';

        // handle default color (#ffffff) if they never changed it
        if (this.color === '#ffffff' || this.color === '#FFFFFF' || this.color === '#212529') {
            this.color = '#212529';
            this.bgColor = '#ffffff';
        }

        if (request.organizationId) {
            this.organizationId = request.organizationId;
        }
    }
}

export class ViolationStatusRollup
{
    data: ViolationStatus[];
    counts: { title: string; violationsCount: number; }[];

    constructor(request: any) {
        if (request.data) {
            this.data = request.data.map((s: any) => new ViolationStatus(s));
        }

        if (request.counts) {
            this.counts = request.counts.map((c: any) => {
                return {
                    title: c.title,
                    violationsCount: c.violationsCount || 0,
                };
            });
        }
    }
}
