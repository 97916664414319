import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PropertyManagementCompany } from '../../models/entities/property-management-company';
import { Organization } from '../../models/entities/organization';

@Injectable({
    providedIn: 'root',
})
export class PropertyManagementCompanyDataService
{
    private pmcIdParam: number = null;
    private readonly pmcSubject$: BehaviorSubject<PropertyManagementCompany> = new BehaviorSubject<PropertyManagementCompany>(null);

    public get pmc$(): Observable<PropertyManagementCompany> {
        return this.pmcSubject$.asObservable();
    }

    public get pmcId(): number {
        return this.pmcSubject$.getValue()?.id || this.pmcIdParam;
    }

    public set pmcId(value: number) {
        this.pmcIdParam = value;
    }

    public get pmcOrganizations(): Organization[] {
        return this.pmcSubject$.getValue()?.organizations;
    }

    public switch(pmc: PropertyManagementCompany): PropertyManagementCompany {
        this.pmcSubject$.next(pmc);

        return this.pmcSubject$.getValue();
    }

    public clear() {
        this.pmcSubject$.next(null);
    }
}
