import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { PaymentAttempt } from './payment-attempt';
import { Transaction } from './transactions/transaction';
import Moment = moment.Moment;

export class TransactionPaymentAttempt
{
    id: number;
    transactionId: number;
    paymentAttemptId: number;
    createdAt: Moment;
    updatedAt: Moment;

    paymentAttempt: PaymentAttempt;
    transaction: Transaction;

    constructor(transactionPaymentAttempt: any) {
        if (transactionPaymentAttempt.id) {
            this.id = transactionPaymentAttempt.id;
        }
        if (transactionPaymentAttempt.transactionId) {
            this.transactionId = transactionPaymentAttempt.transactionId;
        }
        if (transactionPaymentAttempt.paymentAttemptId) {
            this.paymentAttemptId = transactionPaymentAttempt.paymentAttemptId;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (transactionPaymentAttempt.createdAt) {
            this.createdAt = moment.utc(transactionPaymentAttempt.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (transactionPaymentAttempt.updatedAt) {
            this.updatedAt = moment.utc(transactionPaymentAttempt.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (transactionPaymentAttempt.paymentAttempt) {
            this.paymentAttempt = new PaymentAttempt(transactionPaymentAttempt.paymentAttempt);
        }
        if (transactionPaymentAttempt.transaction) {
            this.transaction = new Transaction(transactionPaymentAttempt.transaction);
        }
    }
}
