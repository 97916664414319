export class TransactionRuleCondition
{
    id: number;
    field: string;
    comparison: string;
    value: string;

    friendly: string = '';

    constructor(req: any) {
        this.id = req.id;
        this.field = req.field;
        this.comparison = req.comparison;
        this.value = req.value;

        switch (this.comparison) {
            case 'contains':
                this.friendly = 'containing "' + this.value + '"';
                break;
            case 'not contains':
                this.friendly = 'not containing "' + this.value + '"';
                break;
            case 'exactly':
                this.friendly = 'exactly equal to "' + this.value + '"';
                break;
            case 'eq':
                this.friendly = 'equals $' + (parseInt(this.value, 10) / 100).toFixed(2);
                break;
            case 'ne':
                this.friendly = 'is not equal to $' + (parseInt(this.value, 10) / 100).toFixed(2);
                break;
            case 'gt':
                this.friendly = 'is greater than $' + (parseInt(this.value, 10) / 100).toFixed(2);
                break;
            case 'lt':
                this.friendly = 'is less than $' + (parseInt(this.value, 10) / 100).toFixed(2);
                break;
        }
    }
}
