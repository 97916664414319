import { Address } from './address';

export class AuxiliaryContact
{
    id: number;
    type: string;

    name: string;
    email: string;
    phone: string;
    address: Address;

    constructor(contact: any) {
        if (contact.id) {
            this.id = contact.id;
        }

        this.type = contact.contactableType || 'unit';

        if (contact.name) {
            this.name = contact.name;
        }

        this.email = contact.email;

        this.phone = contact.phone;

        if (contact.address) {
            this.address = new Address(contact.address);
        }
    }
}
