import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';

import { OffClickModule } from '../../../../directives/off-click/off-click.module';
import { SingleUnitSelectorComponent } from './single-unit-selector.component';

@NgModule({
    imports: [
        // Shared
        SharedModule,
        // Dependencies
        OffClickModule,
    ],
    exports: [SingleUnitSelectorComponent],
    declarations: [SingleUnitSelectorComponent],
    providers: [],
})
export class SingleUnitSelectorModule
{
}
