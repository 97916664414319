import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from '../../../services/modal.service';
import { ComponentInjectorComponent } from '../component-injector/component-injector.component';
import { starAnimations } from '../../../../@pay/animations';

@Component({
    selector: 'lf-modal',
    styleUrls: ['./modal.scss'],
    templateUrl: './modal.component.html',
    animations: starAnimations,
})
export class ModalComponent implements OnDestroy, OnInit, OnChanges
{
    @Input()
    public type: any;
    @Input('inputs')
    public inputs: {} = null;
    @Input()
    public overflows: boolean = false;
    @Input()
    public title: string = '';
    @Input()
    public show: boolean = false;
    @Input()
    public closable: boolean = true;
    @Input()
    public closableWithEscape: boolean = true;
    @Input()
    public closeOnSave: boolean = true;
    @Input()
    public ifAddPad: boolean = true;
    @Input()
    public isSmallWidth: boolean = false;
    @Output()
    public showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    public saved: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    public closed: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('injector', {read: ComponentInjectorComponent})
    public injector: ComponentInjectorComponent;

    private _modalSubscriptions: Subscription[] = [];

    constructor(
            private _modalService: ModalService,
            private _renderer: Renderer2
    ) {
    }

    ngOnInit() {
        if (this.type) {
            const self = this;
            this._modalSubscriptions.push(
                    this._modalService.modalSaved.subscribe(
                            (object: any) => {
                                if (object.component === self.type) {
                                    self.saved.emit(object.object);
                                    if (this.closeOnSave) {
                                        self.close();
                                    }
                                }
                            },
                    ),
            );

            this._modalSubscriptions.push(
                    this._modalService.modalClosed.subscribe(
                            (object: any) => {
                                if (object.component === self.type) {
                                    self.close();
                                }
                            },
                    ),
            );
        }
    }

    ngOnDestroy() {
        this._renderer.removeClass(document.body, 'modal-active');

        if (this._modalSubscriptions && this._modalSubscriptions.length) {
            this._modalSubscriptions.forEach(function (subscription) {
                if (subscription && typeof subscription.unsubscribe !== 'undefined') {
                    subscription.unsubscribe();
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('show' in changes) {
            if (this.show) {
                this._renderer.addClass(document.body, 'modal-active');
            } else {
                this._renderer.removeClass(document.body, 'modal-active');
            }
        }
    }

    /**
     * Closes the modal.
     */
    public close(): void {
        this.show = false;
        this.showChange.emit(this.show);
        this.closed.emit(null);
    }

    /**
     * Scans keydown events. Closes modal on escape key being pressed.
     * @param $event
     */
    @HostListener('document:keydown', ['$event'])
    public keydown($event: any): void {
        // Close modal if escape was pressed.
        if (this.closableWithEscape && this.closable && $event.keyCode === 27) {
            this.close();
        }
    }
}
