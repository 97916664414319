import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Organization } from './organization';
import { SurveyQuestion } from './survey-question';
import { SurveyResponse } from './survey-response';
import { SurveyParticipant } from 'app/models/entities/survey-participant';
import Moment = moment.Moment;

export class Survey
{
    id: number;
    organizationId: number;
    title: string;
    description: string;
    closed: boolean;
    anonymous: boolean;
    responsesCount: number;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    images: string[];

    organization: Organization;
    questions: SurveyQuestion[] = [];
    responses: SurveyResponse[] = [];
    participants: SurveyParticipant[];
    participantsResponded: number = 0;

    constructor(survey: any) {
        if (survey.id) {
            this.id = survey.id;
        }
        if (survey.organizationId) {
            this.organizationId = survey.organizationId;
        }
        if (survey.title) {
            this.title = survey.title;
        }
        if (survey.description) {
            this.description = survey.description;
        }
        this.closed = survey.closed || false;
        this.anonymous = survey.anonymous || false;

        const timezone = LegFiJwtService.getTimezone();
        if (survey.createdAt) {
            this.createdAt = moment.utc(survey.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (survey.updatedAt) {
            this.updatedAt = moment.utc(survey.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (survey.deletedAt) {
            this.deletedAt = moment.utc(survey.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (survey.images) {
            this.images = survey.images;
        }
        if (survey.organization) {
            this.organization = new Organization(survey.organization);
        }
        if (survey.questions) {
            this.questions = survey.questions.map((question: any) => {
                return new SurveyQuestion(question);
            });
        }
        if (survey.responses) {
            this.responses = survey.responses.map((response: any) => {
                return new SurveyResponse(response);
            });
        }
        if (survey.participants) {
            const respondantsByResponses = this.responses.map(r => r.membershipId);
            this.participants = survey.participants.map((participant: any) => {
                if (respondantsByResponses.includes(participant.membershipId)) {
                    participant.responded = true;
                }
                return new SurveyParticipant(participant);
            });
            const respondantsByParticipants = this.participants.filter(p => p.responded).map(p => p.membershipId);

            // Dedupe values in the array - https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
            const allRespondants = Array.from(new Set(respondantsByResponses.concat(respondantsByParticipants)));
            this.participantsResponded = allRespondants.length;
        }
        if (survey.responsesCount) {
            this.responsesCount = survey.responsesCount;
        }
    }
}
