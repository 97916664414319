<div [class.mt-5]="total < 2">
    <app-button *ngIf="total > 1"
                type="link"
                class="justify-content-end"
                iconPosition="after"
                [icon]="'navigate_next'"
                [iconClasses]="['w-auto']"
                [buttonClasses]="['mr-0', 'text-font-smaller']"
                (onClick)="next()">{{ (index + 1) + ' of ' + total }}</app-button>
</div>

<ng-container *ngIf="(loadedAdvertisement$ | async) as ad">
    <div class="payhoa-side-advertisement animated fadeInLeft"
         [class.pt-1]="ad.isDismissible"
         [class.pt-4]="!ad.isDismissible"
         [style.background-color]="ad.color">
        <app-button *ngIf="ad.isDismissible"
                    type="link"
                    class="ml-auto mr-0"
                    [icon]="'close'"
                    [buttonClasses]="['inverse', 'mr-0', 'px-2']"
                    (onClick)="dismissAd()"></app-button>
        <div class="side-ad-image">
            <img alt="{{ ad.name }}" [src]="ad.icon"/>
        </div>
        <p class="font-weight-medium text-center text-white mb-0 px-3 py-1">{{ ad.tagline }}</p>
        <app-button class="m-3"
                    [buttonClasses]="['mr-0']"
                    (onClick)="openAd()">{{ ad.cta.text }}</app-button>
    </div>
</ng-container>
