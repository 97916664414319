/**
 * Storage for options added to a form question e.g. options added to a select/dropdown
 */
export class FormQuestionOption
{
    /** db id */
    id: number = 0;
    /** Unique value of this item in the list of options, gets stored when user answers */
    value: number = 0;
    /** Which question is this tied to */
    formQuestionId: number = 0;
    /** The text that displays when the person views the form itself */
    label: string = '';
    /** Whether this option has been deleted (but must persist for versioning) */
    isEnabled?: boolean;

    constructor(request: any) {
        this.id = request.id || 0;
        this.value = request.value || 0;
        this.formQuestionId = request.formQuestionId || 0;
        this.label = request.label || '';
        this.isEnabled = request.isEnabled;
    }
}

/**
 * Class to render template options of a formly question.
 * These fields are specific to the formly npm package, not identical to db model.
 */
export class FormlyOption
{
    value: number;
    label: string;

    constructor(request: FormQuestionOption) {
        this.value = request.value || 0;
        this.label = request.label || '';
    }
}
