import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IfOnDomDirective } from './if-on-dom.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [IfOnDomDirective],
    exports: [IfOnDomDirective],
})
export class IfOnDomModule
{
}
