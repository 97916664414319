import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';
import { SharedModule } from '../shared.module';
import { RichTextEditorComponent } from './rich-text-editor.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        EditorModule,
        SharedModule,
        FileUploadModule,
    ],
    declarations: [RichTextEditorComponent],
    exports: [RichTextEditorComponent],
    providers: [
    ],
})
export class RichTextEditorModule
{
}
