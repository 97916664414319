<ng-template #tagIcon let-color="color">
    <span *ngIf="!isRefreshingColor"
          class="fa-stack mr-1">
        <i class="fa fa-circle fa-stack-2x"
           [style.color]="color"></i>
        <i class="fa fa-circle-thin fa-stack-2x"
           [adjustColor]="color"
           [adjustment]="adjustColorValues.BORDER"></i>
        <i class="fa fa-stack-1x fa-tag"
           [adjustColor]="color"
           [adjustment]="adjustColorValues.ICON"></i>
    </span>
</ng-template>

<h3 mat-dialog-title>{{ dialogTitle }}</h3>

<mat-dialog-content class="small-content"
                    [class.pt-0]="data.sharedItems !== undefined">
    <div *ngIf="data.sharedItems !== undefined"
         class="py-3 text-left shared-toggle">
        <mat-slide-toggle [checked]="isDisplayingSharedItems"
                          (change)="toggleSharedItems($event.checked)">
            <label>
                {{ 'Show common tags shared across selected ' + data.taggableType.toLowerCase() }}s
                <i class="fa fa-info-circle ml-2" [matTooltip]="sharedTagsTooltip"></i>
            </label>
        </mat-slide-toggle>

        <hr/>
    </div>

    <div class="tags-input"
         [class.tags-list]="isReadonly">
        <tag-input #dialogTagInput
                   [theme]="'minimal'"
                   [addOnBlur]="true"
                   [editable]="!isReadonly"
                   [removable]="!isReadonly"
                   [hideForm]="isReadonly"
                   [placeholder]="data.placeholder"
                   [secondaryPlaceholder]="data.secondaryPlaceholder"
                   [onAdding]="onAdding"
                   [(ngModel)]="items">
            <ng-template let-item="item" let-index="index">
                <div class="tag__body"
                     [class.tag__readonly]="item.readonly">
                    <ng-container *ngTemplateOutlet="tagIcon; context:{ color: item.taggableColor }">
                    </ng-container>
                    <span class="tag__text pr-2">{{ item.display }}</span>
                    <ng-container *ngIf="!isReadonly && !item.readonly">
                        <span class="tag__delete fa-stack"
                              [attr.tabindex]="-1"
                              (click)="dialogTagInput.removeItem(item, index); refreshColors()">
                            <i class="fa fa-circle fa-stack-2x"></i>
                            <i class="fa fa-times fa-stack-1x"></i>
                        </span>
                    </ng-container>
                </div>
            </ng-template>
            <tag-input-dropdown [autocompleteObservable]="searchTags"
                                [minimumTextLength]="0"
                                [keepOpen]="true"
                                [showDropdownIfEmpty]="true"
                                [dynamicUpdate]="false"
                                [appendToBody]="false">
                <ng-template let-index="index" let-item="item">
                    <div class="ng2-tag-input__dropdown-item">
                        <ng-container *ngTemplateOutlet="tagIcon; context:{ color: item.taggableColor }"></ng-container>
                        <span class="tag__text mr-1">{{ item.display }}</span>
                    </div>
                </ng-template>
            </tag-input-dropdown>
        </tag-input>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <app-button [isDisabled]="isSaving" mat-dialog-close>{{ isReadonly ? 'Close' : 'Cancel' }}</app-button>
    <div class="btn-group btn-save-container">
        <app-spinner-button *ngIf="isSaving">{{ 'Saving' }}</app-spinner-button>
        <app-button type="primary"
                    mobileText="{{ 'Save' }}"
                    [buttonClasses]="['mr-0']"
                    [isDisabled]="isSaving"
                    (onClick)="onSubmit()">
            {{ 'Update' }}
        </app-button>
    </div>
</mat-dialog-actions>
