import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JwtLegFiClaims } from '../../../../services/auth/jwt-legfi-claims.model';
import { Router } from '@angular/router';
import { NotificationsService } from '../../../../services/notifications/notifications-service';
import { GrowlerService } from '../../../../services/growler.service';
import { DatabaseNotification } from '../../../../models/entities/database-notification';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ConfirmActionDialogComponent, ConfirmActionDialogInput } from '../../../app-layout/shared/confirm-action-dialog/confirm-action-dialog.component';
import { MatDialogSizes } from '../../../../enums/mat-dialog-sizes.enum';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { I18nPluralPipe } from '@angular/common';
import { HostListenerService } from '../../../../services/host-listener.service';

export enum NotificationContext {
    PMC = 'pmc',
    ORGANIZATION = 'org',
}

@UntilDestroy()
@Component({
    selector: 'app-notifications-list',
    templateUrl: './notifications-list.component.html',
    styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit
{
    @Input() context = NotificationContext.PMC;
    @Input() isDashboardCard = false;

    @Output() notificationNavigation: EventEmitter<void> = new EventEmitter<void>();
    @Output() notificationsLoaded: EventEmitter<boolean> = new EventEmitter<boolean>(false);
    @Output() workingStateUpdated: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    loadingInitialData = false;
    isDeleting = false;
    jwt: JwtLegFiClaims;

    readonly notificationsMapping: { [prop: string]: string; } = {
        '=0': 'No notifications',
        '=1': '1 notification',
        'other': '# notifications'
    };
    readonly notificationsZeroMappingOverride: { [prop: string]: string; } = {
        '=0': 'All notifications',
    };

    private readonly showEmptyState$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
            private _router: Router,
            private _notificationService: NotificationsService,
            private _growler: GrowlerService,
            private _host: HostListenerService,
            private _dialog: MatDialog,
            private _i18n: I18nPluralPipe,
    ) {
        this._notificationService.notifications$.pipe(untilDestroyed(this)).subscribe({
            next: (notifications) => {
                this.showEmptyState$.next(notifications.length === 0);
            },
        });
    }

    get tabletMode$() {
        return this._host.isTabletMode();
    }

    get notifications$() {
        return this._notificationService.notifications$.asObservable();
    }

    get showJumbotron$() {
        return this.showEmptyState$.asObservable();
    }

    ngOnInit(): void {
        this.loadingInitialData = this._notificationService.notifications$.getValue().length === 0;
        this._notificationService.getNotifications().pipe(
                finalize(() => {
                    // only update flag...list data is set from notification service subject
                    this.loadingInitialData = false;
                    this.notificationsLoaded.emit(true);
                }),
                untilDestroyed(this),
        ).subscribe({
            error: () => {
                this._growler.oops('Unable to fetch notifications');
            },
        });
    }

    handleNotificationClick(notification: DatabaseNotification) {
        if (!notification.readAt) {
            return this.markAsRead([notification.id], true);
        }

        if (this.context === NotificationContext.PMC && notification.data.hasOwnProperty('pmcLink')) {
            this._router.navigate([notification.data.pmcLink]).then(() => this.notificationNavigation.emit());
        } else if (this.context === NotificationContext.ORGANIZATION && notification.data.hasOwnProperty('orgLink')) {
            this._router.navigate([notification.data.orgLink]).then(() => this.notificationNavigation.emit());
        }
    }

    markAsRead(notificationIds: string[], routeAfterSave = false) {
        const mapping = { ...this.notificationsMapping, ...this.notificationsZeroMappingOverride };
        const context = this._i18n.transform(notificationIds.length, mapping);

        this.workingStateUpdated.emit(true);
        this._notificationService.markNotificationAsRead(notificationIds).pipe(
                finalize(() => this.workingStateUpdated.emit(false)),
                untilDestroyed(this),
        ).subscribe({
            next: (notifications) => {
                if (routeAfterSave) {
                    this.handleNotificationClick(notifications[0]);
                } else {
                    this._growler.success('Success', `${context} marked as read`);
                }
            },
            error: () => this._growler.oops(`Unable to mark ${context} as read`),
        });
    }

    deleteNotifications(notificationIds: string[]) {
        const mapping = { ...this.notificationsMapping, ...this.notificationsZeroMappingOverride };
        const context = this._i18n.transform(notificationIds.length, mapping).toLowerCase();

        const data: ConfirmActionDialogInput = {
            title: `Delete ${context}?`,
            content: 'This action cannot be undone.',
            confirmButton: `Yes, delete ${context}`,
            cancelButton: `No, cancel delete`,
            newStyles: true,
        };
        this._dialog.open(ConfirmActionDialogComponent, {
            panelClass: [
                'pmc-layout-dialog',
                'confirmation-layout',
            ],
            width: MatDialogSizes.SM,
            autoFocus: 'dialog',
            data,
        }).afterClosed().subscribe((res) => {
            if (res?.success) {
                this.workingStateUpdated.emit(true);
                this.isDeleting = true;
                this._notificationService.deleteNotifications(notificationIds).pipe(
                        finalize(() => {
                            this.workingStateUpdated.emit(false);
                            this.isDeleting = false;
                        }),
                        untilDestroyed(this),
                ).subscribe({
                    next: () => this._growler.success('Success', `${context} deleted`),
                    error: () => this._growler.oops(`Unable to delete ${context}`),
                });
            }
        });
    }
}
