import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';

@Injectable()
export class ForwardToFormsGuard implements CanActivate
{
    constructor(private _router: Router) {
    }

    canActivate() {
        // check if this user has forms permission
        const hasFormReadAccess = LegFiJwtService.doesUserHaveModulePermission(
                'forms',
                false,
        );

        if (hasFormReadAccess) {
            this._router.navigate(['/app/form-notifications-permissions']);
        } else {
            this._router.navigate(['/app/owner/dashboard']);
        }
        return false;
    }
}
