import { NgModule } from '@angular/core';
import { AdminLayoutComponent } from './admin-layout.component';
import { adminLayoutRouting } from './admin-layout.routing';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HeaderBarModule } from '../shared/header-bar/header-bar.module';
import { PageMenuModule } from '../app-layout/shared/page-menu/page-menu.module';

@NgModule({
    imports: [
        adminLayoutRouting,
        MatSidenavModule,
        HeaderBarModule,
        PageMenuModule,
    ],
    declarations: [
        AdminLayoutComponent,
    ],
})
export class AdminLayoutModule
{
}
