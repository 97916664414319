import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Organization } from './organization';
import { UnifiedBankAccount } from './unified-bank-account';
import { BankReconciliationTransaction } from './bank-reconciliation-transaction';
import Moment = moment.Moment;

export class BankReconciliation
{
    id: number;
    organizationId: number;
    unifiedBankAccountId: number;
    startDate: Moment;
    startingBalance: number;
    endDate: Moment;
    endingBalance: number;
    totalDeposits: number;
    totalPayments: number;
    completedAt: Moment;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    unifiedBankAccount: UnifiedBankAccount;
    organization: Organization;
    reconciliationTransactions: BankReconciliationTransaction[] = [];


    constructor(reconciliation: any) {
        this.id = reconciliation.id || null;
        this.organizationId = reconciliation.organizationId || null;
        this.unifiedBankAccountId = reconciliation.unifiedBankAccountId || null;
        this.startingBalance = reconciliation.startingBalance || 0;
        this.endingBalance = reconciliation.endingBalance || 0;
        this.totalDeposits = reconciliation.totalDeposits || 0;
        this.totalPayments = reconciliation.totalPayments || 0;

        const timezone = LegFiJwtService.getTimezone();
        if (reconciliation.startDate) {
            this.startDate = moment.utc(reconciliation.startDate, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (reconciliation.endDate) {
            this.endDate = moment.utc(reconciliation.endDate, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (reconciliation.completedAt) {
            this.completedAt = moment.utc(reconciliation.completedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (reconciliation.createdAt) {
            this.createdAt = moment.utc(reconciliation.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (reconciliation.updatedAt) {
            this.updatedAt = moment.utc(reconciliation.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (reconciliation.deletedAt) {
            this.deletedAt = moment.utc(reconciliation.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (reconciliation.unifiedBankAccount) {
            this.unifiedBankAccount = new UnifiedBankAccount(reconciliation.unifiedBankAccount);
        }
        if (reconciliation.organization) {
            this.organization = new Organization(reconciliation.organization);
        }
        if (reconciliation.reconciliationTransactions) {
            this.reconciliationTransactions = reconciliation.reconciliationTransactions.map(item => new BankReconciliationTransaction(item));
        }
    }

    get payments(): BankReconciliationTransaction[] {
        return this.reconciliationTransactions.filter(t => t.amount > 0);
    }

    get deposits(): BankReconciliationTransaction[] {
        return this.reconciliationTransactions.filter(t => t.amount < 0);
    }

    get totalPaymentsLive(): number {
        return this.payments.map(t => t.amount)
                .reduce((carry, value) => carry + value, 0);
    }

    get totalDepositsLive(): number {
        return this.deposits.map(t => Math.abs(t.amount))
                .reduce((carry, value) => carry + value, 0);
    }

    get clearedBalance(): number {
        return this.startingBalance - this.totalPaymentsLive + Math.abs(this.totalDepositsLive);
    }

    get difference(): number {
        return this.endingBalance - this.clearedBalance;
    }
}
