import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { Routes } from '../../config/routes';
import { CustomUnitFieldValue } from '../../models/entities/custom-unit-field-value';
import { Owner } from '../../models/entities/owner';
import { Unit, UnitsResponse } from '../../models/entities/unit';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { GtmService } from '../gtm.service';

@Injectable({
    providedIn: 'root',
})
export class UnitsService
{
    public deleteUnit$ = new Subject<Unit>();
    public moveInUser$ = new Subject<Unit>();
    public moveOutUser$ = new Subject<{
        owner: Owner,
        unit: Unit
    }>();
    public openNewRecurringChargeModal$ = new Subject<Unit>();
    public recordPayment$ = new Subject<Unit>();
    public openNewChargeModal$ = new Subject<Unit>();
    public showChangeOwnership$ = new Subject<Unit>();
    public reloadUnitList$ = new Subject<boolean>();

    public $units = new BehaviorSubject<Unit[]>([]);

    private _units: Unit[] = [];
    private _unitIdsForMember: number[] = [];


    constructor(
            private _http: ApplicationHttpClient,
            private _gtm: GtmService,
    ) {
        this._units = [];
    }

    isUnitOwner(unitId: number, jwt: JwtLegFiClaims): Observable<boolean> {
        return this.getMemberUnits(jwt.orgId, jwt.memberId)
                .pipe(
                        map((units: Unit[]) => {
                            // Check if the unitId matches either the jwt value, or units belonging to the owner
                            return unitId === jwt.unitId || units.some(unit => unit.id === unitId);
                        }),
                );
    }

    public downloadFile(url: string, contentType: string) {
        const newHeaders = new HttpHeaders({
            'Content-Type': contentType,
        });

        const options: Object = {
            headers: newHeaders,
            responseType: 'arraybuffer',
        };

        return this._http.get(url, options);
    }


    public refreshUnitBalance(unitId: number): Observable<number> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationUnitBalance(jwt.orgId, unitId));

        return this._http.post(url, JSON.stringify({})).pipe(map((res: any) => res.balance));
    }

    /**
     * Return list of all units for an org
     * @param {number} organizationId
     * @param withs
     * @returns {Observable<Object>}
     */
    public getOrganizationUnitsSimple(organizationId: number, withs: string[] = []): Observable<Unit[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationUnitsSimple(organizationId || jwt.orgId) + (withs && withs.length
                ? '?with[]=' + withs.join('&with[]=')
                : ''));

        return this._http.get(url).pipe(map((response: Object[]) => {
            this._units = response
                    .filter((unit) => {
                        return unit !== null;
                    })
                    .map((unit) => {
                        return new Unit(unit);
                    });

            return this._units;
        }));
    }

    /**
     *
     * @param orgId
     * @param search
     * @param sortColumn
     * @param sortDirection
     * @param pageIndex
     * @param perPage
     * @param options
     */
    public getUnits(
            orgId: number,
            search: string = '',
            sortColumn: string = 'name',
            sortDirection: string = 'desc',
            pageIndex: number = 1,
            perPage: number = 100,
            options: {
                unitTags?: string[],
                withoutUnitTags?: string[],
                ownerTags?: string[],
                withoutOwnerTags?: string[],
                showDeleted?: boolean,
                searchOr?: boolean,
                searchBy?: string[],
            } = {},
    ): Observable<UnitsResponse> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        let url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationUnits(orgId || jwt.orgId));

        const params = [];
        params.push('page=' + pageIndex);
        params.push('search=' + encodeURIComponent(search));
        params.push('column=' + sortColumn);
        params.push('direction=' + sortDirection);
        params.push('perPage=' + perPage);
        if ('unitTags' in options) {
            params.push('unitTags=' + options.unitTags.map(t => encodeURIComponent(t)).join(','));
        }
        if ('withoutUnitTags' in options) {
            params.push('withoutUnitTags=' + options.withoutUnitTags.map(t => encodeURIComponent(t)).join(','));
        }
        if ('ownerTags' in options) {
            params.push('ownerTags=' + options.ownerTags.map(t => encodeURIComponent(t)).join(','));
        }
        if ('withoutOwnerTags' in options) {
            params.push('withoutOwnerTags=' + options.withoutOwnerTags.map(t => encodeURIComponent(t)).join(','));
        }
        if ('searchOr' in options) {
            params.push('searchOr=1');
        }
        if ('showDeleted' in options && options.showDeleted) {
            params.push('showDeleted=1');
        }
        if ('searchBy' in options && options.searchBy) {
            params.push('searchBy=' + options.searchBy.join(','));
        }

        url = url + '?' + params.join('&');

        return this._http.get(url)
                .pipe(map((resp) => {
                    const unitsResponse: UnitsResponse = new UnitsResponse(resp);
                    unitsResponse.data.forEach((unit) => {
                        this._addUnit(unit);
                    });
                    this.$units.next(this._units);
                    return unitsResponse;
                }));
    }

    /**
     * Return list of all units for an org
     * @param {number} orgId
     * @param {string} search
     * @param {string} sortColumn
     * @param {string} sortDirection
     * @param {number} pageIndex
     * @param {number} perPage
     * @param {tags: string[]; unitIds: number[]; columns: string[] } options
     * @returns {Observable<Object>}
     */
    public getOrganizationUnitsListCsv(
            orgId: number,
            search: string = '',
            sortColumn: string = 'name',
            sortDirection: string = 'desc',
            pageIndex: number = 1,
            perPage: number = 100,
            options: {
                unitTags?: string[],
                withoutUnitTags?: string[],
                ownerTags?: string[],
                withoutOwnerTags?: string[],
                unitIds: number[],
                columns: string[],
                showDeleted?: boolean,
            } = {
                unitIds: [],
                columns: [],
                showDeleted: false,
            },
    ): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        let url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationUnits(orgId || jwt.orgId));
        const params = [];
        params.push('page=' + pageIndex);
        params.push('search=' + encodeURIComponent(search));
        params.push('column=' + sortColumn);
        params.push('direction=' + sortDirection);
        params.push('perPage=' + perPage);
        if ('unitTags' in options) {
            params.push('unitTags=' + options.unitTags.map(t => encodeURIComponent(t)).join(','));
        }
        if ('withoutUnitTags' in options) {
            params.push('withoutUnitTags=' + options.withoutUnitTags.map(t => encodeURIComponent(t)).join(','));
        }
        if ('ownerTags' in options) {
            params.push('ownerTags=' + options.ownerTags.map(t => encodeURIComponent(t)).join(','));
        }
        if ('withoutOwnerTags' in options) {
            params.push('withoutOwnerTags=' + options.withoutOwnerTags.map(t => encodeURIComponent(t)).join(','));
        }
        params.push('columns=' + options.columns.map(t => encodeURIComponent(t)).join(','));
        params.push('unitIds=' + options.unitIds.join(','));
        params.push('showDeleted=' + (options.showDeleted ? 1 : 0));
        params.push('csv=1');

        url = url + '?' + params.join('&');

        return this.downloadFile(url, 'text/csv');
    }

    /**
     * @param {number} organizationId
     * @param {number} memberId
     * @returns {Observable<Unit[]>}
     */
    public getMemberUnits(organizationId: number, memberId: number): Observable<Unit[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.MemberUnits(organizationId, memberId),
        );

        return this._http.get(url).pipe(map((response: Object[]) => {
            this._unitIdsForMember = [];
            return response.map((res) => {
                const unit = new Unit(res);
                this._unitIdsForMember.push(unit.id);
                return unit;
            });
        }));
    }

    /**
     * @param {number} id
     * @param {string[]} withs
     * @returns {Observable<Object>}
     */
    public getUnit(id: number, withs: string[] = []): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Unit(id) + (withs && withs.length ? '?with[]=' + withs.join('&with[]=') : ''),
        );

        return this._http.get(url).pipe(map((response: Object) => new Unit(response)));
    }

    /**
     * @param {number} id
     * @param {string[]} withs
     * @returns {Observable<Object>}
     */

    /**
     * Create Unit
     * @param {Object} requestBody
     * @returns {Observable<Object>}
     */
    public createUnit(requestBody: any): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(
                Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationUnits(jwt.orgId)),
                JSON.stringify(requestBody),
        ).pipe(
                map((res) => {
                    this._gtm.addUnit({
                        orgId: jwt.orgId,
                        unitName: requestBody.unit.title,
                        addedBy: jwt.id,
                    });
                    return res;
                }),
        );
    }

    /**
     * @param {number} orgId
     * @param {number} unitId
     * @param {Object} requestBody
     * @returns {Observable<Object>}
     */
    public updateUnit(orgId: number, unitId: number, requestBody: object): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationUnit(orgId, unitId));

        return this._http.put(url, JSON.stringify(requestBody));
    }

    /**
     * @param {number} orgId
     * @param {number} unitId
     * @returns {Observable<Object>}
     */
    public restoreUnit(orgId: number, unitId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.RestoreUnit());
        const requestBody = {orgId: orgId, unitId: unitId};
        return this._http.post(url, JSON.stringify(requestBody));
    }

    /**
     * @param {number} unitId
     * @returns {Observable<Object>}
     */
    public deleteUnit(unitId: number): Observable<Object> {
        return this.deleteUnits([unitId]);
    }

    /**
     * @param {number[]} unitIds
     * @returns {Observable<Object>}
     */
    public deleteUnits(unitIds: number[]): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.delete(
                Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationUnits(jwt.orgId) + '?unitIds=' + unitIds.join(',')),
        );
    }

    /**
     * @param {number} orgId
     * @param {number} unitId
     * @param {number} imageId
     * @param {Object} requestBody
     * @returns {Observable<Object>}
     */
    public updateUnitImageCaption(
            orgId: number,
            unitId: number,
            imageId: number,
            requestBody: object,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrganizationUnitImageCaption(orgId, unitId, imageId),
        );

        return this._http.put(url, JSON.stringify(requestBody));
    }

    /**
     * @param {number} unitId
     * @param {number} imageId
     * @returns {Observable<Object>}
     */
    public deleteUnitImage(unitId: number, imageId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationUnitImage(jwt.orgId, unitId, imageId));

        return this._http.delete(url);
    }

    /**
     * @param {number} unitId
     * @param {Object} requestBody
     * @returns {Observable<Object>}
     */
    public moveMembersIntoUnit(unitId: number, requestBody: object): Observable<Owner[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.UnitMoveMembersIn(unitId));

        return this._http.post(url, JSON.stringify(requestBody))
                .pipe(map((res: Object[]) => {
                    return res.map(owner => new Owner(owner));
                }));
    }

    /**
     * @param {number} unitId
     * @param {{owners:Owner[], forgive:string, pause:boolean}} requestBody
     * @returns {Observable<Object>}
     */
    public moveOwnersOutOfUnit(
            unitId: number,
            requestBody: { owners: number[] },
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(
                Routes.MakeLegFiCoreUrl(Routes.LegFiCore.UnitMoveMembersOut(unitId)),
                JSON.stringify(requestBody),
        );
    }

    /**
     * @param {Object} requestBody
     * @returns {Observable<Object>}
     */
    public bulkImportUnits(requestBody: object): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(
                Routes.MakeLegFiCoreUrl(Routes.LegFiCore.UnitBulkImport(jwt.orgId)),
                JSON.stringify(requestBody),
        );
    }

    /**
     * @param {string} batchId
     * @returns {Observable<Object>}
     */
    public getImportResults(batchId: string): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.UnitImportResults(jwt.orgId, batchId));

        return this._http.get(url);
    }

    public getCustomFieldValues(unitId: number): Observable<CustomUnitFieldValue[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Unit(unitId) + '/custom-fields');

        return this._http
                .get(url).pipe(
                        map((response: Object[]) => {
                            return response.map(v => {
                                return new CustomUnitFieldValue(v);
                            });
                        }));
    }

    /**
     * @param {number} unitId
     * @param {number} customFieldId
     * @param {Object} requestBody
     * @returns {Observable<Object>}
     */
    public updateUnitCustomField(
            unitId: number,
            customFieldId: number,
            requestBody: CustomUnitFieldValue,
    ): Observable<CustomUnitFieldValue> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http
                .post(
                        Routes.MakeLegFiCoreUrl(
                                Routes.LegFiCore.Unit(unitId) +
                                '/custom-fields/' +
                                '?customUnitField[]={id:' +
                                customFieldId +
                                '}&value=' +
                                requestBody.value,
                        ),
                        JSON.stringify(requestBody),
                ).pipe(
                        map((res: Response) => {
                            return new CustomUnitFieldValue(res);
                        }));
    }

    /**
     * @param {number} unitId
     * @param {Object} requestBody
     * @returns {Observable<Object>}
     */
    public createUnitCustomField(unitId: number, requestBody: CustomUnitFieldValue): Observable<CustomUnitFieldValue> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http
                .post(
                        Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Unit(unitId) + '/custom-fields'),
                        JSON.stringify(requestBody),
                ).pipe(
                        map((res: Response) => {
                            return new CustomUnitFieldValue(res);
                        }));
    }

    /**
     * @param {number} unitId
     * @param {boolean} isCurrentOwnerToggled
     * @returns {Observable<Object>}
     */
    public getUnitAccountHistory(unitId: number, isCurrentOwnerToggled: boolean): Observable<Array<any>> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.get(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.Organization(jwt.orgId) + Routes.LegFiCore.Unit(unitId) + '/account-history' + '/' + (isCurrentOwnerToggled
                                ? 1
                                : 0),
                ),
        );
    }

    /**
     * @param {number} organizationId
     * @param {number} unitId
     * @param {boolean} doIncludeAllOccupancies
     * @returns {Observable<Object>}
     */
    public generateUnitPdf(
            organizationId: number,
            unitId: number,
            doIncludeAllOccupancies: boolean,
    ): Observable<Response> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.UnitPdf(organizationId || jwt.orgId, unitId, doIncludeAllOccupancies));

        const newHeaders = new HttpHeaders({
            'Content-Type': 'application/pdf',
        });

        const options: Object = {
            headers: newHeaders,
            responseType: 'arraybuffer',
        };

        return this._http.get(url, options);
    }

    public resetStore() {
        this._units = [];
        this.$units.next(this._units);
    }

    private _addUnit(unit: Unit) {
        const index = this._units.findIndex(u => u.id === unit.id);
        if (index !== -1) {
            Object.assign(this._units[index], unit);
        } else {
            this._units.push(unit);
        }
    }
}
