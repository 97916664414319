import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './admin-layout.component';
import { PermissionsGuard } from '../../common/auth/permissions.guard';
import { RouteModules } from '../../enums/route-modules.enum';

const adminLayoutRoutes: Routes = [
    {
        path: 'admin',
        component: AdminLayoutComponent,
        children: [
            {
                canActivate: [PermissionsGuard],
                path: 'lockbox',
                loadChildren: () => import('./manage-lockbox-imports/manage-lockbox-imports.module').then(m => m.ManageLockboxImportsModule),
                data: {module: RouteModules.SUPER_ADMIN},
            },
            {
                canActivate: [PermissionsGuard],
                path: 'alliance',
                loadChildren: () => import('./alliance-dashboard/alliance-dashboard.module').then(m => m.AllianceDashboardModule),
                data: {module: RouteModules.SUPER_ADMIN},
            },
            {
                canActivate: [PermissionsGuard],
                path: 'alliance/:credentialId/:associationId/checks',
                loadChildren: () => import('./alliance-dashboard/alliance-checks/alliance-checks.module').then(m => m.AllianceChecksModule),
                data: {module: RouteModules.SUPER_ADMIN},
            },
            {
                canActivate: [PermissionsGuard],
                path: 'alliance/:credentialId/:accountNumber/transactions',
                loadChildren: () => import('./alliance-dashboard/alliance-transactions/alliance-transactions.module').then(m => m.AllianceTransactionsModule),
                data: {module: RouteModules.SUPER_ADMIN},
            },
            {
                canActivate: [PermissionsGuard],
                path: 'organizations',
                loadChildren: () => import('./organizations/organizations.module').then(m => m.ManageOrganizationsModule),
                data: {module: RouteModules.SUPER_ADMIN},
            },
            {
                canActivate: [PermissionsGuard],
                path: 'organizations/:organizationId',
                loadChildren: () => import('./manage-organization/manage-organization.module').then(m => m.ManageOrganizationModule),
                data: {module: RouteModules.SUPER_ADMIN},
            },
            {
                canActivate: [PermissionsGuard],
                path: 'reports/billing-report',
                loadChildren: () => import('./reports/billing-report/billing-report.module').then(m => m.BillingReportModule),
                data: {module: RouteModules.SUPER_ADMIN},
            },
            {
                canActivate: [PermissionsGuard],
                path: 'products',
                loadChildren: () => import('./products/products.module').then(m => m.ManageProductsModule),
                data: {module: RouteModules.SUPER_ADMIN},
            },
            {
                canActivate: [PermissionsGuard],
                path: 'subscription-templates',
                loadChildren: () => import('./subscription-templates/subscription-templates.module').then(m => m.ManageSubscriptionTemplatesModule),
                data: {module: RouteModules.SUPER_ADMIN},
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/admin/organizations',
            },
        ],
    },
];

export const adminLayoutRouting = RouterModule.forChild(adminLayoutRoutes);
