import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ScrollService
{
    public scrollTo(element: HTMLElement, to: number, duration: number): void {
        if (duration <= 0) {
            return;
        }

        const difference = to - element.scrollTop;
        const self = this;

        setTimeout(function () {
            element.scrollTop = element.scrollTop + (difference / duration * 10);
            if (element.scrollTop === to) {
                return;
            }
            self.scrollTo(element, to, duration - 10);
        }, 10);
    }

    public scrollToTop(): void {
        const scrollableElement: HTMLElement = document.hasOwnProperty('body') && document.body.hasOwnProperty('scrollTop')
                ? document.body
                : document.documentElement;
        this.scrollTo(scrollableElement, 0, 1);
    }
}
