import { Component, Input } from '@angular/core';
import { ModalService } from '../../../../services/modal.service';


// TODO - deprecate and remove this, replace with loading-dialog or loading-overlay
@Component({
    selector: 'lf-loading-modal',
    styleUrls: ['./loading-modal.scss'],
    templateUrl: './loading-modal.component.html',
})
export class LoadingModalComponent
{
    public loaderImgUrl: string;

    @Input()
    public messageText: string = 'Please wait while we process your transaction.';

    private _modalService: ModalService;

    constructor(_modalService: ModalService) {
        this._modalService = _modalService;

        // randomly choose one of 3 images
        const imgArray = [
            'assets/images/app/misc/loading-documents.png',
            'assets/images/app/misc/loading-balloons.png',
            'assets/images/app/misc/loading-rocket.png',
        ];

        const index = Math.floor(Math.random() * 3);
        this.loaderImgUrl = imgArray[index];
    }

    //noinspection JSMethodCanBeStatic
    public clickGuard($event: any) {
        $event.stopPropagation();
    }
}
