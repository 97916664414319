import { NgModule } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { SharedModule } from '../shared.module';
import { CurrencyInputComponent } from './currency-input.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
    imports: [
        // Shared
        SharedModule,
        NgxMaskModule,
    ],
    declarations: [CurrencyInputComponent],
    exports: [CurrencyInputComponent],
    providers: [DecimalPipe],
})
export class CurrencyInputModule
{
}
