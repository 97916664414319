import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { User } from './user';

export class AuthActivity
{
    id: number;
    userId: number;
    activity: string;
    createdAt: Moment;
    updatedAt: Moment;

    user: User;

    constructor(authActivity: any) {
        if (authActivity.id) {
            this.id = authActivity.id;
        }
        if (authActivity.userId) {
            this.userId = authActivity.userId;
        }
        if (authActivity.activity) {
            this.activity = authActivity.activity;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (authActivity.createdAt) {
            this.createdAt = moment.utc(authActivity.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (authActivity.updatedAt) {
            this.updatedAt = moment.utc(authActivity.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (authActivity.user) {
            this.user = new User(authActivity.user);
        }
    }
}
