import { User } from './user';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import moment from 'moment-timezone';
import Moment = moment.Moment;
import { Organization } from './organization';
import { Membership } from './membership';


export class PayableApprover
{
    id: number;
    membershipId: number;
    organizationId: number;
    createdAt: Moment;
    updatedAt: Moment;
    membership: Membership;
    organization: Organization;

    constructor(payableApprover: any) {
        if (payableApprover.id) {
            this.id = payableApprover.id;
        }
        if (payableApprover.memberId) {
            this.membershipId = payableApprover.membershipId;
        }
        if (payableApprover.organizationId) {
            this.organizationId = payableApprover.organizationId;
        }
        const timezone = LegFiJwtService.getTimezone();
        if (payableApprover.createdAt) {
            this.createdAt = moment.utc(payableApprover.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (payableApprover.updatedAt) {
            this.updatedAt = moment.utc(payableApprover.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        // relationships
        if (payableApprover.membership) {
            this.membership = new Membership(payableApprover.membership);
        }
        if (payableApprover.orgranization) {
            this.organization = new Organization(payableApprover.organization);
        }
    }
}
