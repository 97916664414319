import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Product } from './product';
import { SubscriptionProduct } from './subscription-product';
import { Organization } from './organization';
import { Site } from './site';

export class Subscription
{
    id: number;
    siteId: number;
    organizationId: number;
    label: string;
    description: string;
    basis: string;
    lastBilling: Moment = null;
    nextBilling: Moment;
    expires: Moment;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    site: Site;
    organization: Organization;
    subscriptionProducts: SubscriptionProduct[] = [];
    products: Product[] = [];

    productSum: number;

    constructor(product: any) {
        if (product.id) {
            this.id = product.id;
        }
        if (product.siteId) {
            this.siteId = product.siteId;
        }
        if (product.organizationId) {
            this.organizationId = product.organizationId;
        }
        if (product.label) {
            this.label = product.label;
        }
        if (product.description) {
            this.description = product.description;
        }
        if (product.basis) {
            this.basis = product.basis;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (product.lastBilling) {
            this.lastBilling = moment.utc(product.lastBilling, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (product.nextBilling) {
            this.nextBilling = moment.utc(product.nextBilling, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (product.expires) {
            this.expires = moment.utc(product.expires, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (product.createdAt) {
            this.createdAt = moment.utc(product.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (product.updatedAt) {
            this.updatedAt = moment.utc(product.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (product.deletedAt) {
            this.deletedAt = moment.utc(product.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (product.site) {
            this.site = new Site(product.site);
        }
        if (product.organization) {
            this.organization = new Organization(product.organization);
        }
        if (product.subscriptionProducts) {
            this.subscriptionProducts = product.subscriptionProducts.map(
                    (subscriptionProduct) => {
                        return new SubscriptionProduct(subscriptionProduct);
                    },
            );
            this.productSum = this.subscriptionProducts.map(
                    (prod: SubscriptionProduct) => {
                        return Number(prod.amountPerBasis);
                    },
            ).reduce((a: number, b: number) => {
                return a + b;
            }, 0);
        }
        if (product.products) {
            this.products = product.products.map(
                    (prod) => {
                        return new Product(prod);
                    },
            );
        }
    }
}
