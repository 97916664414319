<div [class.row]="!newStyles" [class.py-5]="!newStyles" [ngClass]="classList">
    <div [class.col-12]="!newStyles" [class.mb-4]="!newStyles">
        <div [class.px-5]="!newStyles" [class.text-center]="!newStyles">
            <img *ngIf="showEmptyImg"
                 class="mb-3"
                 style="width: 130px"
                 [attr.src]="'assets/images/payhoa/otherEmpty/empty-inbox.png'" />
            <mat-icon *ngIf="!showEmptyImg && svgIcon"
                      class="empty-icon"
                      [svgIcon]="svgIcon"></mat-icon>
            <mat-icon *ngIf="!showEmptyImg && icon"
                      class="empty-icon mat-native">{{icon}}</mat-icon>
            <div *ngIf="!newStyles"
                 [class.h3]="isLead"
                 [class.h5]="!isLead"
                 [class.text-bold]="isLead"
                 [class.text-secondary]="!isLead">{{ titleForJumbotron }}</div>
            <div *ngIf="newStyles" class="jumbotron-title">{{ titleForJumbotron }}</div>
            <p *ngIf="subtext"
               class="d-block h5 text-secondary"
               [innerHTML]="subtext"></p>
        </div>
    </div>
    <ng-container *ngFor="let button of buttons; let i=index">
        <div class="col-12 d-flex jumbotron-actions"
             [class.justify-content-end]="buttons.length > 1 && (i % 2 === 0)"
             [class.justify-content-center]="buttons.length === 1"
             [class.col-md-6]="buttons.length > 1">
            <ng-container *ngIf="button.icon !== 'add'; else addButton">
                <app-button [type]=" button.type"
                            [icon]="button.icon"
                            (onClick)="button.click ? button.click($event) : null"
                            [routeTo]="button.click ? null : [button.route]">{{button.label}}</app-button>
            </ng-container>
            <ng-template #addButton>
                <app-add-button (onClick)="button.click ? button.click($event) : null"
                                [routeTo]="button.click ? null : [button.route]">{{button.label}}</app-add-button>
            </ng-template>
        </div>
    </ng-container>
    <div *ngIf="buttons.length === 0"
         class="col-12 d-flex justify-content-center jumbotron-actions">
        <ng-content></ng-content>
    </div>
</div>
