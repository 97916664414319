import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { CreditCard } from './credit-card';
import { Membership } from './membership';
import { PaymentBankAccount } from './payment-bank-account';
import { DepositBankAccount } from './deposit-bank-account';

export class MembershipProfile
{
    membershipId: number;
    paymentMethodId: number;
    paymentMethodType: string;
    givenNames: string;
    familyName: string;
    timezone: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    phone: string;
    secondaryPhone: string;
    autopayMax: number;
    autopayEnabled: boolean;
    hasProfileImage: boolean;
    agreedToBillpayTerms: Moment;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;
    hideDemo: boolean;

    givenNamesSharedOnDirectory: boolean;
    familyNameSharedOnDirectory: boolean;
    emailSharedOnDirectory: boolean;
    unitAddressSharedOnDirectory: boolean;
    addressSharedOnDirectory: boolean;
    phoneSharedOnDirectory: boolean;
    secondaryPhoneSharedOnDirectory: boolean;
    acknowledgedSharedDirectory: Moment;

    membership: Membership;
    paymentMethod: CreditCard | PaymentBankAccount | DepositBankAccount;
    autopaidTotal: number = 0;

    name: string;
    isTableListView = false;
    sharedOnDirectory = 0;

    constructor(membershipProfile: any) {
        this.isTableListView = membershipProfile.isTableListView || false;
        if (membershipProfile.membershipId) {
            this.membershipId = membershipProfile.membershipId;
        }
        if (membershipProfile.paymentMethodId) {
            this.paymentMethodId = membershipProfile.paymentMethodId;
        }
        if (membershipProfile.paymentMethodType) {
            this.paymentMethodType = membershipProfile.paymentMethodType;
        }
        if (membershipProfile.givenNames) {
            this.givenNames = membershipProfile.givenNames;
        }
        if (membershipProfile.familyName) {
            this.familyName = membershipProfile.familyName;
        }

        if (this.givenNames || this.familyName) {
            const given = this.givenNames ? this.givenNames : '';
            const family = this.familyName ? this.familyName : '';
            this.name = given + ' ' + family;
        }

        if (membershipProfile.timezone) {
            this.timezone = membershipProfile.timezone;
        }
        if (membershipProfile.address1) {
            this.address1 = membershipProfile.address1;
        }
        if (membershipProfile.address2) {
            this.address2 = membershipProfile.address2;
        }
        if (membershipProfile.city) {
            this.city = membershipProfile.city;
        }
        if (membershipProfile.state) {
            this.state = membershipProfile.state;
        }
        if (membershipProfile.zip) {
            this.zip = membershipProfile.zip;
        }
        if (membershipProfile.country) {
            this.country = membershipProfile.country;
        }
        if (membershipProfile.phone) {
            this.phone = membershipProfile.phone;
        }
        if (membershipProfile.secondaryPhone) {
            this.secondaryPhone = membershipProfile.secondaryPhone;
        }
        if (membershipProfile.autopayMax) {
            this.autopayMax = membershipProfile.autopayMax;
        }
        if (membershipProfile.autopayEnabled) {
            this.autopayEnabled = membershipProfile.autopayEnabled;
        }
        if (membershipProfile.autopaidTotal) {
            this.autopaidTotal = membershipProfile.autopaidTotal;
        }
        if (membershipProfile.hasProfileImage) {
            this.hasProfileImage = membershipProfile.hasProfileImage;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (membershipProfile.agreedToBillpayTerms) {
            this.agreedToBillpayTerms = moment.utc(membershipProfile.agreedToBillpayTerms, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (membershipProfile.createdAt) {
            this.createdAt = moment.utc(membershipProfile.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (membershipProfile.updatedAt) {
            this.updatedAt = moment.utc(membershipProfile.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (membershipProfile.deletedAt) {
            this.deletedAt = moment.utc(membershipProfile.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (membershipProfile.membership) {
            this.membership = new Membership(membershipProfile.membership);
        }

        if (membershipProfile.paymentMethod && membershipProfile.paymentMethodType !== null) {
            if (membershipProfile.paymentMethodType.indexOf('PaymentBankAccount') !== -1) {
                this.paymentMethod = new PaymentBankAccount(membershipProfile.paymentMethod);
            } else if (membershipProfile.paymentMethodType.indexOf('CreditCard') !== -1) {
                this.paymentMethod = new CreditCard(membershipProfile.paymentMethod);
            }
        }

        this.hideDemo = membershipProfile.hideDemo;

        if (membershipProfile.givenNamesSharedOnDirectory) {
            this.givenNamesSharedOnDirectory = membershipProfile.givenNamesSharedOnDirectory;
            this.sharedOnDirectory++;
        }

        if (membershipProfile.familyNameSharedOnDirectory) {
            this.familyNameSharedOnDirectory = membershipProfile.familyNameSharedOnDirectory;
            this.sharedOnDirectory++;
        }

        if (membershipProfile.phoneSharedOnDirectory) {
            this.phoneSharedOnDirectory = membershipProfile.phoneSharedOnDirectory;
            this.sharedOnDirectory++;
        }

        if (membershipProfile.secondaryPhoneSharedOnDirectory) {
            this.secondaryPhoneSharedOnDirectory = membershipProfile.secondaryPhoneSharedOnDirectory;
            this.sharedOnDirectory++;
        }

        if (membershipProfile.emailSharedOnDirectory) {
            this.emailSharedOnDirectory = membershipProfile.emailSharedOnDirectory;
            this.sharedOnDirectory++;
        }

        if (membershipProfile.addressSharedOnDirectory) {
            this.addressSharedOnDirectory = membershipProfile.addressSharedOnDirectory;
            this.sharedOnDirectory++;
        }

        if (membershipProfile.unitAddressSharedOnDirectory) {
            this.unitAddressSharedOnDirectory = membershipProfile.unitAddressSharedOnDirectory;
            this.sharedOnDirectory++;
        }

        if (membershipProfile.acknowledgedSharedDirectory) {
            this.acknowledgedSharedDirectory = moment.utc(membershipProfile.acknowledgedSharedDirectory, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
    }
}
