import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

export const USER_PASSWORD_VALIDATION_ICON = 'fa fa-times text-danger mr-2';
export const USER_PASSWORD_SPECIAL_CHARACTERS = '!@#$%^&"-';
export const USER_PASSWORD_VALIDATIONS: { rule: string; text: string; icon: string; }[] = [
    {
        rule: 'minlength',
        text: 'Minimum of 10 Characters',
        icon: USER_PASSWORD_VALIDATION_ICON,
    },
    {
        rule: 'upperCaseLetter',
        text: 'At least one uppercase character',
        icon: USER_PASSWORD_VALIDATION_ICON,
    },
    {
        rule: 'lowerCaseLetter',
        text: 'At least one lowercase character',
        icon: USER_PASSWORD_VALIDATION_ICON,
    },
    {
        rule: 'numericLetter',
        text: 'At least one numeric character',
        icon: USER_PASSWORD_VALIDATION_ICON,
    },
    {
        rule: 'specialCharacter',
        text: 'At least one special character (ex. ' + USER_PASSWORD_SPECIAL_CHARACTERS + ')',
        icon: USER_PASSWORD_VALIDATION_ICON,
    }
];

export const USER_CONFIRM_PASSWORD_VALIDATION = {
    rule: 'passwordMatch',
    text: 'Passwords should match',
    icon: USER_PASSWORD_VALIDATION_ICON,
};

@Directive({
    selector: '[validatePassword][formControlName],[validatePassword][formControl],[validatePassword][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PasswordValidatorDirective),
            multi: true,
        },
    ],
})
export class PasswordValidatorDirective implements Validator
{
    @Input() stacked = false;

    validate(control: AbstractControl): ValidationErrors {
        // when stacked, we will display all validation errors
        const validationMap: {[prop: string]: boolean} = {};

        // All passwords must be at least 10 characters in length.
        if (control.value && control.value.length < 10) {
            if (this.stacked) {
                validationMap['minlength'] = true;
            } else {
                return {minlength: true};
            }
        }

        // Only enforce alphanumeric / casing restrictions if the password provided is all ASCII characters.
        if (/^[\u0000-\u007f]*$/.test(control.value)) {
            // ASCII characters only.

            // At least a single uppercase letter required.
            if (!(/[A-Z]/.test(control.value))) {
                if (this.stacked) {
                    validationMap['upperCaseLetter'] = true;
                } else {
                    return {upperCaseLetter: true};
                }
            }

            // At least a single lowercase letter required.
            if (!(/[a-z]/.test(control.value))) {
                if (this.stacked) {
                    validationMap['lowerCaseLetter'] = true;
                } else {
                    return {lowerCaseLetter: true};
                }
            }

            // At least a single numeric required.
            if (!(/[0-9]/.test(control.value))) {
                if (this.stacked) {
                    validationMap['numericLetter'] = true;
                } else {
                    return {numericLetter: true};
                }
            }

            // At least a single special character required.
            const specialCharRegex = new RegExp('[' + USER_PASSWORD_SPECIAL_CHARACTERS + ']');
            if (!(specialCharRegex.test(control.value))) {
                if (this.stacked) {
                    validationMap['specialCharacter'] = true;
                } else {
                    return {specialCharacter: true};
                }
            }
        }

        return Object.keys(validationMap).length > 0 ? validationMap : null;
    }
}
