import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: '[button-spacer], button-spacer, [buttonSpacer]',
})
export class ButtonSpacerDirective
{
    @Input() size: 'sm' | 'md' = 'md';

    @HostBinding('class') classValue = 'button-spacer';
    @HostBinding('class.sm') get smallSpacer() {
        return this.size === 'sm';
    }
}
