import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdjustColorDirective } from './adjust-color.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [AdjustColorDirective],
    exports: [AdjustColorDirective],
})
export class AdjustColorModule
{
}
