import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { Routes } from '../../config/routes';
import { Form1099 } from '../../models/entities/form1099';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class Form1099Service
{
    private _http: ApplicationHttpClient;

    constructor(_http: ApplicationHttpClient) {
        this._http = _http;
    }

    createForm1099(data): Observable<Form1099> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }
        data['organizationId'] = jwt.orgId;
        return this._http.post(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Form1099s), JSON.stringify(data))
                .pipe(map(response => new Form1099(response)));
    }

    updateForm1099(id: number, data: any): Observable<Form1099> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }
        data['id'] = id;
        data['organizationId'] = jwt.orgId;
        return this._http.put(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Form1099s), JSON.stringify(data))
                .pipe(map(response => new Form1099(response)));
    }

    getForm1099s(): Observable<Form1099[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }
        return this._http.get(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Form1099s))
                .pipe(map((response: Object[]) => {
                    return response.map(form => new Form1099(form));
                }));
    }

    getPrevious1099(id: number): Observable<Form1099> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }
        return this._http.get(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Form1099s + '/' + id))
                .pipe(map((response: Object) => {
                    return new Form1099(response);
                }));
    }

    deleteForm1099(id: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }
        return this._http.delete(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Form1099s + '/' + id));
    }

    transmitForm1099(id: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }
        return this._http.post(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Form1099s + '/' + id + '/transmit'), null);
    }

    getForm1099Pdf(id: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }
        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Form1099s + '/' + id + '/get-pdf');
        return this._http.post(url, '{}', {responseType: 'arraybuffer'});
    }
}
