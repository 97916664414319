import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { Routes } from '../../config/routes';
import { map } from 'rxjs/operators';
import { PropertyManagementCompany, PropertyManagementCompanyResponse } from '../../models/entities/property-management-company';
import { OrganizationResponse, SimpleOrganization } from '../../models/entities/organization';
import { PropertyManagementCompanyDataService } from './property-manager-company-data.service';
import { FormResponse } from '../../models/entities/forms/form';
import { FormSubmission, FormSubmissionResponse } from '../../models/entities/forms/form-submission';
import { Observable } from 'rxjs';
import { FormSubmissionStatusRollup } from '../../models/entities/forms/form-submission-status';
import { TableFilters } from '../../models/entities/table-filters';
import { PropertyManagementCompanyDateFilters } from '../../enums/property-management-company-date-filters.enum';
import { ViolationStatusRollup } from '../../models/entities/violation-status';
import { ViolationStatement, ViolationStatementResponse } from '../../models/entities/violation-statement';
import { FormAnswer } from '../../models/entities/forms/form-answer';

@Injectable({
    providedIn: 'root',
})
export class PropertyManagementCompanyService
{
    constructor(
            private _http: ApplicationHttpClient,
            private _data: PropertyManagementCompanyDataService,
    ) {
    }

    public getPropertyManagementCompanies(
            search: string = '',
            sortColumn: string = 'name',
            sortDirection: string = 'asc',
            pageIndex: number = 1,
            perPage: number = 100,
            // filters: { [key: string]: any } = {},
    ) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject({
            page: pageIndex,
            search: search,
            column: sortColumn,
            direction: sortDirection,
            per_page: perPage,
            // ...filters,
        });
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementCompanies + query,
        );

        return this._http.get(url).pipe(map((res) => {
            return new PropertyManagementCompanyResponse(res);
        }));
    }

    public getPropertyManagementCompany(
            pmcId: number,
            filters: { [key: string]: any } = {},
    ) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject({
            ...filters,
        });
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementCompany(pmcId) + query,
        );

        return this._http.get(url).pipe(map((res: any) => new PropertyManagementCompany(res)));
    }

    public getPropertyManagementOrganizations(
            search: string = '',
            sortColumn: string = 'name',
            sortDirection: string = 'asc',
            pageIndex: number = 1,
            perPage: number = 100,
            filters: { [key: string]: any } = {},
    ) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject({
            page: pageIndex,
            search: search,
            column: sortColumn,
            direction: sortDirection,
            per_page: perPage,
            ...filters,
        });

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementOrganizations(this._data.pmcId) + query,
        );
        return this._http.get(url).pipe(map((res) => {
            return new OrganizationResponse(res);
        }));
    }

    public getPropertyManagementOrganizationList() {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementOrganizations(this._data.pmcId) + '/list',
        );
        return this._http.get(url).pipe(map((res: Object[]) => {
            return res.map((r) => new SimpleOrganization(r));
        }));
    }

    public getPropertyManagementRequestForms(
            search: string = '',
            sortColumn: string = 'name',
            sortDirection: string = 'asc',
            pageIndex: number = 1,
            perPage: number = 100,
            filters: { [key: string]: any } = {},
    ) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject({
            page: pageIndex,
            search: search,
            column: sortColumn,
            direction: sortDirection,
            per_page: perPage,
            ...filters,
        });
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementRequestForms(this._data.pmcId) + query,
        );

        return this._http.get(url).pipe(map((res) => {
            return new FormResponse(res);
        }));
    }

    public getPropertyManagementRequestFormSubmissions(
            search: string = '',
            sortColumn: string = 'name',
            sortDirection: string = 'asc',
            pageIndex: number = 1,
            perPage: number = 100,
            filters: TableFilters = {},
    ) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject({
            page: pageIndex,
            search: search,
            column: sortColumn,
            direction: sortDirection,
            per_page: perPage,
            ...filters,
        });
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementRequestFormSubmissions(this._data.pmcId) + query,
        );

        return this._http.get(url).pipe(map((res) => {
            return new FormSubmissionResponse(res);
        }));
    }

    public getPropertyManagementRequestFormSubmission(submissionId: number): Observable<FormSubmission> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementRequestFormSubmissions(this._data.pmcId) + `/${submissionId}`,
        );

        return this._http.get(url).pipe(map((response: Object) => {
            const submission = new FormSubmission(response['submission']);
            submission.answers = response['answers'].map((a: Object) => new FormAnswer(a));
            return submission;
        }));
    }

    public getPropertyManagementRequestFormSubmissionStatuses(
            filters: { [key: string]: any } = {},
    ): Observable<FormSubmissionStatusRollup> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject(filters);
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementRequestFormSubmissionStatuses(this._data.pmcId) + query,
        );

        return this._http.get(url).pipe(map((res: { data: Object[]; counts: Object[]; }) => {
            return new FormSubmissionStatusRollup(res);
        }));
    }

    public getPropertyManagementViolations(
            search: string = '',
            sortColumn: string = 'name',
            sortDirection: string = 'asc',
            pageIndex: number = 1,
            perPage: number = 100,
            filters: TableFilters = {},
    ) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject({
            page: pageIndex,
            search: search,
            column: sortColumn,
            direction: sortDirection,
            per_page: perPage,
            ...filters,
        });
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementViolations(this._data.pmcId) + query,
        );

        return this._http.get(url).pipe(map((res) => {
            return new ViolationStatementResponse(res);
        }));
    }

    public getPropertyManagementViolation(violationId: number): Observable<ViolationStatement> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementViolations(this._data.pmcId) + `/${violationId}`,
        );

        return this._http.get(url).pipe(map((response: Object) => {
            return new ViolationStatement(response);
        }));
    }

    public getPropertyManagementViolationStatuses(
            filters: { [key: string]: any } = {},
    ): Observable<ViolationStatusRollup> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject(filters);
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementViolationStatuses(this._data.pmcId) + query,
        );

        return this._http.get(url).pipe(map((res: { data: Object[]; counts: Object[]; }) => {
            return new ViolationStatusRollup(res);
        }));
    }

    public getPropertyManagementRequestFormCounts(dateFilter: PropertyManagementCompanyDateFilters): Observable<{
        formSubmissionsOpenedBetweenCount: number,
        formSubmissionsClosedBetweenCount: number
    }> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject({requests: dateFilter});
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementRequestFormCounts(this._data.pmcId) + query,
        );

        return this._http.get(url);
    }

    public getPropertyManagementViolationCounts(dateFilter: PropertyManagementCompanyDateFilters): Observable<{
        violationsOpenedBetweenCount: number,
        violationsClosedBetweenCount: number
    }> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const query = this._http.queryStringFromObject({violations: dateFilter});
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PropertyManagementViolationCounts(this._data.pmcId) + query,
        );

        return this._http.get(url);
    }
}
