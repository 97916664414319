import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Routes } from '../../config/routes';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { Membership } from '../../models/entities/membership';
import { UnconfirmedOwnerRegistration } from 'app/models/entities/unconfirmed-owner-registration';
import { map } from 'rxjs/operators';
import { PeopleResponse } from './member.datasource';
import { MembershipDirectoryResponse } from '../../models/entities/membership-directory-entry';
import { MembershipProfile } from '../../models/entities/membership-profile';

export class OverdueMember
{
    id: number;
    userId: number;
    memberType: string;
    address1: string;
    address2: string;
    givenNames: string;
    familyName: string;
    phone: string;
    chargeCount: number;
    chargesAmount: number;
    overdueTotal: number;
    paymentsAmount: number;
    hasAddress: boolean;
    hasPhone: boolean;

    checked: boolean = false;

    constructor(member: any) {
        if (member.id) {
            this.id = member.id;
        }
        if (member.userId) {
            this.userId = member.userId;
        }
        if (member.memberType) {
            this.memberType = member.memberType;
        }
        if (member.address1) {
            this.address1 = member.address1;
        }
        if (member.address2) {
            this.address2 = member.address2;
        }
        if (member.givenNames) {
            this.givenNames = member.givenNames;
        }
        if (member.familyName) {
            this.familyName = member.familyName;
        }
        if (member.phone) {
            this.phone = member.phone;
        }
        if (member.chargeCount) {
            this.chargeCount = member.chargeCount;
        }
        if (member.chargesAmount) {
            this.chargesAmount = member.chargesAmount;
        }
        if (member.overdueTotal) {
            this.overdueTotal = member.overdueTotal;
        }
        if (member.paymentsAmount) {
            this.paymentsAmount = member.paymentsAmount;
        }
        if (member.hasAddress) {
            this.hasAddress = member.hasAddress;
        }
        if (member.hasPhone) {
            this.hasPhone = member.hasPhone;
        }
    }
}

export class NewMembership
{
    email: string;
    memberType: string;

    givenNames: string;
    familyName: string;
    phone: string;

    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    region: { region: string; countryCode: string };

    profileImage: string;
    auxiliaryEmails: string[];

    constructor(member: any) {
        if (member) {
            if (typeof member.email !== 'undefined') {
                this.email = member.email;
            }
            if (typeof member.memberType !== 'undefined') {
                this.memberType = member.memberType;
            }
            if (typeof member.givenNames !== 'undefined') {
                this.givenNames = member.givenNames;
            }
            if (typeof member.familyName !== 'undefined') {
                this.familyName = member.familyName;
            }
            if (typeof member.phone !== 'undefined') {
                this.phone = member.phone;
            }
            if (typeof member.address1 !== 'undefined') {
                this.address1 = member.address1;
            }
            if (typeof member.address2 !== 'undefined') {
                this.address2 = member.address2;
            }
            if (typeof member.city !== 'undefined') {
                this.city = member.city;
            }
            if (typeof member.state !== 'undefined') {
                this.state = member.state;
            }
            if (typeof member.zip !== 'undefined') {
                this.zip = member.zip;
            }
            if (typeof member.country !== 'undefined') {
                this.country = member.country;
            }
            if (member.region) {
                if (typeof member.region.region !== 'undefined') {
                    this.region.region = member.region.region;
                }
                if (typeof member.region.countryCode !== 'undefined') {
                    this.region.countryCode = member.region.countryCode;
                }
            }
            if (typeof member.profileImage !== 'undefined') {
                this.profileImage = member.profileImage;
            }
            if (member.auxiliaryEmails) {
                this.auxiliaryEmails = member.auxiliaryEmails;
            }
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class MembersService
{
    private _http: ApplicationHttpClient;

    constructor(_http: ApplicationHttpClient) {
        this._http = _http;
    }

    public resetMemberPassword(memberId: number, data: any): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.ResetMemberPassword(jwt.orgId, memberId),
        );

        return this._http.post(url, JSON.stringify(data));
    }

    public addMemberToUnits(memberId: number, unitIds: number[]): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.AddMemberToUnits(memberId));

        return this._http.post(url, JSON.stringify({
            unitIds: unitIds,
        }));
    }

    public getUnconfirmedOwner(
            orgId: number,
            registrationId: number,
    ): Observable<UnconfirmedOwnerRegistration> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string =
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.UnconfirmedOwners(orgId || jwt.orgId),
                ) +
                '/' +
                registrationId;

        return this._http.get(url).pipe(map(response => {
            return new UnconfirmedOwnerRegistration(response);
        }));
    }

    public getUnconfirmedOwners(
            orgId: number,
    ): Observable<UnconfirmedOwnerRegistration[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.UnconfirmedOwners(orgId || jwt.orgId),
        );

        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map(function (unconfOwner) {
                return new UnconfirmedOwnerRegistration(unconfOwner);
            });
        }));
    }

    public updateUnconfirmedOwner(
            orgId: number,
            unconfirmedOwnerId: number,
            updateFields: any,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.UpdateUnconfirmedOwner(
                        orgId || jwt.orgId,
                        unconfirmedOwnerId,
                ),
        );

        return this._http.patch(url, updateFields);
    }

    public confirmUnconfirmedOwner(
            orgId: number,
            unconfirmedOwnerId: number,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.UpdateUnconfirmedOwner(
                        orgId || jwt.orgId,
                        unconfirmedOwnerId,
                ),
        );

        return this._http.post(url, '');
    }

    public deleteUnconfirmedOwner(
            orgId: number,
            unconfirmedOwnerId: number,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.UpdateUnconfirmedOwner(
                        orgId || jwt.orgId,
                        unconfirmedOwnerId,
                ),
        );

        return this._http.delete(url);
    }

    /**
     * Gets paginated collection of transactions for the specified organization.
     * @returns {any}
     * @param orgId
     * @param search
     * @param sortColumn
     * @param sortDirection
     * @param pageIndex
     * @param perPage
     * @param options
     */
    public getPeople(
            orgId: number,
            search: string = '',
            sortColumn: string = 'name',
            sortDirection: string = 'desc',
            pageIndex: number = 1,
            perPage: number = 100,
            options: {
                status: string,
                tags: string[],
                withoutTags?: string[]
            } = {
                status: 'active',
                tags: [],
            },
    ): Observable<PeopleResponse> {

        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        let url = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PeopleList(orgId || jwt.orgId),
        );
        const params = [];
        params.push('page=' + pageIndex);
        params.push('search=' + encodeURIComponent(search));
        params.push('column=' + sortColumn);
        params.push('direction=' + sortDirection);
        params.push('perPage=' + perPage);
        params.push('status=' + options.status);
        params.push('tags=' + options.tags.map(t => encodeURIComponent(t)).join(','));
        if ('withoutTags' in options) {
            params.push('withoutTags=' + options.withoutTags.map(t => encodeURIComponent(t)).join(','));
        }

        url = url + '?' + params.join('&');

        return this._http.get(url)
                .pipe(map((resp) => new PeopleResponse(resp)));
    }

    public getMembers(
            organizationId: number,
            options?: any,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const params: any[] = [];
        if (options && options.status && options.status.length) {
            if (Array.isArray(options.status)) {
                options.status.forEach(function (elem: any) {
                    params.push('status[]=' + elem.toString());
                });
            } else {
                params.push('status=' + options.status.toString());
            }
        }
        if (options && options.simple) {
            params.push('simple=' + options.simple.toString());
        }
        if (options && options.permissions) {
            params.push('with[]=permissions');
        }
        if (options && options.owners) {
            params.push('with[]=owners');
        }
        if (options && options.allTags) {
            params.push('with[]=tags');
        }
        if (options && options.paymentMethodCount) {
            params.push('with[]=paymentMethodCount');
        }
        if (options && options.tags) {
            // params.push('with[]=tags');
            options.tags.forEach(tag => {
                params.push('tags[]=' + tag);
            });
        }
        if (options && options.unitId) {
            params.push('unitId=' + options.unitId);
        }
        const queryString: string =
                params.length > 0 ? '?' + params.join('&') : '';

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Members(organizationId || jwt.orgId) + queryString,
        );

        return this._http.get(url);
    }

    public getMember(
            organizationId: number,
            membershipId: number,
            withs?: string[],
    ): Observable<Membership> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Membership(
                        organizationId,
                        membershipId,
                ) + (withs ? '?with=' + withs.join(',') : ''),
        );

        return this._http.get(url).pipe(map((response: Object) => {
            return new Membership(response);
        }));
    }

    public getMembershipDirectory(
            organizationId: number,
    ): Observable<MembershipDirectoryResponse> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.MembershipDirectory(organizationId || jwt.orgId),
        );

        return this._http.get(url).pipe(map((response: Object) => {
            return new MembershipDirectoryResponse(response);
        }));
    }

    public getOptedInMembershipDirectoryPdf(organizationId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.MembershipDirectory(organizationId || jwt.orgId) + '/pdf',
        );

        const options: Object = {
            responseType: 'blob',
        };

        return this._http.get(url, options);
    }

    public getTeam(organizationId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Team(organizationId || jwt.orgId),
        );

        return this._http.get(url);
    }

    public sendStatementFromPaymentLogin(
            email: string,
    ): Observable<any> {
        const url: string =
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.SendPaymentLoginEmail(),
                );
        const body = {
            email: email,
        };
        return this._http.post(url, JSON.stringify(body));
    }


    public getUserMemberships(userId: number): Observable<Membership[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.UserMemberships(userId || jwt.id),
        );

        return this._http.get(url).pipe(map((res: Object[]) => {
            return res.map((item: Object) => new Membership(item));
        }));
    }

    public addTeamMember(orgId: number, body: any): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Team(orgId || jwt.orgId),
        );

        return this._http.post(url, JSON.stringify(body));
    }

    public deleteTeamMember(
            orgId: number,
            memberId: number,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.TeamMember(orgId || jwt.orgId, memberId),
        );

        return this._http.delete(url);
    }

    public restoreMembers(
            organizationId: number,
            memberIds: number[],
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.MembersRestore(organizationId || jwt.orgId) +
                '?members=' +
                memberIds.join(','),
        );

        return this._http.put(url, JSON.stringify({}));
    }

    public unlockMembers(
            organizationId: number,
            userIds: number[],
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const body = {
            users: userIds,
        };
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.UnlockMembers(organizationId || jwt.orgId),
        );

        return this._http.post(url, JSON.stringify(body));
    }

    public lockMembers(
            organizationId: number,
            userIds: number[],
            lockedReason: string,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const body = {
            users: userIds,
            reason: lockedReason,
        };
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.LockMembers(organizationId || jwt.orgId),
        );

        return this._http.post(url, JSON.stringify(body));
    }

    public deleteMembers(
            organizationId: number,
            memberIds: number[],
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Members(organizationId || jwt.orgId) +
                '?members=' +
                encodeURIComponent(memberIds.join(',')),
        );

        return this._http.delete(url);
    }

    public getMemberSummary(
            organizationId: number,
            memberId: number,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.MemberSummary(
                        organizationId,
                        memberId,
                ),
        );

        return this._http.get(url);
    }

    /**
     * Updates membership details from Core.
     * @param memberId
     * @param requestBody
     * @returns {Observable<Membership>}
     */
    public updateMember(
            memberId: number,
            requestBody: Object,
    ): Observable<Membership> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http
                .patch(
                        Routes.MakeLegFiCoreUrl(
                                Routes.LegFiCore.Membership(jwt.orgId, memberId || jwt.memberId),
                        ),
                        JSON.stringify(requestBody),
                ).pipe(
                        map((response: Object) => {
                            return new Membership(response);
                        }));
    }

    public getCustomFieldValues(
            organizationId: number,
            memberId: number,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.get(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.CustomFieldValuesForMember(
                                organizationId || jwt.orgId,
                                memberId,
                        ),
                ),
        );
    }

    public updateCustomFields(
            organizationId: number,
            memberId: number,
            requestBody: Object,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.put(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.CustomFieldValuesForMember(
                                organizationId || jwt.orgId,
                                memberId,
                        ),
                ),
                JSON.stringify(requestBody),
        );
    }

    /**
     * @param {number} memberId
     * @param {string} nonce
     * @returns {Observable<Object>}
     */
    public getMemberActivation(
            memberId: number,
            nonce: string,
    ): Observable<Object> {
        return this._http.get(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.MemberActivation(memberId, nonce),
                ),
        );
    }

    /**
     * @param {number} organizationId
     * @param {number[]} memberIds
     * @returns {Observable<Object>}
     */
    public resendActivations(
            organizationId: number,
            memberIds: number[],
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.get(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.MemberActivations(
                                organizationId || jwt.orgId,
                                memberIds,
                        ),
                ),
        );
    }

    /**
     * @param {number} memberId
     * @param {string} nonce
     * @param {any} activationData
     * @returns {Observable<Object>}
     */
    public submitMemberActivation(
            memberId: number,
            nonce: string,
            activationData: any,
    ): Observable<Object> {
        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.MemberActivation(memberId, nonce),
                ),
                JSON.stringify(activationData),
        );
    }

    /**
     * @param memberId
     * @param {any[]} autopaySettings
     * @returns {any}
     */
    public enableAutopaySettings(
            memberId: number,
            autopaySettings: any,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.EnableAutopay(memberId),
                ),
                JSON.stringify(autopaySettings),
        );
    }

    /**
     * @returns {any}
     * @param memberId
     */
    public disableAutopaySettings(
            memberId: number,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.DisableAutopay(memberId),
                ),
                '',
        );
    }
}
