import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { Observable } from 'rxjs';
import { Routes } from '../../config/routes';
import { map } from 'rxjs/operators';
import { PageAdvertisement } from '../../models/entities/page-advertisement';

@Injectable({
    providedIn: 'root',
})
export class PageAdvertisementService
{
    private _http: ApplicationHttpClient;

    constructor(_http: ApplicationHttpClient) {
        this._http = _http;
    }

    public getPageAdvertisement(): Observable<PageAdvertisement[]> {
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.PageAdvertisement,
        );

        return this._http.get(url).pipe(map((response: any[]) => {
            return response.map((res) => res.hasOwnProperty('id') ? new PageAdvertisement(res) : null);
        }));
    }
}
