import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';

interface DatabaseNotificationData
{
    icon: string;
    title: string;
    description: string;
    pmcLink?: string;
    orgLink?: string;
    organizationId?: number;
    organizationName?: string;
    subjectType: string;
    subjectId: number;
}

export class DatabaseNotification
{
    id: string; // uuid
    organizationId: number;
    notifiableType: string;
    notifiableId: number;
    data: DatabaseNotificationData;
    readAt: Moment = null;
    createdAt: Moment;

    constructor(notification: any) {
        this.id = notification.id;
        this.notifiableType = notification.notifiableType;
        this.notifiableId = notification.notifiableId;
        this.data = notification.data;

        const timezone = LegFiJwtService.getTimezone();

        if (notification.readAt) {
            this.readAt = moment.utc(notification.readAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (notification.createdAt) {
            this.createdAt = moment.utc(notification.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
    }
}
