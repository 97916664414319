import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatDialogSizes } from '../../../../enums/mat-dialog-sizes.enum';
import { AbstractControl, FormGroup, UntypedFormBuilder } from '@angular/forms';

export interface ConfirmActionDialogInput
{
    title: string;
    content: string;
    confirmButton: string;
    secondaryButton?: string;
    cancelButton?: string;
    hideCancelButton?: boolean;
    danger?: string;
    newStyles?: boolean;
    control?: {
        control: AbstractControl;
        type: 'toggle'; // TODO: add controls as needed
        label: string;
    };
}

export interface ConfirmActionDialogResult
{
    success: boolean;
    secondary?: boolean;
}

@Component({
    selector: 'app-confirm-action-dialog',
    templateUrl: './confirm-action-dialog.component.html',
})
export class ConfirmActionDialogComponent implements OnInit
{
    static readonly width = MatDialogSizes.SM;

    formGroup: FormGroup;

    constructor(
            private _dialogRef: MatDialogRef<ConfirmActionDialogComponent>,
            private _fb: UntypedFormBuilder,
            @Inject(MAT_DIALOG_DATA) public data: ConfirmActionDialogInput,
    ) {
        if (!this.data.title) {
            this.data.title = 'Please Confirm';
        }
    }

    ngOnInit(){
        if (this.data.control) {
            this.formGroup = this._fb.group({});
            this.formGroup.registerControl(this.data.control.type, this.data.control.control);
        }
    }

    closeSecondary() {
        this._dialogRef.close(<ConfirmActionDialogResult>{success: true, secondary: true});
    }

    closeAndConfirm() {
        this._dialogRef.close(<ConfirmActionDialogResult>{success: true});
    }

    closeAndCancel() {
        this._dialogRef.close(<ConfirmActionDialogResult>{success: false});
    }

}
