import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { RecurringChargeTemplate } from './recurring-charge-template';
import { Charge } from './charge';
import { ExpectedCharge } from './expected-charge';
import Moment = moment.Moment;

export class RecurringCharge
{
    id: number;
    recurringChargeTemplateId: number;
    chargeId: number;
    expectedChargeId: number;

    createdAt: Moment;
    updatedAt: Moment;

    recurringChargeTemplate: RecurringChargeTemplate = null;
    charge: Charge = null;
    expectedCharge: ExpectedCharge = null;

    constructor(recurringCharge) {
        if (recurringCharge.id) {
            this.id = recurringCharge.id;
        }
        if (recurringCharge.recurringChargeTemplateId) {
            this.recurringChargeTemplateId = recurringCharge.recurringChargeTemplateId;
        }
        if (recurringCharge.recurringChargeTemplate) {
            this.recurringChargeTemplate = new RecurringChargeTemplate(recurringCharge.recurringChargeTemplate);
        }
        if (recurringCharge.chargeId) {
            this.chargeId = recurringCharge.chargeId;
        }
        if (recurringCharge.charge) {
            this.charge = new Charge(recurringCharge.charge);
        }
        if (recurringCharge.expectedChargeId) {
            this.expectedChargeId = recurringCharge.expectedChargeId;
        }
        if (recurringCharge.expectedCharge) {
            this.expectedCharge = recurringCharge.expectedCharge;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (recurringCharge.createdAt) {
            this.createdAt = moment.utc(recurringCharge.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (recurringCharge.updatedAt) {
            this.updatedAt = moment.utc(recurringCharge.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
    }
}
