import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';

export interface IdleTimeoutDialogInput
{
    countdown$: Observable<number>;
}

export interface IdleTimeoutDialogOutput {
    keepAlive: boolean;
    preserveLocation: boolean;
}

@Component({
    selector: 'app-idle-timeout-dialog',
    templateUrl: './idle-timeout-dialog.component.html',
})
export class IdleTimeoutDialogComponent
{
    constructor(
            private _matDialogRef: MatDialogRef<IdleTimeoutDialogComponent>,
            @Inject(MAT_DIALOG_DATA) public data: IdleTimeoutDialogInput,
    ) {
    }

    closeAndConfirm() {
        const output: IdleTimeoutDialogOutput = {
            keepAlive: true,
            preserveLocation: false,
        };
        this._matDialogRef.close(output);
    }
}
