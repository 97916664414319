export enum ColorSourcesEnum {
    TURQUOISE = '#358D8D',
    ORANGE = '#EB9B58',
    LAPIS_LAZULI = '#4859A0',
    VIOLET = '#7C409D',
    BURNT_SIENNA = '#EB7258',
    LIME = '#C1E054',
    DENIM_BLUE = '#3B7B93',
    YELLOW = '#EBD858',
    LAKE_BLUE = '#5899BF',
    LIGHT_CRIMSON = '#F16888',
    PURPLE = '#7A64C8',
    AZURE = '#007BFF',
    OFF_WHITE = '#F8FAFB',
}
