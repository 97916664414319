import { Subject } from 'rxjs';
import { SidenavContentConfig, SidenavContentService } from './sidenav-content.service';
import { ComponentRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

export class SidenavContentRef<T, R = any> {
    _componentRef: ComponentRef<any>;

    private readonly _afterOpened = new Subject<void>();
    private readonly _afterClosed = new Subject<R | undefined>();
    private readonly _beforeClosed = new Subject<R | undefined>();

    constructor(
            public _sidenavInstance: MatSidenav,
            private _sidenavContentService: SidenavContentService,
            private _config?: SidenavContentConfig<any>,
    ) {
        this._sidenavInstance.open().then(() => {
            if (this._config?.initialLoadingState) {
                this._sidenavContentService.loading = true;
            }

            this._afterOpened.next();
            this._afterOpened.complete();
        });
    }

    set loading(isLoading: boolean) {
        this._sidenavContentService.loading = isLoading;
    }

    close(sidenavResult?: R): void {
        this._beforeClosed.next(sidenavResult);
        this._beforeClosed.complete();

        this._sidenavInstance.close().then(() => {
            this._sidenavContentService._title = null;
            this._sidenavContentService._actions = null;
            this._sidenavContentService.loading = false;
            this._afterClosed.next(sidenavResult);
            this._afterClosed.complete();
        });
    }

    afterOpened() {
        return this._afterOpened.asObservable();
    }

    afterClosed() {
        return this._afterClosed.asObservable();
    }

    beforeClosed() {
        return this._beforeClosed.asObservable();
    }
}
