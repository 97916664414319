<div class="promo-bar" *ngIf="isShowing">
    <ng-container *ngIf="isOnTrial">
        <i class="fa fa-rocket mr-2"></i>
        <span>
            Sign up today & modernize your HOA.  Your trial ends in {{remainingTime}}
        </span>
        <a [routerLink]="['/app/settings/organization/billing']" class="btn btn-outline-secondary ml-2">Sign Up</a>
    </ng-container>
    <span *ngIf="!isOnTrial">
        Please <a [routerLink]="['/app/settings/organization/billing']">update your payment method</a> to avoid an interruption in your PayHOA service.
    </span>
    <img (click)="close()" class="mat-close" src="/assets/images/payhoa/other/icon_close.svg">
</div>
