import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { UnifiedBankAccount } from './unified-bank-account';

export interface PlaidAccount
{
    accountId: string;
    mask: string;
    name: string;
    officialName: string;
    type: string;
    subtype: string;
    balances: {
        available: number;
        current: number;
        limit: number;
    };
    unifiedBankAccount: UnifiedBankAccount;
}

export interface PlaidError
{
    displayMessage: string;
    errorCode: string;
    errorMessage: string;
    errorType: string;
    requestId: string;
}

export interface PlaidInstitution
{
    name: string;
}

export class PlaidToken
{
    id: number;
    organizationId: number;
    propertyManagementCompanyId: number;
    plaidInstitutionId: string;
    updateLinkToken: string;
    plaidAccessToken: string;

    transactionsLastPulled: Moment;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    institution: PlaidInstitution = null;
    plaidAccounts: PlaidAccount[] = [];
    plaidError: PlaidError = null;
    unifiedBankAccounts: UnifiedBankAccount[] = [];

    constructor(plaidToken: any) {
        this.id = plaidToken.id || null;
        this.organizationId = plaidToken.organizationId || null;
        this.propertyManagementCompanyId = plaidToken.propertyManagementCompanyId || null;
        this.plaidInstitutionId = plaidToken.plaidInstitutionId || null;
        this.updateLinkToken = plaidToken.updateLinkToken || null;
        this.institution = plaidToken.institution || null;
        this.plaidAccounts = plaidToken.plaidAccounts || [];
        this.plaidAccessToken = plaidToken.plaidAccessToken || null;

        this.plaidError = plaidToken.plaidError || null;
        this.unifiedBankAccounts = plaidToken.unifiedBankAccounts
                ? plaidToken.unifiedBankAccounts.map((uba) => new UnifiedBankAccount(uba))
                : [];

        this.plaidAccounts.forEach((acct) => {
            const index = this.unifiedBankAccounts.findIndex((uba) => uba.plaidAccountId === acct.accountId);
            if (index > -1) {
                acct.unifiedBankAccount = this.unifiedBankAccounts[index];
            }
        });

        for (const acct of this.plaidAccounts) {
            acct.unifiedBankAccount = acct.unifiedBankAccount
                    ? new UnifiedBankAccount(acct.unifiedBankAccount)
                    : null;
        }

        const timezone = LegFiJwtService.getTimezone();

        this.transactionsLastPulled = plaidToken.transactionsLastPulled
                ? moment.utc(plaidToken.transactionsLastPulled, 'YYYY-MM-DD hh:mm:ss').tz(timezone)
                : null;
        this.createdAt = plaidToken.createdAt
                ? moment.utc(plaidToken.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone)
                : null;
        this.updatedAt = plaidToken.updatedAt
                ? moment.utc(plaidToken.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone)
                : null;
        this.deletedAt = plaidToken.deletedAt
                ? moment.utc(plaidToken.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone)
                : null;
    }

    get label() {
        if (this.plaidError) {
            return this.institution.name + ' with error';
        }
        return this.institution.name + ' with ' + this.plaidAccounts.length + ' accounts';
    }
}
