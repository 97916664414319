import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Membership } from './membership';
import { CommunicationActivity } from './communication-activity';

export class ScheduledPhoneCall
{
    id: number;
    membershipId: number;
    communicationActivityId: number;
    message: string;
    callDate: Moment;
    createdAt: Moment;
    updatedAt: Moment;

    membership: Membership;
    communicationActivity: CommunicationActivity;

    constructor(phoneCall: any) {
        if (phoneCall.id) {
            this.id = phoneCall.id;
        }
        if (phoneCall.membershipId) {
            this.membershipId = phoneCall.membershipId;
        }
        if (phoneCall.communicationActivityId) {
            this.communicationActivityId = phoneCall.communicationActivityId;
        }
        if (phoneCall.message) {
            this.message = phoneCall.message;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (phoneCall.callDate) {
            this.callDate = moment.utc(phoneCall.callDate, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (phoneCall.createdAt) {
            this.createdAt = moment.utc(phoneCall.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (phoneCall.updatedAt) {
            this.updatedAt = moment.utc(phoneCall.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (phoneCall.membership) {
            this.membership = new Membership(phoneCall.membership);
        }
        if (phoneCall.communicationActivity) {
            this.communicationActivity = new CommunicationActivity(phoneCall.communicationActivity);
        }
    }
}
