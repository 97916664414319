import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { AuthLayoutComponent } from './auth-layout.component';

const routes: Routes = [
    {
        path: 'sign-up',
        component: AuthLayoutComponent,
        // loadChildren: './login-maintenance-mode/login-maintenance-mode.module'
        loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpModule),
    },

    // legacy join/* redirects to sign-up
    { path: 'join', redirectTo: '/sign-up', pathMatch: 'full' },
    { path: 'join/:org_url', redirectTo: '/sign-up/:org_url', pathMatch: 'full' },

    {
        path: 'auth',
        component: AuthLayoutComponent,
        children: [
            {
                matcher: (url: UrlSegment[]) => {
                    return url[0].path === 'login' ? {consumed: url} : null;
                },
                loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
            },
            {
                path: 'activate/:memberId/:n',
                loadChildren: () => import('./member-activation/member-activation.module').then(m => m.MemberActivationModule),
            },
            {
                path: 'payment-login',
                // loadChildren: './login-maintenance-mode/login-maintenance-mode.module'
                loadChildren: () => import('./payment-login/payment-login.module').then(m => m.PaymentLoginModule),
            },
            {
                path: 'renew',
                // loadChildren: './login-maintenance-mode/login-maintenance-mode.module'
                loadChildren: () => import('./renewal/renewal.module').then(m => m.RenewalModule),
            },
            {
                path: 'password/request-reset-email',
                loadChildren: () => import('./password-reset/request-reset-email.module').then(m => m.PasswordResetEmailFormModule),
            },
            {
                path: 'password/reset',
                loadChildren: () => import('./password-reset/reset-password.module').then(m => m.ResetPasswordFormModule),
            },
            {
                path: 'oauth/callback',
                loadChildren: () => import('./shared/oauth/oauth.module').then(m => m.OAuthModule),
            },
            {
                path: 'verification-notice',
                loadChildren: () => import('./verification-notice/verification-notice.module').then(m => m.VerificationNoticeModule),
            },

            // legacy auth/* redirects to sign-up
            { path: 'join', redirectTo: '/sign-up', pathMatch: 'full' },
            { path: 'join/:org_url', redirectTo: '/sign-up/:org_url', pathMatch: 'full' },
            { path: 'signup', redirectTo: '/sign-up', pathMatch: 'full' },

            // error handling
            { path: '', redirectTo: '/error', pathMatch: 'full' },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthLayoutRoutingModule
{ }
