import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { BillPayment } from './bill-payment';

export class LobBankAccount
{
    id: number;
    propertyManagementCompanyId: number = null;
    organizationId: number = null;
    membershipId: number;
    lobAccountId: string;
    bankName: string;
    description: string;
    signatory: string;
    routingNumber: string;
    accountNumber: string;
    accountNumberSalt: string;
    last4: string;

    verified: Moment;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    BillPayments: BillPayment[];

    nextCheckNumber: number;

    constructor(lobBankAccount: any) {
        if (lobBankAccount.id) {
            this.id = lobBankAccount.id;
        }
        if (lobBankAccount.propertyManagementCompanyId) {
            this.propertyManagementCompanyId = lobBankAccount.propertyManagementCompanyId;
        }
        if (lobBankAccount.organizationId) {
            this.organizationId = lobBankAccount.organizationId;
        }
        if (lobBankAccount.membershipId) {
            this.membershipId = lobBankAccount.membershipId;
        }
        if (lobBankAccount.lobAccountId) {
            this.lobAccountId = lobBankAccount.lobAccountId;
        }
        if (lobBankAccount.bankName) {
            this.bankName = lobBankAccount.bankName;
        }
        if (lobBankAccount.description) {
            this.description = lobBankAccount.description;
        }
        if (lobBankAccount.signatory) {
            this.signatory = lobBankAccount.signatory;
        }
        if (lobBankAccount.routingNumber) {
            this.routingNumber = lobBankAccount.routingNumber;
        }
        if (lobBankAccount.accountNumber) {
            this.accountNumber = lobBankAccount.accountNumber;
            this.last4 = this.accountNumber.slice(this.accountNumber.length - 4, this.accountNumber.length);
        }
        if (lobBankAccount.last4) {
            this.last4 = lobBankAccount.last4;
        }
        if (lobBankAccount.accountNumberSalt) {
            this.accountNumberSalt = lobBankAccount.accountNumberSalt;
        }
        if (lobBankAccount.nextCheckNumber) {
            this.nextCheckNumber = lobBankAccount.nextCheckNumber;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (lobBankAccount.verified) {
            this.verified = moment.utc(lobBankAccount.verified, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (lobBankAccount.createdAt) {
            this.createdAt = moment.utc(lobBankAccount.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (lobBankAccount.updatedAt) {
            this.updatedAt = moment.utc(lobBankAccount.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (lobBankAccount.deletedAt) {
            this.deletedAt = moment.utc(lobBankAccount.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (lobBankAccount.BillPayments) {
            this.BillPayments = lobBankAccount.BillPayments.map(
                    (billPayment) => {
                        return new BillPayment(billPayment);
                    },
            );
        }
    }
}
