import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { User } from './user';
import { ViolationStatement } from './violation-statement';

export class ViolationStatementComment
{
    id: number;
    violationStatementId: number;
    userId: number;
    message: string;
    isAdmin: boolean = false;

    createdAt: moment.Moment;
    updatedAt: moment.Moment;
    deletedAt: moment.Moment;

    violation: ViolationStatement;
    user: User;

    constructor(comment: any) {
        if (comment.id) {
            this.id = comment.id;
        }
        if (comment.isAdmin) {
            this.isAdmin = comment.isAdmin;
        }
        if (comment.violationStatementId) {
            this.violationStatementId = comment.violationStatementId;
        }
        if (comment.userId) {
            this.userId = comment.userId;
        }
        if (comment.message) {
            this.message = comment.message;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (comment.createdAt) {
            this.createdAt = moment.utc(comment.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (comment.updatedAt) {
            this.updatedAt = moment.utc(comment.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (comment.deletedAt) {
            this.deletedAt = moment.utc(comment.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (comment.violation) {
            this.violation = new ViolationStatement(comment.violation);
        }
        if (comment.user) {
            this.user = new User(comment.user);
        }
    }

    get authorName() {
        return this.user?.name || this.user?.email || 'Unknown';
    }
}
