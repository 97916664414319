import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Location } from '@angular/common';
import environment from '../../../environments/environment';
import { Base64Service } from './base64.service';
import { LocalService } from '../storage/local.service';
import { JwtLegFiClaims } from './jwt-legfi-claims.model';
import { RouteModules } from '../../enums/route-modules.enum';
import { Base64 } from 'js-base64';

@Injectable({
    providedIn: 'root',
})
export class LegFiJwtService
{
    protected _location: Location;
    protected _router: Router;

    constructor(_location: Location, _router: Router) {
        this._location = _location;
        this._router = _router;
    }

    public static isMasterOrg(orgId: number): boolean {
        return [
            2401,
            2402,
        ].includes(orgId);
    }

    public static isDemoAccount(orgId: number): boolean {
        const internal = [
            2613,
            3121,
            3582,
        ];
        return internal.includes(orgId);
    }

    public static featureEnabled(feat: string) {
        const claims = LegFiJwtService.read();
        return claims.superUser || claims?.ff?.includes(feat);
    }

    /**
     * Gets the JWT string in HTML5 local storage if present and returns.
     * Returns null if no JWT is stored.
     * @returns {string}
     */
    public static raw(): string {
        return LocalService.get('legfi-jwt');
    }

    /**
     * Clear the Legfi JWT from storage.
     */
    public static clear(): void {
        LocalService.destroy('legfi-jwt');
    }

    /**
     * Stores JWT string in HTML5 local storage.
     * @param {string} jwt
     */
    public static store(jwt: string): void {
        LocalService.set('legfi-jwt', jwt);
    }

    /**
     * Attempts to read the stored JWT. Returns null if not found or the Claims Object otherwise.
     * @returns {JwtLegFiClaims}
     */
    public static read(): JwtLegFiClaims {
        // Retrieve JWT from storage.
        const jwt: string = LocalService.get('legfi-jwt');
        if (jwt === null || jwt.length === 0) {
            return null;
        }

        // Terminate early if we don't have 3 JWT parts.
        //noinspection JSMismatchedCollectionQueryUpdate
        const jwtParts: string[] = jwt.split('.');
        if (jwtParts.length !== 3) {
            LegFiJwtService.clear();
            return null;
        }

        // Decode JWT.
        const payload: any = LegFiJwtService.decodeToken(jwt);
        let legfi: any = {};
        if (!payload.hasOwnProperty('legfi')) {
            LegFiJwtService.clear();
            return null;
        } else {
            legfi = payload['legfi'];
        }

        // Checks against some standard JWT claims.
        if (payload.hasOwnProperty('exp')) {
            const expires: number = payload['exp'];
            const now = Math.round((new Date().getTime()) / 1000);
            if (expires < now) {
                // console.info('Jwt has expired, returning to login.');
                LegFiJwtService.clear();
                return null;
            }
        }
        if (payload.hasOwnProperty('nbf')) {
            const notBefore: number = payload['nbf'];
            const now = Math.round((new Date().getTime()) / 1000);
            if (notBefore > now) {
                // console.info('Jwt is not authorized yet.');
                LegFiJwtService.clear();
                return null;
            }
        }

        // 'super' is a reserved word in typescript, so rename this field.
        if (legfi.hasOwnProperty('super')) {
            legfi.superUser = legfi.hasOwnProperty('super') && !!legfi['super'];
            delete legfi['super'];
        }

        // Flatten names.
        if (legfi.hasOwnProperty('name')) {
            legfi.givenNames = legfi.hasOwnProperty('name') && legfi['name'].hasOwnProperty('given')
                    ? legfi['name']['given']
                    : '';
            legfi.familyName = legfi.hasOwnProperty('name') && legfi['name'].hasOwnProperty('family')
                    ? legfi['name']['family']
                    : '';
            delete legfi['name'];
        }

        // Check if this is used for Demo
        legfi.isDemoAccount = LegFiJwtService.isDemoAccount(legfi.orgId);

        // Cast payload to JWT LegFi Claims object and return.
        return <JwtLegFiClaims>legfi;
    }

    /**
     * @param jwt
     * @returns {any}
     */
    public static decodeToken(jwt: string) {
        // Decode JWT.
        const jwtParts: string[] = jwt.split('.');
        if (jwtParts.length !== 3) {
            LegFiJwtService.clear();
            return null;
        }

        // Decode the payload.
        let decodedJwt: string = Base64.decode(jwtParts[1]).trim();

        // Trim end of payload after final closing curly brace in case our fallback got triggered.
        // Fixes issue with base64 encode polyfill leaving a null termination character at end.
        if (decodedJwt[decodedJwt.length - 1] !== '}') {
            decodedJwt = decodedJwt.substr(0, decodedJwt.lastIndexOf('}') + 1);
        }

        return JSON.parse(decodedJwt);
    }

    /**
     * @param {string} moduleName
     * @param {boolean} requiresWrite
     * @returns {boolean}
     */
    public static doesUserHaveModulePermission(moduleName: string, requiresWrite: boolean): boolean {
        const jwt = LegFiJwtService.read();

        if (jwt === null) {
            return false;
        }

        if ((moduleName === RouteModules.SUPER_ADMIN) && !jwt.superUser) {
            return false;
        }

        if (jwt.superUser || jwt.admin) {
            return true;
        }

        if (jwt.perms === null || jwt.perms.length < 1) {
            return false;
        }

        for (const p in jwt.perms) {
            if (jwt.perms.hasOwnProperty(p)) {
                if (jwt.perms[p].name === moduleName) {
                    return (requiresWrite) ? jwt.perms[p].writable : true;
                }
            }
        }

        return false;
    }

    public static mayUserBackdateRecurringCharges() {
        const jwt = LegFiJwtService.read();

        if (jwt === null) {
            return false;
        }
        return jwt.superUser || jwt.id === 234690; // team@yourhoahelp.com
    }

    /**
     * @returns {string}
     */
    public static getTimezone() {
        const jwt = LegFiJwtService.read();
        if (jwt !== null) {
            return jwt.orgTimezone;
        }

        return environment.DefaultTimezone;
    }

    /**
     * Performs redirect if static read of JWT produces null.
     * @returns {JwtLegFiClaims}
     */
    public readOrRedirect(): JwtLegFiClaims {
        const jwt = LegFiJwtService.read();
        if (jwt !== null) {
            return jwt;
        }

        // Detect the page that fired the request and use that as redirect URI.
        const uri = this._location.path();
        const loginView = ['/auth/login'];
        loginView.push(<any>{uri: encodeURIComponent(uri)});
        const tree: UrlTree = this._router.createUrlTree(loginView);
        // noinspection JSIgnoredPromiseFromCall
        this._router.navigateByUrl(tree.toString());

        return null;
    }
}
