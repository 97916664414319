import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { SurveyQuestion } from './survey-question';
import { Membership } from './membership';
import Moment = moment.Moment;

export class SurveyQuestionChoice
{
    id: number;
    surveyQuestionId: number;
    choiceText: string;
    members: Membership[] = [];
    createdAt: Moment;
    updatedAt: Moment;
    optionLetter: string;

    question: SurveyQuestion;
    imageUrl: string;
    uploadedFileId: number;

    constructor(choice: any) {
        if (choice.id) {
            this.id = choice.id;
        }
        if (choice.surveyQuestionId) {
            this.surveyQuestionId = choice.surveyQuestionId;
        }
        if (choice.choiceText) {
            this.choiceText = choice.choiceText;
        }
        if (choice.optionLetter) {
            this.optionLetter = choice.optionLetter;
        }
        if (choice.imageUrl) {
            this.imageUrl = choice.imageUrl;
        }
        if (choice.uploadedFileId) {
            this.uploadedFileId = choice.uploadedFileId;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (choice.createdAt) {
            this.createdAt = moment.utc(choice.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (choice.updatedAt) {
            this.updatedAt = moment.utc(choice.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (choice.question) {
            this.question = new SurveyQuestion(choice.question);
        }

        if (choice.members) {
            this.members = choice.members.map(m => new Membership(m));
        }
    }
}
