import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleSwitchComponent } from './toggle-switch.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ToggleSwitchComponent],
    exports: [ToggleSwitchComponent],
})
export class ToggleSwitchModule
{
}
