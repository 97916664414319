import { Component } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
    selector: 'app-feature-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class FeatureButtonComponent extends ButtonComponent {
    constructor() {
        super();
        this.type = 'primary';
        this.hostClass = 'feature-button';
        this.buttonClassesCallback = () => this.buttonClassSet.add('mx-auto');
    }
}
