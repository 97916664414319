import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { User } from './user';

export class Nonce
{
    id: number;
    userId: number;
    nonce: string;
    createdAt: Moment;
    updatedAt: Moment;

    user: User;

    constructor(nonce: any) {
        if (nonce.id) {
            this.id = nonce.id;
        }
        if (nonce.userId) {
            this.userId = nonce.userId;
        }
        if (nonce.nonce) {
            this.nonce = nonce.nonce;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (nonce.createdAt) {
            this.createdAt = moment.utc(nonce.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (nonce.updatedAt) {
            this.updatedAt = moment.utc(nonce.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (nonce.user) {
            this.user = new User(nonce.user);
        }
    }
}
