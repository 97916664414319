<ng-template #nativeInput>
    <div class="currency-input">
        <img
            (click)="input.focus()"
            *ngIf="showSymbol && (currency === 'usd' || currency === 'cad')"
            src="/assets/images/payhoa/other/dollar.svg"
        />
        <!-- suppress HtmlUnknownAttribute -->
        <input
            #input
            (blur)="setValueFromDisplay($event.target.value); blurred.emit(true)"
            (focus)="$event.target.select()"
            [(ngModel)]="displayAmount"
            [attr.required]="required"
            [class.dollar-padding]="showSymbol && (currency === 'usd' || currency === 'cad')"
            [class.error]="error"
            [class.form-control-sm]="!showSymbol"
            [class.no-money-padding]="!showSymbol"
            [disabled]="disabled"
            [readonly]="readonly"
            class="form-control form-control-sm"
            [attr.name]="name"
            placeholder="{{ placeholder }}"
            type="text"
        />
    </div>
</ng-template>

<ng-container *ngIf="formGroup; else nativeInput">
    <ng-container [formGroup]="formGroup">
        <div class="currency-input">
            <img *ngIf="showSymbol && (currency === 'usd' || currency === 'cad')"
                 src="/assets/images/payhoa/other/dollar.svg"
                 [style.top.px]="10"
                 (click)="input.focus()" />
            <input #input
                   class="form-control form-control-sm"
                   mask="separator.2"
                   thousandSeparator=","
                   type="text"
                   [allowNegativeNumbers]="negativeAmounts"
                   [formControlName]="controlName"
                   [class.dollar-padding]="showSymbol && (currency === 'usd' || currency === 'cad')"
                   [class.form-control-sm]="!showSymbol"
                   [class.no-money-padding]="!showSymbol"
                   [placeholder]="placeholder">
        </div>
    </ng-container>
</ng-container>
