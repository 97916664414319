import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ScrollService } from '../../../../services/scroll.service';

@Component({
    selector: 'app-feature-payables',
    templateUrl: './feature-payables.component.html',
})
export class FeaturePayablesComponent implements OnInit
{
    @Output() getStartedEnrollment: EventEmitter<any> = new EventEmitter();

    constructor(
            private _scrollService: ScrollService,
    ) {
    }

    ngOnInit(): void {
        // Scroll to top on view load.
        this._scrollService.scrollToTop();
    }
}
