import { FormQuestionOption } from './form-question-option';
import { FormQuestionType, FormQuestionTypeEnum } from './form-question-type';

const formTypes = FormQuestionTypeEnum;

/**
 * Class to map form builder form elements (Form Array > Form Group >  input values],
 * to the matching api FormQuestion class. Comes back from db, maps to FormlyQuestion,
 * and from there, Formly Question renders the form to the user.
 */
export class FormQuestion
{
    id: number;
    formId: number;
    sortOrder: number;
    key: string;
    label: string;
    description: string;
    isRequired?: boolean;
    isEnabled?: boolean;
    isMultiselect?: boolean;
    type: string;
    options: FormQuestionOption[] = [];
    icon?: string;

    /**
     * Constructor, comes from a form group set of values, or from the database.
     *
     * @param request
     */
    constructor(request: any) {
        this.id = request.id || 0;
        this.formId = request.formId || 0;
        this.sortOrder = request.sortOrder || 1;
        this.key = request.key || '';
        this.label = request.label || '';
        this.description = request.description || '';
        this.isRequired = request.isRequired || false;
        this.isEnabled = request.isEnabled || false;
        this.isMultiselect = (request.isMultiselect === 1) || request.isMultiselect || false;

        // it will take 'typeName' if coming from the create form; or 'type' as string coming from database
        this.type = request.typeName || request.type;
        if (this.type === 'multiselect') {
            // multiselect is not a real type
            this.type = 'select';
            this.isMultiselect = true;
        }

        if (request.options && request.options.length) {
            this.options = request.options.map(o => new FormQuestionOption(o));
        }

        // set the icon here for easy display when re-rendering Questions with Answers
        this.icon = formTypes.find((ft: FormQuestionType) => ft.type === this.type).icon;
    }
}
