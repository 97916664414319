import { NgModule } from '@angular/core';
import { MarketingLayoutComponent } from './marketing-layout.component';
import { MarketingLayoutRoutingModule } from './marketing-layout.routing';

@NgModule({
    imports: [
        MarketingLayoutRoutingModule,
    ],
    declarations: [
        MarketingLayoutComponent,
    ],
})
export class MarketingLayoutModule
{
}
