import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import moment from 'moment-timezone';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface IRequestOptions
{
    headers?: HttpHeaders;
    observe?: any;
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
    body?: any;
}

export function applicationHttpClientFactory(http: HttpClient, location: Location, router: Router) {
    return new ApplicationHttpClient(http, location, router);
}

@Injectable({
    providedIn: 'root',
})
export class ApplicationHttpClient
{
    public static instance;
    private _location: Location;
    private _router: Router;

    constructor(
            public http: HttpClient,
            _location: Location,
            _router: Router,
    ) {
        this._location = _location;
        this._router = _router;
        ApplicationHttpClient.instance = this;
    }

    public get<T>(
            url: string,
            options?: IRequestOptions,
    ): Observable<T> {
        return this.http.get<T>(url, options);
    }

    public getPdf(url: string) {
        const newHeaders = new HttpHeaders({
            'Content-Type': 'application/pdf',
        });

        const options: Object = {
            headers: newHeaders,
            responseType: 'arraybuffer',
        };

        return this.get(url, options);
    }

    public post<T>(
            url: string,
            body: string,
            options?: IRequestOptions,
    ): Observable<T> {
        options = options || <IRequestOptions>{};
        options.body = body;
        return this.http.post<T>(
                url,
                body,
                options,
        );
    }

    public put<T>(
            url: string,
            body: string,
            options?: IRequestOptions,
    ): Observable<Object> {
        options = options || <IRequestOptions>{};
        options.body = body;
        return this.http.put<T>(
                url,
                body,
                options,
        );
    }

    public patch<T>(
            url: string,
            body: string,
            options?: IRequestOptions,
    ): Observable<Object> {
        options = options || <IRequestOptions>{};
        options.body = body;
        return this.http.patch<T>(
                url,
                body,
                options,
        );
    }

    //noinspection ReservedWordAsName
    public delete<T>(
            url: string,
            options?: IRequestOptions,
    ): Observable<Object> {
        return this.http.delete<T>(
                url,
                options,
        );
    }

    public redirectAndThrow401Observable() {
        // Detect the page that fired the request and use that as redirect URI.
        const uri = this._location.path();
        const loginView = ['/auth/login'];
        loginView.push(<any>{uri: encodeURIComponent(uri)});
        const tree = this._router.createUrlTree(loginView);

        //noinspection JSIgnoredPromiseFromCall
        this._router.navigateByUrl(tree.toString());

        return throwError(new HttpErrorResponse({
            error: JSON.stringify({
                errors: [
                    {
                        msg: 'You must be logged in to perform this action.',
                        dev: 'No JWT available.',
                    },
                ],
            }),
            status: 401,
            statusText: 'Unauthorized',
        }));
        // return observableThrowError(HttpConstants.UnauthorizedResponse);
    }

    public redirectAndThrow401Promise() {
        return this.redirectAndThrow401Observable().toPromise();
    }

    public serializeObject(data: { [key: string]: any }): string {
        for (const param in data) {
            if (data[param] instanceof moment) {
                data[param] = data[param].format('YYYY-MM-DD');
            }
        }
        return JSON.stringify(data);
    }

    public queryStringFromObject(obj: { [key: string]: any }): string {
        return this.makeQueryString(this.makeQueryParameters(obj));
    }

    public makeQueryString(parameters: string[]): string {
        return parameters.length > 0 ? '?' + parameters.join('&') : '';
    }

    public makeQueryParameters(filters: { [key: string]: any }): string[] {
        const queryParameters: string[] = [];
        for (const param in filters) {
            if (filters[param] instanceof moment) {
                queryParameters.push(
                        param + '=' + filters[param].format('YYYY-MM-DD'),
                );
            } else if (Array.isArray(filters[param])) {
                queryParameters.push(param + '=' + filters[param].join(','));
            } else {
                queryParameters.push(param + '=' + filters[param]);
            }
        }
        return queryParameters;
    }
}
