import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Activity } from './activity';

export class PaymentAttemptAudit
{
    id: number;
    activityId: number;
    modifiedField: string;
    oldValue: string;
    newValue: string;
    createdAt: Moment;
    updatedAt: Moment;

    activity: Activity;

    constructor(paymentAttemptAudit: any) {
        if (paymentAttemptAudit.id) {
            this.id = paymentAttemptAudit.id;
        }
        if (paymentAttemptAudit.activityId) {
            this.activityId = paymentAttemptAudit.activityId;
        }
        if (paymentAttemptAudit.modifiedField) {
            this.modifiedField = paymentAttemptAudit.modifiedField;
        }
        if (paymentAttemptAudit.oldValue) {
            this.oldValue = paymentAttemptAudit.oldValue;
        }
        if (paymentAttemptAudit.newValue) {
            this.newValue = paymentAttemptAudit.newValue;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (paymentAttemptAudit.createdAt) {
            this.createdAt = moment.utc(paymentAttemptAudit.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (paymentAttemptAudit.updatedAt) {
            this.updatedAt = moment.utc(paymentAttemptAudit.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (paymentAttemptAudit.activity) {
            this.activity = new Activity(paymentAttemptAudit.activity);
        }
    }
}
