import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface LoadingDialogInput
{
    title: string;
}

@Component({
    selector: 'app-loading-dialog',
    templateUrl: './loading-dialog.component.html',
})
export class LoadingDialogComponent implements OnInit
{

    constructor(
            private _dialogRef: MatDialogRef<LoadingDialogComponent>,
            @Inject(MAT_DIALOG_DATA) public data: LoadingDialogInput,
    ) {
    }

    ngOnInit() {
    }

}
