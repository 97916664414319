<div *ngIf="isLoggedIn" class="sidebar-sticky">
    <div class="nav flex-column">
        <ng-template [ngForOf]="appNavigation$ | async" let-li ngFor>
            <div *ngIf="li.isVisibleRoute && li.id !== appNavigationKeys.LOG_OUT"
                class="nav-item nav-item-with-mat-icon animated fadeInTop"
                [class.mb-3]="li.hasMarginBelow"
                (click)="updateSideNav()">
                <app-sidenav-link [svg]="'sidenav:icon_' + li.iconString"
                                  [attr.data-intercom-target]="li.displayText + ' Page Menu'"
                                  [isActive]="li.isActiveRoute"
                                  (onClick)="redirectToExternal(li)"
                                  [routeTo]="li.externalUrl ? '' : [li.routeString, li.queryParams]">{{li.displayText}}</app-sidenav-link>
            </div>
            <div *ngIf="li.isVisibleRoute && li.id === appNavigationKeys.LOG_OUT">
                <hr class="mt-4 mb-3">
                <app-sidenav-link [icon]="'logout'"
                                  [attr.data-intercom-target]="'Log Out'"
                                  (onClick)="logout()">{{li.displayText}}</app-sidenav-link>
            </div>
        </ng-template>
    </div>
</div>

