import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { ButtonComponent } from './button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { BackButtonComponent } from './button.back.component';
import { AddButtonComponent } from './button.add.component';
import { SpinnerButtonComponent } from './button.spinner.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SidenavLinkComponent } from './button.sidenav.component';
import { SuperButtonComponent } from './button.super.component';
import { SelectionButtonComponent } from './button.selection.component';
import { CardButtonComponent } from './button.card.component';
import { FeatureButtonComponent } from './button.feature.component';
import { CheckmarkButtonComponent } from './button.checkmark.component';
import { IconButtonComponent } from './button.icon.component';
import { UnitButtonComponent } from './button.unit.component';
import { OwnerButtonComponent } from './button.owner.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
    ],
    declarations: [
        ButtonComponent,
        BackButtonComponent,
        AddButtonComponent,
        SidenavLinkComponent,
        SpinnerButtonComponent,
        SuperButtonComponent,
        SelectionButtonComponent,
        CardButtonComponent,
        FeatureButtonComponent,
        CheckmarkButtonComponent,
        IconButtonComponent,
        UnitButtonComponent,
        OwnerButtonComponent,
    ],
    exports: [
        ButtonComponent,
        BackButtonComponent,
        AddButtonComponent,
        SidenavLinkComponent,
        SpinnerButtonComponent,
        SuperButtonComponent,
        SelectionButtonComponent,
        CardButtonComponent,
        FeatureButtonComponent,
        CheckmarkButtonComponent,
        IconButtonComponent,
        UnitButtonComponent,
        OwnerButtonComponent,
    ],
})
export class ButtonModule {
}
