<app-header-bar [layout]="'admin'"
                (toggleSidenav)="sidenav.toggle()"></app-header-bar>

<mat-sidenav-container class="payhoa-app-container">
    <mat-sidenav #sidenav="matSidenav"
                 class="payhoa-sidenav"
                 [mode]="isInMobileMode ? 'over' : 'side'"
                 [disableClose]="!isInMobileMode">
        <app-page-menu [isAdminMenu]="true"
                       (toggleSidenav)="handleSidenavClickOnMobile()"></app-page-menu>
    </mat-sidenav>
    <mat-sidenav-content class="payhoa-content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
