import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { Routes } from '../../config/routes';
import { map } from 'rxjs/operators';
import { DepositBankAccount } from '../../models/entities/deposit-bank-account';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DepositBankAccountsService
{
    private _http: ApplicationHttpClient;

    constructor(_http: ApplicationHttpClient) {
        this._http = _http;
    }

    getStripeLink(depositAccount: DepositBankAccount) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrgBankAccount(jwt.orgId, depositAccount.id) + '/verify/link');

        return this._http.get(url);
    }

    index(): Observable<DepositBankAccount[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrgDepositAccounts(jwt.orgId));

        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map((bank) => new DepositBankAccount(bank));
        }));
    }

}
