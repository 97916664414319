/**
 * A single stored answer to a custom form question; lives on form submission.
 */
import { FormQuestion } from './form-question';
import { UploadedFile } from 'app/models/entities/uploaded-file';

export class FormAnswer
{
    id: number;
    formSubmissionId: number;
    formQuestionId: number;
    answer: string;
    question: FormQuestion;
    files: UploadedFile[] = [];

    constructor(request: any) {
        this.id = request.id;
        this.formSubmissionId = request.formSubmissionId;
        this.formQuestionId = request.formQuestionId;
        this.answer = request.answer;

        if (request.question) {
            this.question = new FormQuestion(request.question);
        }

        if (request.files) {
            this.files = request.files.map((f) => new UploadedFile(f));
        }
    }
}
