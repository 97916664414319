import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MaintenanceModeDialogComponent } from './maintenance-mode-dialog.component';

@NgModule({
    declarations: [
        MaintenanceModeDialogComponent,
    ],
    imports: [
        SharedModule,
        MatDialogModule,
    ],
})
export class MaintenanceModeDialogModule
{
}
