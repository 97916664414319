import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
    selector: 'app-add-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class AddButtonComponent extends ButtonComponent implements OnChanges {
    constructor() {
        super();
        this.type = 'primary';
        this.icon = 'add';
        this.hostClass += ' add-button';
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);

        if ('type' in changes) {
            this.type = changes.type.currentValue;
        }
    }
}
