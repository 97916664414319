import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '../button/button.module';
import { JumbotronComponent } from './jumbotron.component';

@NgModule({
    declarations: [
        JumbotronComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        RouterModule,
        ButtonModule,
    ],
    exports: [
        JumbotronComponent
    ],
    providers: [],
})
export class JumbotronModule
{ }
