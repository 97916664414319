import Swal from 'sweetalert2';

const StyledAlert = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger',
        closeButton: 'mt-3',
        // container: 'alert-container',
        popup: 'p-4',
        // content: 'alert-content',
        actions: 'd-flex justify-content-between flex-wrap mt-4 w-100',
    },
    backdrop: 'rgba(1,7,25,0.7)',
    buttonsStyling: false,
    showCloseButton: false,
    reverseButtons: true,
});

export default StyledAlert;
