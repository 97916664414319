import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturePayablesComponent } from './feature-payables.component';
import { ButtonModule } from '../../../shared/button/button.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        FeaturePayablesComponent,
    ],
    exports: [
        FeaturePayablesComponent,
    ],
    imports: [
        CommonModule,
        ButtonModule,
        MatIconModule,
    ],
})
export class FeaturePayablesModule
{
}
