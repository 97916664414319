export enum RouteModules
{
    // special cases, not real modules
    SUPER_ADMIN = 'super_admin',
    ORG_ADMIN = 'org_admin',
    // real modules
    ARCHITECTURAL_REQUESTS = 'architectural-requests',
    BILLPAY = 'billpay',
    BUDGETS = 'budgets',
    CALENDAR = 'calendar',
    CHARGES = 'charges',
    COLLECTIONS = 'collections',
    COMMUNICATION = 'communication',
    CROWDFUNDING = 'crowdfunding',
    DASHBOARD = 'dashboard',
    DOCUMENTS = 'documents',
    FORMS = 'forms',
    GENERAL_REQUESTS = 'general-requests',
    MAINTENANCE_REQUESTS = 'maintenance-requests',
    MEMBERS = 'members',
    MESSAGE_BOARD = 'message-board',
    MESSAGING = 'messaging',
    PAYMENTS = 'payments',
    REPORTS = 'reports',
    SUPPORT = 'support',
    SURVEYS = 'surveys',
    TRANSACTIONS = 'transactions',
    UNIT = 'unit',
    VIOLATION = 'violation',
    WEBSITE_BUILDER = 'website-builder',
    MEMBERSHIP_DIRECTORY = 'membership-directory',
    COMMUNITIES = 'communities',
    REQUEST_FORMS = 'request-forms',
    GROUPS = 'property_management_groups',
}
