import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'legfiJson',
})
export class LegfiJsonPipe implements PipeTransform
{
    public transform(value: string, args: any[] = []) {
        if (value === null) {
            return '';
        }

        return JSON.stringify(value, null, 4);
    }
}
