import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';

export class BillPayTrackingEvent
{
    id: number;
    billPaymentId: number;
    name: string;
    location: number;
    eventTime: Moment;
    createdAt: Moment;
    updatedAt: Moment;

    constructor(event: any) {
        if (event.id) {
            this.id = event.id;
        }
        if (event.billPaymentId) {
            this.billPaymentId = event.billPaymentId;
        }
        if (event.name) {
            this.name = event.name;
        }
        if (event.location) {
            this.location = event.location;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (event.eventTime) {
            this.eventTime = moment.utc(event.eventTime, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (event.createdAt) {
            this.createdAt = moment.utc(event.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (event.updatedAt) {
            this.updatedAt = moment.utc(event.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
    }
}
