import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { PaymentBankAccount } from './payment-bank-account';
import { CreditCard } from './credit-card';
import Moment = moment.Moment;

export class Site
{
    id: number;
    paymentMethodId: number;
    paymentMethodType: string;
    name: string;
    domain: string;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    paymentMethod: PaymentBankAccount | CreditCard;

    constructor(site: any) {
        if (site.id) {
            this.id = site.id;
        }
        if (site.paymentMethodId) {
            this.paymentMethodId = site.paymentMethodId;
        }
        if (site.paymentMethodType) {
            this.paymentMethodType = site.paymentMethodType;
        }
        if (site.name) {
            this.name = site.name;
        }
        if (site.domain) {
            this.domain = site.domain;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (site.createdAt) {
            this.createdAt = moment.utc(site.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (site.updatedAt) {
            this.updatedAt = moment.utc(site.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (site.deletedAt) {
            this.deletedAt = moment.utc(site.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (site.paymentMethod && site.paymentMethodType !== null) {
            if (site.paymentMethodType.indexOf('PaymentBankAccount') !== -1) {
                this.paymentMethod = new PaymentBankAccount(site.paymentMethod);
            } else if (site.paymentMethodType.indexOf('CreditCard') !== -1) {
                this.paymentMethod = new CreditCard(site.paymentMethod);
            }
        }
    }
}
