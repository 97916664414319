/**
 * Permission to a specific form.
 */
import { Membership } from '../membership';

export class FormPermission
{
    id: number;
    membershipId: number;
    formId: string;
    membership: Membership;
    isReadable: boolean;
    isWritable: boolean;
    hasFullFormReadAccess: boolean;
    hasFullFormWriteAccess: boolean;
    isAdmin: boolean;
    isFormApprover: boolean;

    constructor(request: any) {
        this.id = request.id;
        this.membershipId = request.membershipId;
        this.formId = request.formId;
        this.isReadable = request.isReadable;
        this.isWritable = request.isWritable;
        this.hasFullFormReadAccess = request.hasFullFormReadAccess;
        this.hasFullFormWriteAccess = request.hasFullFormWriteAccess;
        this.isAdmin = request.isAdmin;
        this.isFormApprover = request.isFormApprover;

        if (request.membership) {
            this.membership = new Membership(request.membership);
        }
    }
}
