import { ControlValueAccessor } from '@angular/forms';

export abstract class AbstractValueAccessor<T> implements ControlValueAccessor
{

    public disabled: boolean = false;

    protected _value: T = null;
    public get value(): T {
        return this._value;
    }

    public writeValue(value: T) {
        if (value !== this._value) {
            this._value = value;
            this.propagateChange(this._value);
        }
    }

    propagateChange: any = () => {
    };
    propagateTouch: any = () => {
    };

    // noinspection JSUnusedGlobalSymbols
    public touch() {
        this.propagateTouch();
    }

    public registerOnChange(fn) {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn) {
        this.propagateTouch = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
