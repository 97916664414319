import { NgModule } from '@angular/core';
import { appLayoutRouting } from './app-layout.routing.payhoa';
import { AppFooterModule } from './shared/app-footer/app-footer.module';
import { BankAccountsService } from '../../services/financial-accounts/bank-accounts.service';
import { WelcomeModalComponentModule } from './onboarding/welcome-modal/welcome-modal.module';
import { SharedModule } from '../shared/shared.module';
import { OrganizationService } from '../../services/organization/organization.service';
import { HeaderBarModule } from '../shared/header-bar/header-bar.module';
import { PageMenuModule } from './shared/page-menu/page-menu.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AppLayoutModule } from './app-layout.module';
import { MembersService } from '../../services/members/members.service';
import { FeatureDialogModule } from './onboarding/feature-dialog/feature-dialog.module';

@NgModule({
    imports: [
        AppLayoutModule,
        AppFooterModule,
        appLayoutRouting,
        HeaderBarModule,
        MatSidenavModule,
        PageMenuModule,
        SharedModule,
        WelcomeModalComponentModule,
        FeatureDialogModule,
    ],
    providers: [
        BankAccountsService,
        MembersService,
        OrganizationService,
    ],
})
export class AppLayoutModulePayhoa
{
}
