import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { TransformPhoneNoPipe } from '../../../pipes/format/transform-phone-no.pipe';

export const PHONE_VALIDATION_MASK = '000-000-0000';
export const PHONE_EXT_VALIDATION_MASK = '0{5}';

@Directive({
    selector: '[validatePhone][formControlName],[validatePhone][formControl],[validatePhone][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PhoneValidatorDirective),
            multi: true,
        },
    ],
})
export class PhoneValidatorDirective implements Validator
{
    public validate(control: AbstractControl): { [key: string]: any } {
        const transformer = new TransformPhoneNoPipe();
        let value = transformer.transform(control.value, []);
        if (value.length === 0) {
            return null;
        }

        if (value.length > 16) {
            return {invalidPhoneFormat: true};
        }

        value = value.replace(/-/g, '');
        value = value.replace(/[\s]/g, '');
        value = value.replace(/\(/g, '');
        value = value.replace(/\)/g, '');

        const regex = /^[\+]?[\d]{10,15}$/;
        if (!regex.test(value)) {
            return {invalidPhoneFormat: true};
        }

        return null;
    }
}
