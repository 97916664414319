import moment from 'moment-timezone';
import Moment = moment.Moment;
import { UnifiedBankAccount } from './unified-bank-account';
import { Membership } from './membership';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';

export class PayabliPayment {
    id: number;
    payabliUnifiedBankAccountId: number;
    payabliSignerMembershipId: number;
    paymentAmount: number;
    scheduledPaymentDate: Moment;
    sentToPayabliAt: Moment;
    paymentType: string;
    payabliBillNumber: string;
    payabliBillStatus: string;
    payabliBillStatusAt: Moment;
    payabliTransactionNumber: string;
    payabliTransactionStatus: string;
    payabliTransactionStatusAt: Moment;
    payabliEventType: string;
    payabliEventAt: Moment;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;
    account: UnifiedBankAccount;
    signer: Membership;

    constructor(payabliPayment: any) {
        if (payabliPayment.id) {
            this.id = payabliPayment.id;
        }
        this.payabliUnifiedBankAccountId = payabliPayment.payabliUnifiedBankAccountId || null;
        this.payabliSignerMembershipId = payabliPayment.payabliSignerMembershipId || null;
        this.paymentAmount = payabliPayment.paymentAmount || 0;
        this.paymentType = payabliPayment.paymentType || '';
        this.payabliBillNumber = payabliPayment.payabliBillNumber || '';
        this.payabliBillStatus = payabliPayment.payabliBillStatus || '';
        this.payabliTransactionNumber = payabliPayment.payabliTransactionNumber || '';
        this.payabliTransactionStatus = payabliPayment.payabliTransactionStatus || '';
        this.payabliEventType = payabliPayment.payabliEventType || '';

        // timestamps
        const timezone = LegFiJwtService.getTimezone();
        if (payabliPayment.scheduledPaymentDate) {
            this.scheduledPaymentDate = moment(payabliPayment.scheduledPaymentDate).tz(timezone);
        }
        if (payabliPayment.sentToPayabliAt) {
            this.sentToPayabliAt = moment(payabliPayment.sentToPayabliAt).tz(timezone);
        }
        if (payabliPayment.payabliBillStatusAt) {
            this.payabliBillStatusAt = moment(payabliPayment.payabliBillStatusAt).tz(timezone);
        }
        if (payabliPayment.payabliTransactionStatusAt) {
            this.payabliTransactionStatusAt = moment(payabliPayment.payabliTransactionStatusAt).tz(timezone);
        }
        if (payabliPayment.payabliEventAt) {
            this.payabliEventAt = moment(payabliPayment.payabliEventAt).tz(timezone);
        }
        if (payabliPayment.createdAt) {
            this.createdAt = moment.utc(payabliPayment.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (payabliPayment.updatedAt) {
            this.updatedAt = moment.utc(payabliPayment.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (payabliPayment.deletedAt) {
            this.deletedAt = moment.utc(payabliPayment.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        // relationships
        if (payabliPayment.account) {
            this.account = new UnifiedBankAccount(payabliPayment.account);
        }
        if (payabliPayment.signer) {
            this.signer = new Membership(payabliPayment.signer);
        }
    }
}
