export class AllianceCredential
{
    id: number;
    name: string;
    managementCompanyId: string;

    constructor(ref) {
        this.id = ref.id;
        this.name = ref.name;
        this.managementCompanyId = ref.managementCompanyId;
    }
}
