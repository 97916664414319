import { Injectable } from '@angular/core';
import Echo from 'laravel-echo';
import { environment } from 'environments/environment';
import * as client from 'pusher-js';
import { LegFiJwtService } from './auth/legfi-jwt.service';
import { AuthService } from './auth/auth.service';
import { Channel } from 'pusher-js';

// @ts-ignore
window.Pusher = client;

@Injectable({
    providedIn: 'root',
})
export class EchoService
{
    private _echo;

    constructor(
            private _authService: AuthService,
    ) {
    }

    public channel(channel: string) {
        return this.getEchoInstance().channel(channel);
    }

    public private(channel: string) {
        return this.getEchoInstance().private(channel);
    }

    public leave(channel: string) {
        return this.getEchoInstance().leave(channel);
    }

    public leaveChannel(channel: string) {
        return this.getEchoInstance().leaveChannel(channel);
    }

    public getSocketId() {
        if (this._echo && this._echo.socketId()) {
            return this._echo.socketId();
        }
        return null;
    }

    private getEchoInstance() {
        // TODO - fix this so it works on org change
        if (!this._echo) {
            const customAuthorizer = (channel: Channel, options: any): { authorize: Function } => {
                return {
                    authorize: async (socketId: string, callback: Function): Promise<void> => {
                        try {
                            const response = await fetch(environment.LegFiCoreApi + '/broadcasting/auth', {
                                method: 'POST',
                                body: JSON.stringify({
                                    socket_id: socketId,
                                    channel_name: channel.name,
                                }),
                                credentials: 'include',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + LegFiJwtService.raw(),
                                    'X-XSRF-TOKEN': this._authService.getCookie('XSRF-TOKEN'),
                                    'X-Legfi-Site-Id': environment.SiteId.toString(),
                                }
                            });

                            const data = await response.json();

                            // Echo expects these callback signatures in the case of success or failure
                            if (data.auth) {
                                callback(null, data);
                            } else {
                                callback(new Error('Authorization failed'), null);
                            }
                        } catch (error) {
                            // Handle fetch errors
                            callback(error, null);
                        }
                    }
                };
            };

            this._echo = new Echo({
                broadcaster: 'pusher',
                key: environment.PusherAppKey,
                cluster: environment.PusherAppCluster,
                forceTLS: true,
                host: environment.LegFiCoreApi,
                authorizer: customAuthorizer,
            });
        }
        return this._echo;
    }
}
