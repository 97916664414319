import { Component, Input, OnInit } from '@angular/core';
import { Audit } from 'app/models/entities/audit';

@Component({
    selector: 'app-audit-diff-formatter',
    templateUrl: './audit-diff-formatter.component.html',
    styleUrls: ['./audit-diff-formatter.component.scss'],
})
export class AuditDiffFormatterComponent implements OnInit
{

    @Input() audit: Audit;

    constructor() {
    }

    ngOnInit(): void {
    }

}
