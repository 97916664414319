import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { HeaderSearchComponent } from './header-search.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

@NgModule({
    imports: [
        SharedModule,
        MatFormFieldModule,
        MatIconModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatListModule,
        MatInputModule,
    ],
    declarations: [HeaderSearchComponent],
    providers: [],
    exports: [
        HeaderSearchComponent,
    ],
})
export class HeaderSearchModule
{
}
