<span class="d-flex align-content-center">
    <span class="mailing-status my-auto mr-1 p-0"
          [style.height.px]="10"
          [style.width.px]="10"
          [style.background-color]="color"
          [style.border-width.px]="1"
          [style.border-style]="isDisabled ? 'none' : 'solid'"
          [adjustColor]="color"
          [attribute]="'border-color'"
          [adjustment]="adjustColorValues.BORDER"></span>
    <ng-content></ng-content>
</span>
