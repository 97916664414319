import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, Renderer2 } from '@angular/core';

/**
 * Transforms a node into a dropdown.
 */
@Directive({
    selector: '[lfDropdown]',
    exportAs: 'lfDropdown',
})
export class DropdownDirective
{
    public toggleElement: any;
    /**
     * Indicates that the dropdown should open upwards
     */
    @Input()
    up: boolean = false;
    @HostBinding('class.dropup')
    _up: boolean;
    @HostBinding('class.dropdown')
    _down: boolean;
    /**
     * Only used when opening up; need to know how many items are in the dropup, for absolute positioning
     * @type {string}
     */
    @Input() addClass: string = '';
    /**
     * Indicates that dropdown should be closed when selecting one of dropdown items (click) or pressing ESC.
     */
    @Input() autoClose: boolean = true;
    /**
     *  Defines whether or not the dropdown-menu is open initially.
     */
    @Input() public open = false;
    /**
     *  An event fired when the dropdown is opened or closed.
     *  Event's payload equals whether dropdown is open.
     */
    @Output() openChange = new EventEmitter();
    // globalListenFunc will hold the function returned by "renderer.listenGlobal"
    private outsideClickListener: Function;
    /**
     * Binds the open state to the container
     */
    @HostBinding('class.open')
    private _open: boolean = false;

    constructor(private renderer: Renderer2) {
        this.up = false;
        this._up = this.up;
        this._down = !this.up;
        this._open = this.open;
        this.autoClose = true;
    }

    /**
     * Checks if the dropdown menu is open or not.
     */
    isOpen(): boolean {
        return this._open;
    }

    /**
     * Opens the dropdown menu of a given navbar or tabbed navigation.
     */
    openDropdown(): void {
        if (!this._open) {
            this._open = true;
            this.openChange.emit(true);
            // We cache the function "listenGlobal" returns
            this.outsideClickListener = this.renderer.listen('document', 'click', (event) =>
                    this.closeFromOutsideClick(event),
            );
        }
    }

    /**
     * Closes the dropdown menu of a given navbar or tabbed navigation.
     */
    close(): void {
        if (this._open) {
            this._open = false;
            this.openChange.emit(false);
            // Removes "listenGlobal" listener
            this.outsideClickListener();
        }
    }

    /**
     * Toggles the dropdown menu of a given navbar or tabbed navigation.
     */
    toggle(): void {
        if (this.isOpen()) {
            this.close();
        } else {
            this.openDropdown();
        }
    }

    @HostListener('keyup.esc')
    closeFromOutsideClick($event) {
        if (this.autoClose && !this._isEventFromToggle($event)) {
            this.close();
        }
    }

    closeFromOutsideEsc() {
        if (this.autoClose) {
            this.close();
        }
    }

    // /**
    //  * @internal
    //  */
    // set toggleElement(toggleElement:any) {
    //     this._toggleElement = toggleElement;
    // }

    private _isEventFromToggle($event) {
        return !!this.toggleElement && this.toggleElement.contains($event.target);
    }
}

/**
 * Allows the dropdown to be toggled via click. This directive is optional.
 */
@Directive({
    selector: '[lfDropdownToggle]',
    // host: {
    //     'class': 'dropdown-toggle',
    //     'aria-haspopup': 'true',
    //     '[attr.aria-expanded]': 'dropdown.isOpen',
    //     '(click)': 'toggleOpen()'
    // }
})
export class DropdownToggleDirective
{
    @HostBinding('class.dropdown-toggle') toggle: boolean = true;
    @HostBinding('attr.aria-haspopup') popup: boolean = true;

    private dropdownList: any;

    constructor(public dropdown: DropdownDirective, elementRef: ElementRef) {
        dropdown.toggleElement = elementRef.nativeElement;
    }

    @HostBinding('attr.aria-expanded')
    get isExpanded() {
        return this.dropdown.isOpen();
    }

    @HostListener('click', ['$event.target'])
    toggleOpen(target) {
        if (this.isChildOfUl(target)) {
            return;
        }

        this.dropdown.toggle();
    }

    isChildOfUl(item) {
        if (item.classList.contains('dropdown-menu')) {
            return true;
        }
        if (item.parentElement) {
            return this.isChildOfUl(item.parentElement);
        }
        return false;
    }
}

export const NGB_DROPDOWN_DIRECTIVES = [
    DropdownToggleDirective,
    DropdownDirective,
];
