import { forwardRef, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SharedModule } from '../shared.module';
import { LegfiCurrencyPipe } from '../../../pipes/format/legfi-currency.pipe';
import { LegfiCurrencyPipeModule } from '../../../pipes/format/legfi-currency.module';
import { RangeSliderComponent } from './range-slider.component';
import { CurrencyInputModule } from '../currency-input/currency-input.module';

export const RANGE_SLIDER_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RangeSliderComponent),
    multi: true,
};

@NgModule({
    imports: [
        // Shared
        SharedModule,
        // Dependencies
        CurrencyInputModule,
        LegfiCurrencyPipeModule,
    ],
    declarations: [RangeSliderComponent],
    exports: [RangeSliderComponent],
    providers: [
        RANGE_SLIDER_VALUE_ACCESSOR,
        LegfiCurrencyPipe,
    ],
})
export class RangeSliderModule
{
}
