import { VendorCustomFieldValue } from './vendor-custom-field-value';

export class VendorCustomField
{
    id: number;
    organizationId: number;
    name: string;

    fieldValue: VendorCustomFieldValue;
    isNew: boolean = false;

    constructor(vendorCustomField: any) {
        if (vendorCustomField.id) {
            this.id = vendorCustomField.id;
        }
        if (vendorCustomField.organizationId) {
            this.organizationId = vendorCustomField.organizationId;
        }
        if (vendorCustomField.name) {
            this.name = vendorCustomField.name;
        }

        if (vendorCustomField.customFieldValues && vendorCustomField.customFieldValues.length > 0) {
            this.fieldValue = new VendorCustomFieldValue(vendorCustomField.customFieldValues[0]);
        } else {
            this.fieldValue = new VendorCustomFieldValue({});
        }

    }
}
