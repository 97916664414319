import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { OrganizationFile } from './organization-file';

export class TransactionAttachment
{
    id: number;
    transactionId: number;
    url: string;
    filename: string;
    filesize: string;
    icon: string;
    matIcon: { icon: string; color: string; };

    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    constructor(attachment: any) {
        if (attachment.id) {
            this.id = attachment.id;
        }
        if (attachment.transactionId) {
            this.transactionId = attachment.transactionId;
        }
        if (attachment.url) {
            this.url = attachment.url;
        }
        if (attachment.filename) {
            this.filename = attachment.filename;
            this.icon = TransactionAttachment.findFileExtensionIcon(this.filename);

            const iconClasses = this.icon.split(' ');
            const color = iconClasses.pop();
            const icon = iconClasses.pop().replace('fa-', '').replace('-o', '');
            this.matIcon = { icon, color };
        }

        this.filesize = OrganizationFile.convertFileSizeToHumanReadable(attachment.filesize, 1);

        const timezone = LegFiJwtService.getTimezone();
        if (attachment.createdAt) {
            this.createdAt = moment.utc(attachment.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (attachment.updatedAt) {
            this.updatedAt = moment.utc(attachment.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (attachment.deletedAt) {
            this.deletedAt = moment.utc(attachment.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
    }

    private static findFileExtensionIcon(fileName: string) {
        const parts = fileName.split('.');
        const ending = parts.pop();

        switch (ending) {
            case 'xls':
            case 'xlsx':
                return 'fa mr-1 fa-file-excel-o text-success';
            case 'doc':
            case 'docx':
                return 'fa mr-1 fa-file-word-o text-primary';
            case 'zip':
            case 'gzip':
            case 'gzip2':
            case 'bunzip':
            case 'bunzip2':
                return 'fa mr-1 fa-file-zip-o text-muted';
            case 'html':
            case 'xml':
                return 'fa mr-1 fa-file-code-o text-muted';
            case 'jpg':
            case 'jpeg':
            case 'tif':
            case 'tiff':
            case 'png':
            case 'gif':
                return 'fa mr-1 fa-file-image-o text-info';
            case 'wav':
            case 'mp3':
            case 'mp4':
            case 'mov':
                return 'fa mr-1 fa-file-video-o text-info';
            case 'pdf':
                return 'fa mr-1 fa-file-pdf-o text-danger';
            default:
                return 'fa mr-1 fa-file-text-o text-muted';
        }
    }
}
