export class MembershipDirectoryEntry
{
    givenNames: string;
    familyName: string;
    phone: string;
    secondaryPhone: string;
    email: string;
    address: string;
    unitAddress: string;

    constructor(membership: any) {
        if (membership.givenNames) {
            this.givenNames = membership.givenNames;
        }

        if (membership.familyName) {
            this.familyName = membership.familyName;
        }

        if (membership.phone) {
            this.phone = membership.phone;
        }

        if (membership.secondaryPhone) {
            this.secondaryPhone = membership.secondaryPhone;
        }

        if (membership.email) {
            this.email = membership.email;
        }

        if (membership.address) {
            this.address = membership.address;
        }

        if (membership.unitAddress) {
            this.unitAddress = membership.unitAddress;
        }
    }
}

export class MembershipDirectoryResponse
{
    directory: MembershipDirectoryEntry[];

    constructor(membershipOptInResponse: any) {
        this.directory = membershipOptInResponse.hasOwnProperty('directory') ? membershipOptInResponse.directory.map((member: any) => {
            return new MembershipDirectoryEntry(member);
        }) : [];
    }
}
