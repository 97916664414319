import { Moment } from 'moment';
import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';
import moment from 'moment-timezone';


export class StripePayout
{
    id: number;
    stripePayoutId: string;
    transactionId: number;
    status: string;
    createdAt: Moment;
    updatedAt: Moment;

    constructor(data) {
        this.id = data.id || null;
        this.stripePayoutId = data.stripePayoutId || null;
        this.transactionId = data.transactionId || null;
        this.status = data.status || null;

        const timezone = LegFiJwtService.getTimezone();
        this.createdAt = data.createdAt ? moment.utc(data.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone) : null;
        this.updatedAt = data.updatedAt ? moment.utc(data.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone) : null;
    }
}
