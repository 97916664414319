import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TruncateModule } from '../../../../pipes/format/truncate.module';
import { AvatarBlockComponent } from './avatar-block.component';
import { MatIconModule } from '@angular/material/icon';
import { TruncatePipe } from '../../../../pipes/format/truncate.pipe';
import { AvatarBlockDescriptionDirective, AvatarBlockTitleDirective } from './avatar-block.directive';

@NgModule({
    imports: [
        CommonModule,
        TruncateModule,
        MatIconModule,
        NgOptimizedImage,
    ],
    declarations: [
        AvatarBlockComponent,
        AvatarBlockTitleDirective,
        AvatarBlockDescriptionDirective,
    ],
    exports: [
        AvatarBlockComponent,
        AvatarBlockTitleDirective,
        AvatarBlockDescriptionDirective,
    ],
    providers: [
        TruncatePipe,
    ],
})
export class AvatarBlockModule
{
}
