import { NgModule } from '@angular/core';

import { DatedBrowserComponent } from './dated-browser.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [DatedBrowserComponent],
    declarations: [DatedBrowserComponent],
    providers: [],
})
export class DatedBrowserModule
{
}
