import { Injectable } from '@angular/core';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { Routes } from '../../config/routes';
import { map, tap } from 'rxjs/operators';
import { EchoService } from '../echo.service';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { DatabaseNotification } from '../../models/entities/database-notification';
import { BehaviorSubject, Observable } from 'rxjs';
import moment from 'moment-timezone';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService
{
    notifications$: BehaviorSubject<DatabaseNotification[]> = new BehaviorSubject([]);
    unreadCount$ = this.notifications$.pipe(
            map((notifications) => notifications.filter((n) => !n.readAt).length)
    );

    private notifications: DatabaseNotification[] = [];

    constructor(
            private _echoService: EchoService,
            private _http: ApplicationHttpClient,
    ) {
        const jwt = LegFiJwtService.read();
        this._echoService.private(`User.${jwt.id}`).notification((notification) => {
            this.notifications.unshift(new DatabaseNotification({
                id: notification.id,
                readAt: null,
                createdAt: moment(),
                data: notification,
                organizationId: notification.organizationId ?? null,
                type: notification.type,
            }));
            this.notifications$.next(this.notifications);
        });
    }

    getNotifications(): Observable<DatabaseNotification[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();

        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl('/notifications');

        return this._http.get(url)
                .pipe(map((res: Object[]) => res.map(notification => new DatabaseNotification(notification))))
                .pipe(tap((notifications: DatabaseNotification[]) => {
                    this.notifications = notifications;
                    this.notifications$.next(notifications);
                }));
    }

    markNotificationAsRead(notificationIds: string[] = []) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();

        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl('/notifications/mark-read');

        return this._http.post(url, JSON.stringify({
            notificationIds: notificationIds,
        })).pipe(
                map(() => {
                    if (!notificationIds.length) {
                        // Mark all notifications as read if no notificationIds are provided
                        this.notifications.forEach((notification) => {
                            notification.readAt = moment();
                        });
                        this.notifications$.next(this.notifications);
                    } else {
                        // Mark the provided notifications as read
                        notificationIds.forEach((notificationId) => {
                            const notification = this.notifications.find((n) => n.id === notificationId);
                            if (notification) {
                                notification.readAt = moment();
                                this.notifications$.next(this.notifications);
                            }
                        });
                    }

                    return this.notifications;
                }),
        );
    }

    deleteNotifications(notificationIds: string[] = []) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();

        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const queryString = notificationIds.map(id => `notificationIds[]=${encodeURIComponent(id)}`).join('&');
        const url: string = Routes.MakeLegFiCoreUrl('/notifications') + '?' + queryString;
        return this._http.delete(url).pipe(
                map(() => {
                    if (notificationIds.length === 0) {
                        // Delete all notifications if no notificationIds are provided
                        this.notifications = [];
                        this.notifications$.next(this.notifications);
                    } else {
                        // Delete the provided notifications
                        this.notifications = this.notifications.filter(
                                (n) => !notificationIds.includes(n.id)
                        );
                        this.notifications$.next(this.notifications);
                    }

                    return this.notifications;
                }),
        );
    }
}
