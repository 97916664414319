import { Injectable } from '@angular/core';

import moment from 'moment-timezone';
import { JwtLegFiClaims } from './auth/jwt-legfi-claims.model';
import { LegFiJwtService } from './auth/legfi-jwt.service';
import { Moment } from 'moment';
import { Tag } from '../models/entities/tag';
import { AuthService } from 'app/services/auth/auth.service';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import environment from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class FileUploaderService
{
    constructor(
            private _authService: AuthService,
    ) {
    }

    public initUploader(options: FileUploaderOptions) {
        const uploader = new FileUploader(options);

        uploader.setOptions({
            headers: [
                {
                    name: 'X-Legfi-Site-Id',
                    value: environment.SiteId.toString(),
                },
                {
                    name: 'Authorization',
                    value: 'Bearer ' + LegFiJwtService.raw(),
                },
                {
                    name: 'X-XSRF-TOKEN',
                    value: this._authService.getCookie('XSRF-TOKEN'),
                },
            ],
        });

        return uploader;
    }
}
