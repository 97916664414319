import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'transformPhoneNo',
})
export class TransformPhoneNoPipe implements PipeTransform
{
    public transform(value: string, args: any[]) {
        if (typeof value === 'undefined' || value === null) {
            return '';
        }

        value = value.trim();
        const addPlus = value[0] === '+';
        const regex = /[^\d]/g;
        value = value.replace(regex, '');

        return addPlus ? '+' + value : value;
    }
}
