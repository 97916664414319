import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { MembershipTableItem } from '../../models/entities/membership';
import { PaginationLinks, PaginationMeta } from '../../models/entities/pagination';
import { MembersService } from './members.service';

export interface PeopleDatasourceOptions
{
    status: string;
    tags: string[];
    withoutTags?: string[];
}

export class MemberDatasource implements DataSource<MembershipTableItem>
{
    private membershipSubject = new BehaviorSubject<MembershipTableItem[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private countSubject = new BehaviorSubject<number>(0);
    private cancelInFlight = new Subject();

    constructor(private _membersService: MembersService) {
    }

    get loading$() {
        return this.loadingSubject.asObservable();
    }

    get count$() {
        return this.countSubject.asObservable();
    }

    loadPeople(
            orgId: number,
            search: string = '',
            sortColumn: string = 'name',
            sortDirection: string = 'desc',
            pageIndex: number = 1,
            perPage: number = 100,
            options: PeopleDatasourceOptions = {
                status: 'active',
                tags: [],
                withoutTags: [],
            },
    ) {
        this.loadingSubject.next(true);
        this.cancelInFlight.next(true);

        this._membersService.getPeople(orgId, search, sortColumn, sortDirection, pageIndex, perPage, options)
                .pipe(takeUntil(this.cancelInFlight))
                .pipe(
                        catchError((err) => of({data: [], meta: {total: 0}}).pipe(tap(_ => console.error(err)))),
                        finalize(() => this.loadingSubject.next(false)),
                )
                .subscribe((transactions: PeopleResponse) => {
                    this.membershipSubject.next(transactions.data);
                    const total = (transactions.meta.total) ? transactions.meta.total : 0;
                    this.countSubject.next(total);
                });
    }

    connect(): Observable<MembershipTableItem[]> {
        return this.membershipSubject.asObservable();
    }

    disconnect(): void {
        this.membershipSubject.complete();
        this.loadingSubject.complete();
        this.countSubject.complete();
    }

}

export class PeopleResponse
{
    data: MembershipTableItem[];
    links: PaginationLinks;
    meta: PaginationMeta;

    constructor(request: any) {
        this.data = (request.data && request.data.length) ? request.data.map(tx => {
            return new MembershipTableItem(tx);
        }) : [];
        this.links = new PaginationLinks(request.links);
        this.meta = new PaginationMeta(request.meta);
    }
}
