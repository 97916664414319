import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';

/** TODO - rename a lot of these bools to match standard (here and in database?) */
export class OrganizationPreference
{
    organizationId: number;
    formsModuleEnabled: boolean;
    violationsModuleEnabled: boolean;
    architecturalRequestsModuleEnabled: boolean;
    maintenanceRequestsModuleEnabled: boolean;
    generalRequestsModuleEnabled: boolean;
    surveysModuleEnabled: boolean;
    calendarModuleEnabled: boolean;
    messageBoardModuleEnabled: boolean;
    membershipDirectoryModuleEnabled: boolean;
    payablesModuleEnabled: boolean;

    newChargeEmailsEnabled: boolean;
    monthlyStatementEmailsEnabled: boolean;
    sendMonthlyStatementsToAutopayMembers: boolean;
    monthlyStatementEmailsDayToSend: number;
    pastDueEmailsEnabled: boolean;
    pastDueEmailsDaysPastDue: number;
    reminderEmailsEnabled: boolean;
    reminderEmailsDaysBeforeDue: number;
    adminDailySummaryEmailsEnabled: boolean;
    attachPdfInvoiceToStatementEmails: boolean;
    attachPdfViolationToViolationEmails: boolean;
    paymentsPayFeesFirst: boolean;
    usePlaidBalances: boolean;
    useChargeDueDateForPrepaids: boolean;
    sendReminderEmailsToAutopayMembers: boolean;
    sendNewLateFeeChargeEmails: boolean;
    cpiLockboxIntegrationEnabled: boolean;
    lockboxIntegrationDepositAccountId: number;
    autoTagMembersWithoutEmail: boolean;
    autoTagMembersWithoutPhone: boolean;
    autoTagMembersNeverLoggedIn: boolean;
    autoTagUnitsWithoutEmail: boolean;
    autoTagUnitsWithoutPhone: boolean;
    autoTagPaperlessUnits: boolean;
    membersWithoutEmailTag: string;
    membersWithoutPhoneTag: string;
    membersNeverLoggedInTag: string;
    unitsWithoutEmailTag: string;
    unitsWithoutPhoneTag: string;
    paperlessUnitsTag: string;
    manualBankAccountAddingEnabled: boolean;
    billPayBankAccountAddingEnabled: boolean;
    useCashAccounting: boolean;
    unitsImportWithStreetviewPhoto: boolean;

    allowCustomEmailSend: boolean;
    allowSmsSend: boolean;
    allowPhoneCallSend: boolean;

    allianceLockboxAutoImportEnabled: boolean;
    allianceLockboxImportAfter: Moment;
    allianceLockboxEnabled: boolean;
    allianceLockboxId: string;
    allianceLockboxPmcId: string;
    allianceLockboxDepositAccountId: number;
    sendNewChargesToAutopay: boolean;

    lockProfileNames: boolean;
    lockProfilePhoneNumbers: boolean;
    lockProfileEmailAddress: boolean;
    lockProfileMailingAddress: boolean;
    lockProfileAuxEmails: boolean;
    lockProfileCustomFields: boolean;
    lockProfileImage: boolean;

    constructor(preferences: any) {
        // ids
        this.organizationId = preferences.organizationId;

        this.cpiLockboxIntegrationEnabled = preferences.cpiLockboxIntegrationEnabled;
        this.lockboxIntegrationDepositAccountId = preferences.lockboxIntegrationDepositAccountId;

        // modules
        this.formsModuleEnabled = preferences.formsModuleEnabled;
        this.violationsModuleEnabled = preferences.violationsModuleEnabled;
        this.surveysModuleEnabled = preferences.surveysModuleEnabled;
        this.calendarModuleEnabled = preferences.calendarModuleEnabled;
        this.useChargeDueDateForPrepaids = preferences.useChargeDueDateForPrepaids;
        this.messageBoardModuleEnabled = preferences.messageBoardModuleEnabled;
        this.membershipDirectoryModuleEnabled = preferences.membershipDirectoryModuleEnabled;
        this.payablesModuleEnabled = preferences.payablesModuleEnabled;

        // emails
        this.newChargeEmailsEnabled = preferences.newChargeEmailsEnabled;
        this.sendNewChargesToAutopay = preferences.sendNewChargesToAutopay;
        this.monthlyStatementEmailsEnabled = preferences.monthlyStatementEmailsEnabled;
        this.monthlyStatementEmailsDayToSend = preferences.monthlyStatementEmailsDayToSend;
        this.pastDueEmailsEnabled = preferences.pastDueEmailsEnabled;
        this.pastDueEmailsDaysPastDue = preferences.pastDueEmailsDaysPastDue;
        this.reminderEmailsEnabled = preferences.reminderEmailsEnabled;
        this.reminderEmailsDaysBeforeDue = preferences.reminderEmailsDaysBeforeDue;
        this.adminDailySummaryEmailsEnabled = preferences.adminDailySummaryEmailsEnabled;
        this.attachPdfInvoiceToStatementEmails = preferences.attachPdfInvoiceToStatementEmails;
        this.attachPdfViolationToViolationEmails = preferences.attachPdfViolationToViolationEmails;
        this.sendReminderEmailsToAutopayMembers = preferences.sendReminderEmailsToAutopayMembers;
        this.sendMonthlyStatementsToAutopayMembers = preferences.sendMonthlyStatementsToAutopayMembers;

        // bank stuff
        this.paymentsPayFeesFirst = preferences.paymentsPayFeesFirst;
        this.usePlaidBalances = preferences.usePlaidBalances;
        this.sendNewLateFeeChargeEmails = preferences.sendNewLateFeeChargeEmails;
        this.manualBankAccountAddingEnabled = preferences.manualBankAccountAddingEnabled;
        this.billPayBankAccountAddingEnabled = preferences.billPayBankAccountAddingEnabled;
        this.useCashAccounting = preferences.useCashAccounting;

        // autotag
        this.autoTagMembersWithoutEmail = preferences.autoTagMembersWithoutEmail;
        this.autoTagMembersWithoutPhone = preferences.autoTagMembersWithoutPhone;
        this.autoTagMembersNeverLoggedIn = preferences.autoTagMembersNeverLoggedIn;
        this.autoTagUnitsWithoutEmail = preferences.autoTagUnitsWithoutEmail;
        this.autoTagUnitsWithoutPhone = preferences.autoTagUnitsWithoutPhone;
        this.autoTagPaperlessUnits = preferences.autoTagPaperlessUnits;
        this.membersWithoutEmailTag = preferences.membersWithoutEmailTag;
        this.membersWithoutPhoneTag = preferences.membersWithoutPhoneTag;
        this.membersNeverLoggedInTag = preferences.membersNeverLoggedInTag;
        this.unitsWithoutEmailTag = preferences.unitsWithoutEmailTag;
        this.unitsWithoutPhoneTag = preferences.unitsWithoutPhoneTag;
        this.paperlessUnitsTag = preferences.paperlessUnitsTag;

        // units
        this.unitsImportWithStreetviewPhoto = preferences.unitsImportWithStreetviewPhoto;

        // profile field locks
        this.lockProfileNames = preferences.lockProfileNames;
        this.lockProfilePhoneNumbers = preferences.lockProfilePhoneNumbers;
        this.lockProfileEmailAddress = preferences.lockProfileEmailAddress;
        this.lockProfileMailingAddress = preferences.lockProfileMailingAddress;
        this.lockProfileAuxEmails = preferences.lockProfileAuxEmails;
        this.lockProfileCustomFields = preferences.lockProfileCustomFields;
        this.lockProfileImage = preferences.lockProfileImage;

        this.allowCustomEmailSend = preferences.allowCustomEmailSend;
        this.allowSmsSend = preferences.allowSmsSend;
        this.allowPhoneCallSend = preferences.allowPhoneCallSend;

        // aab preferences
        this.allianceLockboxAutoImportEnabled = preferences.allianceLockboxAutoImportEnabled || false;
        this.allianceLockboxEnabled = preferences.allianceLockboxEnabled;
        this.allianceLockboxId = preferences.allianceLockboxId;
        this.allianceLockboxPmcId = preferences.allianceLockboxPmcId;
        this.allianceLockboxDepositAccountId = preferences.allianceLockboxDepositAccountId;

        const timezone = LegFiJwtService.getTimezone();
        if (preferences.allianceLockboxImportAfter) {
            this.allianceLockboxImportAfter = moment.utc(preferences.allianceLockboxImportAfter, 'YYYY-MM-DD hh:mm:ss')
                    .tz(timezone);
        }
    }
}
