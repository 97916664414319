import { Component, Inject, Input } from '@angular/core';
import { DepositBankAccount } from '../../../../models/entities/deposit-bank-account';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DepositBankAccountsService } from 'app/services/financial-accounts/deposit-bank-accounts.service';

export interface KYCModalInput
{
    account: DepositBankAccount;
}

@Component({
    selector: 'app-kyc-modal',
    templateUrl: './kyc-modal.component.html',
    styleUrls: ['./kyc-modal.component.scss'],
})
export class KycModalComponent
{
    constructor(
            private _depositBankAccountsService: DepositBankAccountsService,
            @Inject(MAT_DIALOG_DATA) public data: KYCModalInput,
    ) {
    }

    verifyAccount() {
        this._depositBankAccountsService.getStripeLink(this.data.account).subscribe({
                    next: (resp) => {
                        if (resp && resp.hasOwnProperty('url')) {
                            window.location.href = resp['url'];
                        }
                    },
                    error: (err) => {
                        console.error('there was a problem getting link: ', err);
                    },
                },
        );
    }
}
