import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyInputModule } from '../../../shared/currency-input/currency-input.module';
import { LegfiCurrencyPipe } from '../../../../pipes/format/legfi-currency.pipe';
import { LegfiCurrencyPipeModule } from '../../../../pipes/format/legfi-currency.module';
import { LegfiDatePipe } from '../../../../pipes/format/legfi-date.pipe';
import { LegfiDatePipeModule } from '../../../../pipes/format/legfi-date.module';
import { NewlineToBreakPipeModule } from '../../../../pipes/format/nl2br.module';
import { NewlineToBreakPipe } from '../../../../pipes/format/nl2br.pipe';
import { RangeSliderModule } from '../../../shared/range-slider/range-slider.module';
import { SingleUnitSelectorModule } from '../../shared/single-unit-selector/single-unit-selector.module';
import { RecordOwnerPaymentComponent } from './record-owner-payment.component';
import { SingleOwnerSelectorModule } from '../../shared/single-owner-selector/single-owner-selector.module';
import { ToggleSwitchModule } from 'app/components/shared/toggle-switch/toggle-switch.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';

@NgModule({
    imports: [
        // Shared
        SharedModule,
        // Dependencies
        CurrencyInputModule,
        LegfiCurrencyPipeModule,
        LegfiDatePipeModule,
        NgbModule,
        NewlineToBreakPipeModule,
        RangeSliderModule,
        SingleOwnerSelectorModule,
        SingleUnitSelectorModule,
        ToggleSwitchModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatSlideToggleModule,
    ],
    declarations: [RecordOwnerPaymentComponent],
    exports: [RecordOwnerPaymentComponent],
    providers: [
        // Pipes
        LegfiCurrencyPipe,
        LegfiDatePipe,
        NewlineToBreakPipe,
    ],
})
export class RecordOwnerPaymentModule
{
}
