import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { GroupMember } from './group-member';
import { Membership } from './membership';
import { Organization } from './organization';
import { Permission } from './permission';

export class Group
{
    id: number;
    organizationId: number;
    label: string;
    description: string;
    memberTypeGroup: boolean;
    createdAt: Moment;
    updatedAt: Moment;

    membersCount: number;

    organization: Organization;
    group: Group;
    members: GroupMember[];
    memberships: Membership[];
    permissions: Permission[];

    checked: boolean;

    constructor(groupMember: any) {
        if (groupMember.id) {
            this.id = groupMember.id;
        }
        if (groupMember.organizationId) {
            this.organizationId = groupMember.organizationId;
        }
        if (groupMember.label) {
            this.label = groupMember.label;
        }
        if (groupMember.description) {
            this.description = groupMember.description;
        }
        if (groupMember.memberTypeGroup) {
            this.memberTypeGroup = groupMember.memberTypeGroup;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (groupMember.createdAt) {
            this.createdAt = moment.utc(groupMember.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (groupMember.updatedAt) {
            this.updatedAt = moment.utc(groupMember.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (groupMember.membersCount) {
            this.membersCount = groupMember.membersCount;
        } else {
            this.membersCount = 0;
        }

        if (groupMember.organization) {
            this.organization = new Organization(groupMember.organization);
        }
        if (groupMember.group) {
            this.group = new Group(groupMember.group);
        }
        if (groupMember.members) {
            this.members = groupMember.members.map(
                    (member) => {
                        return new GroupMember(member);
                    },
            );
        }
        if (groupMember.memberships) {
            this.memberships = groupMember.memberships.map(
                    (membership) => {
                        return new Membership(membership);
                    },
            ).sort((a: Membership, b: Membership) => {
                if (!a.profile && !b.profile) {
                    return 0;
                }

                if (a.profile.familyName < b.profile.familyName) {
                    return -1;
                } else if (a.profile.familyName > b.profile.familyName) {
                    return 1;
                }

                return 0;
            });
        }
        if (groupMember.permissions) {
            this.permissions = groupMember.permissions.map(
                    (permission) => {
                        return new Permission(permission);
                    },
            );
        }
    }
}
