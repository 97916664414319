import { TransactionRuleAccount } from './transaction-rule-account';
import { TransactionRuleAction } from './transaction-rule-action';
import { TransactionRuleCondition } from './transaction-rule-condition';
import { Vendor } from 'app/models/entities/vendors/vendor';

export class TransactionRule
{
    id: number;
    organizationId: number;
    vendorId: number;
    name: string;
    type: string;
    autoApprove: boolean;
    matchAll: boolean;
    priority: number;

    accounts: TransactionRuleAccount[];
    conditions: TransactionRuleCondition[];
    actions: TransactionRuleAction[];

    vendor: Vendor;

    constructor(rule: any) {
        this.id = rule.id || null;
        this.organizationId = rule.organizationId || null;
        this.vendorId = rule.vendorId || null;
        this.name = rule.name || null;
        this.type = rule.type || null;
        this.autoApprove = rule.autoApprove || false;
        this.matchAll = rule.matchAll || false;
        this.priority = rule.priority || 0;
        this.vendor = rule.vendor ? new Vendor(rule.vendor) : null;

        this.accounts = (rule.transactionRuleAccounts && rule.transactionRuleAccounts.length)
                ? rule.transactionRuleAccounts.map(a => {
                    return new TransactionRuleAccount(a);
                })
                : [];
        this.conditions = (rule.transactionRuleConditions && rule.transactionRuleConditions.length)
                ? rule.transactionRuleConditions.map(a => {
                    return new TransactionRuleCondition(a);
                })
                : [];
        this.actions = (rule.transactionRuleActions && rule.transactionRuleActions.length)
                ? rule.transactionRuleActions.map(a => {
                    return new TransactionRuleAction(a);
                })
                : [];
    }
}

