import { map } from 'rxjs/operators';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { Audit } from '../../models/entities/audit';
import { Injectable } from '@angular/core';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { Observable } from 'rxjs';
import { Routes } from '../../config/routes';
import { OrganizationPendingReview } from '../../components/admin-layout/organizations/organizations.component';
import { GtmService } from '../gtm.service';
import { OrganizationReviewActions } from '../../enums/organization-review-actions.enum';
import environment from '../../../environments/environment';

export interface AuditHistoryResponse
{
    record: any;
    audits: any[];
    relatedRecords: {
        [key: string]: {
            className: string;
            id: number;
        }[]
    }[];
}

export interface AuditHistory
{
    record: any;
    audits: Audit[];
    relatedRecords: {
        [key: string]: {
            className: string;
            id: number;
        }[]
    }[];
}

@Injectable({
    providedIn: 'root',
})
export class AdminService
{
    constructor(
            private _http: ApplicationHttpClient,
            private _gtm: GtmService,
    ) {
    }

    public seed() {
        let url = '/rainforest/seed/0';
        if(environment.LegFiCoreApi.indexOf('qa') !== -1) {
            url = '/rainforest/seed/1';
        }
        return this._http.post(Routes.MakeLegFiCoreUrl(url), JSON.stringify({
            callback_type: 'before_run'
        }));
    }


    /**
     * @param {number} id
     * @returns {Observable<Object>}
     */
    public impersonateUser(id: string): Observable<Object> {
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.ImpersonateUser() + '?id=' + id,
        );

        return this._http.get(url);
    }

    /**
     * @returns {Observable<Object>}
     */
    public restore(): Observable<Object> {
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.RestoreSuperAdmin(),
        );

        return this._http.get(url);
    }

    public getAuditHistory(model: string, id: number): Observable<AuditHistory> {
        const url: string = Routes.MakeLegFiCoreUrl('/audits/' + model + '/' + id);
        return this._http.get(url).pipe(map((res: AuditHistoryResponse) => {
            return {
                record: res.record,
                audits: res.audits.map(a => new Audit(a)),
                relatedRecords: res.relatedRecords,
            };
        }));
    }

    public getAudits(model: string): Observable<Audit[]> {
        const url: string = Routes.MakeLegFiCoreUrl('/audits?model=' + model);
        return this._http.get(url).pipe(map((res: Object[]) => {
            return res.map(a => new Audit(a));
        }));
    }

    /**
     * @returns {Observable<Object>}
     */
    public getSubscriptionsBreakdown(): Observable<Object> {
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Admin +
                Routes.LegFiCore.SubscriptionsBreakdown,
        );

        return this._http.get(url);
    }

    /**
     * @returns {Observable<Object>}
     */
    public getSubscriptionsSummary(): Observable<Object> {
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Admin +
                Routes.LegFiCore.SubscriptionsSummary,
        );

        return this._http.get(url);
    }

    public getOrgsPendingReview(): Observable<OrganizationPendingReview[]> {
        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationsPendingReview);
        return this._http.get(url);
    }

    public reviewOrganization(organizationId: number, action: OrganizationReviewActions) {
        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationsPendingReview) + '/' + organizationId + '/' + action;
        return this._http.post(url, JSON.stringify({})).pipe(
                map((res) => {
                            this._gtm.reviewOrganization({
                                orgId: organizationId,
                                action,
                            });
                            return res;
                        },
                ));
    }

    /**
     * @returns {Observable<Object>}
     */
    public getOrganizationsBySubscriptionStatus(interval: string, status: string): Observable<Object> {
        const params = [
            'interval=' + interval,
            'status=' + status,
        ];

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Admin +
                Routes.LegFiCore.SubscribedOrganizations + '?' + (params.join('&')),
        );

        return this._http.get(url);
    }

    /**
     * @returns {Observable<Object>}
     */
    public saveOrganizationRates(organizationId: number, rates: {}): Observable<Object> {
        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationRates(organizationId));

        return this._http.post(url, JSON.stringify(rates));
    }

    /**
     * @param {{}} options
     * @returns {Observable<Object>}
     */
    public getOrganizationPayments(options?: { [key: string]: any }): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const queryParams = [];
        let isPagingPresent: boolean = false;
        for (const key in options) {
            if (options.hasOwnProperty(key)) {
                queryParams.push(key + '=' + options[key]);
                isPagingPresent = isPagingPresent || (key === 'paging');
            }
        }
        if (!isPagingPresent) {
            queryParams.push('paging=true');
        }
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Admin +
                Routes.LegFiCore.PaymentGroups + '/organization-payments',
        ) + (queryParams.length > 0 ? '?' + queryParams.join('&') : '');

        return this._http.get(url);
    }

    /**
     * @param {{}} options
     * @returns {Observable<Object>}
     */
    public getSites(options?: { [key: string]: any }): Observable<Object> {
        const queryParams = [];
        for (const key in options) {
            if (options.hasOwnProperty(key)) {
                queryParams.push(key + '=' + options[key]);
            }
        }
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Admin +
                Routes.LegFiCore.Sites + (queryParams.length > 0 ? '?' + queryParams.join('&') : ''),
        );

        return this._http.get(url);
    }

    public getDailyHistory(): Observable<any[]> {
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.Admin + '/daily-history',
        );

        return this._http.get(url);
    }

    public getFailedJobs(): Observable<Object> {
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.AdminFailedJobs(),
        );

        return this._http.get(url);
    }

    public getMasterOrg(): Observable<{ orgId: number; }> {
        const url = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.AdminMasterOrg(),
        );

        return this._http.get(url);
    }

    public addOrgCredit(orgId, amount: number) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.AdminCreditOrg(),
        );
        return this._http.post(url, JSON.stringify({
            orgId: orgId,
            amount: amount,
        }));
    }

    public processOrgPayment(orgId) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.AdminProcessOrgPayment(),
        );
        return this._http.post(url, JSON.stringify({
            orgId: orgId,
        }));
    }
}
