import moment from 'moment-timezone';
import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';
import { Organization } from '../organization';
import Moment = moment.Moment;
import { COAAccount } from '../../../services/accounting/chart-of-accounts.service';

export type CategoryAccountType = 'income'|'expense';

export const CATEGORY_ACCOUNT_TYPE = 'category';
export const CATEGORY_INCOME_ACCOUNT_TYPE: CategoryAccountType = 'income';
export const CATEGORY_EXPENSE_ACCOUNT_TYPE: CategoryAccountType = 'expense';

export class Category implements COAAccount
{
    id: number;
    parentId: number;
    organizationId: number;
    name: string;
    type: string;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;
    sortOrder: number;
    internalUse: number;

    organization: Organization;

    children: Category[];
    old?: any[];

    readonly accountType = CATEGORY_ACCOUNT_TYPE;

    constructor(category: any) {
        this.id = category.id || null;
        this.parentId = category.parentId || null;
        this.organizationId = category.organizationId || null;
        this.name = category.name || null;
        this.type = category.type || null;
        this.sortOrder = category.sortOrder || null;
        this.internalUse = category.internalUse || false;

        const timezone = LegFiJwtService.getTimezone();
        this.createdAt = category.createdAt
                ? moment.utc(category.createdAt, 'YYYY-MM-DD hh:mm:ss')
                        .tz(timezone)
                : null;

        this.updatedAt = category.updatedAt
                ? moment.utc(category.updatedAt, 'YYYY-MM-DD hh:mm:ss')
                        .tz(timezone)
                : null;

        this.deletedAt = category.deletedAt
                ? moment.utc(category.deletedAt, 'YYYY-MM-DD hh:mm:ss')
                        .tz(timezone)
                : null;

        this.organization = category.organization ? new Organization(category.organization) : null;

        if (category.children) {
            this.children = category.children.map(cat => {
                return new Category(cat);
            });
        } else {
            this.children = [];
        }
    }

    get label() {
        return this.name;
    }
}
