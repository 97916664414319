<mat-dialog-content class="feature-content border-0">
    <div class="d-flex align-items-center mb-3">
        <img alt="{{ 'PayHOA' }}" class="img-fluid" src="/assets/images/payhoa/marketing/payhoa-logo.png"/>
    </div>
    <div class="card d-flex mx-auto p-md-3">
        <div class="card-body">
            <div class="d-flex mb-3">
                <img alt="{{ 'Stripe Verification' }}" class="img-fluid mx-auto" src="/assets/images/payhoa/dashboardEmpty/illustration.svg"/>
            </div>
            <ng-template #verificationHeading>
                <h3 tabindex="0" class="mat-dialog-title text-left font-weight-medium mt-0 mx-0">
                    {{ 'Quick Verification Process through Stripe' }}
                </h3>
            </ng-template>
            <ng-container *ngIf="data.account.currentDeadline; else verificationHeading">
                <h3 tabindex="0" class="mat-dialog-title text-left font-weight-medium mt-0 mx-0">
                    {{ 'New KYC Requirements Due ' + (data.account.currentDeadline | legfiDate: 'MM/DD/YYYY') }}
                </h3>
            </ng-container>

            <h4 class="mat-dialog-subtitle">{{ 'Know Your Customer' }}</h4>
            <p>{{ 'KYC regulations require us to gather information about our customers when processing payments on their behalf. Please fill out the information below to receive deposits into your bank accounts.' }}</p>

            <div class="bg-light d-flex flex-column flex-md-row align-items-start mx-n3 p-3 p-md-4">
                <mat-icon class="mx-auto mr-md-2">security</mat-icon>
                <p class="mt-md-0">
                    {{ 'Your information is fully encrypted and is only used by our payment processor to verify your identity. Your data is never shared or sold. You can learn more about our security by clicking ' }}
                    <a href="https://intercom.help/payhoa/en/articles/3482621-payhoa-security" target="_blank">{{ 'here' }}</a>.
                </p>
            </div>

            <div class="d-flex flex-column align-items-center mt-3">
                <app-feature-button icon="launch" (onClick)="verifyAccount()">{{ 'Verify now' }}</app-feature-button>
                <app-button type="link" [buttonClasses]="['mr-0']" mat-dialog-close>{{ 'I will do verification later.' }}</app-button>
            </div>
        </div>
    </div>
</mat-dialog-content>
