import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdleTimeoutDialogComponent } from './idle-timeout-dialog.component';
import { SharedModule } from '../shared.module';
import { ButtonModule } from '../button/button.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ButtonModule,
    ],
    declarations: [IdleTimeoutDialogComponent],
    exports: [IdleTimeoutDialogComponent],
})
export class IdleTimeoutDialogModule
{
}
