<div class="grid-layout grid-2 mt-0">
    <div class="d-flex flex-column">
        <div>Before:</div>
        <pre>{{ audit.oldValues | json }}</pre>
    </div>
    <div class="d-flex flex-column">
        <div>After:</div>
        <pre>{{ audit.newValues | json }}</pre>
    </div>
</div>
