<ng-template #onlyCurrentOrg>
    <!-- either still loading or cannot view more than current org -->
    <button class="btn btn-sm d-flex align-content-center" disabled>
        <span class="org-name">
            <span [class.pr-2]="isLoading">{{ currentOrgName }} ({{ currentOrgId }})</span>
        </span>
        <span *ngIf="isLoading"
              class="d-inline-flex loading"></span>
    </button>
</ng-template>

<ng-template #singleOrg>
    <ng-container *ngIf="totalAvailable === 0 && (jwt.admin || jwt.superUser); else onlyCurrentOrg">
        <!-- loaded switcher with single "current" organization and ability to add new HOAs -->
        <button class="btn btn-sm d-flex align-content-center"
                (click)="matMenuOpened = true">
            <span class="org-name">
                <span class="pr-2">{{ currentOrgName }} ({{ currentOrgId }})</span>
                <i class="fa fa-chevron-down"></i>
            </span>
        </button>

        <div class="header-switcher-container mat-menu-container r-0 animated-short fadeIn"
             [hidden]="!matMenuOpened">
            <div class="mat-menu-panel">
                <ng-template [ngTemplateOutlet]="addHOA"></ng-template>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-container *ngIf="totalAvailable > 0 && jwt; else singleOrg">
    <!-- loaded switcher with multiple organizations/units -->
    <button class="btn btn-sm d-flex align-content-center"
            (click)="openMenu()">
        <span class="org-name">
            <span class="pr-2">{{ currentOrgName }} ({{ currentOrgId }})</span>
            <i class="fa fa-chevron-down"></i>
        </span>
    </button>

    <div class="header-switcher-container mat-menu-container r-0 animated-short fadeIn"
         [hidden]="!matMenuOpened">
        <div class="mat-menu-panel">
            <div class="header-switcher"
                 (click)="stopClickFromClosing($event)">
                <div *ngIf="totalAvailable > 10"
                     class="switcher-search p-3">
                    <lf-material-search defaultPlaceholder="{{ 'Find ' + (switcherType === 'unit' ? 'Unit' : 'HOA' ) }}&hellip;"
                                        [delayTime]="500"
                                        [hasGroupAddon]="false"
                                        [hasButtonStyles]="true"
                                        (updated)="filterSwitcher($event)"></lf-material-search>
                </div>
                <div *ngIf="searchString !== ''"
                     class="px-3 py-2 border-bottom">
                    <ng-template #noOrganizationItems>
                        {{ 'No results found' }}
                    </ng-template>
                    <ng-container *ngIf="organizationItems.length > 0; else noOrganizationItems">
                        {{ 'Found ' + (organizationItems.length | number:'1.0':'en-US') + ' result' + (organizationItems.length === 1 ? '' : 's') }}
                    </ng-container>
                </div>

                <cdk-virtual-scroll-viewport #scroller itemSize="24" class="content">
                    <ng-container *cdkVirtualFor="let org of organizationItems">
                        <ng-template #orgItem>
                                    <span class="org-name d-flex justify-content-between"
                                          title="{{ org.org }} ({{ org.id }})">
                                        <span class="name-text">{{ org.org }}</span>
                                        <span>({{ org.id }})</span>
                                    </span>
                        </ng-template>
                        <button class="py-2"
                                (click)="requestSwitch(org); closeMenu()"
                                mat-menu-item>
                            <ng-container *ngIf="switcherType === 'unit'; else orgItem">
                                        <span class="org-name d-flex justify-content-between"
                                              title="{{ org.org }} ({{ org.id }}){{ org.unit ? ': ' + org.unit.title : '' }}">
                                            <span class="name-text"
                                                  [class.text-secondary]="org.unit"
                                                  [class.text-font-smaller]="org.unit">{{ org.org }}</span>
                                            <span>({{ org.id }})</span>
                                        </span>
                                <span *ngIf="org.unit" class="unit-name d-flex">{{ org.unit.title }}</span>
                            </ng-container>
                        </button>
                    </ng-container>
                </cdk-virtual-scroll-viewport>
            </div>

            <ng-container *ngIf="jwt.admin || jwt.superUser">
                <ng-template [ngTemplateOutlet]="addHOA"></ng-template>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-template #addHOA>
    <!-- only admins and supers can add new HOAs -->
    <div class="mat-icon-container add-icon d-flex justify-content-between">
        <button #addHOABtn
                class="btn w-100 d-flex align-content-center"
                (click)="requestNewHoaModal()">
            <mat-icon svgIcon="add"></mat-icon>
            <span class="my-auto ml-2">{{ 'Create New HOA' }}</span>
        </button>
    </div>
</ng-template>
