import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './app-layout.component';

const appLayoutRoutes: Routes = [
    {
        path: 'app',
        component: AppLayoutComponent,
        children: [],
    },
];

export const appLayoutRouting = RouterModule.forChild(appLayoutRoutes);
