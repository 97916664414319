import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';

@Pipe({name: 'legfiDate'})
export class LegfiDatePipe implements PipeTransform
{
    public transform(value: any, pattern: string = 'mediumDate') {
        const timezone = LegFiJwtService.getTimezone();

        // Cast string to Moment object to Date object before applying standard pipe.
        if (typeof value === 'string') {
            // if we didnt pass a time, use the date as-is
            if(value.indexOf(':') === -1) {
                value = moment(value);
            }
            value = moment.utc(value).tz(timezone);
        } else if (typeof value === 'number' && value > 0) {
            // Integers assumed to already be in ms precision.
            if (Math.round(value) === value) {
                value = moment.utc(value).tz(timezone);
            } else {
                // Decimal-based ms precision present.
                value = moment.unix(value).tz(timezone);
            }
        }

        if (!moment.isMoment(value)) {
            return value;
        }

        switch (pattern) {
            case 'medium':
                return value.format('MMM D, YYYY, hh:mm:ss A');
            case 'short':
                return value.format('M/D/YYYY, hh:mm A');
            case 'fullDate':
                return value.format('dddd, MMMM D, YYYY');
            case 'longDate':
                return value.format('MMMM D, YYYY');
            case 'mediumDate':
                return value.format('MMM D, YYYY');
            case 'shortDate':
                return value.format('M/D/YYYY');
            case 'mediumTime':
                return value.format('hh:mm:ss A');
            case 'shortTime':
                return value.format('hh:mm A');
            default:
                return value.format(pattern);
        }
    }
}
