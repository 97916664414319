import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { AvatarBlockModule } from '../../shared/avatar-block/avatar-block.module';
import { SharedDirectivesModule } from '../../shared/directives/shared-directives.module';
import { HeaderMenuComponent } from './header-menu.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatListModule,
        MatIconModule,
        MatBadgeModule,
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        SharedModule,
        ScrollingModule,
        SharedDirectivesModule,
        AvatarBlockModule,
    ],
    declarations: [HeaderMenuComponent],
    providers: [],
    exports: [
        HeaderMenuComponent,
    ],
})
export class HeaderMenuModule
{
}
