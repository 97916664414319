import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Organization } from './organization';
import { Membership } from './membership';
import Moment = moment.Moment;

export interface FileSharedWith
{
    id: number;
    name: string;
}

export class OrganizationFile
{
    id: number;
    parentId: number;
    organizationId: number;
    uploadedBy: number;
    directory: boolean;
    fileName: string;
    public: boolean;
    createdAt: Moment;
    updatedAt: Moment;
    uploader: string;
    url: string;
    sharedWith: FileSharedWith[];
    path: string = '';
    organization: Organization;
    parent: Organization;
    uploadingMember: Membership;
    children: OrganizationFile[];
    members: Membership[];
    checked: boolean = false;
    friendlyFileSize: string;
    public icon: string;

    constructor(organizationFile: any) {
        if (organizationFile.id) {
            this.id = organizationFile.id;
        }
        // can be null, still want it to be set
        this.parentId = organizationFile.parentId;

        if (organizationFile.organizationId) {
            this.organizationId = organizationFile.organizationId;
        }
        if (organizationFile.uploadedBy) {
            this.uploadedBy = organizationFile.uploadedBy;
        }
        if (organizationFile.directory) {
            this.directory = organizationFile.directory;
        }
        if (organizationFile.fileName) {
            this.fileName = organizationFile.fileName;
        }
        if (organizationFile.fileSize) {
            this.fileSize = organizationFile.fileSize;
        }
        if (organizationFile.public === 0 || organizationFile.public === null) {
            this.public = false;
        } else {
            this.public = true;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (organizationFile.createdAt) {
            this.createdAt = moment.utc(organizationFile.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (organizationFile.updatedAt) {
            this.updatedAt = moment.utc(organizationFile.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (organizationFile.path) {
            this.path = organizationFile.path;
        }

        if (organizationFile.uploader) {
            this.uploader = organizationFile.uploader;
        }
        if (organizationFile.url) {
            this.url = organizationFile.url;
        } else {
            // if the file was created on prod and you try to load locally
            this.url = '';
        }

        if (organizationFile.sharedWith) {
            this.sharedWith = organizationFile.sharedWith;
        }

        if (organizationFile.organization) {
            this.organization = new Organization(organizationFile.organization);
        }
        if (organizationFile.parent) {
            this.parent = new Organization(organizationFile.parent);
        }
        if (organizationFile.uploadingMember) {
            this.uploadingMember = new Membership(organizationFile.uploadingMember);
        }
        if (organizationFile.children) {
            this.children = organizationFile.children.map((child) => {
                return new Organization(child);
            });
        }

        if (!this.directory) {
            if (this.fileName.endsWith('.doc')) {
                this.icon = 'doc';
            } else if (this.fileName.endsWith('.xls') || this.fileName.endsWith('.xlsx') || this.fileName.endsWith('.csv')) {
                this.icon = 'xls';
            } else if (this.fileName.endsWith('.png') || this.fileName.endsWith('.jpg') || this.fileName.endsWith('.jpeg')) {
                this.icon = 'png';
            } else if (this.fileName.endsWith('.pdf')) {
                this.icon = 'pdf';
            } else {
                this.icon = 'blank';
            }
        } else {
            this.icon = 'folder';
        }
    }

    private _fileSize: number;

    public get fileSize() {
        return this._fileSize;
    }

    public set fileSize(value: any) {
        this._fileSize = value;
        this.friendlyFileSize = OrganizationFile.convertFileSizeToHumanReadable(this._fileSize, 2);
    }

    public static convertFileSizeToHumanReadable(size: number, decimal: number) {
        if (size === 0) {
            return '0 Byte';
        }

        const k = 1000;
        const dm = decimal + 1 || 3;
        const sizes = [
            'Bytes',
            'KB',
            'MB',
            'GB',
            'TB',
        ];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
