import moment from 'moment-timezone';
import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';
import { DepositBankAccount } from '../deposit-bank-account';
import { Organization } from '../organization';
import { PaymentAttempt } from '../payment-attempt';
import { TransactionAttachment } from '../transaction-attachment';
import { UnifiedBankAccount } from '../unified-bank-account';
import { BankReconciliationTransaction } from '../bank-reconciliation-transaction';
import { PaymentItem } from '../payment-item';
import { TransactionRule } from './transaction-rules/transaction-rule';
import { JournalEntry } from './journal-entries/journal-entry';
import { Transfer } from './transfers/transfer';
import { Category } from '../accounting/category';
import { StripePayout } from './stripe-payout';
import { Vendor } from 'app/models/entities/vendors/vendor';
import { Payable } from '../payable';
import Moment = moment.Moment;

export class Transaction
{
    id: number;
    organizationId: number;
    parentId: number;
    vendorId?: number;
    categoryId: number;
    transactionRuleId: number;
    ownerId: number;
    ownerType: string;
    bankAccountId: number;
    amount: number;
    originalAmount: number;
    description: string = '';
    memo: string = '';
    transactionType: string = null;
    legfiDeposit: boolean = false;
    transactionDate: Moment;
    transactionDateString: string = '';
    unitId: number;
    excluded: boolean = false;
    approved: boolean = false;
    journalEntry: boolean = false;
    wasMatched: boolean;
    matchedDate?: string;
    matchedText?: string;
    importedAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;
    billPaymentId: number;
    isExternalRevenue: boolean;
    allianceTransactionId: number;
    isPending: boolean;
    isPayable: boolean;
    convertToTransaction: boolean;

    attachments: TransactionAttachment[] = [];
    organization: Organization;
    bankAccount: DepositBankAccount | UnifiedBankAccount;
    category: Category;
    vendor: Vendor;
    paymentAttempts: PaymentAttempt[] = [];
    refundedPaymentAttempts: PaymentAttempt[];
    transfer: Transfer;
    children: Transaction[] = [];
    reference: PaymentAttempt | PaymentItem | Payable;
    journalEntries: JournalEntry[];
    transactionRule: TransactionRule = null;
    stripePayout: StripePayout;
    owner: UnifiedBankAccount;

    // Template control public vars
    public selected: boolean;

    public hidden: boolean = false;
    public checked: boolean = false;
    public showDepositDetail: boolean = false;
    public loadingDetail: boolean = false;
    public isExpandableDeposit: boolean = false;
    public missingFunds: boolean = false;
    public missingAmount: number = 0;
    public isOfflinePayment: boolean = false;

    public editCategory: { id: number, type: string } = null;

    public bankReconciliationTransaction: BankReconciliationTransaction;
    public selectedChild: Transaction = null;

    constructor(transaction: any) {
        this.id = transaction.id || null;
        this.organizationId = transaction.organizationId || null;
        this.parentId = transaction.parentId || null;
        this.bankAccountId = transaction.ownerId || null;
        this.categoryId = transaction.categoryId || null;

        if (transaction.reference) {
            if (transaction.referenceType.indexOf('PaymentAttempt') !== -1) {
                this.reference = new PaymentAttempt(transaction.reference);
            }
            if (transaction.referenceType.indexOf('PaymentItem') !== -1) {
                this.reference = new PaymentItem(transaction.reference);
            }
            if (transaction.referenceType.indexOf('Payable') !== -1) {
                this.reference = new Payable(transaction.reference);
            }
        }

        this.children = transaction.children ? transaction.children.map(c => new Transaction(c)) : [];
        this.owner = transaction.owner ? new UnifiedBankAccount(transaction.owner) : null;

        this.isExternalRevenue = transaction.isExternalRevenue || false;
        this.isPending = transaction.isPending || false;
        this.isPayable = transaction.isPayable || false;
        this.vendorId = transaction.vendorId || null;
        this.transactionRuleId = transaction.transactionRuleId || null;

        this.ownerId = transaction.ownerId || null;
        this.ownerType = transaction.ownerType || null;

        this.excluded = transaction.excluded || false;
        this.approved = transaction.approved || false;
        this.billPaymentId = transaction.billPaymentId || null;
        this.journalEntry = transaction.journalEntry || false;
        this.wasMatched = transaction.wasMatched || false;
        this.matchedDate = transaction.matchedDate;
        if (this.wasMatched) {
            this.matchedText = 'Automatically matched from Bill Pay transaction on ' + this.matchedDate + '.';
        }

        this.amount = transaction.amount;
        this.originalAmount = transaction.originalAmount;
        this.description = transaction.description;
        this.memo = transaction.memo;

        this.legfiDeposit = transaction.transactionType === 'stripe_deposit';
        this.transactionType = transaction.transactionType;

        const timezone = LegFiJwtService.getTimezone();
        if (transaction.transactionDate) {
            this.transactionDate = moment.utc(transaction.transactionDate, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
            this.transactionDateString = this.transactionDate.format('MM/DD/YYYY');
        }
        if (transaction.importedAt) {
            this.importedAt = moment.utc(transaction.importedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (transaction.deletedAt) {
            this.deletedAt = moment.utc(transaction.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        this.transfer = (transaction.transfer) ? new Transfer(transaction.transfer) : null;

        if (transaction.unitId) {
            this.unitId = transaction.unitId;
        }
        if (transaction.organization) {
            this.organization = new Organization(transaction.organization);
        }
        if (transaction.owner && transaction.ownerType === 'App\\Models\\Entities\\UnifiedBankAccount') {
            this.bankAccount = new UnifiedBankAccount(transaction.owner);
        }
        if (transaction.category) {
            this.category = new Category(transaction.category);
        }
        if (transaction.attachments) {
            this.attachments = transaction.attachments.map((attachment) => {
                return new TransactionAttachment(attachment);
            });
        }
        if (transaction.journalEntries) {
            this.journalEntries = transaction.journalEntries.map((je) => {
                return new JournalEntry(je);
            });
        }
        if (transaction.paymentAttempts) {
            this.paymentAttempts = transaction.paymentAttempts.map(
                    (paymentAttempt) => {
                        return new PaymentAttempt(paymentAttempt);
                    },
            );
        }

        this.transactionRule = transaction.transactionRule
                ? new TransactionRule(transaction.transactionRule)
                : null;

        if (this.children.length > 0) {
            this.isExpandableDeposit = true;
            if (this.transactionType !== 'lockbox_deposit') {
                this.isOfflinePayment = true;
            }
        }

        if (this.legfiDeposit) {
            let childTotal = 0;
            this.children.forEach((child) => {
                childTotal += child.amount;
            });
            if (childTotal > this.originalAmount) {
                this.missingFunds = true;
                this.missingAmount = this.originalAmount - childTotal;
            }
        }

        if (transaction.bankReconciliationTransaction) {
            this.bankReconciliationTransaction = new BankReconciliationTransaction(transaction.bankReconciliationTransaction);
        }

        if (transaction.stripePayout) {
            this.stripePayout = new StripePayout(transaction.stripePayout);
        }
    }

    isPayableReference(reference: any): reference is Payable {
        return reference && (reference as Payable).invoiceDate !== undefined;
    }

    updateDepositCategory() {
        if (!this.category && this.children) {
            let cats = [];
            for (const child of this.children) {
                if (!child.category) {
                    for (const item of child.children) {
                        cats.push(item.category?.name);
                    }
                } else {
                    cats.push(child.category?.name);
                }
            }
            cats = cats.filter((v, i) => cats.indexOf(v) === i);
            if (cats.length === 1) {
                this.category = new Category({
                    name: cats[0],
                });
            } else if (cats.length > 1) {
                this.category = new Category({
                    name: cats.length + ' Categories',
                });
            }
        }
        if (!this.category && this.isPayable && this.isPayableReference(this.reference)) {
            this.category = new Category({
                name: this.reference.expenseCategories.length + ' Categories',
            });
        }
    }
}
