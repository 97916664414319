import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ButtonModule } from '../../button/button.module';
import { TagsDialogComponent } from './tags-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TagInputModule } from 'ngx-chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdjustColorModule } from '../../../../directives/adjust-color/adjust-color.module';


@NgModule({
    declarations: [
        TagsDialogComponent,
    ], imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        TagInputModule,
        AdjustColorModule,
        MatSlideToggleModule,
        MatTooltipModule,
        ButtonModule,
    ], exports: [
        TagsDialogComponent,
    ], providers: [],
})
export class TagsDialogModule
{
}
