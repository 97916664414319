import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { BankReconciliation } from './bank-reconciliation';
import { Transaction } from './transactions/transaction';
import Moment = moment.Moment;

export class BankReconciliationTransaction
{
    id: number;
    bankReconciliationId: number;
    transactionId: number;
    amount: number;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    bankReconciliation: BankReconciliation;
    transaction: Transaction;

    constructor(reconciliation: any) {
        if (reconciliation.id) {
            this.id = reconciliation.id;
        }
        if (reconciliation.bankReconciliationId) {
            this.bankReconciliationId = reconciliation.bankReconciliationId;
        }
        if (reconciliation.transactionId) {
            this.transactionId = reconciliation.transactionId;
        }
        if (reconciliation.amount) {
            this.amount = reconciliation.amount;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (reconciliation.createdAt) {
            this.createdAt = moment.utc(reconciliation.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (reconciliation.updatedAt) {
            this.updatedAt = moment.utc(reconciliation.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (reconciliation.deletedAt) {
            this.deletedAt = moment.utc(reconciliation.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (reconciliation.bankReconciliation) {
            this.bankReconciliation = new BankReconciliation(reconciliation.bankReconciliation);
        }
        if (reconciliation.transaction) {
            this.transaction = new Transaction(reconciliation.transaction);
        }
    }
}
