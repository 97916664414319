import { NotificationPreference } from './notification-preference';
import { Site } from './site';

export class NotificationType
{
    id: number;
    siteId: number;
    name: string;
    emailAvailable: boolean;
    emailRequired: boolean;
    smsAvailable: boolean;
    smsRequired: boolean;

    site: Site;
    notificationPreferences: NotificationPreference[];

    constructor(notificationType: any) {
        if (notificationType.id) {
            this.id = notificationType.id;
        }
        if (notificationType.siteId) {
            this.siteId = notificationType.siteId;
        }
        if (notificationType.name) {
            this.name = notificationType.name;
        }
        if (notificationType.emailAvailable) {
            this.emailAvailable = notificationType.emailAvailable;
        }
        if (notificationType.emailRequired) {
            this.emailRequired = notificationType.emailRequired;
        }
        if (notificationType.smsAvailable) {
            this.smsAvailable = notificationType.smsAvailable;
        }
        if (notificationType.smsRequired) {
            this.smsRequired = notificationType.smsRequired;
        }

        if (notificationType.site) {
            this.site = new Site(notificationType.site);
        }
        if (notificationType.notificationPreferences) {
            this.notificationPreferences = notificationType.notificationPreferences.map(
                    (notificationPreference) => {
                        return new NotificationPreference(notificationPreference);
                    },
            );
        }
    }
}
