<div *ngIf="show" class="modal-overlay-native fade in"></div>
<div *ngIf="show">
    <div *IfOnDom [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}" class="modal-native">
        <div [class.allow-overflow]="overflows" [class.small-width]="isSmallWidth" class="modal-box-native">
            <div [class.allow-overflow]="overflows" class="modal-content-native">
                <div *ngIf="closable || title" class="modal-header-native">
                    <img (click)="close();" *ngIf="closable" class="modal-close"
                         src="/assets/images/payhoa/other/icon_close.svg"/>
                    <div *ngIf="title" [innerHTML]="title" class="modal-title-native"></div>
                </div>
                <div [class.add-pad]="ifAddPad"
                     class="modal-body-native">
                    <lf-component-injector #injector *ngIf="type" [inputs]="inputs" [type]="type"></lf-component-injector>
                    <ng-content #body *ngIf="!type"></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>
