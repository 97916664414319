import { NgModule } from '@angular/core';
import { ButtonModule } from '../../../shared/button/button.module';
import { KycModalComponent } from './kyc-modal.component';
import { SharedModule } from '../../../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { ModalService } from '../../../../services/modal.service';
import { LegfiDatePipeModule } from '../../../../pipes/format/legfi-date.module';

@NgModule({
    imports: [
        SharedModule,
        MatIconModule,
        LegfiDatePipeModule,
        ButtonModule,
    ],
    declarations: [KycModalComponent],
    exports: [KycModalComponent],
    providers: [ModalService],
})
export class KycModalModule
{
}
