import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ApplicationHttpClient } from './application-http-client';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MaintenanceModeDialogComponent } from '../../app-layout/shared/maintenance-mode-dialog/maintenance-mode-dialog.component';

@Injectable()
export class MaintenanceModeInterceptor implements HttpInterceptor
{
    private isShowingDialog = false;

    constructor(
            private _location: Location,
            private _router: Router,
            private _http: ApplicationHttpClient,
            private _dialog: MatDialog,
    ) {
    }

    public intercept(
            req: HttpRequest<any>,
            next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(req)
                .pipe(catchError((error) => {
                    if (error.status === 503 && !this.isShowingDialog) {
                        this._dialog.open(MaintenanceModeDialogComponent, {
                            disableClose: true,
                        });
                        this.isShowingDialog = true;
                    }
                    return throwError(error);
                }));
    }
}
