<ng-template #projectedContent><ng-content></ng-content></ng-template>

<ng-template #labelContent>
    <ng-container *ngIf="label; else projectedContent">
        <span class="d-flex flex-column">
            <span [innerHTML]="label"></span>
            <ng-content select=".description-text"></ng-content>
        </span>
    </ng-container>
</ng-template>

<ng-template #text>
    <ng-container *ngIf="!mobileText"><ng-container *ngTemplateOutlet="labelContent"></ng-container></ng-container>
    <ng-container *ngIf="mobileText">
        <span class="d-inline d-md-none">{{mobileText}}</span>
        <span class="d-none d-md-inline"><ng-container *ngTemplateOutlet="labelContent"></ng-container></span>
    </ng-container>
</ng-template>

<ng-template #iconContent let-faIcon="faIcon">
    <mat-icon *ngIf="!faIcon; else faIconContent"
              [ngClass]="iconClassSet"
              [svgIcon]="svg ? svg : null"
              [matTooltip]="iconTooltip">{{ icon ? icon : '' }}</mat-icon>
    <ng-template #faIconContent>
        <!-- TODO: Replace with correct svg/icon class -->
        <i [ngClass]="faIcon" [matTooltip]="iconTooltip"></i>
    </ng-template>
</ng-template>

<ng-template #content>
    <span class="d-flex flex-nowrap"
          [class.align-items-start]="iconClassSet.has('align-items-start')"
          [class.align-items-center]="!iconClassSet.has('align-items-start')"
          [class.mat-button-working]="isWorking"
          [matTooltip]="tooltip">
        <ng-container *ngIf="(icon || svg) && iconPosition === 'before'">
            <ng-container *ngTemplateOutlet="iconContent; context: { faIcon }"></ng-container>
        </ng-container>

        <ng-container *ngTemplateOutlet="text"></ng-container>

        <app-super-star *ngIf="isSuperOnly"></app-super-star>
        <ng-container *ngIf="(icon || svg) && iconPosition === 'after'">
            <ng-container *ngTemplateOutlet="iconContent; context: { faIcon }"></ng-container>
        </ng-container>

        <mat-spinner *ngIf="isWorking"
                     class="loading-overlay animated fadeIn"
                     [diameter]="spinnerSize"
                     [strokeWidth]="2"></mat-spinner>
    </span>
</ng-template>

<ng-template #spinner>
    <mat-spinner [diameter]="18"
                 [strokeWidth]="2"></mat-spinner>
    <span class="ml-2">
        <ng-container *ngTemplateOutlet="text"></ng-container>&hellip;
    </span>
</ng-template>

<ng-template #button>
    <ng-container [ngSwitch]="type">
        <!-- Until we can set type dynamically: https://github.com/angular/components/issues/15367 -->
        <button *ngSwitchCase="'spinner'"
                [ngClass]="buttonClassSet"
                [disabled]="true">
            <ng-container *ngTemplateOutlet="spinner"></ng-container>
        </button>
        <button *ngSwitchCase="'primary'"
                mat-raised-button
                color="primary"
                type="{{isSubmit ? 'submit' : 'button'}}"
                [ngClass]="buttonClassSet"
                [disabled]="isDisabled"
                (click)="buttonClicked($event)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </button>
        <button *ngSwitchCase="'primary-outline'"
                mat-raised-button
                mat-stroked-button
                color="primary"
                type="{{isSubmit ? 'submit' : 'button'}}"
                [ngClass]="buttonClassSet"
                [disabled]="isDisabled"
                (click)="buttonClicked($event)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </button>
        <button *ngSwitchCase="'secondary'"
                mat-raised-button
                color="accent"
                type="{{isSubmit ? 'submit' : 'button'}}"
                [ngClass]="buttonClassSet"
                [disabled]="isDisabled"
                (click)="buttonClicked($event)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </button>
        <button *ngSwitchCase="'warn'"
                mat-raised-button
                color="warn"
                type="{{isSubmit ? 'submit' : 'button'}}"
                [ngClass]="buttonClassSet"
                [disabled]="isDisabled"
                (click)="buttonClicked($event)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </button>
        <button *ngSwitchCase="'warn-outline'"
                mat-raised-button
                mat-stroked-button
                color="warn"
                type="{{isSubmit ? 'submit' : 'button'}}"
                [ngClass]="buttonClassSet"
                [disabled]="isDisabled"
                (click)="buttonClicked($event)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </button>
        <button *ngSwitchCase="'success'"
                mat-raised-button
                type="{{isSubmit ? 'submit' : 'button'}}"
                [ngClass]="buttonClassSet"
                [disabled]="isDisabled"
                (click)="buttonClicked($event)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </button>
        <button *ngSwitchCase="'success-outline'"
                mat-raised-button
                mat-stroked-button
                type="{{isSubmit ? 'submit' : 'button'}}"
                [ngClass]="buttonClassSet"
                [disabled]="isDisabled"
                (click)="buttonClicked($event)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </button>
        <button *ngSwitchCase="'link'"
                mat-button
                type="{{isSubmit ? 'submit' : 'button'}}"
                [ngClass]="buttonClassSet"
                [disabled]="isDisabled"
                (click)="buttonClicked($event)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </button>
        <button *ngSwitchDefault
                mat-raised-button
                mat-stroked-button
                color="accent"
                type="{{isSubmit ? 'submit' : 'button'}}"
                [ngClass]="buttonClassSet"
                [disabled]="isDisabled"
                (click)="buttonClicked($event)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </button>
    </ng-container>
</ng-template>

<ng-container *ngIf="routeTo; else button">
    <ng-container [ngSwitch]="type">
        <!-- Until we can set type dynamically: https://github.com/angular/components/issues/15367 -->
        <a *ngSwitchCase="'primary'"
           mat-raised-button
           color="primary"
           target="{{targetType}}"
           [ngClass]="buttonClassSet"
           [disabled]="isDisabled"
           [routerLink]="isDisabled ? null : (params ? [routeString, params] : [routeString])"
           [queryParams]="isDisabled ? null : (queryParams ? queryParams : null)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>
        <a *ngSwitchCase="'primary-outline'"
           mat-raised-button
           mat-stroked-button
           color="primary"
           target="{{targetType}}"
           [ngClass]="buttonClassSet"
           [disabled]="isDisabled"
           [routerLink]="isDisabled ? null : (params ? [routeString, params] : [routeString])"
           [queryParams]="isDisabled ? null : (queryParams ? queryParams : null)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>
        <a *ngSwitchCase="'secondary'"
           mat-raised-button
           color="accent"
           target="{{targetType}}"
           [ngClass]="buttonClassSet"
           [disabled]="isDisabled"
           [routerLink]="isDisabled ? null : (params ? [routeString, params] : [routeString])"
           [queryParams]="isDisabled ? null : (queryParams ? queryParams : null)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>
        <a *ngSwitchCase="'warn'"
           mat-raised-button
           color="warn"
           target="{{targetType}}"
           [ngClass]="buttonClassSet"
           [disabled]="isDisabled"
           [routerLink]="isDisabled ? null : (params ? [routeString, params] : [routeString])"
           [queryParams]="isDisabled ? null : (queryParams ? queryParams : null)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>
        <a *ngSwitchCase="'warn-outline'"
           mat-raised-button
           mat-stroked-button
           color="warn"
           target="{{targetType}}"
           [ngClass]="buttonClassSet"
           [disabled]="isDisabled"
           [routerLink]="isDisabled ? null : (params ? [routeString, params] : [routeString])"
           [queryParams]="isDisabled ? null : (queryParams ? queryParams : null)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>
        <a *ngSwitchCase="'success'"
           mat-raised-button
           target="{{targetType}}"
           [ngClass]="buttonClassSet"
           [disabled]="isDisabled"
           [routerLink]="isDisabled ? null : (params ? [routeString, params] : [routeString])"
           [queryParams]="isDisabled ? null : (queryParams ? queryParams : null)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>
        <a *ngSwitchCase="'success-outline'"
           mat-raised-button
           mat-stroked-button
           target="{{targetType}}"
           [ngClass]="buttonClassSet"
           [disabled]="isDisabled"
           [routerLink]="isDisabled ? null : (params ? [routeString, params] : [routeString])"
           [queryParams]="isDisabled ? null : (queryParams ? queryParams : null)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>
        <a *ngSwitchCase="'link'"
           mat-button
           target="{{targetType}}"
           [ngClass]="buttonClassSet"
           [disabled]="isDisabled"
           [routerLink]="isDisabled ? null : (params ? [routeString, params] : [routeString])"
           [queryParams]="isDisabled ? null : (queryParams ? queryParams : null)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>
        <a *ngSwitchDefault
           mat-raised-button
           mat-stroked-button
           color="accent"
           target="{{targetType}}"
           [ngClass]="buttonClassSet"
           [disabled]="isDisabled"
           [routerLink]="isDisabled ? null : (params ? [routeString, params] : [routeString])"
           [queryParams]="isDisabled ? null : (queryParams ? queryParams : null)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>
    </ng-container>
</ng-container>
