import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import moment from 'moment-timezone';
import Moment = moment.Moment;
import { Organization } from './organization';


export class PayableApprovalRule
{
    id: number;
    organizationId: number;
    value: number;
    numberRequired: number;
    createdAt: Moment;
    updatedAt: Moment;
    organization: Organization;

    constructor(payableApprovalRule: any) {
        if (payableApprovalRule.id) {
            this.id = payableApprovalRule.id;
        }
        if (payableApprovalRule.organizationId) {
            this.organizationId = payableApprovalRule.organizationId;
        }
        if (payableApprovalRule.value) {
            this.value = payableApprovalRule.value;
        }
        if (payableApprovalRule.numberRequired) {
            this.numberRequired = payableApprovalRule.numberRequired;
        }
        const timezone = LegFiJwtService.getTimezone();
        if (payableApprovalRule.createdAt) {
            this.createdAt = moment.utc(payableApprovalRule.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (payableApprovalRule.updatedAt) {
            this.updatedAt = moment.utc(payableApprovalRule.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        // relationships
        if (payableApprovalRule.orgranization) {
            this.organization = new Organization(payableApprovalRule.organization);
        }
    }
}
