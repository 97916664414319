import { Unit } from './unit';
import { RecurringChargeTemplate } from './recurring-charge-template';
import { Moment } from 'moment';

export class RecurringChargeTemplateUnit
{
    id: number;
    templateId: number;
    unitId: number;
    amount: number;

    unit: Unit;
    template: RecurringChargeTemplate;
    pausedAt: Moment;

    constructor(templateUnit: any) {
        if (templateUnit.id) {
            this.id = templateUnit.id;
        }
        if (templateUnit.templateId) {
            this.templateId = templateUnit.templateId;
        }
        if (templateUnit.unitId) {
            this.unitId = templateUnit.unitId;
        }
        if (templateUnit.amount) {
            this.amount = templateUnit.amount;
        }
        if (templateUnit.unit) {
            this.unit = new Unit(templateUnit.unit);
        }
        if (templateUnit.template) {
            this.template = new RecurringChargeTemplate(templateUnit.template);
        }
        if (templateUnit.pausedAt) {
            this.pausedAt = templateUnit.pausedAt;
        }
    }
}
