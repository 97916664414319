import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MaterialSearchComponent } from 'app/components/shared/material-search/material-search.component';
import { SharedModule } from '../shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
    ],
    declarations: [MaterialSearchComponent],
    exports: [MaterialSearchComponent],
})
export class MaterialSearchModule
{
}
