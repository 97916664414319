import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

/** https://s3.amazonaws.com/helpscout.net/docs/assets/5cdefb182c7d3a6d82bda079/attachments/5e998cb02c7d3a7e9aeb20b5/postcode_formats.pdf */
export const US_SHORT_POSTAL_CODE_VALIDATION_MASK = '00000';
export const US_POSTAL_CODE_EXT_VALIDATION_MASK = '0000';
export const US_POSTAL_CODE_VALIDATION_MASK = `${US_SHORT_POSTAL_CODE_VALIDATION_MASK}-${US_POSTAL_CODE_EXT_VALIDATION_MASK}||${US_SHORT_POSTAL_CODE_VALIDATION_MASK}`;
export const MX_POSTAL_CODE_VALIDATION_MASK = US_SHORT_POSTAL_CODE_VALIDATION_MASK;
export const CA_POSTAL_CODE_VALIDATION_MASK = 'A0A-A0A||A0A A0A';

@Directive({
    selector: '[validatePostalCode][formControlName],[validatePostalCode][formControl],[validatePostalCode][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PostalCodeValidatorDirective),
            multi: true,
        },
    ],
})
export class PostalCodeValidatorDirective implements Validator
{
    // code from src/app/config/geo-regions.ts
    @Input() countryCode: 'US'|'USA'|'MX'|'CA'|'CR' = 'US';

    readonly usPattern = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
    readonly mxPattern = new RegExp(/(^\d{5}$)/);
    readonly caPattern = new RegExp(/(^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$)/);

    public validate(control: AbstractControl): { [key: string]: any } {
        if (control.value.length === 0) {
            return null;
        }

        let valid = false;
        switch(this.countryCode) {
            case 'US':
            case 'USA':
            case 'CR':
                valid = this.usPattern.test(control.value);
                break;
            case 'CA':
                valid = this.caPattern.test(control.value);
                break;
            case 'MX':
            default:
                valid = this.mxPattern.test(control.value);
                break;
        }
        if (!valid) {
            return { invalidPostalCodeFormat: true };
        }

        return null;
    }
}
