import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AdminService, AuditHistory } from '../../../../services/admin/admin.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GrowlerService } from '../../../../services/growler.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Audit } from '../../../../models/entities/audit';
import { AuditGroup } from '../../../../models/entities/audit-group';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';

export interface AuditHistoryDialogInput
{
    model: string;
    id: number;
}

@UntilDestroy()
@Component({
    selector: 'app-audit-history',
    templateUrl: './audit-history.component.html',
    styleUrls: ['./audit-history.component.scss'],
})
export class AuditHistoryComponent implements OnInit, AfterViewInit
{

    isLoading: boolean = true;
    audits: Audit[] = [];
    object: any = null;
    objectKeys: string[] = [];
    relatedRecords: {
        [key: string]: {
            className: string;
            id: number;
        }[];
    }[] = [];
    models: string[] = [
        'Address',
        'Charge',
        'Membership',
        'MembershipProfile',
        'Organization',
        'OrganizationProfile',
        'Owner',
        'PayabliPayable',
        'PayableApproval',
        'PaymentGroup',
        'PaymentAttempt',
        'PaymentItem',
        'RecurringChargeTemplate',
        'Transaction',
        'Unit',
        'User',
    ];
    objectName: string;
    relations: string[] = [];
    expandedElement: Audit;
    displayedColumns: string[] = [
        'createdAt',
        'action',
        'user',
        'description',
    ];

    @ViewChild('idInput') public idInput: ElementRef;
    @ViewChild('auditsTable') table: MatTable<Audit[]>;

    expanded: SelectionModel<Audit> = new SelectionModel<Audit>(true, []);

    constructor(
            private _growler: GrowlerService,
            private _adminService: AdminService,
            public dialogRef: MatDialogRef<AuditHistoryComponent>,
            @Inject(MAT_DIALOG_DATA) public data: AuditHistoryDialogInput,
    ) {
    }

    ngOnInit() {
        if (this.data.model && this.data.id) {
            this.loadHistory();
        }
    }

    ngAfterViewInit() {
        fromEvent(this.idInput.nativeElement, 'keyup')
                .pipe(
                        debounceTime(500),
                        distinctUntilChanged(),
                        tap(() => {
                            this.loadHistory();
                        }),
                )
                .subscribe();
    }

    toggleRow(element: Audit): void {
        this.expanded.toggle(element);
        this.table.renderRows();
    }

    isExpandedRow(index: number, record: Audit) {
        return this.expanded.isSelected(record);
    }

    loadHistory() {

        if (this.data.model && this.data.id) {
            this.isLoading = true;
            this.audits = [];
            this.object = null;
            this.objectName = null;
            this.relatedRecords = [];
            this.relations = [];
            this._adminService.getAuditHistory(this.data.model, this.data.id)
                    .pipe(untilDestroyed(this))
                    .subscribe({
                        next: (records: AuditHistory) => {
                            this.object = records.record;
                            this.objectName = this.data.model;
                            this.objectKeys = Object.keys(this.object);
                            this.audits = records.audits.reverse();
                            this.relatedRecords = records.relatedRecords;
                            this.relations = Object.keys(this.relatedRecords);
                            this.isLoading = false;
                        },
                        error: (error) => {
                            this.isLoading = false;
                            this._growler.oops('Failed to load audit history');
                        },
                    });
        }
    }

    selectRelatedRecord(key: string, value: number) {
        this.data.model = key;
        this.data.id = value;
        this.loadHistory();
    }

    camelCaseToCapitalizedWords(camelCaseStr: string): string {
        return camelCaseStr
                // Insert a space before each uppercase letter
                .replace(/([A-Z])/g, ' $1')
                // Capitalize the first letter of each word
                .replace(/\b\w/g, char => char.toUpperCase())
                // Trim any leading or trailing spaces
                .trim();
    }

    getLastPartAfterSlash(inputString: string) {
        const searchString = 'Entities\\';
        const index = inputString.indexOf(searchString);

        if (index === -1) {
            return inputString;
        }

        return inputString.substring(index + searchString.length);
    }
}
