<div class="container">
    <div class="d-flex align-items-center justify-content-center text-muted" style="height:600px;">
        <div class="card p-5">
            <div class="mb-5">
                <img style="width:180px;" class="logo img payhoa-logo" alt="Home" src="/assets/images/payhoa/hoa-management-software/payhoa-logo.svg">
            </div>
            <h1 class="h1 mb-4">Oops!</h1>
            <h2 class="h2">404 Not Found</h2>
            <h5 class="h5 my-5">
                Sorry, an error has occurred. The requested page was not found!
            </h5>
            <div>
                <a href="/" class="btn btn-primary btn-block"><i class="fa fa-home mr-2"></i>Take Me Home </a>
            </div>
        </div>
    </div>
</div>
