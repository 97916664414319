import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { CustomField } from './custom-field';
import { Membership } from './membership';

export class CustomFieldValue
{
    id: number;
    customFieldId: number;
    membershipId: number;
    value: any;
    createdAt: Moment;
    updatedAt: Moment;

    customField: CustomField;
    membership: Membership;

    constructor(customFieldValue: any) {
        if (customFieldValue.id) {
            this.id = customFieldValue.id;
        }
        if (customFieldValue.customFieldId) {
            this.customFieldId = customFieldValue.customFieldId;
        }
        if (customFieldValue.membershipId) {
            this.membershipId = customFieldValue.membershipId;
        }
        if (customFieldValue.value) {
            this.value = customFieldValue.value;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (customFieldValue.createdAt) {
            this.createdAt = moment.utc(customFieldValue.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (customFieldValue.updatedAt) {
            this.updatedAt = moment.utc(customFieldValue.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (customFieldValue.customField) {
            this.customField = new CustomField(customFieldValue.customField);
        }
        if (customFieldValue.membership) {
            this.membership = new Membership(customFieldValue.membership);
        }
    }
}
