import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor
{

    constructor(
            private _location: Location,
            private _router: Router,
    ) {
    }

    public intercept(
            req: HttpRequest<any>,
            next: HttpHandler,
    ): Observable<HttpEvent<any>> {

        let headers = req.headers;
        const method = req.method;
        const update = {};

        if (method.toUpperCase() !== 'GET' && !req.body) {
            update['body'] = '';
        }

        if (
                method.toUpperCase() !== 'GET' &&
                method.toUpperCase() !== 'DELETE'
                && !headers.has('Content-Type')
        ) {
            headers = headers.set('Content-Type', 'application/json');
        }

        if (!headers.has('Accept')) {
            headers = headers.set('Accept', 'application/json');
        }
        update['headers'] = headers;

        const clonedRequest: HttpRequest<any> = req.clone(update);

        return next.handle(clonedRequest);
    }
}
