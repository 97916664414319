import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { LegFiJwtService } from '../../../../services/auth/legfi-jwt.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PageAdvertisement } from '../../../../models/entities/page-advertisement';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PageAdvertisementService } from '../../../../services/page-advertisement/page-advertisement.service';
import { FeatureDialogComponent } from '../../onboarding/feature-dialog/feature-dialog.component';
import { MatDialogSizes } from '../../../../enums/mat-dialog-sizes.enum';
import { GenericDialogResult } from '../generic-mat-dialog/generic-dialog-result';
import { Router } from '@angular/router';
import { LocalService } from '../../../../services/storage/local.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { delay, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-page-advertisement',
    templateUrl: './page-advertisement.component.html',
    styleUrls: ['./page-advertisement.component.scss'],
    animations: [
        trigger('slide', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(20%)' }),
                animate('400ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
            ]),
        ]),
    ]
})
export class PageAdvertisementComponent implements OnInit
{
    @Input() organizationId: number = null;
    index = 0;

    private pageAdvertisements: PageAdvertisement[] = [];
    private pageAdvertisementSubject$: BehaviorSubject<PageAdvertisement> = new BehaviorSubject<PageAdvertisement>(null);
    private loadingState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(
            private _dialog: MatDialog,
            private _router: Router,
            private _pageAdvertisementService: PageAdvertisementService,
    ) {
    }

    get total() {
        return this.pageAdvertisements.length || 0;
    }

    get loadedAdvertisement$(): Observable<PageAdvertisement> {
        return this.pageAdvertisementSubject$.asObservable();
    }

    get pageAdvertisement(): PageAdvertisement {
        switch(this.total) {
            case 1:
                return this.pageAdvertisements[0];
            case 0:
                return null;
            default:
                return this.pageAdvertisements[this.index];
        }
    }

    ngOnInit() {
        const jwt = LegFiJwtService.read();
        if (jwt.admin) {
            if (this.organizationId === null) {
                this.organizationId = jwt.orgId;
            }
        }
        this._pageAdvertisementService.getPageAdvertisement().pipe(untilDestroyed(this)).subscribe({
            next: (pageAdvertisements) => {
                this.updatePageAdvertisements(pageAdvertisements);
                this.loadingState$.next(false);
            }
        });

        this.loadingState$.asObservable().pipe(switchMap((loading) => iif(() => loading, of(loading), of(loading).pipe(delay(120)))), untilDestroyed(this)).subscribe({
            next: (loading) => this.pageAdvertisementSubject$.next(loading ? null : this.pageAdvertisement),
        });
    }

    openAd() {
        const ad = this.pageAdvertisementSubject$.getValue();
        if (ad.useFeatureDialog) {
            this._dialog.open(FeatureDialogComponent, {
                maxWidth: MatDialogSizes.FULL,
                width: MatDialogSizes.FULL,
                height: MatDialogSizes.FULL,
                data: ad,
            }).afterClosed().subscribe({
                next: (result: GenericDialogResult) => {
                    if (result?.success) {
                        return this.handleAction(ad);
                    }
                }
            });
        } else {
            return this.handleAction(ad);
        }
    }

    dismissAd() {
        const ad = this.pageAdvertisementSubject$.getValue();
        this.loadingState$.next(true);

        // local storage for dismissal
        const dismissed = JSON.parse(LocalService.get('payhoa-dismissed')) || {};
        dismissed[LocalService.key('ad', [ad.id])] = 'true';
        LocalService.set(`payhoa-dismissed`, JSON.stringify(dismissed));

        this.updatePageAdvertisements();
        this.loadingState$.next(false);
    }

    next() {
        const ad = this.pageAdvertisementSubject$.getValue();
        this.loadingState$.next(true);
        let index = this.pageAdvertisements.findIndex((a) => a.id === ad.id);
        index++;
        this.index = index < this.total ? index : 0;
        this.loadingState$.next(false);
    }

    private updatePageAdvertisements(ads = this.pageAdvertisements) {
        this.pageAdvertisements = [];
        const dismissed = JSON.parse(LocalService.get('payhoa-dismissed')) || {};
        for (const ad of ads) {
            if (!dismissed.hasOwnProperty(LocalService.key('ad', [ad.id]))) {
                this.pageAdvertisements.push(ad);
            }
        }
    }

    private handleAction(ad: PageAdvertisement) {
        if (ad.cta.url.indexOf('https://') > -1) {
            // open call-to-action link in new tab for now
            window.open(ad.cta.url, '_blank');
        } else {
            // navigate to call-to-action path
            return this._router.navigate([ad.cta.url]);
        }
    }
}
