/**
 * Shared, standardized sizes for breakpoints across app
 * Using Bootstrap's breakpoints for now (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
 * https://getbootstrap.com/docs/5.0/layout/breakpoints/#max-width
 * TODO: compare material vs. bootstrap vs. tailwind for preferred breakpoints with ng-update
 */
export class BreakpointSizes {
    /** X-Small devices (portrait phones, less than 576px) */
    static readonly XS  = new BreakpointSizes(0, 575);
    /** Small devices (landscape phones, 576px - 767px) */
    static readonly SM  = new BreakpointSizes(576, 767);
    /** Medium devices (tablets, 768px - 991px) */
    static readonly MD  = new BreakpointSizes(768, 991);
    /** Large devices (desktops, 992px - 1199px) */
    static readonly LG  = new BreakpointSizes(992, 1199);
    /** X-Large devices (large desktops, 1200px - 1399px) */
    static readonly XL  = new BreakpointSizes(1200, 1399);
    /** XX-Large devices (larger desktops, 1400px and up) */
    static readonly XXL  = new BreakpointSizes(1400, 9999);

    private constructor(
            private readonly mn: number,
            private readonly mx: number
    ) {
    }

    get min() {
        return `${this.mn}px`;
    }

    get max() {
        return `${this.mx}px`;
    }

    /** return true up to breakpoint's max value (max-width: [MAX_VALUE]px) */
    get observer() {
        return `(max-width: ${this.max})`;
    }
}
