import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Organization } from '../../models/entities/organization';
import { Router, UrlTree } from '@angular/router';
import { AuthService, SwitchAccountsResponse } from '../auth/auth.service';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { Routes } from '../../config/routes';
import environment from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class OrganizationDataService
{
    private orgSubject$: BehaviorSubject<Organization> = new BehaviorSubject<Organization>(null);
    private orgLoadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
            private _router: Router,
            private _authService: AuthService,
    ) {
    }

    public get org$(): Observable<Organization> {
        return this.orgSubject$.asObservable();
    }

    public get orgLoading$(): Observable<boolean> {
        return this.orgLoadingSubject$.asObservable();
    }

    public get orgId(): number {
        return this.orgSubject$.getValue()?.id;
    }

    public switchOrganization(id: number, idParams: { [key: string]: number } = {}, redirectTarget?: any) {
        // this.isShowingLoadingModal = true;
        this.orgLoadingSubject$.next(true);

        if (redirectTarget && Array.isArray(redirectTarget) && redirectTarget.length === 2) {
            const tree: UrlTree = this._router.createUrlTree(redirectTarget);
            redirectTarget = tree.toString();
        }

        const params = {
            ...idParams,
            ...{siteId: environment.SiteId},
        };

        return this._authService.switchAccounts(id, params).pipe(
            map((data) => {
                const jwt = LegFiJwtService.read();

                // get data from response
                let siteId = null;
                if (data.hasOwnProperty('siteId')) {
                    siteId = data.siteId;
                }
                let isExternalRedirect = false;
                let nonce: string = '';
                if (data.hasOwnProperty('external') && data.hasOwnProperty('nonce')) {
                    isExternalRedirect = true;
                    nonce = data.nonce;
                }

                // handle redirect target
                if (!redirectTarget) {
                    let tree: UrlTree;
                    if (!jwt.superUser || jwt.impersonatedBy) {
                        // admins and homeowners
                        if (jwt.admin && !params.hasOwnProperty('unitId')) {
                            tree = this._router.createUrlTree(['/app/organization/dashboard']);
                        } else if (jwt.unitId) {
                            tree = this._router.createUrlTree([`/app/unit/detail/${jwt.unitId}`]);
                        } else {
                            tree = this._router.createUrlTree(['/app/owner/dashboard']);
                        }
                    } else {
                        // supers
                        tree = this._router.createUrlTree(['/app/organization/dashboard']);
                    }
                    redirectTarget = tree.toString();
                }

                // return url/redirection based on data
                if (isExternalRedirect) {
                    return [
                        `${Routes.GetUiDomain(siteId)}/auth/login;orgId=${jwt.orgId};nonce=${nonce};uri=${encodeURIComponent(redirectTarget)}`,
                        true,
                    ];
                } else {
                    return [
                        `/redirect;uri=${encodeURIComponent(redirectTarget)}`,
                        false,
                    ];
                }
            }));
    }

    public clear() {
        this.orgSubject$.next(null);
    }
}
