import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

export enum AdjustColorValues {
    TEXT = -60,
    ICON = -50,
    BORDER = -35,
}

export const AdjustColorThreshold = 128;

export const AdjustColorConvertToRGB = (color: string) => {
    color = color.replace(/^#/, '').toLocaleLowerCase();
    const rgb = {
        r: parseInt(color.slice(0, 2), 16),
        g: parseInt(color.slice(2, 4), 16),
        b: parseInt(color.slice(4, 6), 16),
    };

    return rgb;
};

export const AdjustColorGetRGBDisplay = (color: string) => {
    const rgb = AdjustColorConvertToRGB(color);
    return 'R: ' + rgb.r + ', G: ' + rgb.g + ', B: ' + rgb.b;
};

@Directive({
    selector: '[adjustColor]',
})
export class AdjustColorDirective implements OnChanges
{
    @Input() adjustColor = '';
    @Input() attribute = 'color';
    @Input() adjustment: number;

    constructor(
            private el: ElementRef,
            private renderer: Renderer2,
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('adjustColor')) {
            this.renderer.setStyle(this.el.nativeElement, this.attribute, this.adjustToColor(this.adjustColor, this.adjustment));
        }
    }

    private adjustToColor(color: string, adjustment: number = 0) {
        if (adjustment === 0) {
            return color;
        }

        const rgb = AdjustColorConvertToRGB(color);

        const isDark = this.getBrightness(rgb) < AdjustColorThreshold;
        if (isDark && adjustment < 0) {
            switch(adjustment) {
                case AdjustColorValues.TEXT:
                    adjustment = Math.ceil(AdjustColorThreshold * 0.65);
                    break;
                case AdjustColorValues.ICON:
                    adjustment = Math.ceil(AdjustColorThreshold * 0.6);
                    break;
                case AdjustColorValues.BORDER:
                    adjustment = Math.ceil(AdjustColorThreshold * 0.4);
                    break;
            }
        }

        rgb.r += adjustment;
        rgb.g += adjustment;
        rgb.b += adjustment;

        rgb.r = Math.min(255, Math.max(0, rgb.r));
        rgb.g = Math.min(255, Math.max(0, rgb.g));
        rgb.b = Math.min(255, Math.max(0, rgb.b));

        return '#' + this.getHexStr(rgb.r) + this.getHexStr(rgb.g) + this.getHexStr(rgb.b);
    }

    private getBrightness(rgb: { r: number; g: number; b: number; }) {
        return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
    }

    private getHexStr(value: number) {
        const str = value.toString(16);
        return str.length === 1 ? '0' + str : str;
    }
}
