import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HostListenerService {
    documentClickedEvent$: Subject<MouseEvent> = new Subject<MouseEvent>();
    windowKeydownEvent$: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
    windowKeyupEvent$: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
    windowScrollEvent$: Subject<void> = new Subject();

    readonly smBreakpoint$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly mdBreakpoint$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly lgBreakpoint$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly xlBreakpoint$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    onMouseClick() {
        return this.documentClickedEvent$.asObservable();
    }

    onKeydown() {
        return this.windowKeydownEvent$.asObservable();
    }

    onKeyup() {
        return this.windowKeyupEvent$.asObservable();
    }

    onScroll() {
        return this.windowScrollEvent$.asObservable();
    }

    isMobileMode() {
        return this.smBreakpoint$.asObservable();
    }

    isTabletMode() {
        return this.mdBreakpoint$.asObservable();
    }

    isDesktopMode() {
        return this.lgBreakpoint$.asObservable();
    }

    isLargerDesktopMode() {
        return this.xlBreakpoint$.asObservable();
    }
}
