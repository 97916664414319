import { User } from 'app/models/entities/user';
import { UploadedFileAssociation } from './uploaded-file-association';

export class UploadedFile
{
    /**
     * Direct Properties
     */
    id: number;
    parentId: number;
    uploadedBy: number;
    directory: boolean;
    fileName: string;
    fileHash: string;
    fileType: string;
    fileSize: number;

    /**
     * Associations
     */
    uploader: User;

    associations: UploadedFileAssociation[] = [];

    /**
     * Front end only
     */
    downloadUrl: string = '';
    viewUrl: string = '';
    errorLoadingUrls: boolean = false;
    icon: string = '';
    isImage: boolean = false;

    constructor(file: any) {
        if (file.file) {
            file = file.file;
        }
        if (file.id) {
            this.id = file.id;
        }
        if (file.parentId) {
            this.parentId = file.parentId;
        }
        if (file.uploadedBy) {
            this.uploadedBy = file.uploadedBy;
        }
        if (file.directory) {
            this.directory = file.directory;
        }
        if (file.fileName) {
            this.fileName = file.fileName;
        }
        if (file.fileHash) {
            this.fileHash = file.fileHash;
        }
        if (file.fileType) {
            this.fileType = file.fileType;
        }
        if (file.fileSize) {
            this.fileSize = file.fileSize;
        }

        if (file.associations) {
            this.associations = file.associations.map((association: any) => new UploadedFileAssociation(association));
        }

        this.downloadUrl = file.downloadUrl || '';
        this.viewUrl = file.viewUrl || '';

        if (
                [
                    'doc',
                    'png',
                    'gif',
                    'jpg',
                    'pdf',
                    'xls',
                    'ppt',
                ].indexOf(
                        file.fileType,
                ) !== -1
        ) {
            this.icon = file.fileType;
            if (
                    [
                        'png',
                        'gif',
                        'jpg',
                    ].indexOf(
                            file.fileType,
                    ) !== -1
            ) {
                this.isImage = true;
            }
        } else if ([
            'docx',
            'odt',
        ].indexOf(file.fileType) !== -1) {
            this.icon = 'doc';
        } else if (['jpeg'].indexOf(file.fileType) !== -1) {
            this.icon = 'jpg';
            this.isImage = true;
        } else if ([
            'csv',
            'xlsx',
            'ods',
        ].indexOf(file.fileType) !== -1) {
            this.icon = 'xls';
        } else if ([
            'pptx',
            'odp',
        ].indexOf(file.fileType) !== -1) {
            this.icon = 'ppt';
        } else {
            this.icon = 'generic';
        }
    }
}
