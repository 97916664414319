import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuperStarComponent } from './super-star.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        SuperStarComponent,
    ],
    declarations: [SuperStarComponent],
})
export class SuperStarModule
{
}
