export class PaginationLinks
{
    first: string;
    last: string;
    prev: string;
    next: string;

    constructor(links: any) {
        this.first = links.first || null;
        this.last = links.last || null;
        this.prev = links.prev || null;
        this.next = links.next || null;
    }
}

export class PaginationMeta
{
    currentPage: number;
    from: number;
    lastPage: number;
    path: string;
    perPage: number;
    to: number;
    total: number;
    original: number;
    included?: {
        [key: string]: any[],
    };

    constructor(meta: any) {
        this.currentPage = meta.current_page || 0;
        this.from = meta.from || 0;
        this.lastPage = meta.last_page || 0;
        this.path = meta.path || null;
        this.perPage = meta.per_page || 0;
        this.to = meta.to || 0;
        this.total = meta.total || 0;
        this.original = meta.original || 0;
        this.included = meta.included || null;
    }
}
