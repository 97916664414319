<app-promo-bar></app-promo-bar>
<app-reminder-1099-bar></app-reminder-1099-bar>
<app-cpatax-banner></app-cpatax-banner>

<app-header-bar [layout]="'app'"
                (bannerShowing)="updateBannerStatus($event)"
                (toggleSidenav)="sidenav.toggle()"></app-header-bar>

<mat-sidenav-container class="payhoa-app-container">
    <mat-sidenav #sidenav="matSidenav"
                 class="payhoa-sidenav"
                 [mode]="isInMobileMode ? 'over' : 'side'"
                 [disableClose]="!isInMobileMode">
        <app-page-menu (toggleSidenav)="handleSidenavClickOnMobile()"></app-page-menu>
        <app-page-advertisement></app-page-advertisement>
    </mat-sidenav>
    <mat-sidenav-content class="payhoa-content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
