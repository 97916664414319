<ng-template #legacyDialog>
    <h3 mat-dialog-title>{{ data.title }}</h3>
    <mat-dialog-content class="small-content">
        <p [innerHTML]="data.content"></p>
        <p *ngIf="data.danger"
           class="text-danger font-weight-bolder"
           [innerHTML]="data.danger"></p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button [class.invisible]="data.hideCancelButton" [attr.aria-label]="'cancel'" (click)="closeAndCancel()" class="btn btn-outline-secondary">
            {{data.cancelButton || 'Cancel'}}
        </button>
        <div class="ml-3">
            <button *ngIf="data.secondaryButton" [attr.aria-label]="'secondary action'" (click)="closeSecondary()" class="btn btn-outline-primary">{{data.secondaryButton}}</button>
            <button [attr.aria-label]="'confirm'" (click)="closeAndConfirm()" class="btn btn-primary">{{data.confirmButton}}</button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-container *ngIf="data.newStyles; else legacyDialog">
    <h3 mat-dialog-title>
        {{ data.title }}
        <button mat-icon-button disableRipple mat-dialog-close><mat-icon>close</mat-icon></button>
    </h3>

    <ng-template #baseContent>
        <p [innerHTML]="data.content"></p>
        <p *ngIf="data.danger" class="error-color" [innerHTML]="data.danger"></p>
    </ng-template>

    <mat-dialog-content>
        <form *ngIf="data.control; else baseContent" [formGroup]="formGroup">
            <ng-container *ngTemplateOutlet="baseContent"></ng-container>
            <div class="form-group">
                <mat-slide-toggle *ngIf="data.control.type === 'toggle'" [formControlName]="data.control.type">
                    {{ data.control.label }}
                </mat-slide-toggle>
            </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-flat-button disableRipple color="accent" [class.hidden]="data.hideCancelButton" (click)="closeAndCancel()">{{ data.cancelButton || 'Cancel' }}</button>
        <button-spacer></button-spacer>
        <button *ngIf="data.secondaryButton" mat-flat-button disableRipple color="accent" (click)="closeSecondary()">{{ data.secondaryButton }}</button>
        <button-spacer *ngIf="data.secondaryButton"></button-spacer>
        <button mat-flat-button disableRipple color="primary" (click)="closeAndConfirm()">{{ data.confirmButton }}</button>
    </mat-dialog-actions>
</ng-container>
