<h3 sidenav-title>
    {{ 'Notifications' }}
    <button mat-icon-button disableRipple
            [attr.aria-label]="'Notifications actions menu'"
            [matMenuTriggerFor]="notificationActionsMenu"
            [disabled]="(saving$ | async) || (notifications$ | async)?.length === 0">
        <mat-icon [svgIcon]="'v2:fi-br-menu-dots'"></mat-icon>
    </button>
    <mat-menu #notificationActionsMenu="matMenu" class="pmc-layout-menu">
        <button mat-menu-item (click)="notificationList.markAsRead([])">
            {{ 'Mark All as Read' }}
        </button>
        <button mat-menu-item (click)="notificationList.deleteNotifications([])">
            {{ 'Delete All Notifications' }}
        </button>
    </mat-menu>
</h3>

<sidenav-content>
    <div class="notifications-layout">
        <app-notifications-list #notificationList
                                [context]="context"
                                (workingStateUpdated)="saving$.next($event)"
                                (notificationsLoaded)="saving$.next(false)"
                                (notificationNavigation)="handleNotificationNavigation($event)">
        </app-notifications-list>
    </div>
</sidenav-content>
