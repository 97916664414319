import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Routes } from '../../config/routes';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { Owner } from '../../models/entities/owner';
import { UnitOccupancy } from '../../models/entities/unit-occupancy';

export interface UnitPreviousOwnersResponse
{
    occupancy: UnitOccupancy;
    owners: Owner[];
}

@Injectable({
    providedIn: 'root',
})
export class OwnersService
{
    private _http: ApplicationHttpClient;

    constructor(_http: ApplicationHttpClient) {
        this._http = _http;
    }

    public changeOwnership(unitId: number, request): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(
                Routes.MakeLegFiCoreUrl(Routes.LegFiCore.UnitOwners(unitId)) + '/change',
                JSON.stringify(request),
        );
    }

    public restoreOwner(ownerId: number): Observable<Owner> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Owner(ownerId) + '/restore'), JSON.stringify({}));
    }

    public deleteOccupancy(occupancyId: number) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.delete(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Occupancy(occupancyId)));
    }

    public updateOccupancy(occupancyId: number, data: any) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.put(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.Occupancy(occupancyId)), JSON.stringify(data));
    }

    public getPreviousOwnersByUnitId(unitId: number): Observable<UnitPreviousOwnersResponse[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.get(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.UnitPreviousOwners(unitId),
                )).pipe(map(
                (response: Object[]) => {
                    return response.map(
                            (occupancy: any) => {
                                return <UnitPreviousOwnersResponse>{
                                    occupancy: new UnitOccupancy(occupancy.occupancy),
                                    owners: occupancy.owners.map(o => new Owner(o)),
                                };
                            },
                    );
                },
        ));
    }

    /**
     * Get the owners of one unit
     * @returns {Observable<Owners>}
     */
    public getOwnersByUnitId(unitId: number): Observable<Owner[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.get(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.UnitOwners(unitId),
                )).pipe(map(
                (response: Object[]) => {
                    return response.map(
                            (owner) => {
                                return new Owner(owner);
                            },
                    );
                },
        ));
    }

    /**
     * Get unique owners from set of one or more unit ids.
     *
     * @param unitIds
     */
    public getOwnersByUnitIds(unitIds: number[]): Observable<Owner[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.Owners + '/by-units',
                ),
                JSON.stringify({unitIds: unitIds}),
        ).pipe(map(
                (response: Object[]) => {
                    return response.map(
                            (owner) => {
                                return new Owner(owner);
                            },
                    );
                },
        ));
    }


    /**
     * @param {Object} requestBody
     * @param {Number} organizationId
     * @returns {Observable<Object>}
     */
    public createOwners(requestBody: object, organizationId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.Organization(jwt.orgId || organizationId) + '/units/owner',
                ),
                JSON.stringify(requestBody),
        );
    }
}
