import moment from 'moment-timezone';
import { FormSubmissionStatus } from './form-submission-status';
import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';
import { FormSubmission } from './form-submission';
import { GlobalDonutGraphOptions } from '../../../services/communication/graphing.service';
import { FormSubmissionStates } from '../../../components/app-layout/forms/form-detail/form-submission-table-filters/form-submission-table-filters.component';
import { FormQuestion } from './form-question';

export interface ChartCanvasProperties
{
    count: number;
    dataset: {
        data: number[];
        backgroundColor: string[];
        borderColor: string[];
        hoverBackgroundColor: string[];
        hoverBorderColor: string[];
    };
    labels: string[];
    options: any;
}

export class OrganizationForm
{
    id: number;
    organizationId: number;
    membershipId: number;

    name: string;
    description: string;
    fileDescription: string;
    orgFileId: number;
    iconImage: string;

    isEnabled: boolean;
    isPrivate: boolean;

    questions: FormQuestion[];
    statuses: FormSubmissionStatus[];
    recentSubmission: FormSubmission;

    approversCount: number;
    openSubmissionsCount: number;
    closedSubmissionsCount: number;
    submissionsCount: number;

    createdAt: moment.Moment;

    canUserRead: boolean;
    canUserWrite: boolean;
    canUserWriteStatuses: boolean;
    isUserApprover: boolean;

    /** whether they want approvers to receive emails on create of form submission or not. */
    hasManualApprovalNotifications = false;
    /** model/class tying to db models; just 'inquiry' for now, may scale later */
    category: 'inquiry';

    /** hold information to generate chart on dashboard */
    chart?: ChartCanvasProperties;

    // Default chart options for form charts
    readonly donutChartOptions = GlobalDonutGraphOptions;
    readonly disabledChartOptions = {
        ...GlobalDonutGraphOptions,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: false,
        },
    };

    constructor(request: any) {
        this.id = request.id || 0;
        this.organizationId = request.organizationId || 0;
        this.membershipId = request.membershipId || 0;

        this.name = request.name || '';
        this.description = request.description || '';
        this.fileDescription = request.fileDescription || '';
        this.orgFileId = request.orgFileId || null;
        this.iconImage = request.iconImage || '';

        this.isEnabled = request.isEnabled === 1 || false;
        this.isPrivate = request.isPrivate === 1 || false;

        this.approversCount = request.approversCount || 0;
        this.openSubmissionsCount = request.openSubmissionsCount || 0;
        this.closedSubmissionsCount = request.closedSubmissionsCount || 0;
        this.submissionsCount = request.submissionsCount || 0;

        const timezone = LegFiJwtService.getTimezone();

        if (request.questions) {
            this.questions = request.questions.map((question: any) => new FormQuestion(question));
        }

        if (request.statuses) {
            this.statuses = request.statuses.map((sub: any) => new FormSubmissionStatus(sub));
        }

        if (request.recentSubmission) {
            this.recentSubmission = new FormSubmission(request.recentSubmission);
        }

        this.createdAt = moment.utc(request.createdAt).tz(timezone);

        this.hasManualApprovalNotifications = request.hasManualApprovalNotifications;
        this.category = request.category || '';

        // user access
        this.canUserRead = request.canUserRead;
        this.canUserWrite = request.canUserWrite;
        this.canUserWriteStatuses = request.canUserWriteStatuses;
        this.isUserApprover = request.isUserApprover;
    }


    // Chart canvas properties
    buildChart(state: FormSubmissionStates = FormSubmissionStates.OPEN) {
        const chart: ChartCanvasProperties = {
            count: 0,
            dataset: {
                data: [],
                backgroundColor: [],
                hoverBackgroundColor: [],
                borderColor: [],
                hoverBorderColor: [],
            },
            labels: [],
            options: this.isEnabled ? this.donutChartOptions : this.disabledChartOptions,
        };

        switch (state) {
            case FormSubmissionStates.OPEN:
                chart.count = this.openSubmissionsCount;
                break;
                // for now, if not filtered to open submissions, show all (including closed)
            default:
                chart.count = this.submissionsCount;
                break;
        }

        let chartData: {
            label: string;
            count: number;
            color: string;
            bgColor: string;
        }[] = [];

        if (this.statuses.length === 0) {
            // if the only status is "No Status", then revert to open/closed submissions ui
            chartData.push({
                label: 'Open',
                count: this.openSubmissionsCount,
                color: this.isEnabled ? '#EB9658' : '#E3E8ED',
                bgColor: this.isEnabled ? '#EB965890' : '#E3E8ED90',
            });

            if (state !== FormSubmissionStates.OPEN) {
                chartData.push({
                    label: 'Closed',
                    count: this.closedSubmissionsCount,
                    color: this.isEnabled ? '#53C1A9' : '#CED4DA',
                    bgColor: this.isEnabled ? '#53C1A990' : '#CED4DA90',
                });
            }
        } else {
            // determine/append "No Status" if needed
            const noStatus = {
                id: -1,
                title: 'No Status',
                color: '#CED4DA',
                openSubmissionsCount: this.openSubmissionsCount,
                closedSubmissionsCount: this.closedSubmissionsCount,
                submissionsCount: this.submissionsCount,
            };
            for (const status of this.statuses) {
                noStatus.openSubmissionsCount -= status.openSubmissionsCount;
                noStatus.closedSubmissionsCount -= status.closedSubmissionsCount;
                noStatus.submissionsCount -= status.submissionsCount;
            }

            // use copy of instance's custom statuses for chartData
            let statuses: FormSubmissionStatus[];
            if ((state === FormSubmissionStates.OPEN && noStatus.openSubmissionsCount > 0) || (state === FormSubmissionStates.ALL && noStatus.submissionsCount > 0)) {
                statuses = [
                    new FormSubmissionStatus(noStatus),
                    ...this.statuses,
                ];
            } else {
                statuses = [...this.statuses];
            }

            switch (state) {
                case FormSubmissionStates.OPEN:
                    chart.count = this.openSubmissionsCount;
                    break;
                    // for now, if not filtered to open submissions, show all (including closed)
                default:
                    chart.count = this.submissionsCount;
                    break;
            }

            // sort statuses highest to lowest count
            statuses.sort((a, b) => {
                return state === FormSubmissionStates.OPEN
                        ? b.openSubmissionsCount - a.openSubmissionsCount
                        : b.submissionsCount - a.submissionsCount;
            });

            const threshold = 4;
            const data = statuses.length > threshold ? statuses.slice(0, threshold) : statuses;
            chartData = data.map((item, index) => {
                let color = item.color;
                let bgColor = item.bgColor;
                if (!this.isEnabled) {
                    // still hold "some" gradation even when disabled for custom statuses displayed on chart
                    const append = Math.floor(((index + 1) / statuses.length) * 100) - 1;
                    color = bgColor = '#CED4DA' + append;
                }
                return {
                    label: item.title,
                    count: state === FormSubmissionStates.OPEN ? item.openSubmissionsCount : item.submissionsCount,
                    color,
                    bgColor,
                };
            });

            if (statuses.length > threshold) {
                // introduce "Other" placeholder to keep legend to 5 items
                const combinedOther = statuses.slice(threshold);
                chartData.push({
                    label: 'Other',
                    count: combinedOther.map((status) => {
                        return state === FormSubmissionStates.OPEN
                                ? status.openSubmissionsCount
                                : status.submissionsCount;
                    }).reduce((a, b) => a + b, 0),
                    color: this.isEnabled ? '#000000' : '#E3E8ED',
                    bgColor: this.isEnabled ? '#00000090' : '#E3E8ED90',
                });
            }
        }

        for (const item of chartData) {
            chart.dataset.data.push(item.count);
            chart.dataset.backgroundColor.push(item.color);
            chart.dataset.hoverBackgroundColor.push(item.bgColor);
            chart.dataset.borderColor.push('#FFFFFF');
            chart.dataset.hoverBorderColor.push('#FFFFFF');
            chart.labels.push(item.label);
        }

        this.chart = chart;
    }
}
